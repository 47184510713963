import React, { ReactElement, ReactNode } from 'react';
import { SpotSvg } from '@enterprise/spot';
import { Col, Row, Container } from 'react-bootstrap';
import classNames from 'classnames';
import DropdownItem from './DropdownItem';

interface PageProps {
    children: ReactNode | DropdownItem[];
    title: string | ReactNode;
    value?: any;
    isSearchBarDropdown?: boolean;
    isShowContentOnly?: boolean;
    contentWidth?: string;
    contentHeight?: string;
    isAlignContentLeft?: boolean;
    isSelfControlled?: boolean;
    isMultiSelect?: boolean;
    isSameValueSelectionClearsValue?: boolean;
    width?: number;
    onChanged: any;
    style?: any;
    contentStyle?: any;
}

interface PageState {
    isOpen: boolean;
}

class Dropdown extends React.Component<PageProps, PageState> {
    static defaultProps = {
        title: 'Choose',
        children: [],
        isSearchBarDropdown: false,
        isShowContentOnly: false,
        value: null,
        contentWidth: '212px',
        contentHeight: undefined,
        isAlignContentLeft: true,
        isSelfControlled: false,
        isMultiSelect: false,
        isSameValueSelectionClearsValue: true,
        isCanUnselectValue: true,
        width: undefined,
        onChanged: () => {},
    };

    state = {
        isOpen: false,
    };

    wrapperRef = null;

    label = null;

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (this.props.value !== newProps.value) {
            this.label = null;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    setValue(itemValue, itemLabel) {
        this.setLabel(itemLabel);

        let newValue: any = this.props.isSameValueSelectionClearsValue ? null : itemValue;

        if (itemValue !== undefined) {
            if (this.props.isMultiSelect) {
                newValue = this.props.value || [];

                if (newValue.includes(itemValue)) {
                    newValue = newValue.filter((v) => v !== itemValue);
                    newValue = newValue.length > 0 ? newValue : null;
                } else {
                    newValue.push(itemValue);
                }
            } else if (this.props.value !== itemValue) {
                newValue = itemValue;
            }
        }

        this.props.onChanged(newValue);
    }

    setLabel = (itemLabel) => {
        if (this.props.isMultiSelect && this.props.value) {
            this.label = this.props.value.length > 1 ? `${this.props.title} (${this.props.value.length})` : itemLabel;
        } else if (itemLabel) {
            this.label = itemLabel;
        }
    };

    open() {
        this.setState({ isOpen: true });
    }

    close() {
        this.setState({ isOpen: false });
    }

    toggle() {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    }

    handleClickOutside = (event) => {
        const { isOpen } = this.state;
        // @ts-ignore
        if (isOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    };

    handleItemClick = (itemValue, itemLabel) => {
        if (this.props.isSelfControlled) {
            return;
        }
        this.setValue(itemValue, itemLabel);
        this.handleToggleClick();
    };

    handleToggleClick = () => {
        this.toggle();
    };

    renderOptions() {
        const { children, isSelfControlled } = this.props;
        const items = React.Children.toArray(children) as (DropdownItem & ReactElement)[];

        return items.map((child) => {
            const childValue = child.value || (child.props && child.props.value);
            const childLabel = child.label || (child.props && child.props.label);
            const isDivider = child.props && child.props.isDivider;
            const isHeading = child.props && child.props.isHeading;

            const isItemSelected = this.isItemSelected(childValue);
            if (isItemSelected) {
                if (this.label !== childLabel) {
                    this.setLabel(childLabel);
                    setTimeout(() => {
                        this.forceUpdate();
                    });
                }
            }
            const styles = {
                cursor: isSelfControlled ? 'default' : '',
            };

            const onClick = () => !isHeading && this.handleItemClick(childValue, childLabel);
            const contentStyle = {
                padding: '0px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingLeft: '5px',
            };

            return (
                <li
                    style={styles}
                    key={child.key}
                    className={classNames({
                        'spot-dropdown__content-item': !isDivider,
                        'spot-dropdown__content--divider': isDivider,
                        'dropdown-item-active': isItemSelected,
                        'spot-dropdown__content-item--heading': isHeading,
                    })}
                    onClick={onClick}
                >
                    <Container fluid={true} style={{ padding: '0px' }}>
                        <Row style={{ margin: '0px' }}>
                            {isItemSelected && (
                                <Col xs={1} style={{ padding: '0px' }}>
                                    <SpotSvg icon={'checkmark'} className="dropdown-item-active-icon" />
                                </Col>
                            )}
                            <Col xs={isItemSelected ? 11 : 12} style={contentStyle}>
                                {{ ...child }}
                            </Col>
                        </Row>
                    </Container>
                </li>
            );
        });
    }

    isItemSelected = (itemValue) => {
        if (this.props.isMultiSelect) {
            return this.props.value ? this.props.value.includes(itemValue) : false;
        }

        return this.props.value === itemValue;
    };

    render() {
        const { isSearchBarDropdown, title, contentWidth, contentHeight, isAlignContentLeft } = this.props;
        const { isOpen } = this.state;
        const options = this.renderOptions();
        const style: any = { ...{ display: 'inline-block' }, ...this.props.style };

        return (
            <div className={classNames('spot-dropdown', { 'dropdown-search-bar': isSearchBarDropdown })} ref={this.setWrapperRef} style={style}>
                {isSearchBarDropdown && !this.props.isShowContentOnly && this.renderSearchBarButton()}

                {!isSearchBarDropdown && !this.props.isShowContentOnly && (
                    <button
                        className="spot-button spot-button--secondary spot-button--with-icon"
                        onClick={this.handleToggleClick}
                        data-automation-id={this.props['data-automation-id']}
                    >
                        <span className="spot-button__text">{this.label || title}</span>
                        <SpotSvg icon={`caret-${isOpen ? 'up' : 'down'}`} className="spot-icon spot-button__icon spot-button__icon--right" />
                    </button>
                )}

                <ul
                    className="spot-dropdown__content"
                    style={{
                        display: isOpen ? 'block' : 'none',
                        minHeight: contentHeight,
                        minWidth: contentWidth,
                        left: isAlignContentLeft ? 0 : undefined,
                        ...this.props.contentStyle,
                    }}
                >
                    {options}
                </ul>
            </div>
        );
    }

    renderSearchBarButton = () => {
        const { title } = this.props;
        const { isOpen } = this.state;
        const contentStyle = {
            padding: '0px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            paddingLeft: 0,
        };

        return (
            <div className="button" onClick={this.handleToggleClick} data-automation-id={this.props['data-automation-id']}>
                <Container fluid={true} style={{ padding: '0px' }}>
                    <Row style={{ margin: '0px' }}>
                        <Col xs={11} style={contentStyle}>
                            <span className="text">{this.label || title}</span>
                        </Col>
                        <Col xs={1} style={{ padding: '0px' }}>
                            <SpotSvg icon={`caret-${isOpen ? 'up' : 'down'}`} className="icon" />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
}

export default Dropdown;
