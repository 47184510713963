import { MANAGEABLE_PIMS } from '../../environment/environment';
import { SiteEntity } from '../entity/SiteEntity';

export function applyApplicationFilters<T = SiteEntity>(applications: T[], filters: ((applications: T[]) => T[])[]) {
    return filters.reduce((apps, filter) => filter(apps), applications);
}

export function filterReadOnlyApplications<T extends SiteEntity>(applications: T[]): T[] {
    return applications.filter((app) => app.pimsName && !MANAGEABLE_PIMS.includes(app.pimsName.toLowerCase()));
}

export function filterManageableApplications<T extends SiteEntity>(applications: T[]): T[] {
    return applications.filter((app) => app.pimsName && MANAGEABLE_PIMS.includes(app.pimsName.toLowerCase()));
}

export function filterActiveApplications<T extends SiteEntity>(applications: T[]): T[] {
    return applications.filter((app) => app.isActive);
}
