import { CardModel } from './Card.model';

export enum COLUMN {
    PRIMARY = 'PRIMARY',
}

export class ColumnModel {
    data!: CardModel[];
    id!: string;
    primary?: boolean;
    stackable?: boolean;
    title!: string;
    uniqueCharacters?: string[];
}
