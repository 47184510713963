import * as React from 'react';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { Col, Row } from 'react-bootstrap';
import classNames from 'classnames';
import i18n from '../../../i18n';
import { memo } from 'react';
import { TaskErrorMessage } from '../../../core/entity/TaskEntity';

interface ItemUpdateProps {
    id: string;
    description: string;
    status: TaskStatus;
    details?: TaskErrorMessage[];
    className?: string;
}

export const ItemUpdateChildRow = memo(function ItemUpdateChildRow(props: ItemUpdateProps) {
    const { id, description, status, details, className } = props;

    let statusText: string = '';
    let statusClassName: string = '';

    if (status === TaskStatus.PENDING) {
        statusText = i18n.t('activityPage:pending', 'Pending');
        statusClassName = 'spot-pill--warning';
    } else if (status === TaskStatus.QUEUED) {
        statusText = i18n.t('activityPage:queued', 'Queued');
        statusClassName = 'spot-pill--warning';
    } else if (status === TaskStatus.RUNNING) {
        statusText = i18n.t('activityPage:running', 'Running');
        statusClassName = 'spot-pill--warning';
    } else if (status === TaskStatus.SUCCESS) {
        statusText = i18n.t('activityPage:success', 'Success');
        statusClassName = 'spot-pill--positive';
    } else if (status === TaskStatus.ERROR) {
        statusText = i18n.t('activityPage:error', 'Error');
        statusClassName = 'spot-pill--negative';
    } else if (status === TaskStatus.CANCEL) {
        statusText = i18n.t('activityPage:canceled', 'Canceled');
        statusClassName = 'spot-pill--secondary';
    }

    return (
        <Row key={id} className={className}>
            <Col xs={3} className="spot-typography__text--body">
                <span data-automation-id={'activity-item-details-name'}>{description}</span>
            </Col>
            <Col xs={3} className="spot-typography__text--body" style={{ textAlign: 'center' }}>
                <span data-automation-id={'activity-item-site-status'} className={classNames(statusClassName, 'spot-pill', 'status-pill')}>
                    {statusText}
                </span>
            </Col>
            <Col xs={6} className="spot-typography__text--body" data-automation-id={'activity-item-site-error-details'}>
                {status === TaskStatus.ERROR &&
                    details?.map((item, index) => {
                        return Object.keys(item).map((key) => {
                            if (key?.toLowerCase() === 'status' || key?.toLowerCase() === 'provider') {
                                return;
                            }

                            return (
                                <div key={`${key}_${index}`}>
                                    <span className="spot-typography__font-weight--bold">{key}</span>: {JSON.stringify(item[key])}
                                </div>
                            );
                        });
                    })}
            </Col>
        </Row>
    );
});
