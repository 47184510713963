import { ItemData, LinkedItem, MappedItem } from '../entity/mapping';
import { CardEvent, CardModel, ColumnModel, INTENT } from '@idexx/cfs-mapping-ui';
import { SiteEntity } from '../entity/SiteEntity';
import i18n from 'i18next';
import { MappingTypes } from '../enums/Mapping';
import { SiteMapping } from '../entity/mapping/SiteMapping';
import { MappingData } from '../entity/mapping/MappingData';

export function linkedItemFromEvent(event: CardEvent, type: MappingTypes) {
    const { item, parentId, sourceColumn } = event;
    if (!sourceColumn) {
        throw 'Missing source column';
    }
    const itemData = new ItemData({
        description: item.description || null,
        siteId: sourceColumn,
    });

    return new LinkedItem({
        data: itemData,
        isApproved: true,
        linkedId: item.id,
        practiceId: sourceColumn,
        pimsType: type,
        pimsParentId: parentId,
    });
}

export function mappedItemFromEvent(event: CardEvent, type: MappingTypes) {
    const linkedItem = linkedItemFromEvent(event, type);
    const { item } = event;
    return new MappedItem({
        data: { description: item.description || null },
        id: event.item.id,
        mapped: [linkedItem],
        parentId: event.targetParentId || null,
        type,
    });
}

export function removeItemsWithoutParent(itemSets: MappedItem[][]): MappedItem[][] {
    return itemSets.map(set => set.filter(item => item.parentId === undefined));
}

export function responseToPrimaryColumn(response: MappedItem[]): ColumnModel {
    return {
        data: mappedItemsToCardModelList(response, INTENT.PRIMARY),
        id: 'master',
        primary: true,
        title: i18n.t('masterData:categories.masterClassifications', 'Master Classifications'),
    };
}

export function responseToStackableColumns(response: SiteMapping[], siteEntities: SiteEntity[]): ColumnModel[] {
    return response.map((siteMapping: SiteMapping) => {
        const siteDetails = siteEntities.find(site => String(site.id) === String(siteMapping.siteId));
        return {
            data: mappingDataToCardModelList(siteMapping.data),
            id: siteMapping.siteId ? siteMapping.siteId.toString() : '',
            stackable: true,
            title: siteDetails ? siteDetails.name : siteMapping.siteId || '',
        };
    });
}

function mappedItemsToCardModelList(mappedItemResponse: MappedItem[], intent: INTENT = INTENT.DEFAULT): CardModel[] {
    const parentCards = mappedItemResponse.filter(card => card.parentId === null);
    const childCards = mappedItemResponse.filter(card => card.parentId !== null);

    childCards.forEach(child => {
        const parentCard = parentCards.find(({ id }) => id === child.parentId);
        if (parentCard) {
            const { id, title, description } = getCommonCardModelData(child);
            parentCard.children = parentCard.children || [];
            parentCard.children.push({
                id,
                title,
                description,
                intent,
                parentId: child.parentId,
                children: child.children || [],
            });
        }
    });

    return parentCards.map(chunk => {
        const { id, title, description } = getCommonCardModelData(chunk);
        return {
            id,
            title,
            description,
            intent,
            parentId: chunk.parentId || undefined,
            children: chunk.children || [],
        };
    });
}

function mappingDataToCardModelList(mappedItemResponse: MappingData[], intent: INTENT = INTENT.DEFAULT, parentId?: string): CardModel[] {
    const cards: CardModel[] = [];
    mappedItemResponse.forEach((chunk: MappingData) => {
        const children = chunk.children || [];
        const isMappedAndDisabled = !!(chunk.isMapped && children.find(({ isMapped }) => isMapped === false));
        if (!chunk.isMapped || isMappedAndDisabled) {
            const { id, title, description } = getCommonCardModelData(chunk);
            cards.push({
                id,
                title,
                description,
                intent,
                parentId,
                children: mappingDataToCardModelList(children, intent, chunk.id) || [],
                isDisabled: isMappedAndDisabled,
            });
        }
    });
    return cards;
}

function getCommonCardModelData(source: MappingData | MappedItem) {
    const { id, data = { name: '', description: '' } } = source;
    const title = data.name || data.description || id || '';
    const description = data.description || '';

    return {
        id,
        title,
        description,
    };
}
