import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { LookupItemTypes } from '../../../../core/models/datasource/lookups/parts';
import { InvoiceItemTypes } from '../../../../core/models/datasource/invoiceItems/enums';

const disabledItemTypes = new Set([InvoiceItemTypes.Group, InvoiceItemTypes.Pick, InvoiceItemTypes.Dispensing]);

interface PageProps {
    applications: any[];
    itemTypes: LookupItemTypes[];
    currentFilter: any;
    updateFilter: (v) => void;
    savePreset: () => void;
    enhancedSearch: boolean;
}

class ItemFilters extends React.Component<PageProps> {
    static defaultProps = {
        applications: [],
        itemTypes: [],
    };

    render() {
        const { applications, itemTypes, currentFilter, updateFilter, enhancedSearch = false } = this.props;
        const updateSiteId = (siteId) => updateFilter({ siteId });
        const updateType = (type) => updateFilter({ type });

        return (
            <div style={{ marginBottom: '10px' }}>
                <div style={{ padding: '10px 0' }}>
                    <Row>
                        <Col xs={10}>
                            <div style={{ display: 'inner-block' }}>
                                <Dropdown
                                    title={i18n.t('itemFilters:site', 'Site')}
                                    style={{ marginRight: '15px' }}
                                    value={currentFilter.siteId}
                                    data-automation-id={'site-filter-dropdown'}
                                    onChanged={updateSiteId}
                                >
                                    <div key="01" data-automation-id={'site-filter-option'}>
                                        <Trans i18nKey="itemFilters:allSites">All Sites</Trans>
                                    </div>
                                    {Boolean(applications.length) && <DropdownItem key={'divider'} isDivider={true} />}
                                    {applications.map((app) => (
                                        <DropdownItem label={app.name} value={app.id} key={app.id.toString()}>
                                            <span data-automation-id={'site-filter-option'}>{app.name}</span>
                                        </DropdownItem>
                                    ))}
                                </Dropdown>

                                <Dropdown
                                    title={i18n.t('itemFilters:type', 'Type')}
                                    value={currentFilter.type}
                                    data-automation-id={'type-filter-dropdown'}
                                    onChanged={updateType}
                                >
                                    <div data-automation-id={'type-filter-option'} key="01">
                                        <Trans i18nKey="itemFilters:allTypes">All Types</Trans>
                                    </div>
                                    {Boolean(applications.length) && <DropdownItem key={'divider'} isDivider={true} />}
                                    {itemTypes
                                        .filter((item) => !disabledItemTypes.has(item.id))
                                        .map((item) => (
                                            <DropdownItem label={item.description} value={enhancedSearch ? item.description : item.id} key={item.id}>
                                                <span data-automation-id={'type-filter-option'}>{item.description}</span>
                                            </DropdownItem>
                                        ))}
                                </Dropdown>
                            </div>
                        </Col>
                        <Col xs={2}>
                            <a href="#" className="spot-link" style={{ float: 'right' }} onClick={this.props.savePreset}>
                                <Trans i18nKey="itemFilters:saveSearch">Save Search</Trans>
                            </a>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}

export default ItemFilters;
