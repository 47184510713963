import * as React from 'react';
import { SpotCollapsibleList, SpotPill, SpotSvg, SpotToggle } from '@enterprise/spot';
import { Container, Col, Row, Card } from 'react-bootstrap';
import { SiteEntity } from '../../core/entity/SiteEntity';
import { NavLink } from 'react-router-dom';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import classNames from 'classnames';
import { head } from 'lodash';

interface ApplicationInfoCardProps {
    app: SiteEntity;
    applicationDetailsRoute: string | null;
    listPracticeNames: boolean;
    isManageable: boolean;
    toggleApplicationActive: ((app: SiteEntity) => void) | null;
    isPending?: boolean;
}

interface ApplicationInfoCardState {
    expandedAppIds: Set<string>;
}

export class ApplicationInfoCard extends React.Component<ApplicationInfoCardProps, ApplicationInfoCardState> {
    app: SiteEntity;

    applicationDetailsRoute: string | null;

    listPracticeNames: boolean;

    isManageable: boolean;

    toggleApplicationActive: (app: SiteEntity) => void;

    state = {
        expandedAppIds: new Set<string>(),
    };

    constructor(props) {
        super(props);
        this.app = props.app;
        this.applicationDetailsRoute = props.applicationDetailsRoute;
        this.listPracticeNames = props.listPracticeNames;
        this.isManageable = props.isManageable;
        this.toggleApplicationActive = props.toggleApplicationActive;
    }

    render() {
        const { isPending = false } = this.props;
        const labelName = head(this.app.labels)?.name;
        return (
            <Card className={classNames('practice-card-component', { 'pending-practice-card-component': isPending })}>
                <Card.Body>
                    <Container>
                        <Row>
                            <Col xs={4} className="spot-typography__text--body spot-typography__font-weight--bold">
                                <div className={classNames('practice-info', { 'pending-practice-info': isPending })}>
                                    {this.showAppStatus(isPending)}
                                    {this.showPracticeName(isPending)}
                                </div>
                                <p className="spot-typography__text--tertiary">{this.app.pimsName}</p>
                            </Col>
                            <Col xs={2} className="spot-typography__text--body">
                                {!isPending && this.app.zip}
                            </Col>
                            <Col xs={3} className="spot-typography__text--body">
                                {!isPending && this.showAppPracticesCount()}
                            </Col>
                            {!isPending && (
                                <>
                                    <Col xs={2} className="spot-typography__text--body">
                                        {labelName && <SpotPill>{labelName}</SpotPill>}
                                    </Col>
                                    <Col xs={1} className="spot-typography__text--body">
                                        {this.showAppActiveSwitch()}
                                    </Col>
                                </>
                            )}
                            {isPending && (
                                <Col xs={3} className="spot-typography__text--body">
                                    <SpotPill color="#eeba22">{i18n.t('myOrganization:connectionPending', 'Connection Pending')}</SpotPill>
                                </Col>
                            )}
                        </Row>
                        {this.showPractices()}
                    </Container>
                </Card.Body>
            </Card>
        );
    }

    private showAppStatus = (isPending: boolean) => {
        let status = 'error';

        if (isPending) {
            status = 'pending';
        } else if (this.app.isAgentWorking && this.app.isDbWorking) {
            status = 'success';
        } else if (this.app.isAgentWorking && !this.app.isDbWorking) {
            status = 'databaseDown';
        }

        return <span className={`app-status ${status}`} />;
    };

    private showAppPracticesCount = () => {
        if (!this.app.practices || this.app.practices.length === 0) {
            return null;
        }

        if (this.listPracticeNames) {
            const isAppExpanded = this.state.expandedAppIds.has(this.app.id);
            const iconName = isAppExpanded ? 'collapse' : 'expand';
            if (isAppExpanded) {
                this.state.expandedAppIds.delete(this.app.id);
            } else {
                this.state.expandedAppIds.add(this.app.id);
            }
            const onClick = () => this.setState({ expandedAppIds: new Set<string>(this.state.expandedAppIds) });
            return (
                <div>
                    <SpotSvg onClick={onClick} icon={iconName} className="spot-link__icon" />
                    <span>
                        <Trans i18nKey="common:practices.practiceCount" count={this.app.practices.length}>
                            {this.app.practices.length} practices
                        </Trans>
                    </span>
                </div>
            );
        } else {
            return <span>{this.app.practices.length} practices</span>;
        }
    };

    private showPracticeName = (isPending: boolean) => {
        const practiceName = (
            <>
                {this.app.isMaster && '*'}
                {this.app.name}
            </>
        );

        if (!isPending && this.applicationDetailsRoute) {
            return (
                <NavLink to={`${this.applicationDetailsRoute}`} className={'spot-link'}>
                    {practiceName}
                </NavLink>
            );
        } else {
            return practiceName;
        }
    };

    private showAppActiveSwitch = () => {
        if (this.isManageable && this.toggleApplicationActive) {
            const onChange = () => this.toggleApplicationActive(this.app);
            return (
                <SpotToggle checked={this.app.isActive} onChange={onChange} automationId={`toggle-active_${this.props.app.name.replace(/ /g, '')}`} />
            );
        }
        return null;
    };

    private showPractices = () => {
        if (!this.state.expandedAppIds.has(this.app.id) || !this.app.practices) {
            return undefined;
        }

        return (
            <Container>
                <Row>
                    <Col xs={12}>
                        <div className="app-practices-header">
                            <span className="spot-typography__text--secondary">
                                <Trans i18nKey="common:practices.connectedPracticeCount" count={this.app.practices.length}>
                                    {this.app.practices.length} Practices connected to this database
                                </Trans>
                            </span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <SpotCollapsibleList isOpen={true} elements={this.app.practices} />
                    </Col>
                </Row>
            </Container>
        );
    };
}
