import './index.scss';
import 'reflect-metadata'; // DO NOT REMOVE THIS IMPORT
import React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './App';
import { boot_di } from './services/ioc';
import { RootStore } from './store/RootStore';
import { unregister } from './registerServiceWorker';
import './i18n';
import { environment, TAG_MANAGER_CONFIG } from './environment/environment';
import { tagManagerInit } from '@enterprise/shared';
import { StoresContext } from './contexts';
import { registerStores } from './pages/stores';
import ioc from './services/ioc';
import { Auth0Provider } from '@auth0/auth0-react';
import { RouterPaths } from './router/RouterPaths';
import { StyledEngineProvider } from '@mui/material/styles';

boot_di();
setTimeout(() => {
    const { AUTH0 } = environment;
    const redirectUrl = `${window.location.origin}${RouterPaths.BasePages.Login}`;

    registerStores();
    tagManagerInit(TAG_MANAGER_CONFIG);
    const rootStore = ioc.get(RootStore);

    // TODO: remove this when update to React 18 or higher
    // eslint-disable-next-line react/no-deprecated
    ReactDOM.render(
        <StoresContext.Provider value={rootStore}>
            <Auth0Provider
                domain={AUTH0.domain}
                clientId={AUTH0.clientId}
                scope={AUTH0.scope}
                audience={AUTH0.audience}
                redirectUri={redirectUrl}
                useRefreshTokens={true}
                cacheLocation={AUTH0.cacheLocation}
                cache={AUTH0.cache}
            >
                <StyledEngineProvider injectFirst>
                    <App />
                </StyledEngineProvider>
            </Auth0Provider>
        </StoresContext.Provider>,
        document.getElementById('root'),
    );

    unregister();
});
