import React, { ForwardedRef, forwardRef, memo, PropsWithChildren } from 'react';
import styles from './pageFooter.module.scss';
import classNames from 'classnames';

export type FooterProps = PropsWithChildren<{
    className?: string;
}>;

const PageFooterRefComponent = forwardRef<HTMLDivElement, FooterProps>(function PageFooter(props: FooterProps, ref: ForwardedRef<HTMLDivElement>) {
    const { className, children } = props;

    return (
        <div ref={ref} className={classNames(styles.holder, className)}>
            {children}
        </div>
    );
});

export const PageFooter = memo(PageFooterRefComponent);
