import { ValidationArguments, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator';

@ValidatorConstraint()
export class RegexpValidator implements ValidatorConstraintInterface {
    validate(text: string, validationArguments: ValidationArguments) {
        return new RegExp(validationArguments.constraints[0]).test(text);
    }

    defaultMessage?(validationArguments?: ValidationArguments): string {
        return validationArguments ? `${validationArguments.property} is invalid` : 'Invalid Field';
    }
}
