import { ColumnEvent } from './ColumnEvent';

export class ActionItemEvent extends ColumnEvent {
    id: string;

    constructor(data: ActionItemEvent) {
        super(data);
        const { id } = data;
        this.id = id;
    }
}
