import { Service } from 'typedi';
import { autorun, makeAutoObservable } from 'mobx';
import { AnonymousUserToken, BaseUserToken, Feature, IEnterpriseUser, Profile, Signup, UserService } from '@enterprise/common';
import { Notifications } from '../pages/Notifications';
import { FeatureStore } from './FeatureStore';
import { FEATURES, Services } from '@enterprise/core';
import { WebsocketManagerStore } from '../websockets/websocket.manager';
import { isEqual, assign } from 'lodash';
import { RootStore } from './RootStore';
import { RouterPaths } from '../router/RouterPaths';
import { controlCenterSet, managementNavigationSet, menu, NavigationMenuItem, PageNavigationItem } from '../router';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { environment } from '../environment/environment';
import { InvoiceItemsState } from './invoiceItem.store';
import { TasksItemsState } from '../pages/controlCenter/activity/ActivityPageStore';

const STATE_KEY_NAME = 'EnterpriseUserState';

export enum SubMenuType {
    OrganizationManagement = 'orgManagement',
    ControlCenter = 'controlCenter',
}

export interface UserState {
    invoiceItems?: InvoiceItemsState;
    tasks?: TasksItemsState;
}

@Service()
export default class AppStore {
    private _isLoaded?: boolean;
    private _authClient?: Auth0ContextInterface;

    title = `IDEXX Enterprise Client`;

    featureStore: FeatureStore;

    token: BaseUserToken<IEnterpriseUser> = new AnonymousUserToken();

    authorizing = false;

    userIsSaving = false;

    websocketManager: WebsocketManagerStore;

    private _userState: UserState;

    constructor(
        private readonly userService: UserService<BaseUserToken<IEnterpriseUser>>,
        public featureManager: Services.FeatureManager,
        private readonly rootStore: RootStore,
    ) {
        makeAutoObservable(this);
        this.websocketManager = new WebsocketManagerStore(this);

        autorun(() => {
            try {
                const state = window.localStorage.getItem(`${STATE_KEY_NAME}_${this.token.user.id}`);
                this._userState = (state && JSON.parse(state)) || {};
            } catch (error) {
                console.error(`Error on reading user state`, error);
                this._userState = {};
            }
        });
    }

    async bootstrap(skip = false): Promise<void> {
        this.websocketManager.openSocketConnection();

        if (!skip) {
            await this.restoreToken();
        }

        this.featureStore = new FeatureStore(this);
        this.featureManager.addSource(this.featureStore);
        this._isLoaded = true;
    }

    setAuthClient(authClient: Auth0ContextInterface) {
        this._authClient = authClient;
    }
    w;

    clean() {
        this.websocketManager?.disconnect();
        this.clearToken();
        this._isLoaded = false;
    }

    private clearToken(): void {
        this.userService.logout(this.token);
        this.token = new AnonymousUserToken();
    }

    get isLoaded(): boolean {
        const { isLoading = true } = this._authClient || {};
        return Boolean(this._isLoaded) && !isLoading;
    }

    get isAuthenticated(): boolean {
        return Boolean(this._authClient?.isAuthenticated) && Boolean(this.token?.user?.id);
    }

    public getAccessToken = async (): Promise<string | undefined> => {
        const { AUTH0 } = environment;

        try {
            const res = await this._authClient?.getAccessTokenSilently({
                audience: AUTH0.audience,
                scope: AUTH0.scope,
            });
            return res;
        } catch (error) {
            console.error(error);
            return;
        }
    };

    private get isAnalyticsEnabled(): boolean {
        return this.featureManager.isAvailable(FEATURES.ANALYTICS, true);
    }

    private get isControlCenterFeatureEnabled(): boolean {
        return this.featureManager.isAvailable(FEATURES.CONTROL_CENTER, true);
    }

    private get isMasterDataFeatureEnabled(): boolean {
        return this.featureManager.isAvailable(FEATURES.MASTER_DATA, true);
    }

    private get itemsAvailability(): Record<string, boolean> {
        return {
            [RouterPaths.AnalyticsPages.Analytics]: this.isAnalyticsEnabled,
            [RouterPaths.ControlCenterPages.ControlCenter]: this.isControlCenterFeatureEnabled,
            [RouterPaths.MasterDataPages.MasterData]: this.isMasterDataFeatureEnabled,
        };
    }

    private isPageAvailable({ path, roles }: NavigationMenuItem): boolean {
        const affectedItem = Object.keys(this.itemsAvailability).find((item) => path.startsWith(item));
        const isAccessible = !roles || roles.some((role) => this.token.hasRole(role));

        if (!affectedItem) {
            return true;
        }

        return isAccessible && this.itemsAvailability[affectedItem];
    }

    public getAvailableMenuItems() {
        return menu.reduce((acc: NavigationMenuItem[], item: NavigationMenuItem) => {
            if (!this.isPageAvailable(item)) {
                return acc;
            }

            if (item.path === RouterPaths.MyOrganizationPages.PracticeConnections) {
                return [...acc, { ...item, notificationsCount: this.rootStore.domain.practicesStore.unreachableApps }];
            }

            return [...acc, item];
        }, []);
    }

    async authorize(accessToken?: string): Promise<BaseUserToken<IEnterpriseUser> | undefined> {
        if (!accessToken || this.authorizing) {
            return;
        }

        this.authorizing = true;

        try {
            const token = await this.userService.authenticate(accessToken);
            if (token) {
                token.access_token = accessToken;
            }
            if (token instanceof Error) {
                throw token;
            }

            this.authorizing = false;

            if (!token) {
                return;
            }

            if (!token.user?.isActive) {
                throw new Error('User is not active');
            }

            this.token = token;
            return token;
        } catch (error) {
            this.authorizing = false;
            Notifications.error(error.message);
            throw error;
        }
    }

    async signup(data: Signup): Promise<void> {
        const accessToken = await this.getAccessToken();
        const headers = { Authorization: `Bearer ${accessToken}` };
        await this.userService.signup(data, { headers });
    }

    refreshAuthorization = async (): Promise<BaseUserToken<IEnterpriseUser> | undefined> => {
        const accessToken = await this.getAccessToken();
        return this.authorize(accessToken);
    };

    updateToken = async (token: BaseUserToken<IEnterpriseUser>): Promise<void> => {
        const accessToken: any = await this.getAccessToken();
        token.access_token = accessToken;
        this.userService.updateToken(token);

        if (!isEqual(this.token, token)) {
            this.token = token;
            void this.bootstrap(true);
        }
    };

    logout = async (): Promise<void> => {
        const redirectUrl = `${window.location.origin}${RouterPaths.BasePages.Logout}`;
        this._authClient?.logout({ returnTo: redirectUrl });
    };

    async restoreToken(): Promise<BaseUserToken<IEnterpriseUser> | undefined> {
        try {
            const token = await this.userService.restore();
            const accessToken: any = await this.getAccessToken();
            if (!isEqual(token, this.token)) {
                console.log('token has been been updated in the app store');
                this.token = token;
                this.token.access_token = accessToken;
            }

            return token;
        } catch (error) {
            console.error(error);
            return;
        }
    }

    updateProfile(profile: Profile): void {
        if (this.token.user?.profile) {
            this.token.user.profile = profile;
            this.userService.updateToken(this.token);
        }
    }

    async setUserDisplayName(displayName: string, userId: string): Promise<void> {
        if (this.userIsSaving) {
            return;
        }

        try {
            this.userIsSaving = true;
            const { user } = this.token;
            if (user?.profile && user?.id === userId) {
                user.profile.displayName = displayName;
                await this.updateProfile(user.profile);
            }
            await this.userService.setUserDisplayName(displayName, userId);
        } finally {
            this.userIsSaving = false;
        }
    }

    async setCollectProfileInformationVisibility(value: boolean): Promise<void> {
        if (this.userIsSaving) {
            return;
        }

        try {
            this.userIsSaving = true;
            const { user } = this.token;
            if (user?.profile) {
                user.profile.collectProfileInformationHidden = value;
                await this.updateProfile(user.profile);
            }
        } finally {
            this.userIsSaving = false;
        }
    }

    async setUserAvatar(avatar: string, userId: string): Promise<void> {
        if (this.userIsSaving) {
            return;
        }

        try {
            this.userIsSaving = true;
            const { user } = this.token;
            if (user?.profile && user?.id === userId) {
                user.profile.avatar = avatar;
                await this.updateProfile(user.profile);
            }
            await this.userService.setUserAvatar(avatar, userId);
        } finally {
            this.userIsSaving = false;
        }
    }

    async updateUserFeatures(features: Feature[]) {
        if (this.userIsSaving) {
            return;
        }

        try {
            this.userIsSaving = true;
            await this.userService.updateUserFeatures(features, this.token.user.id);
            await this.refreshAuthorization();

            this.featureManager.removeSource(this.featureStore);
            const featureStore = new FeatureStore(this);
            this.featureManager.addSource(featureStore);
            this.featureStore = featureStore;
        } finally {
            this.userIsSaving = false;
        }
    }

    get userState(): UserState {
        return this._userState;
    }

    updateUserState(state: Partial<UserState>) {
        const newState = assign(this.userState, state);
        window.localStorage.setItem(`${STATE_KEY_NAME}_${this.token.user.id}`, JSON.stringify(newState));
        this._userState = newState;
    }

    getSubMenuItems(type: SubMenuType): PageNavigationItem[] {
        const set =
            (type === SubMenuType.OrganizationManagement && managementNavigationSet) ||
            (type === SubMenuType.ControlCenter && controlCenterSet) ||
            [];

        return set.filter(({ roles }) => !roles || roles.some((role) => this.token.hasRole(role)));
    }

    isOwner(userId: string): boolean {
        const ownerId = this.token.user?.organization?.owner;
        return Boolean(ownerId && userId === ownerId);
    }
}
