import React, { ReactElement, useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import {
    GridColumnHeaderParams,
    gridTabIndexColumnHeaderSelector,
    useGridSelector,
    useGridRootProps,
    GridEvents,
    useGridApiContext,
    gridRowsStateSelector,
    gridFilteredRowsLookupSelector,
} from '@mui/x-data-grid-premium';
import { Checkbox } from '@mui/material';

export const CheckboxSelectorHeader = (props: GridColumnHeaderParams): ReactElement => {
    const [, forceUpdate] = React.useState(false);
    const { field } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const tabIndexState = useGridSelector(apiRef, gridTabIndexColumnHeaderSelector);
    const { idToIdLookup } = useGridSelector(apiRef, gridRowsStateSelector);
    const selectedRows = apiRef.current.getSelectedRows();
    const filteredLookup = useGridSelector(apiRef, gridFilteredRowsLookupSelector);
    const selectableRowIds = useMemo(() => {
        return Object.values(idToIdLookup)?.filter((id) => {
            return !rootProps?.isRowSelectable || rootProps?.isRowSelectable(apiRef.current.getRowParams(id));
        });
    }, [idToIdLookup, rootProps?.isRowSelectable]);
    const filteredRowIds = useMemo(() => {
        return selectableRowIds.filter((id) => filteredLookup[id]);
    }, [selectableRowIds, filteredLookup]);

    const isChecked = useMemo(() => {
        return Boolean(filteredRowIds.length) && filteredRowIds.every((id) => selectedRows.has(id));
    }, [filteredRowIds, selectedRows]);

    const isIndeterminate = useMemo(() => {
        return Boolean(selectedRows.size) && filteredRowIds.some((id) => !selectedRows.has(id));
    }, [filteredRowIds, selectedRows]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            apiRef.current.selectRows(filteredRowIds, true);
        } else {
            apiRef.current.selectRows([...selectedRows.keys()], false);
        }
    };

    const tabIndex = tabIndexState !== null && tabIndexState.field === field ? 0 : -1;

    useLayoutEffect(() => {
        const element = apiRef.current.getColumnHeaderElement(field);
        if (tabIndex === 0 && element) {
            element!.tabIndex = -1;
        }
    }, [tabIndex, apiRef.current, field]);

    const handleSelectionChange = useCallback(() => {
        forceUpdate((p) => !p);
    }, []);

    useEffect(() => {
        return apiRef.current.subscribeEvent(GridEvents.selectionChange, handleSelectionChange);
    }, [apiRef.current, handleSelectionChange]);

    return (
        <Checkbox
            tabIndex={tabIndex}
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            disableRipple
            data-automation-id="data-grid-selector-header"
        />
    );
};
