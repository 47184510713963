import { PracticeAccess } from './practicesAccess';
import { Field, UserPractices } from '@enterprise/common';
import React, { ChangeEvent } from 'react';

type PracticeAccessFieldProps = {
    name: string;
    disabled?: boolean;
    setDisableSave?: any;
};

export const PracticeAccessField = ({ name, disabled, setDisableSave }: PracticeAccessFieldProps) => {
    return (
        <Field name={name} data-automaton-id={'user-practices-input'}>
            {/*todo: think about validation (partially should be done here and inside PracticeAccess component)*/}
            {({ input: { value, onChange } }) => (
                <PracticeAccess
                    userPractices={value as UserPractices}
                    disabled={disabled}
                    onChange={(userPractices: UserPractices) => onChange(userPractices as ChangeEvent)}
                    setDisableSave={setDisableSave}
                />
            )}
        </Field>
    );
};
