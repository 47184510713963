import React, { Fragment } from 'react';
import { isEmpty, times, isNumber, isNaN, merge } from 'lodash';
import { useField } from '@enterprise/common';
import { SpotFieldError, SpotTextInput } from '@enterprise/spot';
import { PriceBreak } from '../../../../core/models';
import { formatPrice, isDefined, isUndefined, isWithinRange } from '../../../../core/helpers';
import { invoiceItemPriceLimits } from '../../items/invoiceItemPriceLimits.constants';
import { FieldValidator } from 'final-form';
import styles from './pricingTableField.module.scss';

interface UpdateValue<TModel extends object> {
    value: string | number;
    name: keyof TModel;
    index: number;
}

interface RenderValue<TModel extends PriceBreak, TView = Record<keyof TModel, string | number>> {
    priceBreak: TView;
    name: keyof TView;
    index: number;
}

interface PricingTableFieldProps {
    name: string;
    autoCalculatePriceEnabled?: boolean;
}

const MIN_ROWS_COUNT = 5;
const DEFAULT_BASE_INVOICE_PRICE = '0.00';
const { minPrice, maxPrice, maxDecimalPlaces } = invoiceItemPriceLimits;
const isWithinRangeValidator = isWithinRange(minPrice, maxPrice);
const inRange = (val: number) => (isDefined(val) && isWithinRangeValidator(val)) || undefined;
const formatPriceValue = (value: string | number) => {
    const formattedValue = formatPrice(maxDecimalPlaces)(value);
    return isNumber(formattedValue) && !isNaN(formattedValue) ? formattedValue : value;
};

export function PricingTableField<TView extends Record<keyof PriceBreak, string | number>>(props: PricingTableFieldProps) {
    const { name } = props;
    const autoCalculatePriceEnabled = props.autoCalculatePriceEnabled || undefined;
    const defaultPriceBreaks: TView[] = [{ invoicePrice: 0 } as TView, ...times(MIN_ROWS_COUNT - 1, () => ({} as TView))];

    const isDuplicatedField = (priceBreak: PriceBreak, pointIndex, priceBreaks: PriceBreak[], fieldName) => {
        const pointFieldValue = Number(priceBreak[fieldName]);

        let isDuplicated = false;
        priceBreaks.some((otherPoint, index) => {
            if (index === pointIndex) {
                return;
            }
            const otherFieldValue = Number(otherPoint[fieldName]);
            if (pointFieldValue === otherFieldValue) {
                isDuplicated = true;
            }

            return isDuplicated;
        });

        return isDuplicated;
    };

    const isDuplicatedQuantity = (priceBreak: PriceBreak, pointIndex, priceBreaks: PriceBreak[]) => {
        return isDuplicatedField(priceBreak, pointIndex, priceBreaks, 'breakAmount');
    };

    const isDuplicatedPrice = (priceBreak: PriceBreak, pointIndex, priceBreaks: PriceBreak[]) => {
        return isDuplicatedField(priceBreak, pointIndex, priceBreaks, 'invoicePrice');
    };

    const isBasePriceNotSet = (priceBreak: PriceBreak, pointIndex) => {
        return pointIndex === 0 && isUndefined(priceBreak.invoicePrice);
    };

    const isQuantityMissingForPrice = (priceBreak: PriceBreak, pointIndex) => {
        const isBasePricePoint = !pointIndex;
        return !isBasePricePoint && isUndefined(priceBreak.breakAmount) && isDefined(priceBreak.invoicePrice);
    };

    const isPriceMissingForQuantity = (priceBreak: PriceBreak) => {
        return isDefined(priceBreak.breakAmount) && isUndefined(priceBreak.invoicePrice);
    };

    const validatePrices: FieldValidator<PriceBreak[]> = (priceBreaks: PriceBreak[]) => {
        if (!priceBreaks) {
            return undefined;
        }
        const errorArray: Partial<Record<keyof PriceBreak, string | undefined>>[] = [];

        priceBreaks.forEach((priceBreak, index) => {
            const errors: { [key in keyof PriceBreak]?: string } = {};

            errors.breakAmount = inRange(priceBreak?.breakAmount);
            errors.invoicePrice = inRange(priceBreak?.invoicePrice);
            errors.invoiceMarkup = autoCalculatePriceEnabled && inRange(priceBreak?.invoiceMarkup);

            if (isDuplicatedQuantity(priceBreak, index, priceBreaks)) {
                errors.breakAmount = 'Quantity should be unique';
            }
            if (isDuplicatedPrice(priceBreak, index, priceBreaks)) {
                errors.invoicePrice = 'Price should be unique';
            }
            if (isQuantityMissingForPrice(priceBreak, index)) {
                errors.breakAmount = 'Quantity is required when price is specified';
            }
            if (isPriceMissingForQuantity(priceBreak)) {
                errors.invoicePrice = 'Price is required when quantity is specified';
            }
            if (isBasePriceNotSet(priceBreak, index)) {
                errors.invoicePrice = 'Base price is required';
            }
            errorArray.push(errors);
        });

        if (errorArray.some((object) => !isEmpty(object))) {
            return errorArray;
        } else {
            return undefined;
        }
    };

    const { input, meta } = useField<PriceBreak[]>(name, { validate: validatePrices });

    const onChange = ({ value, name, index }: UpdateValue<PriceBreak>) => {
        const priceBreaks = merge(defaultPriceBreaks, input.value || []);
        const priceBreak: PriceBreak = priceBreaks[index];

        priceBreak[name] = (value === '' ? undefined : value) as PriceBreak[typeof name];

        if (autoCalculatePriceEnabled && priceBreak.invoicePrice >= 0 && !priceBreak.invoiceMarkup) {
            priceBreak.invoiceMarkup = 0;
        }

        if (isPriceMissingForQuantity(priceBreak)) {
            priceBreak.invoicePrice = 0;
        }

        input.onChange(priceBreaks);
        input.onBlur();
    };

    const onBlur = ({ value, name, index }: UpdateValue<PriceBreak>) => {
        onChange({ value: formatPriceValue(value), name, index });
    };

    const renderCell = ({ priceBreak, name, index }: RenderValue<PriceBreak>) => {
        const isBaseBreakAmountCell = index === 0 && name === 'breakAmount';
        let value = priceBreak[name];

        if (value === undefined) {
            value = '';
        }

        if (index === 0 && name === 'invoicePrice' && value === 0) {
            value = DEFAULT_BASE_INVOICE_PRICE;
        }

        return (
            <td className={styles.cell}>
                {isBaseBreakAmountCell && <span>Base</span>}
                {!isBaseBreakAmountCell && (
                    <SpotTextInput
                        data-automation-id={'price-break-input-text-boxes'}
                        value={value}
                        onChange={(event) => onChange({ value: event.target.value, name, index })}
                        onBlur={(event) => onBlur({ value: event.target.value, name, index })}
                    />
                )}
            </td>
        );
    };

    const renderRow = (priceBreak: TView, index: number) => {
        return (
            <Fragment key={index}>
                <tr>
                    {renderCell({ priceBreak, name: 'breakAmount', index })}
                    {renderCell({ priceBreak, name: 'invoicePrice', index })}
                    {autoCalculatePriceEnabled && renderCell({ priceBreak, name: 'invoiceMarkup', index })}
                </tr>
                <tr>
                    <td colSpan={3}>
                        <SpotFieldError meta={{ ...meta, error: meta.error?.[index]?.breakAmount }} />
                        <SpotFieldError meta={{ ...meta, error: meta.error?.[index]?.invoicePrice }} />
                        {autoCalculatePriceEnabled && <SpotFieldError meta={{ ...meta, error: meta.error?.[index]?.invoiceMarkup }} />}
                    </td>
                </tr>
            </Fragment>
        );
    };

    const renderRows = () => {
        const inputValues = input.value || [];
        const priceBreaks = [...inputValues, ...defaultPriceBreaks.slice(inputValues.length)];
        return priceBreaks.map((value, index) => renderRow(value as TView, index));
    };

    return (
        <div>
            <table className="spot-data-table spot-data-table--small-spacing" style={{ marginLeft: '0px', width: '300px' }}>
                <thead>
                    <tr>
                        <th className={styles.headerCell}>Quantity</th>
                        <th className={styles.headerCell}>Amount</th>
                        {autoCalculatePriceEnabled && <th className={styles.headerCell}>Markup</th>}
                    </tr>
                </thead>
                <tbody>{renderRows()}</tbody>
            </table>
        </div>
    );
}
