import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useField, useForm, useFormState } from '@enterprise/common';
import { SpotSelectOption, SpotSelectBox } from '@enterprise/spot';
import { InvoiceItemClassification, InvoiceItemWithVariantDetail } from '../../../../../core/models';
import { LookupClassification, LookupSubClassification } from '../../../../../core/models/datasource/lookups';
import { useStores } from '../../../../../hooks';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { head } from 'lodash';
import i18n from 'i18next';

export const ClassificationEditFormFieldset = observer(function ClassificationEditFormFieldset() {
    const {
        domain: { itemsPageStore, constantsStore },
    } = useStores();

    const { organizationSettings, applications } = constantsStore;
    const { isAnimanaOnly } = itemsPageStore;
    const masterSiteId = organizationSettings?.masterSite?.siteId || head(applications)?.siteId;
    const form = useForm();
    const { values } = useFormState<InvoiceItemWithVariantDetail>();
    const { classification } = values;
    const classifications: LookupClassification[] = itemsPageStore.getLookupResults(itemsPageStore.classifications, masterSiteId);
    const classMatchedById = classifications.find(({ pimsGeneratedId }) => pimsGeneratedId && pimsGeneratedId === classification?.pimsGeneratedId);
    const classMatchedByDesc = classifications.find(({ description }) => description === classification?.description);
    const subClassifications = classMatchedById?.subClassifications || [];
    const classField = useField<InvoiceItemClassification>('classification');
    const subClassField = useField<LookupSubClassification>('subClassification');

    useEffect(() => {
        if (!classMatchedById && classMatchedByDesc) {
            form.change('classification', classMatchedByDesc);
        }
    }, [classMatchedById, classMatchedByDesc]);

    const getClassification = <T extends LookupClassification | LookupSubClassification>(
        classifications: T[] = [],
        pimsGeneratedId: string | null | undefined,
    ): T | undefined => {
        return classifications.find((classification) => classification.pimsGeneratedId === pimsGeneratedId);
    };

    const onClassificationChange = (pimsGeneratedId: string) => {
        if (!pimsGeneratedId) {
            classField.input.onChange({ pimsGeneratedId: null });
            return;
        }

        const { name, description, pimsId } = getClassification(classifications, pimsGeneratedId) || {};
        classField.input.onChange({ pimsGeneratedId, pimsId, name, description });
        onSubClassificationChange(null);
    };

    const onSubClassificationChange = (pimsGeneratedId: string | null) => {
        if (!pimsGeneratedId) {
            subClassField.input.onChange({ pimsGeneratedId: null });
            return;
        }

        const { name, description, pimsId } = getClassification(subClassifications, pimsGeneratedId) || {};
        subClassField.input.onChange({ pimsGeneratedId, pimsId, name, description });
    };

    return (
        <Container fluid={true}>
            <Row>
                <Col xs={12}>
                    <div style={{ marginTop: '10px' }}>
                        <legend className="spot-form__radio-group-label common-legend">
                            {!isAnimanaOnly && i18n.t('controlCenter:itemFields.primaryClassification', 'Primary Classification')}
                            {isAnimanaOnly && i18n.t('controlCenter:itemFields.productGroup', 'Product Group')}
                        </legend>
                    </div>
                    <div className="spot-form__field-group">
                        <SpotSelectBox
                            value={classField.input.value?.pimsGeneratedId}
                            onChange={(event) => onClassificationChange(event.target.value)}
                            error={{ ...classField.meta, error: classField.meta.error?.pimsGeneratedId }}
                            data-automation-id="classification-select"
                        >
                            {classifications.map((classification) => (
                                <SpotSelectOption key={classification.pimsGeneratedId} value={classification.pimsGeneratedId}>
                                    {classification.name}
                                </SpotSelectOption>
                            ))}
                        </SpotSelectBox>
                    </div>

                    {!isAnimanaOnly && (
                        <>
                            <div>
                                <legend className="spot-form__radio-group-label common-legend">
                                    {i18n.t('controlCenter:itemFields.subclass', 'Subclass')}
                                </legend>
                            </div>
                            <div className="spot-form__field-group">
                                <SpotSelectBox
                                    value={subClassField.input.value?.pimsGeneratedId}
                                    onChange={(event) => onSubClassificationChange(event.target.value)}
                                    error={{ ...classField.meta, error: subClassField.meta.error?.pimsGeneratedId }}
                                    disabled={!subClassifications?.length}
                                    data-automation-id="sub-classification-select"
                                >
                                    {subClassifications?.map((subclass) => (
                                        <SpotSelectOption key={subclass.pimsGeneratedId} value={subclass.pimsGeneratedId}>
                                            {subclass.description}
                                        </SpotSelectOption>
                                    ))}
                                </SpotSelectBox>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
});
