import { ContentfulContentTypes, ContentfulService } from '../services/contentful.service';
import { Document } from '@contentful/rich-text-types';
import { Asset, Entry } from 'contentful';
import { PricingTiers } from '../core/enums/pricingTiers';
import { Service } from 'typedi';

export interface AnalyticsAdvertisement {
    advertisementBody: Document;
    advertisementImage: Asset;
    header: string;
    learnMoreLink: string;
    upgradeLink: string;
    pricingTier: string;
}

interface OfflineAdvertisementData {
    [key: string]: {
        dismissed: string[];
    };
}

type Advertisement = AnalyticsAdvertisement;

@Service()
export class AdvertisementStore {
    private readonly localStorageKey = 'EnterpriseAdvertisements';

    private readonly contentfulService: ContentfulService;

    private readonly sectionKeys = new Map<ContentfulContentTypes, string>().set(ContentfulContentTypes.AnalyticsAdvertisement, 'analytics');

    private contentType: ContentfulContentTypes = ContentfulContentTypes.AnalyticsAdvertisement;

    private displayableAdvertisements: Entry<Advertisement>[] = [];

    private offlineData: OfflineAdvertisementData;

    constructor() {
        this.contentfulService = new ContentfulService();
    }

    async getRandomAdForPricingTier(pricingTier: PricingTiers): Promise<Advertisement | null> {
        this.loadOfflineData();
        await this.loadRemoteData(pricingTier);
        if (this.displayableAdvertisements.length) {
            const randomEntryIndex = Math.floor(Math.random() * this.displayableAdvertisements.length);
            return this.displayableAdvertisements[randomEntryIndex].fields;
        }
        return null;
    }

    dismissDisplayableAds(): void {
        this.loadOfflineData();

        const advertisementIds = this.displayableAdvertisements.map((entry) => entry.sys.id);
        const sectionKey = this.getOfflineDataSectionKey();
        const { dismissed } = this.offlineData[sectionKey];
        this.offlineData[sectionKey].dismissed = [...new Set([...dismissed, ...advertisementIds])];

        this.updateLocalStorage(this.offlineData);
        this.displayableAdvertisements = [];
    }

    private getDataFromLocalStorage(): OfflineAdvertisementData | null {
        const data = localStorage.getItem(this.localStorageKey);

        if (data) {
            return JSON.parse(data);
        }

        return null;
    }

    private updateLocalStorage(newData): void {
        localStorage.setItem(this.localStorageKey, JSON.stringify(newData));
    }

    private async loadRemoteData(pricingTier: PricingTiers): Promise<void> {
        const query = {
            'fields.pricingTier[match]': pricingTier,
        };

        const remoteData = await this.contentfulService.getEntriesByContentType<Advertisement>(this.contentType, query);

        const sectionKey = this.getOfflineDataSectionKey();
        const { dismissed } = this.offlineData[sectionKey];
        this.displayableAdvertisements = remoteData.filter((entry) => !dismissed.includes(entry.sys.id));
    }

    private getOfflineDataSectionKey(): string {
        const sectionKey = this.sectionKeys.get(this.contentType);
        if (sectionKey) {
            return sectionKey;
        }
        throw new Error(`Section key does not exist for content type ${this.contentType}`);
    }

    private loadOfflineData(): void {
        this.offlineData = this.getDataFromLocalStorage() || { analytics: { dismissed: [] } };
    }
}
