import * as React from 'react';

interface SVGHandleProps {
    className?: string;
}

export const SVGHandle: React.FunctionComponent<SVGHandleProps> = props => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 220 468">
            <g>
                <rect x="0" y="0" width="72" height="72" />
                <rect x="0" y="128" width="72" height="72" />
                <rect x="0" y="256" width="72" height="72" />
                <rect x="0" y="384" width="72" height="72" />

                <rect x="128" y="0" width="72" height="72" />
                <rect x="128" y="128" width="72" height="72" />
                <rect x="128" y="256" width="72" height="72" />
                <rect x="128" y="384" width="72" height="72" />
            </g>
        </svg>
    );
};
