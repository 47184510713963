import React from 'react';
import { SpotCheckboxGroup, SpotHorizontalFields } from '@enterprise/spot';
import { LookupDiscount, LookupTax } from '../../../../core/models/datasource/lookups';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';
import { useFormState } from '@enterprise/common';
import { InvoiceItemTypes, InvoiceItemWithVariantDetail } from '../../../../core/models';
import { GroupSelectField } from '../../components';

export const TaxDiscControls = observer(function TaxDiscControls() {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const discounts = itemsPageStore.getLookupResults(itemsPageStore.discounts);
    const taxes = itemsPageStore.getLookupResults(itemsPageStore.taxes);
    const { values } = useFormState<InvoiceItemWithVariantDetail>();
    const { itemType } = values;

    return (
        <div>
            <SpotHorizontalFields>
                <div data-automation-id="sales-tax-container">
                    <label className="spot-form__label">
                        <Trans i18nKey="controlCenter:item.salesTaxToApply">Sales tax to apply</Trans>
                    </label>
                    <SpotCheckboxGroup isHorizontal={true}>
                        <GroupSelectField
                            name="salesTaxes"
                            options={taxes}
                            getOptionId={({ id }: LookupTax) => String(id)}
                            getOptionTitle={({ description }: LookupTax) => description}
                            disabled={itemType?.id === InvoiceItemTypes.Service}
                            dataAutomationId={'tax-discount-controls-sales-tax'}
                        />
                    </SpotCheckboxGroup>
                </div>
                <div data-automation-id="usage-tax-container">
                    <label className="spot-form__label">
                        <Trans i18nKey="controlCenter:item.usageTaxToApply">Usage tax to apply</Trans>
                    </label>
                    <SpotCheckboxGroup isHorizontal={true}>
                        <GroupSelectField
                            name="usageTaxes"
                            options={taxes}
                            getOptionId={({ id }: LookupTax) => String(id)}
                            getOptionTitle={({ description }: LookupTax) => description}
                            disabled={itemType?.id === InvoiceItemTypes.Service}
                            dataAutomationId={'tax-discount-controls-usage-tax'}
                        />
                    </SpotCheckboxGroup>
                </div>
            </SpotHorizontalFields>
            <div data-automation-id="discounts-container">
                <label className="spot-form__label">
                    <Trans i18nKey="controlCenter:item.discountsToApply">Discounts to apply</Trans>
                </label>
                <SpotCheckboxGroup>
                    <GroupSelectField
                        name="discounts"
                        options={discounts}
                        getOptionId={({ id }: LookupDiscount) => String(id)}
                        getOptionTitle={({ description }: LookupDiscount) => description}
                        dataAutomationId={'tax-discount-controls-discounts'}
                    />
                </SpotCheckboxGroup>
            </div>
        </div>
    );
});
