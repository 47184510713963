import React, { FC } from 'react';

interface ActionMenuIconProps {
    className?: string;
}

export const ActionMenuIcon: FC<ActionMenuIconProps> = ({ className }) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g xmlns="http://www.w3.org/2000/svg" transform="translate(1 13)">
                <circle cx="14.5" cy="3.625" r="3.625" />
                <circle cx="3.625" cy="3.625" r="3.625" />
                <circle cx="25.375" cy="3.625" r="3.625" />
            </g>
        </svg>
    );
};
