import React, { ReactNode, memo } from 'react';
import styles from './pageHeader.module.scss';
import classNames from 'classnames';
import { SpotSvg } from '@enterprise/spot';
import { Trans } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

export interface HeaderProps {
    className?: string;
    children?: ReactNode | string;
    back?: () => void;
    title?: ReactNode | string;
}

export const PageHeader = memo(function PageHeader(props: HeaderProps) {
    const { className, children, back, title } = props;

    // If there is nothing to show in the header, hide it
    if (!title && !back && !children) {
        return null;
    }

    return (
        <div className={classNames(styles.holder, className)}>
            {Boolean(back) && (
                <button
                    className={classNames('spot-button spot-button--link spot-button--with-icon spot-button--large', styles.back)}
                    onClick={back}
                    data-automation-id="page-header-back"
                >
                    <SpotSvg icon={'back'} className={'spot-icon spot-button__icon spot-button__icon--left'} />
                    <span className={classNames('spot-button__text', styles.backText)}>
                        <Trans i18nKey="common:back">Back</Trans>
                    </span>
                </button>
            )}

            {Boolean(title) && (
                <Row noGutters={true} className={styles.title}>
                    <Col className={classNames('spot-typography__heading--level-4', styles.titleText)}>{title}</Col>
                </Row>
            )}
            {!title && children}
        </div>
    );
});
