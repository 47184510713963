import React, { PropsWithChildren, useEffect, useState } from 'react';
import i18n from 'i18next';
import { Col, Row } from 'react-bootstrap';
import styles from './editMyProfile.module.scss';
import { observer } from 'mobx-react';
import { FlexPageWrapper } from '../../layout/main/flexPageWrapper';
import { SpotVerticalRule } from '../../../../../packages/spot/src/components/SpotVerticalRule';
import { EnterpriseUserToken, Form, FormApi, Field, FormSpy, FormState, UserPractices, UserRoles } from '@enterprise/common';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { SpotButton, SpotFlyoverLoading, SpotHorizontalRule, SpotTextInput } from '@enterprise/spot';
import { RouterPaths } from '../../router/RouterPaths';
import { useStores } from '../../hooks';
import { SystemMessaging } from '../systemMessaging';
import { ToastType } from 'react-toastify';
import { SystemToast } from '../../components/toasts/systemMessageToast';
import { NavigationGuard } from '../../components/navigationGuards/navigation.guard';
import { PracticeAccessField, SiteAccessField } from '../userManagement';
import { ChooseAvatarDialog } from '../../components/avatars/chooseAvatarDialog';
import { PermissionLevel } from '../../core/enums/permissionLevel';

interface MyProfileData {
    displayName: string;
    userPractices?: UserPractices;
    avatar?: string;
    permissionLevel?: PermissionLevel;
}

type SubmitErrors = Partial<Record<keyof MyProfileData, string>>;

type EditUserPageProps = PropsWithChildren<{
    back: () => void;
}>;

export const EditMyProfilePage = observer(function MyProfilePage(props: EditUserPageProps) {
    const {
        ui: { app },
        domain: { usersStore },
    } = useStores();

    let submit;
    const { back } = props;
    const title = i18n.t('myProfile:editPageTitle', 'Edit My Profile');
    const { token, userIsSaving } = app;
    const { user } = token as EnterpriseUserToken;
    const { username, profile } = user;
    const defaultDisplayName = token.getDisplayName();
    const defaultAvatar = profile?.avatar;
    const { userPracticesAccess, isLoadingUserAccess, isSavingUserAccess, userAvatars } = usersStore;
    const isLoading = isLoadingUserAccess;
    const isSaving = userIsSaving || isSavingUserAccess;
    const [disableSave, setDisableSave] = useState(false);
    const [updatedAvatar, updateAvatar] = useState(defaultAvatar);
    const initialValues: MyProfileData = {
        displayName: defaultDisplayName,
        userPractices: userPracticesAccess,
        permissionLevel: PermissionLevel.DecisionMaker,
    };

    useEffect(() => {
        void usersStore.fetchUserAccess(user.id);
        void usersStore.fetchUserAvatars(user.id);
    }, [usersStore]);

    const onSubmit = async (data: MyProfileData, form: FormApi<MyProfileData>): Promise<SubmitErrors | void> => {
        const { displayName, userPractices } = data;
        const { modified } = form.getState();
        const errors: SubmitErrors = {};

        if (isSaving || disableSave) {
            return;
        }

        if (modified?.userPractices && userPractices) {
            try {
                await usersStore.updateUserAccess(user.id, userPractices);
            } catch (error) {
                errors.userPractices = error.message || error;
            }
        }

        if (updatedAvatar !== defaultAvatar) {
            try {
                await app.setUserAvatar(updatedAvatar as string, user.id);
                void usersStore.fetchUserAvatars(user.id);
            } catch (error) {
                errors.avatar = error.message || error;
            }
        }

        if (modified?.displayName && displayName) {
            try {
                await app.setUserDisplayName(displayName, user.id);
            } catch (error) {
                errors.displayName = error.message || error;
            }
        }

        return errors;
    };

    const onAvatarChange = async (avatar): Promise<void> => {
        updateAvatar(avatar);
    };

    const onSubmitFinished = ({ submitSucceeded }: FormState<MyProfileData>) => {
        if (submitSucceeded && !disableSave) {
            SystemMessaging.success({ message: 'User Updates Saved' });

            setTimeout(() => {
                back();
            });
        }
    };

    const validateDisplayName = (val: string) => {
        if (val === null || val === undefined || val === '') {
            return i18n.t('myProfile:displayNameNotNullMessage', 'Display Name field cannot be null');
        }
        if (val.length < 5 || val.length > 50) {
            return i18n.t('myProfile:displayNameCharNumberMessage', 'Display Name must be between 5 and 50 characters');
        }
        return undefined;
    };

    const editUserFooter = (
        <div className={styles.editMyProfileFooter}>
            <div className={styles.footerLinks}>
                <Link className={classNames('spot-link')} to={RouterPaths.MyProfilePages.MyProfile}>
                    {i18n.t('myProfile:cancel', 'Cancel')}
                </Link>
            </div>
            <SpotButton
                isPrimary={true}
                data-automation-id={'edit-profile-save'}
                className={classNames('spot-button--large', styles.footerSave)}
                onClick={(event) => {
                    submit?.(event);
                }}
            >
                {i18n.t('myProfile:save', 'Save')}
            </SpotButton>
            <div className={styles.footerSpacer} />
        </div>
    );

    const avatarsDropdownItems = userAvatars || [];

    return (
        <>
            {isSaving && (
                <div className={styles.loadingOverlay}>
                    <SpotFlyoverLoading text={'Saving...'} size={'large'} />
                </div>
            )}
            <FlexPageWrapper header={title} footer={editUserFooter} back={() => back()} isLoading={isLoading} className={styles.holder}>
                <Form
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    render={({ handleSubmit, dirty, hasSubmitErrors }) => {
                        submit = handleSubmit;
                        return (
                            <>
                                <FormSpy
                                    subscription={{ submitSucceeded: true, submitFailed: true }}
                                    onChange={(state: FormState<MyProfileData>) => {
                                        onSubmitFinished(state);
                                    }}
                                />
                                <NavigationGuard when={dirty || isSaving} />
                                {hasSubmitErrors && (
                                    <div className={styles.errorMessage}>
                                        <SystemToast
                                            message={'Unable to Save User Updates'}
                                            subMessage={'Something went wrong, Please try saving again.'}
                                            type={ToastType.ERROR}
                                        />
                                    </div>
                                )}
                                <form id="editProfileForm" className={styles.form} onSubmit={handleSubmit}>
                                    <Row className={styles.container}>
                                        <Col xs={12} md={5} lg={4} className={styles.userInformation}>
                                            <h4 className={styles.columnTitle} data-automaton-id={'user-information-title'}>
                                                {i18n.t('myProfile:userInformation', 'User Information')}
                                            </h4>
                                            <div className={styles.avatarSection} data-automaton-id={'avatar-section'}>
                                                <div>
                                                    <p className={styles.avatarTitle} data-automaton-id={'avatar-title'}>
                                                        {i18n.t('myProfile:avatar', 'Avatar')}
                                                    </p>
                                                    <p className={styles.avatarSubTitle} data-automaton-id={'avatar-subtitle'}>
                                                        {i18n.t('myProfile:avatarSubtitle', 'Visible to others in your organization')}
                                                    </p>
                                                    {userAvatars && <ChooseAvatarDialog items={avatarsDropdownItems} onChange={onAvatarChange} />}
                                                </div>

                                                <div className={styles.profileImage} data-automaton-id={'profile-image'}>
                                                    {updatedAvatar ? (
                                                        <img src={updatedAvatar} />
                                                    ) : (
                                                        <svg>
                                                            <use href={'/assets/img/icons.svg#users'} />
                                                        </svg>
                                                    )}
                                                </div>
                                            </div>
                                            <div>
                                                <p className={styles.displayNameTitle} data-automaton-id={'display-name-title'}>
                                                    * {i18n.t('myProfile:displayName', 'Display Name')}
                                                </p>
                                                <div>
                                                    <Field
                                                        className="spot-form__input"
                                                        type="text"
                                                        name="displayName"
                                                        data-automaton-id={'display-name-input'}
                                                        validate={validateDisplayName}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <SpotTextInput {...input} error={meta} />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div>
                                                <p className={styles.userNameTitle} data-automaton-id={'user-name-title'}>
                                                    {i18n.t('myProfile:userNameTitle', 'USER NAME')}
                                                </p>
                                                <p className={styles.userName} data-automaton-id={'user-name'}>
                                                    {username}
                                                </p>
                                            </div>
                                        </Col>
                                        <SpotVerticalRule className={styles.verticalLine} />
                                        <Col xs={12} className={styles.horizontalLine}>
                                            <SpotHorizontalRule />
                                        </Col>
                                        <Col xs={12} md={7} lg={8} className={styles.permissions}>
                                            <h4 className={styles.columnTitle} data-automaton-id={'permissions-title'}>
                                                {i18n.t('myProfile:permissionsTitle', 'Permissions')}
                                            </h4>
                                            <p className={styles.permissionColumnSectionHeader} data-automaton-id={'practice-access-title'}>
                                                {i18n.t('myProfile:practiceAccessTitle', 'Practice Access')}
                                            </p>
                                            <PracticeAccessField
                                                name="userPractices"
                                                disabled={token.hasRole(UserRoles.SystemOwner) || !token.hasRole(UserRoles.SuperAdmin)}
                                                setDisableSave={setDisableSave}
                                            />
                                            <p className={styles.permissionColumnSectionHeader} data-automaton-id={'site-access-title'}>
                                                {i18n.t('myProfile:siteAccessTitle', 'Site Access')}
                                            </p>
                                            <SiteAccessField name="permissionLevel" disabled={true} userId={user.id} />
                                        </Col>
                                    </Row>
                                </form>
                            </>
                        );
                    }}
                />
            </FlexPageWrapper>
        </>
    );
});
