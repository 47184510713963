import { Inject, Service } from 'typedi';
import { BaseHttp, Transformers } from '@enterprise/common';
import { ParentTask, TaskEntity, TasksPaginatedResponse } from '../core/entity/TaskEntity';
import { TaskType } from '../core/enums/TaskType';
import { TaskStatus } from '../core/enums/TaskStatus';

export interface TasksQuery {
    page?: number;
    pageSize?: number;
}

export interface ChildrenTasksQuery {
    page?: number;
    pageSize?: number;
    parentTask: TaskEntity | undefined;
}

@Service()
export class TaskService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getParentTasks(query: TasksQuery = {}): Promise<TasksPaginatedResponse> {
        const { page, pageSize } = query;
        return this.http.get('/v2/tasks/parents', { params: { page, limit: pageSize } }).then((data) => ({
            ...data,
            tasks: new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(TaskEntity)).transform(data.tasks),
        }));
    }

    getChildrenTasks(query: ChildrenTasksQuery): Promise<ParentTask> {
        console.log(query);
        return this.http
            .get(`/v2/tasks/${query.parentTask?.id}/children`, { params: { page: query.page || undefined, limit: query.pageSize || 100 } })
            .then((data) => new Transformers.ToTypeTransformer(ParentTask).transform({ ...query.parentTask, children: data.tasks }));
    }

    getTasks(ids?: number[]): Promise<TaskEntity[]> {
        return this.http
            .get(`/tasks`, {
                params: {
                    ids,
                    types: [
                        TaskType.SCHEDULED_ITEM_V2,
                        TaskType.BULK_ITEM_UPDATE_PARENT_V2,
                        TaskType.BULK_ITEM_UPDATE_PARENT_V3,
                        TaskType.SCHEDULED_ITEM_V3_PARENT,
                        TaskType.INDEX_SYNC_V3_PARENT,
                    ],
                },
            })
            .then((data) => new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(TaskEntity)).transform(data));
    }

    runTasks(ids: number[]): Promise<void> {
        return this.http.post(`/tasks/run`, null, { params: { ids } });
    }

    runFailedTasks(parentId: number, status: TaskStatus = TaskStatus.ERROR): Promise<number[]> {
        return this.http.post(`/v2/tasks/run`, { parentId, status });
    }

    cancelTasks(ids: number[]): Promise<void> {
        return this.http.put(`/tasks/cancel`, { ids });
    }
}
