import React, { FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import '../../styles/grid.scss';
import { RowData } from '../interfaces/RowData';
import { DataGridDefaultOptions } from '../config';
import { ColumnDefinition } from '../interfaces/ColumnDefinition';

export interface DataGridOptions {
    columnDefinitions: ColumnDefinition[];
    rowData: RowData[];
}

// this component acts as a thin wrapper to prevent exposing all of the underlying libraries methods
export const DataGrid: FC<DataGridOptions> = ({ columnDefinitions, rowData }: DataGridOptions) => {
    return (
        <div className={'ag-theme-balham fill-container'}>
            <AgGridReact gridOptions={DataGridDefaultOptions} columnDefs={columnDefinitions} rowData={rowData} />
        </div>
    );
};

export const generateCsv = () => {
    return DataGridDefaultOptions?.api?.getDataAsCsv();
};
