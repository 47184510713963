import React, { memo, PropsWithChildren, ReactElement, useMemo } from 'react';
import { DataGridPremiumProps, DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef, GridValueGetterParams } from '@mui/x-data-grid-premium';
import styles from './materialDataGrid.module.scss';
import classNames from 'classnames';
import { CheckboxSelectorCell } from '../checkboxSelectorCell';
import { CheckboxSelectorHeader } from '../checkboxSelectorHeader';
import { RadioSelectorCell } from '../radioSelectorCell';
import { RadioSelectorHeader } from '../radioSelectorHeader';
import { defaultColumnDef, defaultDataGridProps } from '../../config';

enum CustomGridColumns {
    radioSelect = 'radio-box-selector',
}

export enum GridFilterOperatorType {
    Contains = 'contains',
    Equals = 'equals',
    StartWith = 'startsWith',
}

const gridRadioboxSelectionColDef: GridColDef = {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    hideable: false,
    field: CustomGridColumns.radioSelect,
    renderHeader: RadioSelectorHeader,
    renderCell: RadioSelectorCell,
    type: 'boolean',
    valueGetter: (params: GridValueGetterParams): boolean => {
        const { id, api } = params;
        return api.getSelectedRows().has(id);
    },
};

export type MaterialDataGridProps<T> = PropsWithChildren<
    {
        className?: string;
        rows: T[];
        columns: GridColDef[];
        headerHeight?: number;
        rowHeight?: number;
        noRowsTitle?: ReactElement | string;
        noResultsTitle?: ReactElement | string;
        radioboxSelection?: true;
    } & Partial<DataGridPremiumProps>
>;

export const MaterialDataGrid = memo(function MaterialDataGrid<T>(props: MaterialDataGridProps<T>) {
    const { className, rows, columns, checkboxSelection, radioboxSelection, noRowsTitle, noResultsTitle, components, ...gridProps } = {
        ...defaultDataGridProps,
        ...props,
    };

    const gridColumns = useMemo(() => {
        const columnsDef: GridColDef[] = columns.map((item) => ({
            ...defaultColumnDef,
            ...item,
            headerName: item.headerName || item.field,
        }));

        if (checkboxSelection) {
            // apply custom cell and header to checkbox selection column
            GRID_CHECKBOX_SELECTION_COL_DEF.hideable = false;
            GRID_CHECKBOX_SELECTION_COL_DEF.renderHeader = CheckboxSelectorHeader;
            GRID_CHECKBOX_SELECTION_COL_DEF.renderCell = CheckboxSelectorCell;
        }

        if (radioboxSelection) {
            // apply custom cell and header radiobox selector column to the beginning of columns array
            columnsDef.unshift(gridRadioboxSelectionColDef);
        }

        return columnsDef;
    }, [columns, checkboxSelection, radioboxSelection]);

    const gridComponents = useMemo(() => {
        const CustomNoRowsOverlay = () => <div className={styles.noRows}>{noRowsTitle || 'No Rows'}</div>;
        const CustomNoResultsOverlay = () => <div className={styles.noRows}>{noResultsTitle || 'No Results'}</div>;

        return {
            NoRowsOverlay: CustomNoRowsOverlay,
            NoResultsOverlay: CustomNoResultsOverlay,
            ...components,
        };
    }, [noRowsTitle, noResultsTitle, components]);

    return (
        <div className={classNames(styles.holder, className)}>
            <DataGridPremium
                components={gridComponents}
                rows={rows}
                columns={gridColumns}
                className={styles.grid}
                checkboxSelection={checkboxSelection}
                disableColumnFilter={true}
                disableMultipleSelection={true}
                {...gridProps}
            />
        </div>
    );
});
