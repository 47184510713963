import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Field } from '@enterprise/common';
import { SpotTextInput } from '@enterprise/spot';
import { useStores } from '../../../../../hooks';
import { observer } from 'mobx-react';
import i18n from 'i18next';
import { useEffect, useRef } from 'react';

export const DescriptionEditFormFieldset = observer(function DescriptionEditFormFieldset() {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
        if (ref.current) {
            ref.current?.focus();
        }
    }, [ref]);

    const { isAnimanaOnly } = itemsPageStore;

    const hospitalDescriptionPlaceholder = isAnimanaOnly
        ? i18n.t('controlCenter:itemFields.name', 'Name')
        : i18n.t('controlCenter:itemFields.description', 'Description');

    return (
        <Container fluid={true}>
            <Row>
                <Col xs={12}>
                    <div style={{ marginTop: '10px' }} className="spot-form__field-group">
                        <Field name="hospitalDescription">
                            {({ input, meta }) => (
                                <div>
                                    <label className="spot-form__label">
                                        {i18n.t('controlCenter:itemFields.practiceDescription', 'Practice Description')}
                                    </label>
                                    <SpotTextInput ref={ref as any} {...input} error={meta} placeholder={hospitalDescriptionPlaceholder} />
                                </div>
                            )}
                        </Field>
                    </div>
                </Col>
            </Row>
        </Container>
    );
});
