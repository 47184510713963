import React, { ReactElement, useEffect } from 'react';
import i18n from '../../i18n';
import { Col, Row } from 'react-bootstrap';
import styles from './userManagement.module.scss';
import { FlexPageWrapper } from '../../layout/main/flexPageWrapper';
import { Link, Route } from 'react-router-dom';
import { SpotHorizontalRule, SpotSvg } from '@enterprise/spot';
import { PageHeaderNavigation } from '../../layout/main/pageHeaderNavigation';
import { useStores } from '../../hooks';
import { observer } from 'mobx-react';
import { UsersGrid } from './usersGrid';
import { SubMenuType } from '../../store';
import { EditUserPage } from './editUser/editUserPage';
import { RouterPaths } from '../../router/RouterPaths';
import { RootRoutes } from '../../router';

export const UserManagementPage = observer(function PracticeConnectionsPage() {
    const {
        domain: { usersStore },
        ui: { app, router },
    } = useStores();
    const { isLoading, users = [] } = usersStore;

    useEffect(() => {
        void usersStore.fetchUsers();
    }, [usersStore]);
    const currentUser = app.token.user;

    const pageMenu = app.getSubMenuItems(SubMenuType.OrganizationManagement);
    const renderInviteUserLink = (): ReactElement => {
        return (
            <Link data-automation-id="invite-user-link" to={'#' /* add invite user url here*/} className="spot-link spot-link--large">
                <SpotSvg icon="add" className="spot-icon spot-link__icon spot-link__icon--left" />
                {i18n.t('myOrganization:inviteUser', 'Invite User')}
            </Link>
        );
    };

    return (
        <RootRoutes>
            <Route
                path={`${RouterPaths.MyOrganizationPages.EditUser}/:userId`}
                element={<EditUserPage back={() => router.push(`${RouterPaths.MyOrganizationPages.UserManagement}`)} />}
            />
            <Route
                path={`${RouterPaths.MyOrganizationPages.UserManagement}`}
                element={
                    <FlexPageWrapper header={<PageHeaderNavigation items={pageMenu} />} className={styles.holder} isLoading={isLoading}>
                        <Row className={styles.container} data-automation-id="user-management">
                            <Col xs={12} data-automation-id="organization-users" className={styles.usersBlock}>
                                <div className={styles.titleBlock}>
                                    <div className="spot-typography__heading--level-3" data-automation-id="user-management-heading">
                                        {i18n.t('myOrganization:users', 'Users')}
                                        {Boolean(users?.length > 1) && <> ({users?.length})</>}
                                    </div>
                                    <div className={styles.connectUser}>{renderInviteUserLink()}</div>
                                </div>

                                <SpotHorizontalRule />
                                <UsersGrid users={users} className={styles.grid} currentUser={currentUser} />
                            </Col>
                        </Row>
                    </FlexPageWrapper>
                }
            />
        </RootRoutes>
    );
});
