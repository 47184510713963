import React from 'react';
import { Board, BoardEvent, BoardOptions, INTENT } from '@idexx/cfs-mapping-ui';
import { MappingTypes } from '../../../core/enums/Mapping';
import { ErrorBoundary } from '../../ErrorBoundary';
import i18n from 'i18next';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';

export enum ClassificationsActionMenuItems {
    CREATE_CLASS = 'create-class',
    CREATE_SUBCLASS = 'create-subclass',
}

export const ClassificationsBoardPage: React.FC = observer(({}) => {
    const {
        domain: { masterDataPageStore },
    } = useStores();

    const { mappedClassifications, unmappedClassifications } = masterDataPageStore;
    if (!mappedClassifications || !unmappedClassifications) {
        return <FallBack />;
    }

    const initial = [mappedClassifications, ...unmappedClassifications];

    const eventHandler = async (event: BoardEvent) => {
        return await masterDataPageStore.processMappingEvent(event, MappingTypes.CLASSIFICATION);
    };

    const options: BoardOptions = {
        allowChildCardCreation: true,
        allowPrimaryCardTitleClick: true,
        columnOptions: {
            primaryActionMenu: [
                {
                    icon: '/assets/spot/icons/spot_icons.svg#add',
                    id: ClassificationsActionMenuItems.CREATE_CLASS,
                    intent: INTENT.PRIMARY,
                    text: i18n.t('masterData:classificationsPage.createNewClassification', 'Create a new Master Classification'),
                },
            ],
        },
        createChildAction: {
            icon: '/assets/spot/icons/spot_icons.svg#add',
            id: ClassificationsActionMenuItems.CREATE_SUBCLASS,
            text: i18n.t('masterData:classificationsPage.createNewSubclassification', 'Create Master Subclassification'),
        },
        disableWhileProcessing: true,
        hideDuplicateCardDescriptions: true,
    };

    return (
        <div className={'board'}>
            <ErrorBoundary fallbackComponent={<FallBack />} suppressNotifications={true}>
                <Board initial={initial} eventHandler={eventHandler} options={options} />
            </ErrorBoundary>
        </div>
    );
});

const FallBack: React.FC = () => (
    <div>
        <h4>{i18n.t('masterData:classificationsPage.unableToDisplayBoard')}</h4>
    </div>
);
