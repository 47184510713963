import React, { PropsWithChildren, useState } from 'react';
import styles from './itemAddPractices.module.scss';
import { observer } from 'mobx-react';
import i18n from 'i18next';
import { SelectPracticesRegionsGrid } from './selectPracticeRegionsGrid';
import { PracticeInfo } from '../../../../practiceConnections/MyOrganizationPageStore';
import { LabelEntity } from 'apps/client/src/core/entity/LabelEntity';
import { SelectionGridState } from './selectIonGridState';

type ItemAddPracticesProps = PropsWithChildren<{
    practices: PracticeInfo[];
    regions: LabelEntity[];
    onChange: (value: SelectionGridState) => void;
    showError?: boolean;
    selectionGridState: SelectionGridState;
}>;
export const ItemAddPractices = observer(function ItemAddPracticesComponent(props: ItemAddPracticesProps) {
    const [showLocalError, setShowLocalError] = useState(true);
    const { practices, regions, onChange, showError, selectionGridState } = props;

    const onPracticeSelectionChange = (value: SelectionGridState) => {
        setShowLocalError(!value.selectedPractices || value.selectedPractices.length < 1);
        onChange(value);
    };

    return (
        <>
            <div className={styles.practicesMainContainer}>
                {showError && showLocalError && (
                    <div className={styles.selectionError} data-automation-id={'bulkUpdatePracticesNoSelectionError'}>
                        <div className={styles.selectionErrorText}></div>
                        {i18n.t('controlCenter:itemSelectPractices:selectionRequired', 'Selection Required')}
                    </div>
                )}
                <SelectPracticesRegionsGrid
                    practices={practices}
                    regions={regions}
                    onSelectionChange={onPracticeSelectionChange}
                    selectionGridState={selectionGridState}
                />
            </div>
        </>
    );
});
