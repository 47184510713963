import {
    InvoiceItem,
    InvoiceItemClassification,
    InvoiceItemInventory,
    InvoiceItemPrices,
    InvoiceItemService,
    InvoiceItemType,
    InvoiceItemTypes,
    PriceBreak,
    UnitOfMeasure,
} from '../../../datasource/invoiceItems';
import { IsNotEmpty, Matches, ValidateNested } from 'class-validator';
import i18n from 'i18next';
import { Exclude, Type, TypeHelpOptions } from 'class-transformer';
import { Default } from '@enterprise/common';

class AnimanaInvoiceItemUnitOfMeasure extends UnitOfMeasure {
    pimsGeneratedId: string;
}

class AnimanaInvoiceItemInventory extends InvoiceItemInventory {
    @ValidateNested()
    @Type(() => AnimanaInvoiceItemUnitOfMeasure)
    @Default({})
    unitOfMeasure?: AnimanaInvoiceItemUnitOfMeasure = new AnimanaInvoiceItemUnitOfMeasure();
}

class AnimanaInvoiceItemClassification extends InvoiceItemClassification {
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.productGroup', 'Product Group') }),
    })
    pimsGeneratedId: string;
}

class AnimanaInvoiceItemSubClassification extends InvoiceItemClassification {
    pimsGeneratedId: string;
}

class AnimanaInvoiceItemType extends InvoiceItemType {
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.itemType', 'Item Type') }),
    })
    id: InvoiceItemTypes;
}

class AnimanaInvoiceItemPrices extends InvoiceItemPrices {
    @Exclude()
    itemTypeId: string;

    constructor(values?: TypeHelpOptions) {
        super();
        this.itemTypeId = values?.newObject?.itemType?.id;
    }

    @Default(0)
    minimumPrice: number;

    @Default(0)
    dispensingFee?: number;

    priceBreaks: PriceBreak[] = [];
}

export class AnimanaInvoiceItem extends InvoiceItem {
    @ValidateNested()
    @Type(() => AnimanaInvoiceItemClassification)
    @Default({})
    classification: AnimanaInvoiceItemClassification = new AnimanaInvoiceItemClassification();

    @Type(() => AnimanaInvoiceItemSubClassification)
    subClassification: AnimanaInvoiceItemSubClassification | null = null;

    @Matches(new RegExp('^[a-zA-Z0-9].*'), {
        message: () =>
            i18n.t(
                'controlCenter:validation.hospitalDescription_first_char',
                'The first character of the hospital description must be one of the following characters. 0-9 or A-Z',
            ),
    })
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.name', 'Name') }),
    })
    hospitalDescription: string;

    @ValidateNested()
    @Type(() => AnimanaInvoiceItemType)
    itemType: AnimanaInvoiceItemType = new AnimanaInvoiceItemType();

    @ValidateNested()
    @Type(type => () => new AnimanaInvoiceItemPrices(type))
    prices: AnimanaInvoiceItemPrices = new AnimanaInvoiceItemPrices();

    @ValidateNested()
    @Type((opts?: TypeHelpOptions) => {
        switch (String(opts?.newObject.itemType.id)) {
            case String(InvoiceItemTypes.Inventory):
                return AnimanaInvoiceItemInventory;
            case String(InvoiceItemTypes.Service):
                return InvoiceItemService;
            default:
                return Function;
        }
    })
    typeDetails: AnimanaInvoiceItemInventory | InvoiceItemService = {};
}
