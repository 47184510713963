import * as React from 'react';
import { Carousel } from '../carousel/Carousel';
import { protipts_data } from '../../environment/protipts_data';
import { Image } from './Image';
import { SpotLoading } from '@enterprise/spot';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

interface ProTipsProps {
    text?: string;
    isShowCancelLink?: boolean;
    onCancelClick?: () => void;
    className?: string;
}

export class ProTips extends React.PureComponent<ProTipsProps> {
    static defaultProps: ProTipsProps = {};
    private cRef: any;
    private interval;

    componentDidMount() {
        if (this.cRef) {
            const rs = Math.floor(Math.random() * (protipts_data.length + 1));
            (this.cRef as Carousel).goToSlide(rs);
        }
        this.interval = setInterval(() => {
            if (this.cRef) {
                (this.cRef as Carousel).goToNextSlide();
            }
        }, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const { text, isShowCancelLink, onCancelClick, className } = this.props;
        const data = protipts_data.map(i => {
            return { ...i, img: <Image {...i.img} /> };
        });
        return (
            <div className={classNames('pro-tips-container', className)}>
                <Carousel ref={r => (this.cRef = r)} content={data} hideArrows={true} hideSlidersIndicator={true} />
                <div style={{ textAlign: 'center' }}>
                    <SpotLoading size="large" text={text} />
                    {isShowCancelLink && (
                        <>
                            <br />
                            <button className="spot-modal__footer-cancel-button cancel-button" onClick={onCancelClick}>
                                <Trans i18nKey="common:cancel">cancel</Trans>
                            </button>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
