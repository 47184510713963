import * as React from 'react';
import { Route, useLocation, Routes, useNavigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import BasicLayout from '../layout/basic/BasicLayout';
import { RedirectToRouteComponent } from './RedirectToRouteComponent';
import { RouterPaths } from './RouterPaths';
import { observer } from 'mobx-react';
import { RouteParams, routes } from './routes';
import { useEffect, useMemo } from 'react';
import { useStores } from '../hooks';
import { SpotLoading } from '@enterprise/spot';

// TODO: re-enable collection of profile information when we want the feature
// import { CollectProfileInformation } from '../components/profile/collectProfileInformation';

const RoutesProvider = observer(function ConnectRouter() {
    const {
        ui: { app, router },
    } = useStores();

    const navigate = useNavigate();
    const location = useLocation();

    const isLoading = !app.isLoaded || !router.location || !router.navigate;

    useEffect(() => {
        router.navigate = navigate;
    }, [navigate]);

    useEffect(() => {
        router.location = location;
    }, [location.pathname]);

    const isAllowedRoute = ({ isSecured, role: expectedRoles }: RouteParams): boolean => {
        if (!isSecured) {
            return true;
        }

        if (!app.isAuthenticated) {
            return false;
        }

        return !expectedRoles || Boolean(Array.from(expectedRoles).find((role) => app.token.hasRole(role)));
    };

    const routesCmp = useMemo(() => {
        return routes.map((route, index) => {
            const { Component, config } = route;
            const Layout = route.Layout ? route.Layout : BasicLayout;
            const isAllowed = isAllowedRoute(route);

            return isAllowed ? (
                <Route
                    key={index}
                    path={`${route.path}${route.exact ? '' : '/*'}`}
                    element={
                        <Layout {...config}>
                            <Component {...config} />
                        </Layout>
                    }
                />
            ) : (
                <Route
                    key={index}
                    path={`${route.path}${route.exact ? '' : '/*'}`}
                    element={<RedirectToRouteComponent to={config?.secureRedirectTo || RouterPaths.BasePages.Root} />}
                />
            );
        });
    }, [location.pathname, routes, app.token, app.isAuthenticated]);

    return (
        <>
            {/*TODO: re-enable collection of profile information when we want the feature*/}
            {/*<CollectProfileInformation />*/}
            {isLoading && <SpotLoading />}
            {!isLoading && <Routes>{routesCmp}</Routes>}
        </>
    );
});

export const ConnectRouter = () => {
    const router = useMemo(() => {
        return createBrowserRouter([
            {
                path: '*',
                Component: RoutesProvider,
            },
        ]);
    }, []);

    return <RouterProvider router={router} />;
};
