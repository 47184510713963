import { Exceptions, Feature } from '@enterprise/common';
import { isMatch } from 'lodash';

export interface FeatureDatasourceInterface {
    [name: string]: () => Feature | undefined;
}

export class FeatureManager {
    /**
     * List of source to ask is feature is avaliable
     * @type {Set<FeatureDatasourceInterface>}
     */
    private datasource: Set<FeatureDatasourceInterface> = new Set();

    constructor(ds: FeatureDatasourceInterface[]) {
        ds.map((d) => this.addSource(d));
    }

    addSource(ds: FeatureDatasourceInterface) {
        if (this.hasSource(ds)) {
            throw new Exceptions.LogicException(`Datasource already registered`);
        }
        return this.datasource.add(ds);
    }

    hasSource(ds: FeatureDatasourceInterface): boolean {
        return this.datasource.has(ds);
    }

    removeSource(ds: FeatureDatasourceInterface) {
        if (!this.hasSource(ds)) {
            throw new Exceptions.LogicException(`Datasource do not exist`);
        }
        return this.datasource.delete(ds);
    }

    /**
     *
     * @param {string} name
     * @param {boolean} strict Can be set to false to allow feature if its does not mention in any of data sources
     * @param criteria Check if feature has the provided criteria
     * @return {boolean}
     */
    isAvailable(name: string, strict: boolean = true, criteria?: Record<string, unknown>): boolean {
        const ds = Array.from(this.datasource);
        const len = ds.length;
        for (let i = 0; i < len; i++) {
            if (ds[i][name]) {
                if (ds[i][name] instanceof Function) {
                    const feature = ds[i][name]();
                    return Boolean(feature?.isActive && (!criteria || isMatch(feature.criteria || {}, criteria)));
                } else {
                    return !!ds[i][name];
                }
            }
        }
        return !strict;
    }

    /**
     * Executes a callback function in case if feature is available.
     * Can be used in Services when there is a need to enable part of the code only in case if it allowed
     * @param {string} name
     * @param {() => void} callback
     * @param {boolean} strict
     */
    runIfAvailable(name: string, callback: () => void, strict?: boolean) {
        if (this.isAvailable(name, strict)) {
            callback();
        }
    }
}
