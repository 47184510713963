import { observer } from 'mobx-react';
import React from 'react';
import { MasterDataPageStore, UnlinkOptions } from '../MasterDataPageStore';
import { RouterStore } from '../../../store';
import { RightSidebar } from '../../../layout/main/rightSidebar';
import { RouterPaths } from '../../../router/RouterPaths';
import { Col, Container, Row } from 'react-bootstrap';
import { LinkedItem, NO_MAPPED_ID } from '../../../core/entity/mapping';
import { MAPPING_UI } from '@idexx/cfs-mapping-ui';
import { RefObject, SyntheticEvent } from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { ClassificationEditFields } from './editable/ClassificationEditFields';
import { SpotSvg } from '@enterprise/spot';
import i18n from 'i18next';
import { DeleteWidget } from '../../../components/helpers/DeleteWidget';
import { StoresContext } from '../../../contexts';
import { matchPath } from 'react-router';

interface ClassificationDetailsPageState {
    loadingError?: string;
}

@observer
export class ClassificationDetailsPage extends React.Component<{}, ClassificationDetailsPageState> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    get masterDataPageStore(): MasterDataPageStore {
        return this.context.domain.masterDataPageStore;
    }

    get routerStore(): RouterStore {
        return this.context.ui.router;
    }

    private refMap = new Map<string, RefObject<HTMLDivElement>>();

    state = {
        loadingError: undefined,
    };

    get isNewClassification(): boolean | undefined {
        return Boolean(matchPath({ path: RouterPaths.MasterDataPages.ClassificationsMappingCreatePage, end: true }, location.pathname));
    }

    componentDidMount() {
        const { id, parentId } = this.classificationParameters;

        if (!this.isNewClassification) {
            this.loadClassification(id, parentId);
        }

        if (this.isNewClassification && parentId) {
            this.loadClassification(parentId);
        }
    }

    componentWillUnmount(): void {
        this.masterDataPageStore.classification = undefined;
    }

    loadClassification = (id: string, parentId?: string) => {
        this.masterDataPageStore.getClassification(String(id), (parentId && String(parentId)) || undefined).catch(() => {
            this.setState({ loadingError: i18n.t('common:classificationDetails.loadingError') });
        });
    };

    get classificationParameters(): { id: string; parentId?: string } {
        const { id, parentId } = this.routerStore.params;
        return { id: String(id), parentId: (parentId || undefined) && String(parentId) };
    }

    get parentId(): string | undefined {
        const { parentId } = this.classificationParameters;
        return parentId;
    }

    back = () => {
        this.routerStore.push(RouterPaths.MasterDataPages.ClassificationsMappingPage);
    };

    cancelEdit = (event: SyntheticEvent) => {
        event.preventDefault();
        if (this.isNewClassification) {
            this.back();
        }
    };

    onSuccessfulEdit = () => {
        if (this.isNewClassification) {
            this.routerStore.push(RouterPaths.MasterDataPages.ClassificationsMappingPage);
        }
    };

    scrollHandler = (event: React.BaseSyntheticEvent) => {
        const letterClicked = event.target.innerText;
        const scrollRef = this.refMap.get(letterClicked);
        if (!scrollRef || !scrollRef.current) {
            return;
        }

        scrollRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    };

    render() {
        const { classification, isUnlinkingClassification, isDeletingClassification, isLoadingClassification } = this.masterDataPageStore;
        const showLoadingSpinner = isLoadingClassification || isUnlinkingClassification || isDeletingClassification;
        const loadingText = isDeletingClassification
            ? i18n.t('common:classificationDetails.deletingClassification', 'Deleting classification...')
            : i18n.t('common:classificationDetails.loadingClassification', 'Loading classification...');

        return (
            <div className="classification-details">
                <RightSidebar
                    isLoading={showLoadingSpinner}
                    loadingText={loadingText}
                    header={this.renderHeader}
                    close={this.back}
                    isSmall={true}
                    modalBodyStyle={{ backgroundColor: '#ffffff' }}
                >
                    <>
                        {this.state.loadingError && <span>{this.state.loadingError}</span>}
                        {!this.state.loadingError && this.isNewClassification && (
                            <ClassificationEditFields onCancel={this.cancelEdit} onSuccess={this.onSuccessfulEdit} parentId={this.parentId} />
                        )}
                        {!this.state.loadingError && !this.isNewClassification && classification && this.renderClassifications(classification.mapped)}
                    </>
                </RightSidebar>
            </div>
        );
    }

    unlink = (siteId, linkedId, pimsParentId, description) => {
        const { id, parentId } = this.classificationParameters;
        const options: UnlinkOptions = {
            mappingId: String(id),
            mappingParentId: (parentId && String(parentId)) || undefined,
            siteId,
            linkedId,
            pimsParentId,
            description,
        };
        this.masterDataPageStore.unlinkClassification(options);
    };

    renderNewClassificationHeader = () => {
        const { classification } = this.masterDataPageStore;

        return !this.parentId ? (
            <Container className="header">
                <Row>
                    <Col xs={12}>
                        <span className="title">
                            <Trans i18nKey="masterData:classificationsPage.newMasterClassification">New Master Classification</Trans>
                        </span>
                    </Col>
                </Row>
            </Container>
        ) : (
            <Container className="header">
                <Row>
                    <Col xs={12}>
                        <span className="title">
                            <Trans i18nKey="masterData:classificationsPage.newMasterSubclassification">New Master Subclassification</Trans>
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <span className="info">
                            {i18n.t('masterData:classificationsPage.classification', 'Classification')}: {classification?.data.description}
                        </span>
                    </Col>
                </Row>
            </Container>
        );
    };

    renderHeader = () => {
        const { classification } = this.masterDataPageStore;

        if (this.isNewClassification) {
            return this.renderNewClassificationHeader();
        }

        if (!classification) {
            return null;
        }

        const onDeleteHandler = () => {
            this.masterDataPageStore.deleteClassification(classification).then((isSuccess: boolean) => {
                if (isSuccess) {
                    this.back();
                }
            });
        };

        return (
            <Container className="header">
                <Row>
                    <Col xs={12}>
                        <span className="title">{classification.data.description}</span>
                        <span className="id">{classification.id}</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <DeleteWidget automationId="delete-classification" onConfirmDelete={onDeleteHandler} />
                    </Col>
                </Row>
            </Container>
        );
    };

    renderClassifications = (mapped: LinkedItem[] = []) => {
        const uniqueSiteIds = Array.from(new Set(mapped.map((mapped) => mapped.practiceId)));
        const sitesSortedByName = this.sortSitesByName(uniqueSiteIds);
        const nameFirstLetters: string[] = Array.from(new Set(sitesSortedByName.map((obj) => obj.siteName[0]).map((letter) => letter.toUpperCase())));
        let clinicsCount = 0;
        if (mapped) {
            const uniqueSiteIds = new Set(mapped.map(({ practiceId }) => practiceId));
            clinicsCount = uniqueSiteIds.size;
        }
        return (
            <div className="body">
                <div className="content">
                    <div className="details">
                        <Trans i18nKey="common:clinics.clinicsCount" count={clinicsCount} />
                    </div>
                    {this.renderMappedClassifications(sitesSortedByName, mapped)}
                </div>
                <div className={classNames(MAPPING_UI.COLUMN_LETTER_BAR, 'letter-bar')}>
                    <div>
                        {nameFirstLetters &&
                            nameFirstLetters.map((letter) => (
                                <span onClick={this.scrollHandler} key={letter}>
                                    {letter}
                                </span>
                            ))}
                    </div>
                </div>
            </div>
        );
    };

    sortSitesByName = (siteIds) => {
        const siteIdName = siteIds.map((siteId) => {
            const site = this.masterDataPageStore.applications.find((app) => `${app.id}` === `${siteId}`);
            const siteName = site ? site.name : 'A';
            return { siteId, siteName };
        });
        siteIdName.sort((a, b) => a.siteName.localeCompare(b.siteName));
        return siteIdName;
    };

    renderMappedClassifications = (sitesSortedByName, mapped: LinkedItem[]) => {
        if (mapped.length === 0) {
            return null;
        }

        const groupedBySite = this.groupMappedBySite(mapped);

        return sitesSortedByName.map((siteIdName) => {
            const siteId = siteIdName.siteId;
            const siteName = siteIdName.siteName;
            const mappedForSite = groupedBySite.get(siteId) || [];
            return this.renderMappedForSiteClassifications(siteId, siteName, mappedForSite);
        });
    };

    groupMappedBySite = (mapped: LinkedItem[]) => {
        const grouped: Map<string, LinkedItem[]> = new Map();
        mapped.forEach((mappedElement) => {
            const siteId = mappedElement.practiceId;
            const mappedForSite = grouped.has(siteId) ? grouped.get(siteId) || [] : [];
            mappedForSite.push(mappedElement);
            grouped.set(siteId, mappedForSite);
        });
        return grouped;
    };

    renderMappedForSiteClassifications = (siteId: string, siteName: string, mappedForSite: LinkedItem[]) => {
        const nameFirstLetter = siteName[0];
        const scrollRef: RefObject<HTMLDivElement> = React.createRef();
        if (!this.refMap.has(nameFirstLetter)) {
            this.refMap.set(nameFirstLetter, scrollRef);
        }

        return (
            <div className="site-mappings" key={siteId}>
                <div className="site-description" ref={scrollRef}>
                    <span className="name">{siteName}</span>
                    <span className="id float-right">{siteId}</span>
                </div>
                {mappedForSite.map((linkedItem) => {
                    const data = linkedItem.data || {};
                    const unlink = () => this.unlink(siteId, linkedItem.linkedId, linkedItem.pimsParentId, linkedItem.data?.description);
                    return (
                        <div className="mapping" key={linkedItem.linkedId}>
                            {this.getDisplayableMappingDetails(linkedItem.linkedId, data.description)}
                            <SpotSvg onClick={unlink} icon="delete" className="spot-link__icon float-right unlink-icon" />
                        </div>
                    );
                })}
            </div>
        );
    };

    private getDisplayableMappingDetails = (linkedId: string, description?: string | null): JSX.Element => {
        if (linkedId === NO_MAPPED_ID) {
            return <span className="name empty">{i18n.t('masterData:classificationsPage.emptySubclassification', 'Empty Subclassification')}</span>;
        }
        return (
            <>
                <span className="name">{description}</span>
                <span className="id">{linkedId}</span>
            </>
        );
    };
}
