import React, { useEffect, useRef, useState, memo } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { matchPath } from 'react-router';
import styles from './header.module.scss';
import { SpotDropdown, SpotPill, SpotSvg } from '@enterprise/spot';
import classNames from 'classnames';
import { NavigationMenuItem } from '../../../router/menu';
import { Trans } from 'react-i18next';
import { RouterPaths } from '../../../router/RouterPaths';

export interface HeaderProps {
    user: { username: string; orgName: string; avatar?: string };
    navigation: NavigationMenuItem[];
    onLogout?: () => void;
}

enum MenuAction {
    UserInfo = 'user-info',
    MyProfilePage = 'my-profile-page',
    SignOut = 'sign-out',
}

const maxNotificationsCount = 999;

export const Header = memo(function Header(props: HeaderProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const logout = () => {
        props.onLogout?.();
    };

    const goToMyProfile = () => {
        navigate(RouterPaths.MyProfilePages.MyProfile);
    };

    const [isOpen, setOpen] = useState<boolean>(false);
    const headerRef = useRef<HTMLDivElement>(null);
    const closeOnClickOutside = (event: MouseEvent) => {
        if (!headerRef.current?.contains(event.target as Node)) {
            setOpen(false);
        }
    };
    useEffect(() => {
        document?.addEventListener('mousedown', closeOnClickOutside, true);

        return () => {
            document?.removeEventListener('mousedown', closeOnClickOutside, true);
        };
    }, []);

    const menuItemHandle = (id: MenuAction) => {
        switch (id) {
            case MenuAction.MyProfilePage:
                goToMyProfile();
                break;
            case MenuAction.SignOut:
                logout();
                break;
        }
    };

    const { navigation, user } = props;
    const { username, orgName, avatar } = user;
    const selectedNavigationItem = navigation.find((item) => matchPath(item.path, location.pathname));
    const mainNavigation = navigation.filter(({ iconographyNavigation }) => !iconographyNavigation);
    const iconographyNavigation = navigation.filter(({ iconographyNavigation }) => Boolean(iconographyNavigation));

    const renderUserInfoBlock = () => (
        <div className={styles.userInfoContainer}>
            <SpotDropdown
                className={styles.userMenuContainer}
                onChange={menuItemHandle}
                elements={[
                    {
                        id: MenuAction.UserInfo,
                        isHeading: true,
                        className: styles.menuUserBlock,
                        value: (
                            <>
                                <div className={classNames(styles.userInfoText, 'spot-typography__text--body spot-typography__font-weight--bold')}>
                                    {username}
                                </div>
                                <div className={classNames(styles.userInfoText, 'spot-typography__text--tertiary')}>{orgName}</div>
                            </>
                        ),
                    },
                    {
                        id: MenuAction.MyProfilePage,
                        value: (
                            <span data-automation-id={'my-profile'}>
                                <Trans i18nKey="common:myProfile">My Profile</Trans>
                            </span>
                        ),
                        className: 'spot-dropdown__content-item--single-line',
                    },
                    { id: 'divider', isDivider: true, className: styles.menuUserBlock },
                    {
                        id: MenuAction.SignOut,
                        value: (
                            <span data-automation-id={'sign-out'}>
                                <Trans i18nKey="common:signOut">Sign Out</Trans>
                            </span>
                        ),
                        className: 'spot-dropdown__content-item--single-line',
                    },
                ]}
                titleClassName={styles.dropdownTitle}
                title={
                    <div className={styles.userInfoBlock} data-automation-id={'user-info-block'}>
                        <div className={classNames(styles.userInfo, 'spot-link--ellipsis')}>
                            <span className="spot-link--ellipsis spot-typography__text--body spot-typography__font-weight--bold" title={username}>
                                {username}
                            </span>
                            <span className="spot-link--ellipsis spot-typography__text--tertiary" title={orgName}>
                                {orgName}
                            </span>
                        </div>
                        <div className={styles.userIcon}>
                            {avatar ? (
                                <img src={avatar} />
                            ) : (
                                <svg>
                                    <use href={'/assets/img/icons.svg#users'} />
                                </svg>
                            )}
                        </div>
                    </div>
                }
            />
        </div>
    );

    return (
        <div className={styles.holder} ref={headerRef} data-automation-id={'header-holder'}>
            <Navbar expand="lg" expanded={isOpen} onToggle={(expanded) => setOpen(expanded)} className={styles.navBar}>
                <Navbar.Collapse id="responsive-navbar-nav" className={styles.menu}>
                    <Nav data-automation-id={'main-navigation'}>
                        {mainNavigation.map(({ automationId, label, path }) => {
                            return (
                                <NavLink
                                    key={path}
                                    to={path}
                                    data-label={label}
                                    className={({ isActive }) => classNames(styles.navigationItem, { [styles.navigationItemActive]: isActive })}
                                    data-automation-id={automationId}
                                >
                                    <span>{label}</span>
                                </NavLink>
                            );
                        })}
                    </Nav>
                </Navbar.Collapse>

                <div className={styles.content}>
                    <div className={styles.leftBlock}>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className={styles.toggleButton} data-automation-id={'navigation-toggle'}>
                            <SpotSvg icon="menu" className={'spot-icon'} />
                        </Navbar.Toggle>
                        <div className={styles.title}>
                            <span>IDEXX</span>
                            <span className={styles.selectedItem}>{selectedNavigationItem?.label}</span>
                        </div>
                        <NavLink key={'/'} to={'/'} className={styles.logo}>
                            <svg>
                                <use href={'/assets/img/IDEXX_Logo_Color.svg#logo'} />
                            </svg>
                        </NavLink>
                    </div>
                    <div className={styles.rightBlock} data-automation-id={'iconography-navigation'}>
                        {iconographyNavigation.map(({ iconPath, notificationsCount, path, automationId }) => {
                            return (
                                <NavLink
                                    key={path}
                                    to={path}
                                    className={({ isActive }) =>
                                        classNames(styles.navigationIcon, {
                                            [styles.navigationIconActive]: isActive || location.pathname.includes('/user-management'),
                                        })
                                    }
                                    data-automation-id={automationId}
                                >
                                    <svg>
                                        <use data-automation-id={'iconography-navigation-icon'} href={iconPath} />
                                    </svg>
                                    {(notificationsCount || false) && (
                                        <SpotPill type={'negative'} isSmall={true} className={styles.navigationIconNotification}>
                                            {notificationsCount > maxNotificationsCount ? maxNotificationsCount : notificationsCount}
                                        </SpotPill>
                                    )}
                                </NavLink>
                            );
                        })}

                        {renderUserInfoBlock()}
                    </div>
                </div>
            </Navbar>
        </div>
    );
});
