import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { kebabCase } from 'lodash';
import { Automatable } from '@enterprise/spot';

interface SideTabProps extends Automatable {
    children: JSX.Element[] | any[];
}

interface SideTabState {
    active: number;
}

export class SideTab extends React.Component<SideTabProps, SideTabState> {
    state: SideTabState = {
        active: 0,
    };

    onLabelClicked = (idx: number) => {
        return () => {
            this.activate(idx);
        };
    };

    activate(idx: number) {
        const { children } = this.props;
        if (idx >= children.length) {
            this.setState({ active: 0 });
            return;
        }
        if (idx < 0) {
            this.setState({ active: children.length - 1 });
            return;
        }
        this.setState({ active: idx });
    }

    next = () => {
        const { active } = this.state;
        this.activate(active + 1);
    };

    prev = () => {
        const { active } = this.state;
        this.activate(active - 1);
    };

    render() {
        const { ['data-automation-id']: automationId = 'side-tab', children, ...rest } = this.props;
        const { active } = this.state;
        const tabs: any[] = React.Children.toArray(children);

        return (
            <Container fluid={true}>
                <Row noGutters={true}>
                    <Col xs={2} style={{ paddingTop: '16px', borderRight: '2px solid #D8D8D8' }}>
                        <ul className="spot-list-group">
                            {tabs.map((tab, idx) => {
                                return (
                                    <li key={idx} className={`spot-list-group__item ${idx === active ? 'spot-list-group__item--active' : ''}`}>
                                        <a
                                            data-automation-id={`${automationId}-${kebabCase(tab.props.label)}-tab`}
                                            href="javascript:void(0)"
                                            className="spot-list-group__link"
                                            onClick={this.onLabelClicked(idx)}
                                        >
                                            <span className="spot-list-group__item-label">{tab.props.label}</span>
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </Col>
                    <Col xs={10}>
                        {React.isValidElement(children[active])
                            ? React.cloneElement(children[active], rest)
                            : React.createElement(children[active], rest)}
                    </Col>
                </Row>
            </Container>
        );
    }
}
