import * as React from 'react';
import { Image } from './Image';
import { Link } from 'react-router-dom';

interface ComingSoonProps {
    to: string;
    toLabel: string;
    image: string;
}

export class ComingSoon extends React.PureComponent<ComingSoonProps> {
    static defaultProps: ComingSoonProps = {
        image: 'img/coomingSoon.svg',
        toLabel: 'Return to the main control center.',
        to: '/control-center/items',
    };

    render() {
        const { image, to, toLabel } = this.props;
        return (
            <div className={'coming-soon--container'}>
                <div>
                    <Image url={image} />
                </div>
                <div>
                    <h1>Coming Soon!</h1>
                    <p>The team is busy working on new features.</p>
                    <Link to={to} className="spot-link">
                        {toLabel}
                    </Link>
                </div>
            </div>
        );
    }
}
