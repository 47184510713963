import {
    GridApi,
    GridColumnOrderChangeParams,
    GridColumnResizeParams,
    GridColumnVisibilityModel,
    GridPinnedColumns,
    GridRowGroupingModel,
    GridSortModel,
    GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@enterprise/material-data-grid';
import { GridState } from '../../../../../store/invoiceItem.store';
import { MutableRefObject, useEffect, useMemo } from 'react';
import { ItemsGridColumn } from './useItemsGridColumns.hook';

export const useItemGridState = (params: {
    columnsState?: GridState;
    onColumnsStateChange?: (state: GridState) => void;
    apiRef: MutableRefObject<GridApi>;
}) => {
    const { columnsState = {}, onColumnsStateChange: onChange, apiRef } = params;

    const onColumnsStateChange = (value: GridState) => {
        // this is required to make state syn properly
        //  and not to lose intermediate state when fire several change events in at once
        Object.assign(columnsState, value);
        onChange?.(columnsState);
    };

    const rowGroupingModel = useMemo(() => {
        return columnsState.groupingModel;
    }, [String(columnsState.groupingModel)]);

    const onRowGroupingModelChange = (model: GridRowGroupingModel | undefined) => {
        const removed = columnsState.groupingModel?.filter((name) => !model?.includes(name)) || [];
        const added = model?.filter((name) => !columnsState.groupingModel?.includes(name)) || [];
        const visibilityModel = columnsState.visibilityModel || {};

        onColumnsStateChange({
            ...columnsState,
            groupingModel: model,
            visibilityModel: {
                ...visibilityModel,
                ...removed.reduce((agg, name) => ({ ...agg, [name]: true }), {}),
                ...added.reduce((agg, name) => ({ ...agg, [name]: false }), {}),
            },
        });
    };

    const pinnedColumns: GridPinnedColumns = useMemo(
        () => ({
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...(columnsState.pinned?.left || [])],
            right: columnsState.pinned?.right,
        }),
        [JSON.stringify(columnsState.pinned)],
    );

    const onPinnedColumnsChange = (pinnedColumns: GridPinnedColumns) => {
        onColumnsStateChange({
            ...columnsState,
            pinned: {
                left: pinnedColumns.left?.filter((item) => item !== GRID_CHECKBOX_SELECTION_COL_DEF.field),
                right: pinnedColumns.right,
            },
        });
    };

    useEffect(() => {
        if (!apiRef.current.state) {
            return;
        }

        if (!columnsState.widthModel) {
            return;
        }

        Object.entries(columnsState.widthModel)?.forEach(([name, width]) => {
            const colDef = apiRef.current.getColumn(name);

            if (colDef && width !== undefined && width !== colDef.width) {
                apiRef.current.setColumnWidth(name, width);
            }
        });
    }, [JSON.stringify(columnsState.widthModel), apiRef.current.state]);

    const onColumnWidthChange = ({ colDef, width }: GridColumnResizeParams) => {
        const model = columnsState.widthModel || {};
        model[colDef.field] = width;

        onColumnsStateChange({
            ...columnsState,
            widthModel: model,
        });
    };

    const columnVisibilityModel: GridColumnVisibilityModel | undefined = useMemo(() => {
        if (!apiRef.current.state) {
            return;
        }

        if (!columnsState.visibilityModel) {
            return {};
        }

        const model = Object.entries(columnsState.visibilityModel).reduce((agg, [key, value]) => {
            const isVisible = !rowGroupingModel?.includes(key as ItemsGridColumn) && value;

            return {
                ...agg,
                [key]: isVisible,
            };
        }, {});

        return model;
    }, [JSON.stringify(columnsState.visibilityModel), rowGroupingModel, apiRef.current.state]);

    const onColumnVisibilityModelChange = (model: GridColumnVisibilityModel) => {
        const visibilityModel: Record<number | string, boolean | undefined> = { ...model };

        Object.entries(visibilityModel).forEach(([key]) => {
            if (columnsState.groupingModel?.includes(key)) {
                visibilityModel[key] = columnsState.visibilityModel?.[key];
            }
        });

        onColumnsStateChange({
            ...columnsState,
            visibilityModel,
        });
    };

    const sortModel: GridSortModel | undefined = useMemo(() => {
        if (!apiRef.current.state) {
            return;
        }

        if (!columnsState.sortModel) {
            return [];
        }

        return columnsState.sortModel;
    }, [columnsState.sortModel, apiRef.current.state]);

    const onSortModelChange = (model: GridSortModel) => {
        onColumnsStateChange({
            ...columnsState,
            sortModel: model,
        });
    };

    const onColumnOrderChange = (params: GridColumnOrderChangeParams) => {
        onColumnsStateChange({
            ...columnsState,
            orderModel: {
                ...(columnsState.orderModel || {}),
                [params.field]: params.targetIndex,
            },
        });
    };

    useEffect(() => {
        if (!columnsState.orderModel || !apiRef.current.state) {
            return;
        }

        apiRef.current.restoreState;

        Object.entries(columnsState.orderModel)?.forEach(([name, order]) => {
            apiRef.current.setColumnIndex(name, order);
        });
    }, [JSON.stringify(columnsState.orderModel), apiRef.current]);

    return {
        pinnedColumns,
        onPinnedColumnsChange,
        onColumnWidthChange,
        columnVisibilityModel,
        onColumnVisibilityModelChange,
        sortModel,
        onSortModelChange,
        rowGroupingModel,
        onRowGroupingModelChange,
        onColumnOrderChange,
    };
};
