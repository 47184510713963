import { observer } from 'mobx-react';
import React, { ReactElement } from 'react';
import { Route, Link, Navigate } from 'react-router-dom';
import { ItemsPage_Legacy } from './items/ItemsPage_Legacy';
import { ActivityPage } from './activity/ActivityPage';
import { FlexPageWrapper } from '../../layout/main/flexPageWrapper';
import { SpotSvg, SpotToggle } from '@enterprise/spot';
import i18n from 'i18next';
import { ImportPage } from './import';
import { RouterPaths } from '../../router/RouterPaths';
import { FEATURES } from '@enterprise/core';
import { FeatureOrComingSoon } from '../../components/helpers/FeatureOrComingSoon';
import { SubMenuType } from '../../store';
import { InvoiceItems } from './items';
import { PageHeaderNavigation } from '../../layout/main/pageHeaderNavigation';
import { ScreenSizeType, useScreenSize, useStores } from '../../hooks';
import styles from './controlCenterPage.module.scss';
import { RootRoutes } from '../../router';

export const ControlCenterPage = observer(() => {
    const {
        ui: { app },
    } = useStores();

    const { isUpTo } = useScreenSize();
    const isMobileView = isUpTo(ScreenSizeType.md);
    const isTabletView = isUpTo(ScreenSizeType.lg);

    const gridViewEnabled = app.featureManager.isAvailable(FEATURES.IMPORT, true);
    const enhancedSearchUiEnabled = app.featureManager.isAvailable(FEATURES.ENHANCED_SEARCH_UI, true);
    const enhancedSearchEnabled = app.featureManager.isAvailable(FEATURES.ENHANCED_PRODUCT_SEARCH, true);
    const newUIEnabled = enhancedSearchUiEnabled && enhancedSearchEnabled;
    const pageMenu = app.getSubMenuItems(SubMenuType.ControlCenter);

    const renderCreateNewItem = (): ReactElement => {
        return (
            <Link data-automation-id="new-item-link" to={RouterPaths.ControlCenterPages.NewItem} className="spot-link spot-link--large">
                <SpotSvg icon="add" className="spot-icon spot-link__icon spot-link__icon--left" />
                {i18n.t('controlCenter:createItem', 'Create New Item')}
            </Link>
        );
    };

    const toggleEnhancedSearch = async () => {
        const features = [
            { name: FEATURES.ENHANCED_PRODUCT_SEARCH, isActive: !newUIEnabled },
            { name: FEATURES.ENHANCED_SEARCH_UI, isActive: !newUIEnabled },
        ];

        await app.updateUserFeatures(features);
    };

    return (
        <FlexPageWrapper
            className={styles.wrapper}
            header={
                <div className={styles.navigation}>
                    <PageHeaderNavigation items={pageMenu} />
                    <div className={styles.headerToggle}>
                        <SpotToggle
                            className={styles.toggle}
                            checked={newUIEnabled}
                            onChange={toggleEnhancedSearch}
                            inputAutomationId="enhanced-search-toggle"
                        />
                        <div className={styles.toggleText}>
                            {(isMobileView || isTabletView) && i18n.t('controlCenter:beta', '(Beta) UI')}
                            {!isMobileView && !isTabletView && i18n.t('controlCenter:betaEnhancedSearch', '(Beta) Enhanced Search')}
                        </div>
                    </div>
                </div>
            }
        >
            <FeatureOrComingSoon name={FEATURES.CONTROL_CENTER}>
                <>
                    <div className={styles.titleBlock}>
                        <div>
                            <div className="spot-typography__heading--level-3" data-automation-id="organization-practices-heading">
                                {i18n.t('controlCenter:title', 'Control Center')}
                            </div>
                            <div className="spot-typography__text--tertiary">
                                {i18n.t('controlCenter:description', 'Centrally manage individual practice applications')}
                            </div>
                        </div>
                        <RootRoutes>
                            <Route path={RouterPaths.ControlCenterPages.Items} element={renderCreateNewItem()} />
                        </RootRoutes>
                    </div>

                    <RootRoutes>
                        <Route path={RouterPaths.ControlCenterPages.ControlCenter} element={<Navigate to={RouterPaths.ControlCenterPages.Items} />} />

                        <Route
                            path={`${RouterPaths.ControlCenterPages.Items}/*`}
                            element={enhancedSearchUiEnabled ? <InvoiceItems /> : <ItemsPage_Legacy />}
                        />
                        {gridViewEnabled && <Route path={`${RouterPaths.ControlCenterPages.Import}/*`} element={<ImportPage />} />}
                        <Route path={`${RouterPaths.ControlCenterPages.Activity}/*`} element={<ActivityPage />} />
                    </RootRoutes>
                </>
            </FeatureOrComingSoon>
        </FlexPageWrapper>
    );
});
