import { Transformers } from '@enterprise/common';
import { pick } from 'lodash';
import { AutoCalculatePrice, InvoiceItem, InvoiceItemTypes, InvoiceItemWithDynamicProps } from '../models';
import { LookupSpecialActionAvailabilities } from '../models/datasource/lookups';

interface InvoiceItemSpecialActionWithDynamicProps {
    action?: {
        description: string;
        id?: string;
    };
    availability?: LookupSpecialActionAvailabilities;
    documentId: number;
}

export class InvoiceItemsWithDynamicPropsToInvoiceItems {
    transform(invoiceItems: InvoiceItemWithDynamicProps[]): Partial<InvoiceItem>[] {
        return invoiceItems.map((invoiceItem) => {
            const item: InvoiceItemWithDynamicProps = new Transformers.ToPlainTransformer().transform(invoiceItem);

            if (!item.pimsGeneratedId) {
                item.pimsGeneratedId = item.pimsId;
            }

            item.discounts = item.discounts?.map((d) => pick(d, ['id', 'departmentCode', 'description', 'percentage']));

            item.specialActions = item.specialActions?.map((specialAction) => {
                const specialActionWithDynamicProps = specialAction as InvoiceItemSpecialActionWithDynamicProps;
                if (specialActionWithDynamicProps.action || specialActionWithDynamicProps.availability) {
                    return {
                        description: specialActionWithDynamicProps.action?.description,
                        documentId: specialActionWithDynamicProps.documentId,
                        specialActionTypeId: specialActionWithDynamicProps.action?.id,
                        whenToApplyDescription: specialActionWithDynamicProps.availability?.whenToApplyDescription,
                        whenToApplyId: specialActionWithDynamicProps.availability?.whenToApplyId,
                    };
                }
                return specialAction;
            });

            const fieldsToMaintainValue = ['barcode', 'clientDescription', 'pimsId'];
            fieldsToMaintainValue.forEach((value) => {
                item[value] = item[value] ?? null;
            });
            this.removeInapplicableSpecialActions(item);
            this.removeRoundToIfAutoCalculatePriceIsDisabled(item);
            this.removeDynamicProps(item);
            this.updateOmitDispensingFee(item);
            return item;
        });
    }

    private removeInapplicableSpecialActions(item): void {
        if (Number(item.itemType) === InvoiceItemTypes.Inventory) {
            item.specialActions = item.specialActions.filter((a) => a.description !== 'Image Request');
        }
    }

    private removeDynamicProps(item: InvoiceItemWithDynamicProps): void {
        // These are carried over from the base level item
        delete item.sites;
        delete item.providers;

        // These properties are set with React Final Form components/methods
        delete item.validateOnly;
    }

    private removeRoundToIfAutoCalculatePriceIsDisabled(item): void {
        if (item.prices?.autoCalculatePrice?.id === AutoCalculatePrice.DoNotAutoCalculatePrice) {
            item.prices.roundTo = null;
        }
    }

    private updateOmitDispensingFee(item): void {
        if (item.itemType?.id !== InvoiceItemTypes.Inventory && item?.prices) {
            item.prices.omitDispensingFeeForMultiplePatients = false;
        }
    }
}
