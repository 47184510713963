import { Service } from 'typedi';
import { makeAutoObservable } from 'mobx';
import { OrganizationService } from '../services/OrganizationService';
import { filterManageableApplications, filterActiveApplications, applyApplicationFilters } from '../core/filters/Applications';
import { QueueService } from '../services/queue/QueueService';
import { OrganizationSettings } from '../core/models/organization';
import { OrganizationSettingsService } from '../services/OrganizationSettingsService';
import { Lookup, LookupClassification, LookupType } from '../core/models/datasource/lookups';
import { LookupsService } from '../services/lookups';
import { DatasourceFullSiteInfo } from '../core/models/datasource/sites';
import { ItemsGroupByType } from '../core/models/organization/settings/enum';

export enum Loadable {
    Applications = 'Applications',
    Classifications = 'Classifications',
    OrganizationSettings = 'OrganizationSettings',
}

@Service()
export default class ConstantsStore {
    applications: DatasourceFullSiteInfo[] = [];

    areClassesBySiteLoaded = false;

    classifications: Lookup<LookupClassification>[] = [];

    classesBySite: Map<string, LookupClassification[]> = new Map();

    organizationSettings: OrganizationSettings | null = null;

    groupsBy: any | null = 'id';

    isLoading = false;

    constructor(
        private readonly lookupsService: LookupsService,
        private readonly organizationService: OrganizationService,
        private readonly organizationSettingsService: OrganizationSettingsService,
    ) {
        makeAutoObservable(this);
    }

    private queueService = new QueueService<Loadable>()
        .register(Loadable.Applications, async () => {
            this.applications = applyApplicationFilters(await this.organizationService.getApplications(), [
                filterManageableApplications,
                filterActiveApplications,
            ]);
        })
        .register(Loadable.Classifications, async () => {
            const { classifications } = await this.lookupsService.getLookupsAsMap([LookupType.CLASSIFICATIONS]);
            this.classifications = classifications;
        })
        .register(Loadable.OrganizationSettings, async () => {
            const settings = await this.organizationSettingsService.getSettings();
            this.groupsBy = settings?.itemsGroupBy || ItemsGroupByType.Id;
            this.organizationSettings = settings;
        });

    async load(toLoad: Loadable[] = Object.values(Loadable), isAddToQueue = false): Promise<void> {
        if (this.isLoading && !isAddToQueue) {
            return Promise.resolve();
        }
        this.isLoading = true;
        await this.queueService.load(toLoad);
        this.isLoading = false;
    }

    async loadClassesAndSubclassesBySite(siteIds: string[]): Promise<void> {
        this.classesBySite.clear();
        this.areClassesBySiteLoaded = false;
        for (const siteId of siteIds) {
            await this.lookupsService
                .getLookups([LookupType.CLASSIFICATIONS_WITH_STATUS], siteId)
                .then((result: any) => {
                    this.classesBySite.set(result?.data[0]?.provider?.siteId, result?.data[0]?.results as LookupClassification[]);
                })
                .catch((err) => console.error);
        }
        this.areClassesBySiteLoaded = true;
        return Promise.resolve();
    }

    reload(): Promise<any> {
        this.isLoading = false;
        return this.load();
    }
}
