import * as React from 'react';
import { SpotSvg } from '../SpotSvg';
import { Automatable } from './common';

export interface SpotButtonProps extends Automatable {
    isSales?: boolean;
    isPrimary?: boolean;
    btnSize?: string;
    icon?: string | SpotSvg;
}

export class SpotButton extends React.PureComponent<SpotButtonProps & { [props: string]: any }> {
    render() {
        const { className, children, type, isPrimary, isSales, btnSize, icon, ['data-automation-id']: automationId, ...rest } = this.props;
        const classes: string[] = [];
        let ico: any = icon;
        if (isPrimary) {
            classes.push('spot-button--primary');
        } else {
            classes.push('spot-button--secondary');
        }
        if (isSales) {
            classes.push('spot-button--sales');
        }

        if (btnSize) {
            classes.push(`spot-button--${btnSize}`);
        }

        if (icon) {
            classes.push('spot-button--with-icon');
            if (!children) {
                classes.push('spot-button--icon-only');
            }
            if (typeof icon === 'string') {
                ico = <SpotSvg className="spot-icon spot-button__icon spot-button__icon--left" icon={icon} />;
            }
        }

        return (
            <button
                className={[`spot-button`, className, ...classes].join(' ')}
                type={(type || 'button') as any}
                {...rest}
                data-automation-id={automationId}
            >
                {!!ico && ico}
                {children && <span className="spot-button__text"> {children} </span>}
            </button>
        );
    }
}
