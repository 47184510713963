import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { sortBy, uniqBy } from 'lodash';
import { NotificationContent, Notifications } from '../../Notifications';
import { PresetEntity, PresetService } from '../../../services/PresetService';
import i18n from '../../../i18n';
import { MappingService } from '../../../services/MappingService';
import MappingUpdate from './mapping/MappingUpdate';
import MappingError from './mapping/MappingError';
import { MappingTypes } from '../../../core/enums/Mapping';
import { InvoiceItemService } from '../../../services/InvoiceItemService';
import {
    BulkUpdatesBody,
    InvoiceItem,
    InvoiceItemData,
    InvoiceItemTypes,
    InvoiceItemWithVariantDetail,
    GetItemQuery,
    CornerstoneInvoiceItemWithVariantDetail,
    AnimanaInvoiceItemWithVariantDetail,
    BulkUpdateInvoiceItem,
} from '../../../core/models';
import { ItemsGroupByType } from '../../../core/models/organization/settings/enum';
import ConstantsStore from '../../../store/ConstantsStore';
import { QueueService } from '../../../services/queue/QueueService';
import { LookupsMap, LookupsService } from '../../../services/lookups';
import {
    Lookup,
    LookupAnalysisGroup,
    LookupClassification,
    LookupDiscount,
    LookupDispensingItem,
    LookupItemTypes,
    LookupPricingChangePolicies,
    LookupRoundingPrecisions,
    LookupSpecialAction,
    LookupSpecialActionAvailabilities,
    LookupSpecialActionWithAvailabilities,
    LookupSupplier,
    LookupTax,
    LookupType,
    LookupUnitsOfMeasure,
} from '../../../core/models/datasource/lookups';
import { DatasourceFullSiteInfo } from '../../../core/models/datasource/sites';
import { RouterPaths } from '../../../router/RouterPaths';
import { FEATURES, Services } from '@enterprise/core';
import { ToastWithSubHeader } from '../../../components/toasts/ToastWithSubheader';
import { InvoiceItemEditType } from './InvoiceItemEditType.enum';
import { InvoiceItemsWithVariantDetails } from '../../../core/transformer/InvoiceItemsWithVariantDetails';
import { FullItemFormType } from './formType/FullItemFormType';
import { PriceDeltaUpdateEntity } from './formType/BulkPriceEditFormType';
import { BulkStatusEditInfo } from './formType/BulkStatusEditFormType';
import { PatchUpdateModel } from './formType/bulkPatchEditFormType';
import { PatchDeltaUpdate } from '../../../core/models/datasource/invoiceItems/parts/patchDeltaUpdate';
import { PaginationModel } from '../../../store/invoiceItem.store';

enum Loadable {
    CommonInvoiceItemLookups = 'CommonInvoiceItemLookups',
    Presets = 'Presets',
}

export enum BulkUpdateFlyoverType {
    Price = 'price',
    Status = 'status',
    Description = 'description',
    Classification = 'classification',
    AddToPractices = 'addToPractices',
}

interface FetchInvoiceItemParams {
    id?: string;
    description?: string;
    practice?: string;
    isSub?: boolean;
}

export interface BulkUpdateParams {
    items?: BulkUpdateInvoiceItem[];
    includedItems?: string[];
    excludedItems?: string[];
    update: PriceDeltaUpdateEntity | BulkStatusEditInfo | PatchUpdateModel;
    type: BulkUpdateFlyoverType;
    itemsSearchQuery?: GetItemQuery;
    pagination?: PaginationModel;
}

@Service()
export class ItemsPageStore {
    discounts: Lookup<LookupDiscount>[] = [];

    dispensingItems: Lookup<LookupDispensingItem>[] = [];

    filter?: GetItemQuery;

    isLoaded = false;

    isLoadingItem = false;

    isLoadingItems = false;

    invoiceItem?: InvoiceItemWithVariantDetail;

    _invoiceItems: InvoiceItemWithVariantDetail[] = [];

    isCreatingNewItem = false;

    itemTypes: Lookup<LookupItemTypes>[] = [];

    presets: PresetEntity[] = [];

    pricingChangePolicies: Lookup<LookupPricingChangePolicies>[] = [];

    roundingPrecisions: Lookup<LookupRoundingPrecisions>[] = [];

    selectedItems = new Set<InvoiceItemWithVariantDetail>();

    specialActions: Lookup<LookupSpecialAction>[] = [];

    specialActionAvailabilities: Lookup<LookupSpecialActionAvailabilities>[] = [];

    taxes: Lookup<LookupTax>[] = [];

    unitsOfMeasure: Lookup<LookupUnitsOfMeasure>[] = [];

    suppliers: Lookup<LookupSupplier>[] = [];

    analysisGroups: Lookup<LookupAnalysisGroup>[] = [];

    private queueService = new QueueService<Loadable>()
        .register(Loadable.Presets, async () => {
            this.presets = await this.presetService.getPresets();
        })
        .register(Loadable.CommonInvoiceItemLookups, async () => {
            const itemLookupGroup = [
                LookupType.DISCOUNTS,
                LookupType.DISPENSING_ITEMS,
                LookupType.ITEM_TYPES,
                LookupType.PRICING_CHANGE_POLICY,
                LookupType.ROUNDING,
                LookupType.SPECIAL_ACTIONS,
                LookupType.SPECIAL_ACTION_TYPES,
                LookupType.TAXES,
                LookupType.UNITS,
                LookupType.SUPPLIERS,
                LookupType.ANALYSIS_GROUPS,
            ];
            const lookups = await this.lookupsService.getLookupsAsMap(itemLookupGroup);
            this.discounts = lookups.discounts;
            this.dispensingItems = lookups.dispensingItems;
            this.itemTypes = this.filterDisabledItemTypes(lookups.invoiceItemTypes);
            this.pricingChangePolicies = lookups.pricingChangePolicy;
            this.roundingPrecisions = lookups.roundingPrecisions;
            this.specialActions = lookups.specialActions;
            this.specialActionAvailabilities = lookups.specialActionAvailabilities;
            this.taxes = lookups.taxes;
            this.unitsOfMeasure = lookups.unitsOfMeasure;
            this.suppliers = lookups.suppliers;
            this.analysisGroups = lookups.analysisGroups;
        });

    constructor(
        private readonly constantsStore: ConstantsStore,
        private readonly invoiceItemService: InvoiceItemService,
        private readonly lookupsService: LookupsService,
        private readonly mappingService: MappingService,
        private readonly presetService: PresetService,
        private readonly featureManager: Services.FeatureManager,
    ) {
        makeAutoObservable(this, {
            getSpecialActionsWithAvailabilities: false,
            filterDisabledItemTypes: false,
            getLookupResults: false,
            getUnmappedProperties: false,
            updateMappings: false,
            isSelected: false,
            isAnyItemSelected: false,
            areAllItemsSelected: false,
            isAllPracticesGridEditorFeatureEnabled: false,
            getEditUrl: false,
            getSuccessMessage: false,
        });
    }

    get invoiceItems(): InvoiceItemWithVariantDetail[] {
        return toJS(this._invoiceItems);
    }

    get applications(): DatasourceFullSiteInfo[] {
        return toJS(this.constantsStore.applications);
    }

    get classifications(): Lookup<LookupClassification>[] {
        return toJS(this.constantsStore.classifications);
    }

    get isAnimanaOnly(): boolean {
        return this.constantsStore.applications.every(({ pimsName }) => pimsName?.toLowerCase() === 'animana');
    }

    get formType(): FullItemFormType<AnimanaInvoiceItemWithVariantDetail | CornerstoneInvoiceItemWithVariantDetail> {
        return this.isAnimanaOnly
            ? new FullItemFormType(AnimanaInvoiceItemWithVariantDetail)
            : new FullItemFormType(CornerstoneInvoiceItemWithVariantDetail);
    }

    get uniqueItemsForBulkUpdate(): InvoiceItem[] {
        return Array.from(this.selectedItems)
            .map((itemGroup) => itemGroup.variants)
            .reduce((acc, val) => acc.concat(val), []);
    }

    get itemsGroupBy(): ItemsGroupByType {
        return this.constantsStore.organizationSettings?.itemsGroupBy || ItemsGroupByType.Id;
    }

    get variantKeys(): string[] | undefined {
        return this.invoiceItem?.variants.map((variant) => InvoiceItemsWithVariantDetails.createDiffKey(variant, variant.provider.siteId));
    }

    get invoiceItemDifference(): InvoiceItemData | undefined {
        if (!this.invoiceItem) {
            return undefined;
        }

        const { diff, variants, ...value } = this.invoiceItem;
        // spiting item and existed diffs to variants. Then we will put\store this values as form values
        const data: InvoiceItemData = {
            master: { ...value },
        };

        Object.keys(diff).forEach((diffKey) => {
            const variant = variants.find((variant) => {
                const variantDiffKey = InvoiceItemsWithVariantDetails.createDiffKey(variant, variant.provider.siteId);
                return variantDiffKey === diffKey;
            });

            data[diffKey] = variant || value;
        });

        if (data[(data.master as any).siteId]) {
            delete data[(data.master as any).siteId];
        }

        return data;
    }

    async load(toLoad: Loadable[] = Object.values(Loadable)) {
        this.isLoaded = false;
        // Presets are not supported in the new UI so we do not load them.
        if (this.featureManager.isAvailable(FEATURES.ENHANCED_PRODUCT_SEARCH) && this.featureManager.isAvailable(FEATURES.ENHANCED_SEARCH_UI)) {
            toLoad = toLoad.filter(function (e) {
                return e !== Loadable.Presets;
            });
        }
        await this.queueService.load(toLoad);
        this.isLoaded = true;
    }

    async fetchInvoiceItem_legacy(query: GetItemQuery): Promise<InvoiceItemWithVariantDetail> {
        this.isLoadingItem = true;
        this.invoiceItem = await this.invoiceItemService.getItemWithVariantDetails(query);
        this.isLoadingItem = false;
        return this.invoiceItem;
    }

    async fetchInvoiceItem(params: FetchInvoiceItemParams): Promise<InvoiceItemWithVariantDetail> {
        const { id, description, practice, isSub } = params;
        const query: GetItemQuery = {
            id,
            description,
            siteId: practice,
            matchHospitalDescription: true,
            depth: 1,
        };

        this.isLoadingItem = true;
        const invoiceItems = await this.invoiceItemService.getItemsWithVariantDetails(query);
        this.isLoadingItem = false;

        if (invoiceItems.length !== 1) {
            if (isSub === true) {
                this.invoiceItem = invoiceItems.find((item) => item.itemParent) || invoiceItems[0];
                return this.invoiceItem;
            } else {
                if (this.itemsGroupBy === ItemsGroupByType.Id && !this.isAnimanaOnly) {
                    this.invoiceItem = invoiceItems.find((item) => item.pimsGeneratedId === query.id && !item.itemParent) || invoiceItems[0];
                } else {
                    this.invoiceItem = invoiceItems.find((item) => !item.itemParent) || invoiceItems[0];
                }
                return this.invoiceItem;
            }
        } else {
            this.invoiceItem = invoiceItems[0];
            return this.invoiceItem;
        }
    }

    async fetchInvoiceItems(criteria?: GetItemQuery): Promise<void> {
        this.isLoadingItems = true;
        this._invoiceItems = [];
        this.clearSelectedItems();
        try {
            this._invoiceItems = await this.invoiceItemService.getItemsWithVariantDetails({ ...criteria, depth: 0 });
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        } finally {
            this.isLoadingItems = false;
        }
    }

    async fetchDuplicateInvoiceItem(criteria?: GetItemQuery): Promise<InvoiceItemWithVariantDetail> {
        try {
            const invoiceItems = await this.invoiceItemService.getItemsWithVariantDetails({ ...criteria, depth: 0 });
            return invoiceItems[0];
        } catch (e) {
            if (e?.innerException?.response?.data.errors[0]?.responseTypeDescription !== 'Not Connected') {
                Notifications.fromException(e);
                throw e;
            } else {
                return e?.innerException?.response?.data.data[0];
            }
        }
    }

    getSpecialActionsWithAvailabilities(siteId?: string): LookupSpecialActionWithAvailabilities[] {
        const uniqueActions = uniqBy(this.getLookupResults(this.specialActions, siteId), (action) => action.pimsGeneratedId);
        const availabilities = this.getLookupResults(this.specialActionAvailabilities, siteId);

        const actionsWithAvailability = uniqueActions.map((action) => {
            const availableForAction = uniqBy(
                availabilities.filter((availability) => Number(availability.specialActionId) === Number(action.pimsGeneratedId)),
                (availability) => availability.whenToApplyId,
            );
            return {
                availabilities: sortBy(availableForAction, (availability) => availability.whenToApplyDescription),
                ...action,
            };
        });
        return sortBy(actionsWithAvailability, (action) => action.description);
    }

    createInvoiceItem(): InvoiceItemWithVariantDetail {
        this.isCreatingNewItem = true;

        if (this.isAnimanaOnly) {
            this.invoiceItem = new AnimanaInvoiceItemWithVariantDetail();
        } else {
            this.invoiceItem = new CornerstoneInvoiceItemWithVariantDetail();
        }

        this.invoiceItem.sites = this.applications;
        return this.invoiceItem;
    }

    async save(item: InvoiceItemWithVariantDetail, message: NotificationContent): Promise<InvoiceItemWithVariantDetail> {
        try {
            const runAt = new Date();
            const name = item.hospitalDescription;
            await this.invoiceItemService.scheduleSave(item.variants, { isUpdatingExistingItem: !this.isCreatingNewItem, name, runAt });
            this.invoiceItem = item;
            Notifications.success(message);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        this.isCreatingNewItem = false;
        return item;
    }

    async scheduleSave(
        item: InvoiceItemWithVariantDetail,
        runAt: Date = new Date(),
        message: NotificationContent,
        name?: string,
    ): Promise<InvoiceItemWithVariantDetail> {
        try {
            const scheduleName = name || `Scheduled save - ${item.hospitalDescription}`;
            await this.invoiceItemService.scheduleSave(item.variants, { isUpdatingExistingItem: !this.isCreatingNewItem, name: scheduleName, runAt });
            this.invoiceItem = item;
            Notifications.success(message);
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
        this.isCreatingNewItem = false;
        return item;
    }

    filterDisabledItemTypes(lookups: Lookup<LookupItemTypes>[]): Lookup<LookupItemTypes>[] {
        const disabledItemTypes = new Set([InvoiceItemTypes.Group, InvoiceItemTypes.Pick, InvoiceItemTypes.Dispensing]);
        return lookups.map(({ results, ...rest }) => ({
            results: results.filter((item) => !disabledItemTypes.has(item.id)),
            ...rest,
        }));
    }

    getLookupResults<T>(lookups: Lookup<T>[], siteId?: string): T[] {
        const masterSiteId = this.constantsStore.organizationSettings?.masterSite?.siteId;
        return LookupsMap.getLookupResults(lookups, siteId || masterSiteId);
    }

    async getUnmappedProperties(item: InvoiceItemWithVariantDetail): Promise<Map<string, MappingError>> {
        const areCategoriesMapped = await this.mappingService.areClassificationsMapped();
        if (!areCategoriesMapped) {
            return new Map();
        }

        const type = MappingTypes.CLASSIFICATION;
        const unmapped = new Map();
        const promises: Promise<void>[] = [];

        for (const variant of item.variants) {
            const { siteId } = variant.provider;
            const { pimsGeneratedId: classId } = variant.classification || {};
            const { pimsGeneratedId: subclassId } = variant.subClassification || {};

            if (variant.classification?.pimsGeneratedId) {
                promises.push(
                    this.mappingService.getMappingForSite(type, siteId, classId).then((mappedClassId) => {
                        const variantError = new MappingError();
                        let hasUnmappedProperty = false;

                        if (mappedClassId) {
                            variantError.mappedClassId = mappedClassId;

                            if (subclassId) {
                                return this.mappingService
                                    .getMappingForSite(type, siteId, subclassId, classId, mappedClassId)
                                    .then((mappedSubclassId) => {
                                        if (!mappedSubclassId) {
                                            variantError.subclassId = subclassId;
                                            hasUnmappedProperty = true;
                                        }
                                        if (hasUnmappedProperty) {
                                            unmapped.set(siteId, variantError);
                                        }
                                    });
                            }
                        } else {
                            variantError.classId = classId;
                            variantError.subclassId = subclassId;
                            hasUnmappedProperty = true;
                        }

                        if (hasUnmappedProperty) {
                            unmapped.set(siteId, variantError);
                        }

                        return;
                    }),
                );
            }
        }

        return Promise.all(promises).then(async () => {
            return unmapped;
        });
    }

    async updateMappings(mappingUpdates: Map<string, MappingUpdate[]>) {
        try {
            for (const siteId of Array.from(mappingUpdates.keys())) {
                const siteUpdates = mappingUpdates.get(siteId) || [];
                for (const siteUpdate of siteUpdates) {
                    const { type, masterId, masterParentId, linkedId } = siteUpdate;

                    const mappingsById = await this.mappingService.getMapping(type, masterId, masterParentId);
                    const mapping = mappingsById[0];
                    if (!mapping.mapped) {
                        mapping.mapped = [];
                    }
                    mapping.mapped.push({
                        isApproved: true,
                        linkedId,
                        practiceId: siteId,
                        pimsType: type,
                        data: {
                            description: siteUpdate.description,
                            siteId,
                        },
                        pimsParentId: siteUpdate.linkedParentId,
                    });

                    await this.mappingService.updateMapping(type, masterId, mapping);
                }
            }
            Notifications.success(i18n.t('itemsPageStore:missingMappingsSaved', 'Selected mappings were saved successfully'));
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    selectItem(item: InvoiceItemWithVariantDetail) {
        if (this.isSelected(item)) {
            this.selectedItems.delete(item);
        } else {
            this.selectedItems.add(item);
        }

        this.selectedItems = new Set(this.selectedItems);
    }

    isSelected(item: InvoiceItemWithVariantDetail): boolean {
        return this.selectedItems.has(item);
    }

    get selectedItemsValue(): InvoiceItemWithVariantDetail[] {
        return [...this.selectedItems.values()];
    }

    isAnyItemSelected(): boolean {
        return this.selectedItems.size > 0;
    }

    areAllItemsSelected(): boolean {
        return this.invoiceItems.length === this.selectedItems.size;
    }

    selectAllItems() {
        if (this.areAllItemsSelected()) {
            this.clearSelectedItems();
        } else {
            this.selectedItems = new Set(this.invoiceItems);
        }
    }

    async createBulkUpdate({ items, update, type }: BulkUpdateParams): Promise<void> {
        const { name, updateAt, ...data } = update;
        const isScheduledUpdate = Boolean(updateAt);
        const priceUpdateTypes = [BulkUpdateFlyoverType.Classification, BulkUpdateFlyoverType.Description];

        const bulkUpdatesBody: BulkUpdatesBody = {
            items,
            name: name || 'Bulk Update',
            runAt: updateAt || new Date(),
            statusUpdate: (type === BulkUpdateFlyoverType.Status && (data as BulkStatusEditInfo)) || undefined,
            priceUpdate: (type === BulkUpdateFlyoverType.Price && (data as PriceDeltaUpdateEntity)) || undefined,
            patchUpdate: (priceUpdateTypes.includes(type) && (data as PatchDeltaUpdate)) || undefined,
        };
        try {
            await this.invoiceItemService.createBulkUpdate(bulkUpdatesBody);
            this.clearSelectedItems();
            const practicesCount = uniqBy(items, (item) => item?.provider.siteId).length || undefined;
            Notifications.success(this.getBulkUpdateSuccessMessage(items?.length, practicesCount, isScheduledUpdate));
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    async savePreset(preset: PresetEntity): Promise<any> {
        try {
            const newPreset = await this.presetService.save(preset);
            this.presets.push(newPreset);
            Notifications.success(i18n.t('itemsPageStore:presetSaved', 'Search was saved successfully'));
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    async deletePreset(preset: PresetEntity): Promise<any> {
        try {
            await this.presetService.delete(preset.id);
            this.presets = this.presets.filter((p) => p.id !== preset.id);
            Notifications.success(i18n.t('itemsPageStore:presetDeleted', 'Search was deleted successfully'));
        } catch (e) {
            Notifications.fromException(e);
            throw e;
        }
    }

    get isAllPracticesGridEditorFeatureEnabled(): boolean {
        return this.featureManager.isAvailable(FEATURES.ALL_PRACTICES_GRID_EDITOR, true);
    }

    getEditUrl(item: InvoiceItem, editType = InvoiceItemEditType.Form) {
        const isSub = Boolean(item.itemParent);

        return this.itemsGroupBy === ItemsGroupByType.Id
            ? `${RouterPaths.ControlCenterPages.Items}/${editType}?id=${encodeURIComponent(item.pimsGeneratedId)}`
            : `${RouterPaths.ControlCenterPages.Items}/${editType}?description=${encodeURIComponent(item.hospitalDescription)}&isSub=${isSub}`;
    }

    dispose() {
        this.discounts = [];
        this.dispensingItems = [];
        this._invoiceItems = [];
        this.itemTypes = [];
        this.pricingChangePolicies = [];
        this.presets = [];
        this.roundingPrecisions = [];
        this.specialActions = [];
        this.specialActionAvailabilities = [];
        this.taxes = [];
        this.unitsOfMeasure = [];
        this.suppliers = [];
        this.analysisGroups = [];

        this.isLoaded = false;
        this.invoiceItem = undefined;
        this.isCreatingNewItem = false;
        this.filter = undefined;

        this.clearSelectedItems();
    }

    clearItemState() {
        this.invoiceItem = undefined;
        this.isCreatingNewItem = false;
    }

    clearSelectedItems() {
        this.selectedItems = new Set<InvoiceItemWithVariantDetail>();
    }

    getSuccessMessage(item: InvoiceItemWithVariantDetail) {
        const itemIdentifier =
            this.constantsStore.organizationSettings?.itemsGroupBy === ItemsGroupByType.Description || !item.pimsId
                ? item.hospitalDescription
                : `${item.pimsId} (${item.hospitalDescription})`;
        const count = item.variants.length;
        const successMessage = i18n.t('controlCenter:toastMessages:itemScheduled', {
            itemIdentifier,
            count,
            defaultValue: `Scheduled: update of ${itemIdentifier} to ${count} practices.`,
        });
        const subHead =
            String(item.childItems?.length || '') &&
            i18n.t('controlCenter:toastMessages:hasSubproducts', {
                count,
                defaultValue: `This item has subproducts that will also be added to the practice.`,
            });

        return ToastWithSubHeader({ message: successMessage, subMessage: subHead });
    }

    getAddItemToPracticesMessage(params: { practiceCount: number; isScheduledUpdate: boolean }): NotificationContent {
        const { practiceCount, isScheduledUpdate } = params;
        if (isScheduledUpdate) {
            const message = i18n.t('controlCenter:toastMessages:scheduledAdditionalPractices', {
                count: practiceCount,
                defaultValue: `Add Item to ${practiceCount} Practice(s) Successfully Scheduled`,
            });
            const subMessage = i18n.t('controlCenter:toastMessages:scheduledUpdateSubHead', {
                defaultValue: `View the update's status in the activity log.`,
            });

            return ToastWithSubHeader({ message, subMessage });
        }

        const message = i18n.t('controlCenter:toastMessages:additionalPractices', {
            count: practiceCount,
            defaultValue: `Adding Item to ${practiceCount} Additional Practice(s)`,
        });
        const subMessage = i18n.t('controlCenter:toastMessages:updateSubHead', { defaultValue: `Refresh page to see changes as they complete.` });

        return ToastWithSubHeader({ message, subMessage });
    }

    getBulkUpdateSuccessMessage(itemsCount: number = 0, practiceCount: number = 0, isScheduledUpdate: boolean): NotificationContent {
        let successMessage, subHead;
        if (isScheduledUpdate) {
            const message1 = i18n.t('controlCenter:toastMessages:scheduledBulkUpdateMessage1', {
                count: itemsCount,
                defaultValue: `Update of ${itemsCount} Item(s) at `,
            });
            const message2 = i18n.t('controlCenter:toastMessages:scheduledBulkUpdateMessage2', {
                count: practiceCount,
                defaultValue: `${practiceCount} Practice Successfully Scheduled`,
            });

            successMessage = message1 + message2;
            subHead = i18n.t('controlCenter:toastMessages:scheduledUpdateSubHead', { defaultValue: `View the update's status in the activity log.` });
        } else {
            const message1 = i18n.t('controlCenter:toastMessages:bulkUpdateMessage1', {
                count: itemsCount,
                defaultValue: `Updating ${itemsCount} Item(s) at `,
            });
            const message2 = i18n.t('controlCenter:toastMessages:bulkUpdateMessage2', {
                count: practiceCount,
                defaultValue: `${practiceCount} Practice(s)`,
            });

            successMessage = message1 + message2;
            subHead = i18n.t('controlCenter:toastMessages:updateSubHead', { defaultValue: `Refresh page to see changes as they complete.` });
        }
        return ToastWithSubHeader({ message: successMessage, subMessage: subHead });
    }
}
