import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { RedirectContext } from '../../core/enums/RedirectContext';
import { useStores } from '../../hooks';
import { environment } from '../../environment/environment';
import i18n from '../../i18n';

const MarketingPage = observer(function MarketingPageObserved() {
    const {
        isLoaded,
        ui: { app, router },
    } = useStores();

    async function redirectToAppOrMarketing() {
        if (app.isAuthenticated) {
            await router.redirectToTarget(RedirectContext.ENTRY);
        } else {
            window.location.href = environment.MARKETING_PAGE_URL;
        }
    }

    useEffect(() => {
        if (!isLoaded) {
            return;
        }

        void redirectToAppOrMarketing();
    }, [isLoaded, app.isAuthenticated]);

    return <>{i18n.t('common:loading', 'Loading...')}</>;
});

export default MarketingPage;
