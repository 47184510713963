import React from 'react';
import { observer } from 'mobx-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import i18n from '../../i18n';
import { RedirectContext } from '../../core/enums/RedirectContext';
import { useStores } from '../../hooks';
import { RouterPaths } from '../../router/RouterPaths';

export const Login = observer(function LoginComponent() {
    const {
        ui: { router, app },
    } = useStores();

    const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        const isAuthenticatedHandler = async () => {
            try {
                const token = await app.refreshAuthorization();

                if (!token) {
                    router.push(RouterPaths.BasePages.Signup);
                    return;
                }

                await router.redirectToTarget(RedirectContext.ENTRY);
            } catch (error) {
                console.error('auto0 token validation failed ', error);

                await app.logout();
                return;
            }
        };

        if (!isAuthenticated) {
            void loginWithRedirect({
                ui_locales: i18n.language,
            });
        } else {
            void isAuthenticatedHandler();
        }
    }, [isLoading, isAuthenticated]);

    return <div>{i18n.t('common:loading', 'Loading...')}</div>;
});
