export class WorkerMessage {
    constructor(readonly subject: any, readonly name?: string) {}
}

export interface WorkerInterface {
    onMessage?: (event: WorkerMessage) => void;

    start();

    stop();
}
