import { FormType } from '@enterprise/common';

export interface BulkStatusEditInfo {
    isActive: boolean;
    name?: string;
    updateAt?: Date;
}

export interface BulkStatusEditModel {
    isActive?: string | number;
    name?: string;
    updateAt?: Date;
}

export class BulkStatusEditFormType extends FormType<BulkStatusEditModel, BulkStatusEditInfo> {
    getEntity({ isActive, name, updateAt }: BulkStatusEditModel): BulkStatusEditInfo {
        return {
            isActive: Boolean(Number(isActive)),
            name,
            updateAt,
        };
    }
}
