import * as React from 'react';
import { Route, NavLink } from 'react-router-dom';
import { FEATURES } from '@enterprise/core';
import { PageWrapper } from '../../layout/main/pageWrapper';
import { ClassificationsMappingPage } from './classifications/ClassificationsMappingPage';
import { InvoiceItemsMappingPage } from './invoiceItems/InvoiceItemsMappingPage';
import { HistoryMappingPage } from './history/HistoryMappingPage';
import { FeatureOrComingSoon } from '../../components/helpers/FeatureOrComingSoon';
import i18n from 'i18next';
import { RouterPaths } from '../../router/RouterPaths';
import { ClassificationsDatabaseMappingPage } from './classifications/ClassificationsDatabaseMappingPage';
import classNames from 'classnames';
import { RootRoutes } from '../../router';

interface MasterDataPageProps {}

export class MasterDataPage extends React.Component<MasterDataPageProps> {
    render() {
        const databaseMappingPage = () => <ClassificationsDatabaseMappingPage returnRoute={RouterPaths.MasterDataPages.ClassificationsMappingPage} />;
        return (
            <PageWrapper className="master-data-page" title="Master Data" description="Manage your data across practices and regions">
                <FeatureOrComingSoon name={FEATURES.MASTER_DATA}>
                    <>
                        <div className="spot-tabs__list-wrap">
                            <ul className="spot-tabs__list">
                                <li className="spot-tabs__tab">
                                    <NavLink
                                        to={RouterPaths.MasterDataPages.ClassificationsMappingPage}
                                        className={({ isActive }) => classNames('spot-tabs__link', { 'spot-tabs__link--active': isActive })}
                                    >
                                        {i18n.t('masterData:classificationsPage.classifications', 'Classifications')}
                                    </NavLink>
                                </li>
                                <li className="spot-tabs__tab">
                                    <NavLink
                                        to={RouterPaths.MasterDataPages.InvoiceItemsMappingPage}
                                        className={({ isActive }) => classNames('spot-tabs__link', { 'spot-tabs__link--active': isActive })}
                                    >
                                        {i18n.t('masterData:invoiceItemsPage.invoiceItems', 'Invoice Items')}
                                    </NavLink>
                                </li>
                                <li className="spot-tabs__tab">
                                    <NavLink
                                        to={RouterPaths.MasterDataPages.HistoryMappingPage}
                                        className={({ isActive }) => classNames('spot-tabs__link', { 'spot-tabs__link--active': isActive })}
                                    >
                                        {i18n.t('masterData:historyPage.history', 'History')}
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="spot-tabs__content-wrap">
                            <div className="spot-tabs__content">
                                <RootRoutes>
                                    <Route path={`${RouterPaths.MasterDataPages.MasterDatabaseMappingPage}/*`} element={databaseMappingPage()} />
                                    <Route
                                        path={`${RouterPaths.MasterDataPages.ClassificationsMappingPage}/*`}
                                        element={<ClassificationsMappingPage />}
                                    />
                                    <Route path={`${RouterPaths.MasterDataPages.InvoiceItemsMappingPage}/*`} element={<InvoiceItemsMappingPage />} />
                                    <Route path={`${RouterPaths.MasterDataPages.HistoryMappingPage}/*`} element={<HistoryMappingPage />} />
                                </RootRoutes>
                            </div>
                        </div>
                    </>
                </FeatureOrComingSoon>
            </PageWrapper>
        );
    }
}
