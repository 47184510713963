import * as React from 'react';
import { Route } from 'react-router-dom';
import { PageWrapper } from '../../layout/main/pageWrapper';
import { AnalyticsSettingsPage } from './analytics/AnalyticsSettingsPage';
import { ControlCenterSettingsPage } from './controlCenter/ControlCenterSettingsPage';
import { MasterDataSettingsPage } from './masterData/MasterDataSettingsPage';
import { MyOrganizationSettingsPage } from './myOrganizations/MyOrganizationSettingsPage';
import { RootRoutes } from '../../router';

export class GeneralSettingsPage extends React.Component {
    static PAGE_URL = '/general-settings';

    render() {
        return (
            <PageWrapper className="general-settings-page" title="General Settings" description="Manage your preferences and settings.">
                <div className="spot-tabs__content-wrap">
                    <div className="spot-tabs__content">
                        <RootRoutes>
                            <Route path={`${GeneralSettingsPage.PAGE_URL}/analytics/*`} element={<AnalyticsSettingsPage />} />
                            <Route path={`${GeneralSettingsPage.PAGE_URL}/control-center/*`} element={<ControlCenterSettingsPage />} />
                            <Route path={`${GeneralSettingsPage.PAGE_URL}/master-data/*`} element={<MasterDataSettingsPage />} />
                            <Route path={`${GeneralSettingsPage.PAGE_URL}/my-organization/*`} element={<MyOrganizationSettingsPage />} />
                        </RootRoutes>
                    </div>
                </div>
            </PageWrapper>
        );
    }
}
