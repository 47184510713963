import * as React from 'react';
import { Trans } from 'react-i18next';
import { Container, Col, Row } from 'react-bootstrap';
import { Form, FormRenderProps, FormType } from '@enterprise/common';
import { SpotButton, SpotRadio, SpotRadioGroup } from '@enterprise/spot';
import { Field } from 'react-final-form';
import { MasterDataPageStore } from '../MasterDataPageStore';
import { observer } from 'mobx-react';
import { ApplicationInfoCard } from '../../../components/application/ApplicationInfoCard';
import { ProTips } from '../../../components/helpers/ProTips';
import { RedirectToRouteComponent } from '../../../router/RedirectToRouteComponent';
import { StoresContext } from '../../../contexts';
import { RouterStore } from '../../../store';

interface PageProps {
    returnRoute: string;
}

interface ClassificationsDatabase {
    masterDatabase: string;
}

@observer
export class ClassificationsDatabaseMappingPage extends React.Component<PageProps> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    private data: ClassificationsDatabase = { masterDatabase: '' };

    private form = new FormType<ClassificationsDatabase>();

    constructor(props) {
        super(props);
    }

    get store(): MasterDataPageStore {
        return this.context.domain.masterDataPageStore;
    }

    get router(): RouterStore {
        return this.context.ui.router;
    }

    async componentDidMount() {
        await this.store.loadMappableSites();
    }

    componentWillUnmount() {
        this.store.dispose();
    }

    render() {
        return this.store.isLoaded ? (
            this.store.areClassificationsMapped ? (
                <RedirectToRouteComponent to={this.props.returnRoute} />
            ) : (
                <Container className={'classifications-database-mapping'}>
                    <Row className={'margin-top-4'}>
                        <Col xs={1} />
                        <Col xs={11}>
                            <h1 className="classifications-header">
                                <Trans i18nKey="masterData:classificationsPage.databaseMappingsTitle">Choose your master database</Trans>
                            </h1>
                        </Col>
                    </Row>
                    <Row className={'margin-top-3'}>
                        <Col xs={1} />
                        <Col xs={11}>
                            <Form formType={this.form} data={this.data} onSubmit={this.onSubmit}>
                                {({ handleSubmit }: FormRenderProps<ClassificationsDatabase>) => (
                                    <>
                                        {this.store.mappableSites.map((site) => (
                                            <SpotRadioGroup isHorizontal={true} key={`${site.id}`}>
                                                <Field name="masterDatabase" type="radio" value={`${site.id}`}>
                                                    {({ input, meta }) => (
                                                        <SpotRadio {...input} value={`${site.id}`} isCentered={true} isFullWidth={true}>
                                                            <ApplicationInfoCard
                                                                app={site}
                                                                applicationDetailsRoute={null}
                                                                listPracticeNames={false}
                                                                isManageable={false}
                                                                toggleApplicationActive={null}
                                                            />
                                                        </SpotRadio>
                                                    )}
                                                </Field>
                                            </SpotRadioGroup>
                                        ))}

                                        <SpotButton isPrimary={true} disabled={false} onClick={handleSubmit}>
                                            <Trans i18nKey="masterData:classificationsPage.submitMaster">Submit Master</Trans>
                                        </SpotButton>

                                        <button className="spot-modal__footer-cancel-button cancel-button" onClick={this.onCancel}>
                                            <Trans i18nKey="common:cancel">cancel</Trans>
                                        </button>
                                    </>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Container>
            )
        ) : (
            <ProTips />
        );
    }

    private onCancel = () => {
        this.router.push(this.props.returnRoute);
    };

    private onSubmit = (data) => {
        if (data && data.masterDatabase) {
            this.store.useApplicationClassesAndSubclassesAsMaster(data.masterDatabase);
        }
    };
}
