import * as React from 'react';
import * as ReactDOM from 'react-dom';

interface PageProps {
    children: any;
    label: string;
    value: string | number;
    onSelect?: any;
    isDivider?: boolean;
    isHeading?: boolean;
}

class DropdownItem extends React.Component<PageProps> {
    static defaultProps = {
        label: '',
        value: '',
        children: [],
        onSelect: null,
        isDivider: false,
        isHeading: false,
    };

    get label() {
        try {
            // @ts-ignore
            return this.props.label || ReactDOM.findDOMNode(this).innerText;
        } catch (e) {
            return this.value || 'Unknown';
        }
    }

    get value() {
        return this.props.value;
    }

    render() {
        const { children } = this.props;
        return children;
    }
}

export default DropdownItem;
