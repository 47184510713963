import * as React from 'react';
import { SpotButton, SpotSvg, SpotModal } from '@enterprise/spot';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';

class TosModal extends React.Component<any> {
    createFooter = () => {
        return (
            <div style={{ textAlign: 'right', width: '100%' }}>
                <SpotButton isPrimary={true} onClick={this.props.onHide}>
                    <Trans i18nKey="common:buttons.close">Close</Trans>
                </SpotButton>
            </div>
        );
    };

    render() {
        const title = <SpotSvg className="tos-idexx-logo" type="logos" icon="idexx-logo.svg#idexx-logo" />;

        return (
            <SpotModal isVisible={this.props.isVisible} onHide={this.props.onHide} footer={this.createFooter} title={title}>
                {() => (
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div>
                                    <h5 className={'tos-header'}>
                                        <Trans i18nKey="tos:title" />
                                    </h5>

                                    <div style={{ whiteSpace: 'pre-line' }}>
                                        <Trans i18nKey="tos:content">
                                            <a className={'spot-link'} href="mailto:enterprisemanager@idexx.com" />
                                            <a className={'spot-link'} href="https://software.idexx.co.uk/gtc/" target="_blank" />
                                            <a
                                                className={'spot-link'}
                                                href="https://www.idexx.com/en/about-idexx/terms-of-sale/one-idexx-master-terms/"
                                                target="_blank"
                                            />
                                        </Trans>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )}
            </SpotModal>
        );
    }
}

export default TosModal;
