import React, { ChangeEvent, FormEvent } from 'react';
import { SpotSvg } from '@enterprise/spot';
import { useField, useFormState } from '@enterprise/common';
import { SpotButton, SpotSelectBox, SpotSelectOption, SpotHorizontalFields } from '@enterprise/spot';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { SpecialActionAvailabilityLookup } from '../../../core/entity/SpecialActionAvailabilityLookup';
import { LookupSpecialAction } from '../../../core/models/datasource/lookups';
import { InvoiceItemSpecialAction, InvoiceItemTypes, InvoiceItemWithVariantDetail } from '../../../core/models';
import { useStores } from '../../../hooks';

const DISABLED_ACTIONS = ['Print Document', 'Update Vital Signs', 'Lab Request'];

type SpecialActionsFieldProps = {
    name: string;
};

export function SpecialActionsField(props: SpecialActionsFieldProps) {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const { name } = props;
    const actions = itemsPageStore.getSpecialActionsWithAvailabilities();
    const { values } = useFormState<InvoiceItemWithVariantDetail>();
    const { itemType } = values;
    const { input, meta } = useField<InvoiceItemSpecialAction[]>(name);
    const specialActions = input.value || [];
    const imageRequest = 'Image Request';
    const isImageRequestSpecialActionNotApplicable =
        itemType?.id === InvoiceItemTypes.Inventory &&
        specialActions?.some((action) => actions.find(({ pimsId }) => pimsId === action.specialActionTypeId)?.description === imageRequest);

    const addAction = () => {
        input.onChange([...specialActions, {}]);
    };

    const removeField = (index: number) => {
        const updatedValue = [...specialActions];
        updatedValue.splice(index, 1);
        input.onChange(updatedValue);
    };

    const onChange = <TKey extends keyof InvoiceItemSpecialAction>(value: InvoiceItemSpecialAction[TKey], name: TKey, index: number) => {
        const updatedValue = [...specialActions];
        updatedValue[index][name] = value;
        input.onChange(updatedValue);
    };

    return (
        <>
            {isImageRequestSpecialActionNotApplicable && (
                <Container style={{ marginBottom: '24px', paddingLeft: '0px' }}>
                    <Row>
                        <Col xs={12} className="spot-typography__text--body errorMessage">
                            <Trans key="controlCenter:items.imageRequestNotApplicable">
                                The 'Image Request' does not apply to the Inventory item type. The 'Image Request' attached to this item will be
                                removed when the item is Published
                            </Trans>
                        </Col>
                    </Row>
                </Container>
            )}
            {specialActions.map((value, index) => {
                const action = actions.find((action) => `${action.pimsId}` === `${value.specialActionTypeId}`);
                return (
                    <SpotHorizontalFields key={value.specialActionTypeId}>
                        <SpotSelectBox
                            onChange={(event: ChangeEvent<HTMLInputElement> & FormEvent<HTMLSelectElement>) =>
                                onChange(event.target.value, `specialActionTypeId`, index)
                            }
                            error={{ ...meta, error: meta.error?.[index]?.specialActionTypeId }}
                        >
                            {actions.map((action: LookupSpecialAction) => {
                                const isDisabled =
                                    DISABLED_ACTIONS.includes(action.description) ||
                                    (itemType?.id === InvoiceItemTypes.Inventory && action.description === imageRequest);
                                return (
                                    <SpotSelectOption key={action.pimsId} value={action.pimsId} disabled={isDisabled}>
                                        {action.description}
                                    </SpotSelectOption>
                                );
                            })}
                        </SpotSelectBox>
                        <SpotSelectBox
                            defaultValue={(value || {}).whenToApplyId}
                            onChange={(event: ChangeEvent<HTMLInputElement> & FormEvent<HTMLSelectElement>) =>
                                onChange(Number(event.target.value), `whenToApplyId`, index)
                            }
                            error={{ ...meta, error: meta.error?.[index]?.whenToApplyId }}
                        >
                            {action?.availabilities.map((availability: SpecialActionAvailabilityLookup) => (
                                <SpotSelectOption key={availability.whenToApplyId} value={availability.whenToApplyId}>
                                    {availability.whenToApplyDescription}
                                </SpotSelectOption>
                            ))}
                        </SpotSelectBox>
                        <a onClick={() => removeField(index)} style={{ cursor: 'pointer' }}>
                            <SpotSvg icon="cancel" width="12px" height="12px" />
                        </a>
                    </SpotHorizontalFields>
                );
            })}
            <SpotButton data-automation-id={'add-special-action-button'} onClick={addAction}>
                <Trans key="controlCenter:items.addAction">Add Action</Trans>
            </SpotButton>
        </>
    );
}
