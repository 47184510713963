import { TaskService, TasksQuery } from '../TaskService';
import { CommandInterface } from './CommandIntervalWorker';
import ioc from '../ioc';
import { ParentTask } from '../../core/entity/TaskEntity';

export class TaskCommand implements CommandInterface {
    private readonly taskService: TaskService;
    constructor(private params: { query: TasksQuery }) {
        this.taskService = ioc.get(TaskService);
    }

    async execute(): Promise<ParentTask[]> {
        const { tasks } = await this.taskService.getParentTasks(this.params.query);

        return tasks;
    }
}
