import { createTheme } from '@mui/material';
import { SpotSvg } from '@enterprise/spot';
import React from 'react';

export const useAppTheme = () => {
    return createTheme({
        shape: {
            borderRadius: 2,
        },
        palette: {
            mode: 'light',
            primary: {
                light: '#00a7b5',
                main: '#00a7b5',
                dark: '#00a7b5',
                contrastText: '#fff',
            },
        },
        components: {
            MuiCheckbox: {
                defaultProps: {
                    className: 'spot-form__checkbox-visual',
                    icon: <></>,
                    checkedIcon: <SpotSvg className="spot-form__checkbox-checked-icon" icon="checkmark" />,
                    indeterminateIcon: <SpotSvg className="spot-form__checkbox-indeterminate-icon" icon="checkbox-indeterminate" />,
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        '&.spot-form__checkbox-visual': {
                            marginRight: 0,
                        },
                        '&>svg': {
                            display: 'unset',
                        },
                    },
                },
            },
        },
    });
};
