import '../styles/stackdropdown.scss';
import React from 'react';
import { StackSet } from './Column';
import { MAPPING_UI } from '../styles';
import { StackDropDownCaret } from '../icons/StackDropDownCaret';

interface StackDropDownProps {
    stackHandler: (number) => void;
    stackSets: StackSet[];
}

export const StackDropDown: React.FC<StackDropDownProps> = ({ stackHandler, stackSets }) => {
    const sendStackUpdate = event => {
        stackHandler(event.target.value);
    };

    const selected = stackSets.find(set => set.selected);
    return (
        <>
            <select defaultValue={selected && selected.index.toString()} onChange={sendStackUpdate} className={MAPPING_UI.STACK_DROP_DOWN}>
                {stackSets.map(a => (
                    <option value={`${a.index}`} key={a.index}>
                        {a.title}
                    </option>
                ))}
            </select>
            <div>
                <StackDropDownCaret className={MAPPING_UI.STACK_DROP_DOWN_CARET} />
            </div>
        </>
    );
};
