import React, { FC, SyntheticEvent } from 'react';
import { SpotButton, SpotTextInput } from '@enterprise/spot';
import { Col, Row } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { EntityFormType, FormRenderProps, ValidationResult } from '@enterprise/common';
import { EditableClassificationFields } from './entity/EditableClassificationFields';
import i18n from 'i18next';
import { MappingTypes } from '../../../../core/enums/Mapping';
import { useStores } from '../../../../hooks';
import { observer } from 'mobx-react';

interface ClassificationEditFieldsProps {
    isEdit?: boolean;
    onCancel: (event: SyntheticEvent) => void;
    onSuccess: () => void;
    parentId?: string;
}

export const ClassificationEditFields: FC<ClassificationEditFieldsProps> = observer(function ({
    isEdit = false,
    onCancel,
    onSuccess,
    parentId,
}: ClassificationEditFieldsProps) {
    const {
        domain: { masterDataPageStore },
    } = useStores();

    const formType = new EntityFormType(EditableClassificationFields);

    const onSubmit = async (values): Promise<{ [key: string]: string } | void> => {
        const isValidId = await masterDataPageStore.isAvailableClassificationId(values.classId, parentId);
        if (!isValidId) {
            return {
                classId: i18n.t('masterData:classificationsPage.classificationExistsError', 'This ID is already in use.'),
            };
        }
        if (!isEdit) {
            await masterDataPageStore.createNewMasterFromFormValues(values, MappingTypes.CLASSIFICATION, parentId);
        }
        onSuccess();
    };

    const validate = async (values): Promise<ValidationResult> => {
        return await formType.validate(values);
    };

    return (
        <Form validate={validate} onSubmit={onSubmit}>
            {({ dirtySinceLastSubmit, handleSubmit, submitting, submitErrors }: FormRenderProps<EditableClassificationFields>) => (
                <div className="editable-fields">
                    <Row>
                        <Col xs={4}>
                            <Field name="classId">
                                {({ input, meta }) => (
                                    <div>
                                        <label className="spot-form__label">{i18n.t('masterData:classificationsPage.id', 'ID')}</label>
                                        <SpotTextInput {...input} error={meta} />
                                        {!submitting && !dirtySinceLastSubmit && submitErrors && (
                                            <span className="spot-form__field-error" role="alert">
                                                <span className="spot-form__field-error-text"> {submitErrors.classId} </span>
                                            </span>
                                        )}
                                    </div>
                                )}
                            </Field>
                        </Col>
                        <Col xs={8}>
                            <Field name="classDescription">
                                {({ input, meta }) => (
                                    <div>
                                        <label className="spot-form__label">
                                            {i18n.t('masterData:classificationsPage.classificationDescription', 'Description')}
                                        </label>
                                        <SpotTextInput {...input} error={meta} />
                                    </div>
                                )}
                            </Field>
                        </Col>
                    </Row>

                    <Row className={'footer'}>
                        <Col>
                            <button className="spot-button spot-button--link" onClick={onCancel}>
                                {i18n.t('buttons:cancel', 'Cancel')}
                            </button>
                            <SpotButton disabled={submitting} isPrimary={true} onClick={handleSubmit}>
                                {isEdit ? i18n.t('buttons:save', 'Save') : i18n.t('buttons:create', 'Create')}
                            </SpotButton>
                        </Col>
                    </Row>
                </div>
            )}
        </Form>
    );
});
