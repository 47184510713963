import { Inject, Service } from 'typedi';
import { BaseHttp } from '@enterprise/common';
import AppStore from '../store/AppStore';
import { Account, EnrollmentFormUrl, PendingPractice, User } from '../core/models';
import { EnrollmentStatusEnum } from '../core/enums/enrollmentStatus.enum';
import { flatten } from 'lodash';
import { LabelType } from '../core/enums/labelTypes';
import { LabelEntity } from '../core/entity/LabelEntity';

export interface RelationsLabel {
    practices: string[];
    labels: string[];
}

@Service()
export class OrganizationService {
    constructor(@Inject('http') private readonly http: BaseHttp, private readonly appStore: AppStore) {}

    get currentUserOrgId(): string | null {
        const org = (this.appStore.token.user as any).organization;
        return org ? org.id || org : null;
    }

    getApplications(): Promise<any[]> {
        return this.http.get(`/organizations/:orgId/applications`, { pathParams: { orgId: this.currentUserOrgId } });
    }

    setApplicationActiveStatus(applicationId, isActive): Promise<any> {
        return this.http.put(`/organizations/:orgId/applications/active/:applicationId/:isActive`, null, {
            pathParams: { orgId: this.currentUserOrgId, applicationId, isActive },
        });
    }

    getLabels(labelType?: LabelType): Promise<LabelEntity[]> {
        return this.http.get(`/v2/labels`, { params: { type: labelType } });
    }

    assignLabel(relationsLabels: RelationsLabel[]): Promise<LabelEntity[]> {
        return this.http.post(`/v2/labels/relations`, relationsLabels);
    }

    removeLabelRelations(relationsLabels: RelationsLabel[]): Promise<LabelEntity[]> {
        return this.http.delete(`/v2/labels/relations`, relationsLabels);
    }

    createLabel(labelName: string, labelType: LabelType): Promise<LabelEntity> {
        return this.http.post(`/v2/labels`, { name: labelName, type: labelType });
    }

    async getCurrentUserAccounts(): Promise<Account[]> {
        try {
            const org = (await this.http.get(`/organizations/:orgId`, { pathParams: { orgId: this.currentUserOrgId } })) || {};
            const { accounts = [] } = org;
            return accounts;
        } catch (err) {
            throw err;
        }
    }

    getConnectPracticeEmbedLink(accountId: string): Promise<EnrollmentFormUrl> {
        return this.http.post(`/v2/account/:accountId/practice-enrollments/embed`, {}, { pathParams: { accountId } });
    }

    savePracticeEnrollment(accountId: string, requestId: string, enrollmentSlug: string) {
        return this.http.post(`/v2/account/:accountId/practice-enrollments`, { data: { enrollmentSlug, requestId } }, { pathParams: { accountId } });
    }

    getEnrollmentStatuses(accountId: string): Promise<PendingPractice[]> {
        return this.http.get(`/v2/account/:accountId/practice-enrollments/status`, { pathParams: { accountId } });
    }

    async getPendingPractices(accountIds: string[]): Promise<PendingPractice[]> {
        const requests = accountIds.map((accountId) => this.getEnrollmentStatuses(accountId));
        const enrollments: PendingPractice[] = flatten(await Promise.all(requests));

        return enrollments.filter((enrollments) => enrollments.status.id === EnrollmentStatusEnum.PENDING);
    }

    getUsers(organizationId: string): Promise<User[]> {
        return this.http.get<User[]>(`/v2/organizations/:orgId/users`, { pathParams: { orgId: organizationId } });
    }
}
