import * as React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { SpotSvg } from '@enterprise/spot';
import { RightSidebar } from '../../../../layout/main/rightSidebar';
import { Trans } from 'react-i18next';
import { StoresContext } from '../../../../contexts';

interface PageProps {
    applyPreset: any;
    close: any;
}

interface PageState {}

@observer
export default class PresetsSidebar extends React.Component<PageProps, PageState> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    static defaultProps = {
        page: {},
    };

    render() {
        return (
            <RightSidebar close={this.props.close} header={this.createHeader} modalBodyStyle={{ margin: '0px', padding: '0px' }} isSmall={true}>
                <div>
                    {this.context.domain.itemsPageStore.presets.map((preset) => {
                        return (
                            <Row key={preset.id} className="align-items-center justify-content-center">
                                <Col xs={12}>{this.createPresetDescription(preset)}</Col>
                            </Row>
                        );
                    })}
                </div>
            </RightSidebar>
        );
    }

    createHeader = () => {
        return (
            <Container className="presets-sidebar">
                <Row>
                    <Col xs={12} className="spot-typography__text--body">
                        <h4 className="title">
                            <Trans i18nKey="presetsSidebar:savedSearches">Saved Searches</Trans>
                        </h4>
                    </Col>
                </Row>
            </Container>
        );
    };

    createPresetDescription(preset) {
        const deletePreset = () => this.context.domain.itemsPageStore.deletePreset(preset);
        const applyPreset = () => this.props.applyPreset(preset.id);
        return (
            <>
                <Card>
                    <Card.Body>
                        <Container>
                            <Row>
                                <Col xs={8} className="spot-typography__text--body">
                                    <span>{preset.name}</span>
                                </Col>
                                <Col xs={3} className="spot-typography__text--body">
                                    <a href="#" className="spot-link" style={{ float: 'right' }} onClick={applyPreset}>
                                        <Trans i18nKey="presetsSidebar:applySearch">Apply Search</Trans>
                                    </a>
                                </Col>
                                <Col xs={1} className="spot-typography__text--body" style={{ textAlign: 'right' }}>
                                    <p>
                                        <a href="#" className="spot-link">
                                            <SpotSvg onClick={deletePreset} icon="delete" className="spot-link__icon" />
                                        </a>
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
            </>
        );
    }
}
