import React from 'react';
import '../styles/card.scss';
import { INTENT, MAPPING_UI } from '../styles';
import { SVGHandle } from '../icons/SVGHandle';
import classNames from 'classnames';

export interface CardProps {
    description?: string;
    id: string;
    parentId?: string;
    intent?: INTENT;
    isDragging?: boolean;
    secondaryDescription?: string;
    targeted?: boolean;
    title: JSX.Element | string;
    isChild?: boolean;
    isParent?: boolean;
    extraCardClasses?: string;
    isDisabled?: boolean;
    hideDuplicateCardDescriptions?: boolean;
    allowCardTitleClick?: boolean;
    onTitleClick?: (id: string, parentId?: string) => void;
}

export const Card: React.FC<CardProps> = ({
    description,
    secondaryDescription,
    id,
    parentId,
    intent,
    title,
    isDragging,
    targeted,
    isChild = false,
    isParent = false,
    extraCardClasses,
    isDisabled = false,
    hideDuplicateCardDescriptions = false,
    allowCardTitleClick = false,
    onTitleClick,
}: CardProps) => {
    const cardClass = classNames(
        MAPPING_UI.CARD,
        isDragging && MAPPING_UI.CARD_IS_DRAGGING,
        targeted && MAPPING_UI.CARD_TARGETED,
        isParent && MAPPING_UI.CARD_IS_PARENT,
        isChild && MAPPING_UI.CARD_IS_CHILD,
        isDisabled && MAPPING_UI.CARD_IS_DISABLED,
        allowCardTitleClick && MAPPING_UI.CARD_IS_TITLE_CLICKABLE,
        extraCardClasses || '',
    );
    const handleClass = classNames(
        MAPPING_UI.CARD_HANDLE,
        intent ? intent : INTENT.DEFAULT,
        isParent && MAPPING_UI.CARD_HANDLE_PARENT,
        isChild && MAPPING_UI.CARD_HANDLE_CHILD,
    );

    const getDescription = () => {
        if (hideDuplicateCardDescriptions) {
            return title === description ? '' : description;
        }
        return description;
    };

    const titleClickHandler = () => {
        if (onTitleClick) {
            onTitleClick(id, parentId);
        }
    };

    const titleComponent = allowCardTitleClick && onTitleClick ? <h3 onClick={titleClickHandler}>{title}</h3> : <h3>{title}</h3>;

    // For the foreseeable future, allow a click anywhere on the card to function as a click on the title
    const cardClickHandler = () => {
        if (allowCardTitleClick && onTitleClick) {
            titleClickHandler();
        }
    };

    return (
        <div className={cardClass} onClick={cardClickHandler}>
            <div className={handleClass}>
                <SVGHandle className={MAPPING_UI.CARD_HANDLE_GRIP} />
            </div>
            <div className={MAPPING_UI.CARD_CONTENT}>
                <div>
                    {titleComponent}
                    <p className={MAPPING_UI.CARD_DESCRIPTION}>{getDescription()}</p>
                </div>
                <div className={MAPPING_UI.CARD_BOTTOM}>
                    <span>{secondaryDescription}</span>
                    <span className={MAPPING_UI.CARD_ID}>{id}</span>
                </div>
            </div>
        </div>
    );
};
