import { FormType, ValidationResult } from '@enterprise/common';
import i18n from 'i18next';
import { MARKUP_TYPE_DOWN, MARKUP_TYPE_UP, PRICE_TYPE_BASE, PRICE_TYPE_MINIMUM } from '../bulkUpdates/constants';
import { isWithinRange } from '../../../../core/helpers';
import { invoiceItemPriceLimits } from '../invoiceItemPriceLimits.constants';
import { PriceDeltaUpdate } from '../../../../core/models';

export interface DeltaUpdateModel {
    priceType?: string;
    markupType?: string;
    name?: string;
    percent?: string | number;
    minimumAmount?: number | string;
    isIncludeZero?: boolean;
    updateAt?: Date;
}

export interface PriceDeltaUpdateEntity extends PriceDeltaUpdate {
    name?: string;
    updateAt?: Date;
}

const { minPrice, maxPrice } = invoiceItemPriceLimits;
const isWithinRangeValidator = isWithinRange(minPrice, maxPrice);

export class BulkPriceEditFormType extends FormType<DeltaUpdateModel, PriceDeltaUpdateEntity> {
    getEntity(values: DeltaUpdateModel): PriceDeltaUpdateEntity {
        return {
            isBasePriceUpdate: values.priceType === PRICE_TYPE_BASE,
            isMinimumPriceUpdate: values.priceType === PRICE_TYPE_MINIMUM,
            isDispensingFeeUpdate: false,
            isMarkUp: values.markupType === MARKUP_TYPE_UP,
            isMarkDown: values.markupType === MARKUP_TYPE_DOWN,
            percent: Number(values.percent),
            minimumAmount: Number(values.minimumAmount),
            isIncludeZero: values.isIncludeZero,
            name: values.name,
            updateAt: values.updateAt,
        };
    }

    validate(values): Promise<ValidationResult> {
        const errors: any = {};

        const isMinimumAmountSpecified = Boolean(values.minimumAmount);
        const isPercentSpecified = Boolean(values.percent);
        const isOutsideRangeError = isWithinRangeValidator(values.minimumAmount);
        const isInvalidPercentError = isWithinRangeValidator(values.percent);

        if (values.isIncludeZero) {
            if (!isMinimumAmountSpecified) {
                errors.minimumAmount = i18n.t(
                    'editPricing:includeZeroMinimumAmount',
                    'Minimum Amount should be specified if "Include Zero" is selected',
                );
            }
            if (isOutsideRangeError) {
                errors.minimumAmount = isOutsideRangeError;
            }
            if (isPercentSpecified) {
                errors.percent = i18n.t('editPricing:includeZeroPercent', 'Percent should not be specified if "Include Zero" is selected');
            }
        } else {
            if (isMinimumAmountSpecified && isOutsideRangeError) {
                errors.minimumAmount = isOutsideRangeError;
            }
            if (isPercentSpecified && isInvalidPercentError) {
                errors.percent = isInvalidPercentError;
            }
            if ((isMinimumAmountSpecified && isPercentSpecified) || (!isMinimumAmountSpecified && !isPercentSpecified)) {
                errors.minimumAmount = i18n.t('editPricing:minimumErrorOnlyOne', 'Either Minimum Amount or Percent should be specified');
                errors.percent = i18n.t('editPricing:percentErrorOnlyOne', 'Either Minimum Amount or Percent should be specified');
            }
        }

        return Promise.resolve(errors);
    }
}
