import '../styles/column.scss';
import React from 'react';
import { RefObject } from 'react';
import { INTENT, MAPPING_UI } from '../styles';
import { Card } from './Card';
import { Draggable, DraggableStateSnapshot, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';

import 'overlayscrollbars/css/OverlayScrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { CardModel } from '../models';
import { validCombineWith, serializeDraggableId } from '../utility';
import { NEW_CHILD_CARD_ID, SCROLL_DURATION, SCROLL_OFFSET } from '../constants';
import { ActionItem, ColumnOptions } from '../interfaces/ColumnOptions';
import { ColumnHeader } from './ColumnHeader';

export interface StackSet {
    index: number;
    selected: boolean;
    title: string;
}

interface ColumnProps {
    allowChildCardCreation?: boolean;
    allowPrimaryCardTitleClick?: boolean;
    columnOptions?: ColumnOptions;
    createChildAction?: ActionItem;
    data: CardModel[];
    hideDuplicateCardDescriptions?: boolean;
    id: string;
    onCardTitleClick?: (isPrimary: boolean, id: string, parentId?: string) => void;
    primary?: boolean;
    stackHandler: (number) => void;
    stackSets?: StackSet[];
    title: string;
    uniqueCharacters?: string[];
}

export const Column: React.FC<ColumnProps> = ({
    allowChildCardCreation,
    allowPrimaryCardTitleClick,
    columnOptions,
    createChildAction,
    data,
    hideDuplicateCardDescriptions,
    id,
    onCardTitleClick,
    primary,
    stackHandler,
    stackSets,
    title,
    uniqueCharacters,
}) => {
    const refMap = new Map<string, RefObject<HTMLDivElement>>();
    const scrollbarRef: RefObject<OverlayScrollbarsComponent> = React.createRef();

    const cardTitleClickHandler = (id, parentId?) => {
        if (allowPrimaryCardTitleClick && primary && onCardTitleClick) {
            onCardTitleClick(primary || false, id, parentId);
        }
    };

    const createCardElement = (card: CardModel, snapshot: DraggableStateSnapshot, isChild: boolean = false, isFinalChild = false) => {
        if (card.placeholder) {
            const placeHolderRef: RefObject<HTMLDivElement> = React.createRef();
            refMap.set(card.title, placeHolderRef);
            return (
                <div className={MAPPING_UI.COLUMN_COLLECTION_CHAR} ref={placeHolderRef}>
                    {card.title}
                </div>
            );
        }
        const extraCardClasses = classNames(isFinalChild && MAPPING_UI.CARD_IS_FINAL_CHILD) || '';
        if (card.isDisabled) {
            card.intent = INTENT.DISABLED;
        }

        return (
            <Card
                {...card}
                isChild={isChild}
                isParent={card.children.length > 0 || (primary && allowChildCardCreation)}
                extraCardClasses={extraCardClasses}
                isDragging={snapshot.isDragging}
                targeted={validCombineWith(snapshot, id)}
                hideDuplicateCardDescriptions={hideDuplicateCardDescriptions}
                allowCardTitleClick={allowPrimaryCardTitleClick && primary}
                onTitleClick={cardTitleClickHandler}
            />
        );
    };

    const createNewChildPlaceholder = (parentCard: CardModel, snapshot: DraggableStateSnapshot, action: ActionItem) => {
        const { id: actionId, text, icon } = action;
        const title = (
            <div className={MAPPING_UI.CARD_CREATE_TITLE}>
                {icon && (
                    <svg className={MAPPING_UI.CARD_CREATE_ICON}>
                        <use xlinkHref={icon} />
                    </svg>
                )}
                {text}
            </div>
        );

        return (
            <Card
                {...parentCard}
                isChild={true}
                extraCardClasses={classNames(MAPPING_UI.CARD_IS_FINAL_CHILD, MAPPING_UI.CARD_IS_CREATE_CHILD)}
                isDragging={snapshot.isDragging}
                targeted={validCombineWith(snapshot, id)}
                id={actionId}
                parentId={parentCard.id}
                title={title}
                description={''}
                secondaryDescription={''}
                allowCardTitleClick={true}
                onTitleClick={cardTitleClickHandler}
            />
        );
    };

    const scrollHandler = (event: React.BaseSyntheticEvent) => {
        const letterClicked = event.target.innerText;
        const placeholderRef = refMap.get(letterClicked);

        if (!placeholderRef) {
            return;
        }
        const osbar = scrollbarRef.current!.osInstance();
        osbar.scroll(placeholderRef.current!.offsetTop - SCROLL_OFFSET, SCROLL_DURATION);
    };

    let cardIndex = 0;
    return (
        <div className={MAPPING_UI.COLUMN}>
            <ColumnHeader
                columnOptions={columnOptions}
                isPrimary={Boolean(primary)}
                stackHandler={stackHandler}
                stackSets={stackSets}
                title={title}
            />
            <div className={MAPPING_UI.COLUMN_HR} />

            <div className={MAPPING_UI.COLUMN_SCROLLABLE}>
                <OverlayScrollbarsComponent
                    options={{
                        scrollbars: { autoHide: 'scroll' },
                        sizeAutoCapable: false,
                    }}
                    ref={scrollbarRef}
                >
                    <Droppable isCombineEnabled={true} droppableId={id}>
                        {(provided, snapshot) => (
                            <div
                                className={classNames(snapshot.isDraggingOver && MAPPING_UI.COLUMN_DROPPABLE_ADD)}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {data.map((card: CardModel) => {
                                    return (
                                        <React.Fragment key={serializeDraggableId({ cardId: card.id, columnId: id })}>
                                            <Draggable
                                                key={serializeDraggableId({ cardId: card.id, columnId: id })}
                                                draggableId={serializeDraggableId({ cardId: card.id, columnId: id })}
                                                index={cardIndex++}
                                                isDragDisabled={primary || card.placeholder || card.isDisabled}
                                            >
                                                {(providedDraggable, snapshotDraggable) => (
                                                    <div
                                                        className={MAPPING_UI.CARD_CONTAINER}
                                                        ref={providedDraggable.innerRef}
                                                        {...providedDraggable.draggableProps}
                                                        {...providedDraggable.dragHandleProps}
                                                    >
                                                        {createCardElement(card, snapshotDraggable)}
                                                    </div>
                                                )}
                                            </Draggable>
                                            {card.children &&
                                                card.children.map((child: CardModel, childIndex: number) => {
                                                    return (
                                                        <Draggable
                                                            key={serializeDraggableId({ cardId: child.id, columnId: id, parentId: card.id })}
                                                            draggableId={serializeDraggableId({ cardId: child.id, columnId: id, parentId: card.id })}
                                                            index={cardIndex++}
                                                            isDragDisabled={primary || card.placeholder}
                                                        >
                                                            {(providedDraggable, snapshotDraggable) => (
                                                                <div
                                                                    className={classNames(MAPPING_UI.CARD_CONTAINER, MAPPING_UI.CARD_CONTAINER_CHILD)}
                                                                    ref={providedDraggable.innerRef}
                                                                    {...providedDraggable.draggableProps}
                                                                    {...providedDraggable.dragHandleProps}
                                                                >
                                                                    {createCardElement(
                                                                        child,
                                                                        snapshotDraggable,
                                                                        true,
                                                                        primary && allowChildCardCreation
                                                                            ? false
                                                                            : childIndex === card.children.length - 1,
                                                                    )}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            {!card.placeholder && primary && allowChildCardCreation && createChildAction && (
                                                <Draggable
                                                    key={serializeDraggableId({ cardId: NEW_CHILD_CARD_ID, columnId: id, parentId: card.id })}
                                                    draggableId={serializeDraggableId({ cardId: NEW_CHILD_CARD_ID, columnId: id, parentId: card.id })}
                                                    index={cardIndex++}
                                                    isDragDisabled={primary || card.placeholder}
                                                >
                                                    {(providedDraggable, snapshotDraggable) => (
                                                        <div
                                                            className={classNames([MAPPING_UI.CARD_CONTAINER, MAPPING_UI.CARD_CONTAINER_CHILD])}
                                                            ref={providedDraggable.innerRef}
                                                            {...providedDraggable.draggableProps}
                                                            {...providedDraggable.dragHandleProps}
                                                        >
                                                            {createNewChildPlaceholder(card, snapshotDraggable, createChildAction)}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </OverlayScrollbarsComponent>
            </div>

            <div className={MAPPING_UI.COLUMN_LETTER_BAR}>
                <div>
                    {uniqueCharacters &&
                        uniqueCharacters.map(letter => (
                            <span onClick={scrollHandler} key={letter}>
                                {letter}
                            </span>
                        ))}
                </div>
            </div>
        </div>
    );
};
