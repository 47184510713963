import React, { useState } from 'react';
import styles from './itemsGridToolbar.module.scss';
import i18n from '../../../../../i18n';
import { useGridApiContext, useGridRootProps } from '@enterprise/material-data-grid';
import classNames from 'classnames';
import { SpotSvg } from '@enterprise/spot';
import { Trans } from 'react-i18next';
import { PaginationModel, SelectionModel } from '../../../../../store/invoiceItem.store';
import { ScreenSizeType, useScreenSize } from '../../../../../hooks';
import { Paper, Popover } from '@mui/material';
import { gridRowsStateSelector, useGridSelector } from '@mui/x-data-grid-premium';

type ItemsGridToolbarProps = {
    selection?: SelectionModel;
    pagination?: PaginationModel;
    onSelectionAllChange?: (params: { value: boolean }) => void;
    onExitBulkMode?: () => void;
    onAddToPractices?: () => void;
    isResetVisible?: boolean;
    onColumnsReset?: () => void;
};

export const ItemsGridToolbar = (props: ItemsGridToolbarProps) => {
    const { selection = {}, pagination = {}, onSelectionAllChange, onAddToPractices, isResetVisible, onColumnsReset, onExitBulkMode } = props;
    const { all: selectedAll } = selection;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const { rows } = rootProps;
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedAny = selectedAll || Boolean(selectedRows.size);
    const { isUpTo } = useScreenSize();
    const { groupingName, tree } = useGridSelector(apiRef, gridRowsStateSelector);
    const isTabledView = isUpTo(ScreenSizeType.md);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
    const totalRowsCount = pagination?.total || rows?.length;
    const totalGroupedRowsCount = pagination?.groups || rows?.length;
    const groupedRows = Object.values(tree).filter((item) => item.isAutoGenerated).length;

    const resetColumnsState = () => {
        onColumnsReset?.();
    };

    const unselectItems = () => {
        apiRef.current.selectRows([...selectedRows.keys()], false);
        onExitBulkMode?.();
    };

    const toggleSelectAll = () => {
        onSelectionAllChange?.({ value: !selectedAll });
    };

    const renderSelection = () => {
        return (
            <div className={styles.selection}>
                <div className={styles.item}>
                    <Trans i18nKey="controlCenter:itemsOnThisPageSelected" count={selectedRows.size}>
                        {selectedRows.size} item(s) on this page selected
                    </Trans>
                </div>

                <button
                    className={classNames('spot-button spot-button--secondary', styles.button, { ['spot-button--active']: selectedAll })}
                    onClick={toggleSelectAll}
                    data-automation-id="toggle-select-all-button"
                >
                    <SpotSvg icon={'checkmark'} className={classNames('spot-icon spot-button__icon spot-button__icon--left', styles.buttonIcon)} />
                    <span className={classNames('spot-button__text')}>
                        {Boolean(selectedAll) && (
                            <Trans i18nKey="controlCenter:itemsSelected" count={totalRowsCount - (selection.excluded?.size || 0)}>
                                {totalRowsCount - (selection.excluded?.size || 0)} items selected
                            </Trans>
                        )}
                        {!selectedAll && (
                            <Trans i18nKey="controlCenter:selectAllItems" count={totalRowsCount}>
                                Select all {totalRowsCount} items?
                            </Trans>
                        )}
                    </span>
                </button>
            </div>
        );
    };

    const renderActions = () => {
        return (
            <>
                <div className={styles.info}>
                    {isResetVisible && (
                        <button
                            className={classNames('spot-button spot-button--link', 'spot-button--with-icon', styles.exitButton)}
                            onClick={resetColumnsState}
                            data-automation-id="reset-to-default-button"
                        >
                            <span className={classNames('spot-button__text', styles.backText)}>
                                <Trans i18nKey="controlCenter:resetToDefault">Reset to Default</Trans>
                            </span>
                        </button>
                    )}
                    <button
                        className={classNames('spot-button spot-button--link', 'spot-button--with-icon', styles.exitButton)}
                        onClick={unselectItems}
                        data-automation-id="unselect-invoice-items-button"
                    >
                        <SpotSvg icon={'close'} className={'spot-icon spot-button__icon spot-button__icon--left'} />
                        <span className={classNames('spot-button__text', styles.backText)}>
                            <Trans i18nKey="controlCenter:exitBulkMode">Exit bulk mode</Trans>
                        </span>
                    </button>
                </div>

                <button
                    className={classNames('spot-button spot-button--secondary', styles.button)}
                    onClick={onAddToPractices}
                    data-automation-id="add-to-all-practices-button"
                >
                    <SpotSvg icon={'add'} className={classNames('spot-icon spot-button__icon spot-button__icon--left', {})} />
                    <span className={classNames('spot-button__text')}>
                        <Trans i18nKey="controlCenter:addToAdditionalPractices">Add to Additional Practice(s)</Trans>
                    </span>
                </button>

                {renderSelection()}
            </>
        );
    };

    return (
        <div className={styles.holder}>
            <div className={styles.leftContainer}>
                {!selectedRows.size && (
                    <div className={styles.info}>
                        {isResetVisible && (
                            <button
                                className={classNames('spot-button spot-button--link', 'spot-button--with-icon', styles.exitButton)}
                                onClick={resetColumnsState}
                                data-automation-id="reset-to-default-button"
                            >
                                <span className={classNames('spot-button__text', styles.backText)}>
                                    <Trans i18nKey="controlCenter:resetToDefault">Reset to Default</Trans>
                                </span>
                            </button>
                        )}
                        <div className={styles.item}>{i18n.t('controlCenter:selectItemToEnterEditMode', 'Select an item to enter edit mode')}</div>
                    </div>
                )}
                {selectedAny && !isTabledView && renderActions()}
                {selectedAny && isTabledView && (
                    <>
                        <button
                            className={classNames('spot-button spot-button--link', 'spot-button--with-icon', styles.exitButton)}
                            onClick={(event) => setAnchorEl(event.currentTarget)}
                            data-automation-id="unselect-invoice-items-button"
                        >
                            <SpotSvg icon={'more-2'} className={'spot-icon spot-button__icon spot-button__icon--left'} />
                            <span className={classNames('spot-button__text', styles.backText)}>
                                <Trans i18nKey="controlCenter:exitBulkMode">more bulk mode tools</Trans>
                            </span>
                        </button>
                        <Popover
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(undefined)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <Paper classes={{ root: styles.paper }}>
                                <span className={styles.paperTitle}>
                                    <Trans i18nKey="controlCenter:additionalBulkModeTools">Additional bulk mode tools</Trans>
                                </span>
                                {renderActions()}
                            </Paper>
                        </Popover>
                    </>
                )}
            </div>
            <div className={styles.pagination}>
                <span>{groupingName === 'none' ? `1 - ${rows?.length} of ${totalRowsCount}` : `1 - ${groupedRows} of ${totalGroupedRowsCount}`}</span>
            </div>
        </div>
    );
};
