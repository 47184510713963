import React from 'react';
import styles from './connectPracticeFlyover.module.scss';
import { RightSidebar } from '../../layout/main/rightSidebar';
import { MyOrganizationPageStore } from '../practiceConnections/MyOrganizationPageStore';
import { Notifications } from '../Notifications';
import i18n from 'i18next';
import { ToastWithSubHeader } from '../../components/toasts/ToastWithSubheader';

interface ConnectPracticeFlyoverProps {
    onClose: () => void;
    page: MyOrganizationPageStore;
}

interface ConnectPracticeFlyoverState {
    isLoading: boolean;
}

class ConnectPracticeFlyover extends React.Component<ConnectPracticeFlyoverProps, ConnectPracticeFlyoverState> {
    state: ConnectPracticeFlyoverState = {
        isLoading: true,
    };

    private messageEventListener = (event: MessageEvent) => this.handleMessage.bind(this)(event);

    private getSuccessMessage = () => {
        const message = i18n.t('myOrganization:connectionStartedTitle', 'Practice Connection Started');
        const subMessage = i18n.t(
            'myOrganization:connectionStartedDetail',
            'A technician may reach out in the next 24 hours to the technical contact that you provided in order to establish a data connection.',
        );
        return ToastWithSubHeader({ message: message, subMessage: subMessage });
    };

    private async handleMessage(event: MessageEvent) {
        if (typeof event.data !== 'string') {
            return;
        }

        const { datapointRequestId, enrollmentSlug } = JSON.parse(event.data);
        if (datapointRequestId && enrollmentSlug) {
            try {
                await this.props.page.savePracticeEnrollment(datapointRequestId, enrollmentSlug);
                Notifications.success(this.getSuccessMessage);
            } catch (err) {
                Notifications.error(i18n.t('myOrganization:connectionError', 'Unable to connect practices at this time, please try again later.'));
            }
        } else {
            Notifications.error(i18n.t('myOrganization:connectionError', 'Unable to connect practices at this time, please try again later.'));
        }
        this.props.onClose();
    }

    async componentDidMount(): Promise<void> {
        try {
            await this.props.page.getConnectPracticeEmbedLink();
            window.addEventListener('message', this.messageEventListener, false);
        } catch (err) {
            Notifications.error(i18n.t('myOrganization:connectionError', 'Unable to connect practices at this time, please try again later.'));
            this.props.onClose();
        }
        this.setState({ isLoading: false });
    }

    componentWillUnmount(): void {
        try {
            window.removeEventListener('message', this.messageEventListener, false);
        } catch (err) {}
    }

    render() {
        const { onClose } = this.props;
        const { isLoading } = this.state;
        const { connectPracticeEmbedUrl } = this.props.page;

        return (
            <RightSidebar
                title={i18n.t('myOrganization:connectPractice', 'Connect Practice')}
                close={onClose}
                hideBackdrop={true}
                isLoading={isLoading}
                className={styles.enrollmentFlyover}
                modalBodyStyle={{ 'overflow-y': 'hidden' }}
            >
                {connectPracticeEmbedUrl && this.renderFrame(connectPracticeEmbedUrl)}
            </RightSidebar>
        );
    }

    renderFrame(url) {
        return <iframe className={styles.enrollmentFrame} src={url} />;
    }
}

export default ConnectPracticeFlyover;
