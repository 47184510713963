import * as React from 'react';
import { SpotSvg } from './SpotSvg';

export class SpotLoading extends React.PureComponent<{ text?: string; size?: 'large' | 'small' }> {
    render() {
        const { text, size } = this.props;
        return (
            <span>
                <SpotSvg className={`spot-icon spot-loading-spinner ${size ? `spot-loading-spinner--${size}` : ''}`} icon="spinner" />
                &nbsp;
                {text}
            </span>
        );
    }
}
