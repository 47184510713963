import { InvoiceItem } from './index';
import { ProviderDetails } from '../index';
import { DatasourceFullSiteInfo } from '../sites';

export class InvoiceItemDifference {
    [key: string]: Partial<InvoiceItem>;
}

export interface InvoiceItemData extends InvoiceItemDifference {
    master: Partial<InvoiceItemWithVariantDetail>;
}

export class InvoiceItemWithVariantDetail extends InvoiceItem {
    diff: InvoiceItemDifference = {} as InvoiceItemDifference;

    providers: ProviderDetails[];

    sites?: DatasourceFullSiteInfo[];

    variants: InvoiceItem[] = [];
}
