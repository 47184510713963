import { useField } from '@enterprise/common';
import * as React from 'react';
import { SpotCheckbox } from '@enterprise/spot';

interface ItemsGroupFieldProps<T> {
    name: string;
    options: T[];
    disabled?: boolean;
    getOptionId?: (T) => string | number;
    getOptionTitle?: (T) => string | number;
    dataAutomationId?: string;
}

export function GroupSelectField<T>(props: ItemsGroupFieldProps<T>) {
    const { name, options, disabled, getOptionId = (option) => option, getOptionTitle = (option) => option } = props;
    const { input } = useField<T[]>(name);

    const addSelection = (option: T) => {
        const currentValue = input.value || [];
        input.onChange([...currentValue, option]);
    };

    const removeSelection = (option: T) => {
        const currentValue = input.value || [];
        const newValue = currentValue.filter((item) => getOptionId(item) !== getOptionId(option));
        input.onChange(newValue);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, option: T) => {
        if (event.target.checked) {
            addSelection(option);
            return;
        }

        removeSelection(option);
    };

    const checked = (option: T) => {
        const currentValue = input.value || [];
        return Boolean(currentValue.find((item) => getOptionId(item) === getOptionId(option)));
    };

    return (
        <>
            {options.map((option) => (
                <div key={getOptionId(option)}>
                    <SpotCheckbox
                        type="checkbox"
                        disabled={disabled}
                        checked={checked(option)}
                        onChange={(event) => handleChange(event, option)}
                        data-automation-id={props.dataAutomationId}
                    >
                        {getOptionTitle(option)}
                    </SpotCheckbox>
                </div>
            ))}
        </>
    );
}
