import * as React from 'react';
import { Collapse } from 'react-bootstrap';

export interface Props {
    isOpen: boolean;
    elements: string[];
}

export class SpotCollapsibleList extends React.PureComponent<Props> {
    render() {
        const { isOpen, elements } = this.props;

        return (
            <Collapse in={isOpen}>
                <div>
                    {elements.map(e => {
                        return (
                            <div key={e}>
                                <span className="spot-typography__text--secondary">{e}</span>
                            </div>
                        );
                    })}
                </div>
            </Collapse>
        );
    }
}
