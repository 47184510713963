export enum AnalysisGroupType {
    Group1 = 'analysisGroup1',
    Group2 = 'analysisGroup2',
    Group3 = 'analysisGroup3',
}

export class LookupAnalysisGroup {
    name: string;

    id: string;

    type: AnalysisGroupType;
}
