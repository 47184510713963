import { envConfig } from './config';
import { FEATURES } from '@enterprise/core';
import { ICache, CacheLocation } from '@auth0/auth0-react';

export const environment = {
    get isDev() {
        return ['LOCAL', 'DEV', 'DEVELOPMENT'].indexOf(this.APP_ENV) !== -1;
    },
    get isLocal() {
        return this.APP_ENV === 'LOCAL';
    },
    APP_ENV: envConfig.name.toUpperCase(),
    adminEmail: 'connectadmin@idexx.com',
    AUTH0: {
        clientId: envConfig.config.AUTH0.CLIENT_ID,
        domain: envConfig.config.AUTH0.DOMAIN,
        scope: envConfig.config.AUTH0.SCOPE,
        audience: envConfig.config.AUTH0.AUDIENCE,
        cacheLocation: envConfig.config.AUTH0.CACHE_LOCATION as CacheLocation,
        cache: envConfig.config.AUTH0.CACHE as ICache | undefined,
    },
    ENTERPRISE_API: {
        baseUrl: envConfig.config.ENTERPRISE_API_BASEURL,
    },
    INTERCOM: {
        app_id: envConfig.config.INTERCOM.APP_ID,
    },
    CONTENTFUL: {
        space: 'bebxfcb6yvpq',
        //accessToken is read only
        //https://www.contentful.com/developers/docs/references/content-management-api/#/reference/api-keys
        accessToken: 'O_YjY2dD-z1i5UFzcy9RXHdi977kVIOdEu5u5Av2Bro',
    },
    dateFormat: 'lll',
    regionColors: [
        '#7fc4ce',
        '#70598c',
        '#f5c072',
        '#a4c564',
        '#4ec263',
        '#4ba5b5',
        '#2976a0',
        '#4F5d7d',
        '#ca5d52',
        '#a660b4',
        '#7526c7',
        '#999999',
    ],
    features: Object.keys(FEATURES).map((n) => FEATURES[n]),
    MAPPING: {
        classificationTemplateFileUrl: envConfig.config.MAPPING.CLASSIFICATION_TEMPLATE_FILE_URL,
    },
    LOOKER: {
        invoiceItemsUpdateDashboardId: envConfig.config.LOOKER.INVOICE_ITEMS_UPDATE_DASHBOARD_ID,
    },
    HUBSPOT: {
        OVERVIEW_URL: envConfig.config.HUBSPOT.OVERVIEW_URL,
    },
    PRACTICE_CONNECT: {
        ENROLLMENT_URL: envConfig.config.PRACTICE_CONNECT.ENROLLMENT_URL,
        BASE_API_URL: envConfig.config.PRACTICE_CONNECT.BASE_API_URL,
    },
    MARKETING_PAGE_URL: envConfig.config.MARKETING_PAGE_URL,
    CONTACT_US_PAGE_URL: envConfig.config.CONTACT_US_PAGE_URL,
};

export const TAG_MANAGER_CONFIG = envConfig.config.TAG_MANAGER;

export const MANAGEABLE_PIMS = ['cornerstone', 'idexx cornerstone', 'animana'];
