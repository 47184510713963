import React, { ReactElement } from 'react';
import { GridRenderCellParams, useGridApiContext, useGridRootProps } from '@mui/x-data-grid-premium';
import styles from './checkboxSelectorCell.module.scss';
import classNames from 'classnames';
import { Checkbox } from '@mui/material';

export const CheckboxSelectorCell = (props: GridRenderCellParams<boolean>): ReactElement => {
    const { field, id, value, tabIndex } = props;
    const apiRef = useGridApiContext();
    const rootProps = useGridRootProps();
    const element = apiRef.current.getCellElement(id, field);
    const isAutoGenerated = apiRef.current.getRowNode(id)?.isAutoGenerated || undefined;
    const selectedRows = apiRef.current.getSelectedRows();
    const isSelectable = !rootProps?.isRowSelectable || rootProps?.isRowSelectable(apiRef.current.getRowParams(id));
    const groupRowIds = isAutoGenerated && apiRef.current?.getRowGroupChildren({ groupId: id, applyFiltering: true, applySorting: true });
    const selectedRowIdsInGroup = isAutoGenerated && groupRowIds?.filter((rowId) => selectedRows.has(rowId));

    const isChecked = isAutoGenerated ? Boolean(selectedRowIdsInGroup?.length === groupRowIds?.length) : value;
    const isIndeterminate =
        Boolean(isAutoGenerated) && Boolean(selectedRowIdsInGroup?.length && selectedRowIdsInGroup.length !== groupRowIds?.length);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!isAutoGenerated) {
            apiRef.current.selectRow(id, event.target.checked);
            return;
        }

        const groupRowIds = apiRef.current?.getRowGroupChildren({ groupId: id, applyFiltering: true, applySorting: true });
        apiRef.current.selectRows(groupRowIds, event.target.checked);
    };

    if (tabIndex === 0 && element) {
        element.tabIndex = -1;
    }

    return (
        <div
            onClick={(event) => {
                event.stopPropagation();
            }}
            className={classNames({ [styles.hidden]: !isSelectable })}
        >
            <Checkbox
                tabIndex={tabIndex}
                checked={isChecked}
                indeterminate={isIndeterminate}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
                disableRipple
                disabled={!isSelectable}
                data-automation-id="data-grid-selector-cell"
            />
        </div>
    );
};
