import { Inject, Service } from 'typedi';
import { BaseHttp } from '@enterprise/common';
import { Lookup, LookupClassification, LookupType } from '../../core/models/datasource/lookups';
import { GenericResponse } from '../../core/models/datasource';
import { LookupsMap } from './LookupsMap';

export const LOOKUPS_PATH = 'v2/lookups';

const sortKeys = {
    default: 'description',
    [LookupType.CLASSIFICATIONS]: 'name',
    [LookupType.SPECIAL_ACTION_TYPES]: 'whenToApplyDescription',
};

@Service()
export class LookupsService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    async getLookups(types: LookupType[], siteId?: string): Promise<GenericResponse<Lookup>> {
        const result = await this.http.get(LOOKUPS_PATH, { params: { siteId, types } });
        return this.sortLookupResults(result);
    }

    async getLookupsAsMap(types: LookupType[], siteId?: string): Promise<LookupsMap> {
        const { data } = await this.getLookups(types, siteId);
        return new LookupsMap(data);
    }

    private sortLookupResults(result: GenericResponse<Lookup>): GenericResponse<Lookup> {
        result.data.forEach(({ lookupType, results }) => {
            const sortKey = sortKeys[lookupType] || sortKeys.default;
            this.sortByField(results, sortKey);
            if (lookupType === LookupType.CLASSIFICATIONS || LookupType.CLASSIFICATIONS_WITH_STATUS) {
                (results as LookupClassification[]).forEach(({ subClassifications = [] }) => this.sortByField(subClassifications, sortKey));
            }
        });
        return result;
    }

    private sortByField(results: object[], field: string): void {
        results.sort((a, b) => (a[field] ? a[field].localeCompare(b[field]) : 0));
    }
}
