import * as React from 'react';
import { SpotSvg } from '@enterprise/spot';

export interface CollapsiblePanelProps {
    isOpen?: boolean;
    hideIfOpen?: boolean;
    className?: string;
    hideToggleIcon?: boolean;
    children?: any;
    expandedContent?: any;
}

export interface CollapsiblePanelState {
    isExpanded: boolean | null;
}

export interface CollapsiblePanelControlProps {
    open: () => void;
    close: () => void;
    toggle: () => void;
    isOpen: boolean;
}

export class CollapsiblePanel extends React.Component<CollapsiblePanelProps, CollapsiblePanelState> {
    state: CollapsiblePanelState = {
        isExpanded: null,
    };

    open = () => {
        this.setState({ isExpanded: true });
    };

    close = () => {
        this.setState({ isExpanded: false });
    };

    toggle = () => {
        const { isExpanded } = this.state;
        this.setState({ isExpanded: !isExpanded });
    };

    get isOpen(): boolean {
        const { isExpanded } = this.state;
        const { isOpen } = this.props;
        if (isExpanded === null) {
            if (isOpen !== undefined && isOpen !== null) {
                return isOpen;
            }
        }
        return Boolean(isExpanded);
    }

    render() {
        const { expandedContent, hideIfOpen, hideToggleIcon } = this.props;
        return (
            <div className="collapsible-panel">
                {!hideToggleIcon && (
                    <div className="toggle-control">
                        <a onClick={this.toggle}>
                            {this.isOpen && <SpotSvg icon="caret-up" width="16px" height="16px" style={{ fill: '#33bfcc' }} />}
                            {!this.isOpen && <SpotSvg icon="caret-down" width="16px" height="16px" style={{ fill: '#33bfcc' }} />}
                        </a>
                    </div>
                )}
                {!(Boolean(hideIfOpen) && this.isOpen) && <div>{this.renderContent()}</div>}
                {this.isOpen && Boolean(expandedContent) && <div>{this.renderExpandedContent()}</div>}
            </div>
        );
    }

    private renderContent() {
        const { children } = this.props;
        const childProps: CollapsiblePanelControlProps = { open: this.open, close: this.close, isOpen: this.isOpen, toggle: this.toggle };
        return React.isValidElement(children) ? React.cloneElement(children, childProps) : React.createElement(children, childProps);
    }

    private renderExpandedContent() {
        const { expandedContent } = this.props;
        const childProps: CollapsiblePanelControlProps = { open: this.open, close: this.close, isOpen: this.isOpen, toggle: this.toggle };
        return React.isValidElement(expandedContent)
            ? React.cloneElement(expandedContent, childProps)
            : React.createElement(expandedContent, childProps);
    }
}
