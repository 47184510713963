import BasicLayout from '../layout/basic/BasicLayout';
import { MainLayout } from '../layout/main/mainLayout';
import { FlexLayout } from '../layout/main/flexBoxLayout';
import { RedirectToRouteComponent } from './RedirectToRouteComponent';
import { AnalyticsPage } from '../pages/analytics/analyticsPage';
import { ControlCenterPage } from '../pages/controlCenter/ControlCenterPage';
import { GeneralSettingsPage } from '../pages/generalSettings/GeneralSettingsPage';
import { MasterDataPage } from '../pages/masterData/MasterDataPage';
import { SignupPage } from '../pages/signup/SignupPage';
import MarketingPage from '../pages/marketing/MarketingPage';
import { RouterPaths } from './RouterPaths';
import { MyProfilePage } from '../pages/myProfile/myProfilePage';
import { PracticeConnectionsPage } from '../pages/practiceConnections/practiceConnections.page';
import { environment } from '../environment/environment';
import { UserManagementPage } from '../pages';
import { UserRoles } from '@enterprise/common';
import { Login, Logout } from '../components/auth';

export interface RouteParams {
    path?: string | null;
    isSecured: boolean;
    Layout: any;
    role: '*' | string[];
    Component?: any | null;
    exact?: boolean;
    config?: { global?: boolean; to?: string; secureRedirectTo?: string };
}

const baseRoute: RouteParams = {
    path: null,
    isSecured: false,
    Layout: BasicLayout,
    role: '*',
    Component: null,
    config: undefined,
};

const securedRoute: RouteParams = {
    ...baseRoute,
    isSecured: true,
    role: ['ADMIN', 'USER'],
    Layout: MainLayout,
};

export const DEFAULT_AUTH_ROUTE = RouterPaths.ControlCenterPages.Items;

export const routes = [
    {
        ...baseRoute,
        path: RouterPaths.BasePages.Root,
        exact: true,
        Component: MarketingPage,
    },
    {
        ...baseRoute,
        path: RouterPaths.BasePages.Login,
        Component: Login,
    },
    {
        ...baseRoute,
        path: RouterPaths.BasePages.Logout,
        Component: Logout,
    },
    {
        ...baseRoute,
        path: RouterPaths.BasePages.Signup,
        Component: SignupPage,
    },
    {
        ...securedRoute,
        path: RouterPaths.AnalyticsPages.Analytics,
        Component: AnalyticsPage,
    },
    {
        ...securedRoute,
        path: RouterPaths.ControlCenterPages.ControlCenter,
        Component: ControlCenterPage,
        Layout: FlexLayout,
    },
    {
        ...securedRoute,
        role: [UserRoles.SuperAdmin, UserRoles.SystemOwner],
        path: RouterPaths.MasterDataPages.MasterData,
        Component: MasterDataPage,
    },
    {
        ...securedRoute,
        path: GeneralSettingsPage.PAGE_URL,
        Component: GeneralSettingsPage,
    },
    {
        ...securedRoute,
        path: RouterPaths.MyProfilePages.MyProfile,
        Component: MyProfilePage,
        Layout: FlexLayout,
    },
    {
        ...securedRoute,
        path: RouterPaths.MyOrganizationPages.PracticeConnections,
        Component: PracticeConnectionsPage,
        Layout: FlexLayout,
    },
    {
        ...securedRoute,
        role: [UserRoles.SuperAdmin, UserRoles.SystemOwner],
        config: { secureRedirectTo: RouterPaths.MyOrganizationPages.MyOrganization },
        path: RouterPaths.MyOrganizationPages.UserManagement,
        Component: UserManagementPage,
        Layout: FlexLayout,
    },
    {
        ...securedRoute,
        path: RouterPaths.MyOrganizationPages.MyPractices_DEPRECATED,
        Component: RedirectToRouteComponent,
        config: { to: RouterPaths.MyOrganizationPages.MyOrganization },
    },
    {
        ...securedRoute,
        path: RouterPaths.MyOrganizationPages.MyOrganization,
        Component: RedirectToRouteComponent,
        config: { to: RouterPaths.MyOrganizationPages.PracticeConnections },
    },
    {
        ...baseRoute,
        path: RouterPaths.Cornerstone,
        Component: RedirectToRouteComponent,
        config: { global: true, to: environment.HUBSPOT.OVERVIEW_URL },
    },
    {
        ...baseRoute,
        // exact: true,
        Component: RedirectToRouteComponent,
        config: { to: DEFAULT_AUTH_ROUTE },
    },
] as RouteParams[];
