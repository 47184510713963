import * as React from 'react';
import { SpotLoading, SpotPill, SpotSvg } from '@enterprise/spot';
import { _value, Value } from '../../../components/helpers/Value';
import { Card, CardDeck, Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { autorun, IReactionDisposer, toJS } from 'mobx';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { InvoiceItemDifference, InvoiceItemWithVariantDetail } from '../../../core/models/datasource/invoiceItems/InvoiceItemWithVariantDetail';
import { InvoiceItemsWithVariantDetails } from '../../../core/transformer/InvoiceItemsWithVariantDetails';
import { InvoiceItem, InvoiceItemParent } from '../../../core/models/datasource/invoiceItems';
import { ItemsGroupByType } from '../../../core/models/organization/settings/enum';
import { OrganizationSettingsMasterSite } from '../../../core/models/organization/settings';
import { StoresContext } from '../../../contexts';
import { ItemAddToPracticesParams, ItemAddPractices } from './ItemAddPractice/ItemAddPractices';
import { SiteEntityInfo } from '../../practiceConnections/MyOrganizationPageStore';
import { ItemsPageStore } from '../items/ItemsPageStore';
import { Transformers } from '@enterprise/common';
import { cloneDeep } from 'lodash';
import { defaultEmptyPriceBreakValues, removeEmptyPriceBreaks } from '../../../core/helpers/priceFieldHelpers';
import { DatasourceFullSiteInfo } from 'apps/client/src/core/models/datasource/sites';

interface ItemCardDetailProps {
    item: InvoiceItemWithVariantDetail;
    onEditClicked?: (item: InvoiceItem) => void;
    masterSite?: OrganizationSettingsMasterSite;
    matchSubLabel: boolean;
}

interface ItemCardDetailsState {
    fetching: boolean;
    fullItem?: InvoiceItemWithVariantDetail;
    showSelectPracticesModal: boolean;
}

@observer
export class ItemCardDetails extends React.Component<ItemCardDetailProps, ItemCardDetailsState> {
    static contextType = StoresContext;
    private data: InvoiceItemDifference = {};
    private disposer?: IReactionDisposer;
    context!: React.ContextType<typeof StoresContext>;
    get itemsPageStore() {
        return this.context.domain.itemsPageStore;
    }

    state: ItemCardDetailsState = {
        fetching: true,
        showSelectPracticesModal: false,
    };

    componentDidMount(): void {
        if (!this.itemsPageStore.isCreatingNewItem) {
            this.reloadItem()
                .then((result) => {
                    const jsItem = toJS(result);
                    this.setState({ fullItem: jsItem });
                })
                .then(() => {
                    this.setState({ fetching: false });
                });
            this.disposer = autorun(() => {
                // this function is going to be executed only on this.store.invoiceItemDifference value change according to mobX documentation
                if (!this.store.invoiceItemDifference) {
                    return;
                }

                this.data = this.store.invoiceItemDifference;
            });
        }
    }

    componentWillUnmount() {
        this.disposer?.();
    }

    reloadItem(): Promise<InvoiceItemWithVariantDetail> {
        const { item } = this.props;
        if (this.itemsPageStore.itemsGroupBy === ItemsGroupByType.Description) {
            const isSub = Boolean(item.itemParent);
            return this.itemsPageStore.fetchInvoiceItem({ description: item.hospitalDescription, isSub });
        }
        return this.itemsPageStore.fetchInvoiceItem_legacy({ id: item.pimsGeneratedId });
    }

    getSitesWithoutItem = (): string[] => {
        const { fullItem } = this.state;
        const sitesWithItem = fullItem?.sites?.map((site) => site.id) || [];
        const activeSites = this.itemsPageStore.applications?.filter((app) => app.isActive);
        if (!activeSites) {
            return [];
        }
        const allSites = activeSites.map((site) => site.id);
        return allSites?.filter((site) => !sitesWithItem.includes(site));
    };

    onSubmitSelectedPractices = ({ originPractice, targetPractices, runAt, name }: ItemAddToPracticesParams) => {
        const activeSites = this.store.applications.filter((app) => app.isActive);
        const newList = this.filterSelectedPractices(targetPractices as SiteEntityInfo[], activeSites, originPractice.id);
        this.setState({ showSelectPracticesModal: false });
        this.onSave(newList, originPractice as SiteEntityInfo, runAt, name);
    };

    filterSelectedPractices = (practices: SiteEntityInfo[], activeSites: DatasourceFullSiteInfo[], masterSiteId): DatasourceFullSiteInfo[] => {
        const newList: DatasourceFullSiteInfo[] = [];
        for (const practice of practices) {
            const match = activeSites.find((site) => {
                return site.id === practice.id;
            });
            if (match) {
                match.provider.siteId = practice.id;
                match.provider.name = practice.pimsName || '';
                match.masterSiteId = masterSiteId;
                newList.push(match);
            }
        }

        return newList;
    };

    get store(): ItemsPageStore {
        return this.context.domain.itemsPageStore;
    }

    getOriginalItemParent = (originalKey?: string): InvoiceItemParent | undefined => {
        if (!originalKey) {
            return undefined;
        }

        const { master } = this.data;
        const { variants } = this.store.invoiceItem as InvoiceItemWithVariantDetail;
        const { pimsGeneratedId } = InvoiceItemsWithVariantDetails.splitDiffKey(originalKey);
        const originalVariant = variants.find((variant) => variant.pimsGeneratedId === pimsGeneratedId);

        if (!master?.itemParent) {
            return undefined;
        }

        return originalVariant?.itemParent;
    };

    unpackEntity(activeSites: DatasourceFullSiteInfo[], copyFromPractice: SiteEntityInfo): InvoiceItemWithVariantDetail {
        function splitTaxes(val: any) {
            const v = { ...val };
            v.taxes = [].concat(
                (v.usageTaxes || []).map((t) => ({ ...t, isUsageTax: true })),
                (v.salesTaxes || []).map((t) => ({ ...t, isUsageTax: false })),
            );
            delete v.usageTaxes;
            delete v.salesTaxes;
            return v;
        }

        const { variantKeys } = this.store;
        const dataKeys = Object.keys(this.data);
        const nonMasterData = dataKeys?.find((v) => {
            return v.includes(copyFromPractice.id);
        });
        const data = nonMasterData ? { master: this.data[nonMasterData] } : { master: this.data.master };

        const entity = new Transformers.ToTypeTransformer(InvoiceItemWithVariantDetail).transform(splitTaxes(data?.master));

        entity.variants = activeSites
            .map(({ id, provider, pimsName, masterSiteId }) => {
                const { master } = data;

                // pimsGeneratedIds get lost on variants and will default to the master id, so we need to restore them
                const originalKey = variantKeys?.find((key) => {
                    const { siteId: existingSiteId } = InvoiceItemsWithVariantDetails.splitDiffKey(key);
                    return existingSiteId === id;
                });

                const originalItemParent = this.getOriginalItemParent(originalKey);
                const variant: Partial<InvoiceItemWithVariantDetail> = Object.assign(cloneDeep(master), {
                    provider: { name: master?.provider?.name, siteId: provider.siteId },
                    masterSiteId: masterSiteId,
                });

                if (master.itemParent) {
                    variant.itemParent = originalItemParent;
                }

                return variant;
            })
            .filter((variant) => {
                const { master } = data;
                // remove all sub-products variants with does not have parent product before send to api
                return !master.itemParent || variant.itemParent;
            })
            .map(splitTaxes)
            .map(removeEmptyPriceBreaks)
            .map(defaultEmptyPriceBreakValues);
        entity.providers = activeSites;
        entity.provider = { name: entity.provider.name, siteId: copyFromPractice.id };
        return entity;
    }

    onSave = (
        sites: DatasourceFullSiteInfo[],
        copyFromPractice: SiteEntityInfo,
        runAt?: Date,
        name?: string,
    ): Promise<InvoiceItemWithVariantDetail> => {
        const item = this.unpackEntity(sites, copyFromPractice);
        const isScheduledSave = Boolean(runAt);
        const message = this.store.getAddItemToPracticesMessage({ practiceCount: item.variants.length, isScheduledUpdate: isScheduledSave });

        if (isScheduledSave) {
            return this.store.scheduleSave(item, runAt, message, name);
        } else {
            return this.store.save(item, message);
        }
    };

    render() {
        const { fetching, fullItem, showSelectPracticesModal } = this.state;
        const sitesWithoutItem = this.getSitesWithoutItem();

        return (
            <>
                {fetching && <SpotLoading />}
                {!fetching && fullItem && (
                    <Container className="item-card-details" fluid={true}>
                        <Row noGutters={true} className={'practice-pill-container'}>
                            <Col xs={2} className={'practice-pill-buttons'}>
                                <SpotPill className={'practice-pill-button-text'}>
                                    <Trans i18nKey="controlCenter:itemSearch.practices" count={fullItem.sites?.length}>
                                        {fullItem.sites?.length} hospital(s)
                                    </Trans>
                                </SpotPill>
                            </Col>
                            <Col xs={2} className={'practice-pill-buttons'}>
                                <SpotPill type="warning" className={'practice-pill-button-text'}>
                                    <Trans i18nKey="controlCenter:itemSearch.variants" count={Object.keys(fullItem.diff).length}>
                                        {Object.keys(fullItem.diff).length} variant(s)
                                    </Trans>
                                </SpotPill>
                            </Col>
                            {sitesWithoutItem.length > 0 && !this.props.matchSubLabel && (
                                <Col xs={3}>
                                    <div className={'add-additional-clinics'}>
                                        <a
                                            onClick={() => {
                                                this.setState({ showSelectPracticesModal: true });
                                            }}
                                            className={'spot-link'}
                                            href={'javascript:void(0)'}
                                            data-automation-id="add-additional-practices-button"
                                        >
                                            <SpotSvg icon="add" className={'clinic-list-add-icon'} />
                                            <span>{i18n.t('controlCenter:itemSelectPractices:addToPractices', 'Add to Additional Practice(s)')}</span>
                                        </a>
                                    </div>
                                </Col>
                            )}
                        </Row>
                        <Row noGutters={true}>
                            <Col xs={12}>
                                <CardDeck>
                                    {Array.from(fullItem.variants)
                                        .sort(
                                            (a, b) =>
                                                _value(a, 'prices.priceBreaks[0].invoicePrice', 0) -
                                                _value(b, 'prices.priceBreaks[0].invoicePrice', 0),
                                        )
                                        .sort((a) => (this.props.masterSite?.siteId === a.provider.siteId.toString() ? -1 : 1))
                                        .map((variant: InvoiceItem) => (
                                            <div key={`${variant.provider.siteId}-${variant.pimsGeneratedId}`} className="card-holder">
                                                <p>
                                                    <strong>
                                                        <Trans i18nKey="common:price"> Price($)</Trans>:{' '}
                                                        <Value value={variant} path="prices.priceBreaks[0].invoicePrice" defaultValue="0" />
                                                    </strong>
                                                </p>
                                                <Card className="app-info ">
                                                    <div className="site-name">
                                                        {
                                                            (
                                                                fullItem.sites?.find((site) => String(site.id) === variant.provider.siteId) || {
                                                                    name: i18n.t('common:unknown', 'Unknown'),
                                                                }
                                                            ).name
                                                        }
                                                    </div>
                                                    <div className="status">
                                                        <small>
                                                            {variant.isActive
                                                                ? i18n.t('common:active', 'Active')
                                                                : i18n.t('common:inactive', 'Inactive')}
                                                        </small>
                                                    </div>
                                                </Card>
                                            </div>
                                        ))}
                                </CardDeck>
                            </Col>
                        </Row>

                        {sitesWithoutItem.length > 0 && this.props.matchSubLabel && (
                            <div>
                                <Row noGutters={true} className={'subProductMessage'}>
                                    <span className={'text'}>
                                        <Trans i18nKey="common:item.addToRemainingSubProductText" />
                                    </span>
                                    <span className={'subtext'}>
                                        <Trans i18nKey="common:item.addToRemainingSubProductSubtext" />
                                    </span>
                                </Row>
                            </div>
                        )}
                    </Container>
                )}
                {showSelectPracticesModal && (
                    <ItemAddPractices
                        sitesWithoutItems={sitesWithoutItem}
                        title={i18n.t('controlCenter:itemSelectPractices:addToAdditionalPractices', 'Add this item to Additional Practices')}
                        secondaryTitle={this.props.item.hospitalDescription}
                        onSubmit={this.onSubmitSelectedPractices}
                        onClose={() => {
                            this.setState({ showSelectPracticesModal: false });
                        }}
                    />
                )}
            </>
        );
    }
}
