import { observer } from 'mobx-react';
import { Col, Container, Row } from 'react-bootstrap';
import { Cmp, UserRoles } from '@enterprise/common';
import { SpotLoading, SpotSearchbar } from '@enterprise/spot';
import { NavLink, Route } from 'react-router-dom';
import { BulkUpdateDetailsPage } from './BulkUpdateDetailsPage';
import { ScheduledItemDetailsPage } from './ScheduledItemDetailsPage';
import moment from 'moment';
import { environment } from '../../../environment/environment';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { ParentTask, TaskEntity } from '../../../core/entity/TaskEntity';
import i18n from '../../../i18n';
import classNames from 'classnames';
import styles from './ActivityPage.module.scss';
import { Trans } from 'react-i18next';
import { useStores } from '../../../hooks';
import React, { useEffect, useMemo, useState } from 'react';
import { RouterPaths } from '../../../router/RouterPaths';
import { CancelTaskModal, CancelTaskModalResponse } from './cancelTaskModal';
import { GridCellParams, GridColDef, GridRowData, GridSortModel, MaterialDataGrid, GridValueGetterParams } from '@enterprise/material-data-grid';
import { PermissionLevel } from '../../../core/enums/permissionLevel';
import { GridCallbackDetails } from '@mui/x-data-grid/models/api';
import { RootRoutes } from '../../../router';

const DEFAULT_PAGE_SIZE = 25;

enum GridColumns {
    Description = 'Description',
    Status = 'Status',
    Date = 'Date',
}

const headerCell = (params: GridColDef): React.ReactElement => {
    const header = params.headerName || params.field || '';

    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)} data-automation-id="activity-center">
                {header}
            </div>
        </div>
    );
};

export const ActivityPage = observer(function ActivityPageComponent() {
    const {
        domain: { activityPageStore, usersStore },
        ui: { app },
    } = useStores();

    const { token } = app;
    const { isLoaded, isTaskLoading, parentTasks, gridState = {} } = activityPageStore;
    const { pageSize = DEFAULT_PAGE_SIZE, page = 0 } = gridState;
    const { isLoadingUserAccess, userPracticesAccess = {} } = usersStore;
    const isLoading = !isLoaded || isLoadingUserAccess;
    const [searchTerm, setSearchTerm] = useState('');
    const [cancelItemId, setCancelItemId] = useState<number>();
    const hasAccess =
        token.hasRole(UserRoles.SystemOwner) ||
        token.hasRole(UserRoles.SuperAdmin) ||
        usersStore.userPermissionLevel === PermissionLevel.DecisionMaker;
    const hasAllPracticesAccess = hasAccess && !userPracticesAccess.practices?.length && !userPracticesAccess.regions?.length;
    const rowHeight = 90;
    const [sortModel, setSortModel] = React.useState<GridSortModel>([{ field: GridColumns.Date, sort: 'desc' }]);

    const handleSortModelChange = (newSortModel: GridSortModel) => {
        setSortModel(newSortModel);
    };

    useEffect(() => {
        void activityPageStore.load();
        void activityPageStore.fetchParentTasks({ page: 0, pageSize });
        void usersStore.fetchUserAccess(token.user.id);

        return () => {
            activityPageStore.clearTasks();
        };
    }, []);

    const onFilterChanged = (value: string) => {
        setSearchTerm(value);
    };

    const cancelTask = (action: CancelTaskModalResponse): void => {
        if (action === CancelTaskModalResponse.Yes && cancelItemId) {
            void activityPageStore.cancelTasks([cancelItemId]);
        }

        setCancelItemId(undefined);
    };

    const rows = useMemo(() => {
        if (!searchTerm) {
            return parentTasks?.tasks || [];
        }

        return (
            parentTasks?.tasks.filter(({ name }: TaskEntity) => {
                return name.toLowerCase().includes(searchTerm.toLowerCase());
            }) || []
        );
    }, [parentTasks, searchTerm]);

    const descriptionCell = (params: GridCellParams): React.ReactElement => {
        const task = params.row as ParentTask;

        return (
            <div className={classNames('spot-typography__text--body', styles.activityRow)} data-automation-id="activity-center">
                {createParentTaskDescription(task)}
            </div>
        );
    };

    const statusCell = (params: GridCellParams): React.ReactElement => {
        const task = params.row as ParentTask;

        return (
            <div className={classNames('spot-typography__text--body', styles.activityStatusRow)} data-automation-id="activity-center">
                {createStatus(task)}
            </div>
        );
    };

    const dateCell = (params: GridCellParams): React.ReactElement => {
        const task = params.row as ParentTask;

        return (
            <div className={classNames('spot-typography__text--body')} data-automation-id="activity-center">
                {createDate(task)}
            </div>
        );
    };

    const getParentDescription = (
        task: ParentTask,
    ): { description: string; date?: Date; className: string; count?: number; isError?: boolean; status: TaskStatus } | undefined => {
        const { pending = 0, running = 0, queued = 0, error = 0, success = 0, cancel = 0 } = task.childrenStats || {};
        switch (task.status) {
            case TaskStatus.PENDING:
                return {
                    description: i18n.t('activityPage:pending', 'Pending'),
                    count: pending,
                    date: task.runAt,
                    className: 'spot-pill--warning',
                    status: task.status,
                };
            case TaskStatus.RUNNING:
                return {
                    description: i18n.t('activityPage:running', 'Running'),
                    count: running + queued,
                    date: task.runAt,
                    className: 'spot-pill--warning',
                    status: task.status,
                };
            case TaskStatus.SUCCESS:
                return {
                    description: task.childrenStats?.total ? i18n.t('activityPage:success', 'Success') : i18n.t('activityPage:pending', 'Pending'),
                    count: success,
                    date: task.latestAttempt || task.runAt,
                    className: task.childrenStats?.total ? 'spot-pill--positive' : 'spot-pill--warning',
                    status: task.childrenStats?.total ? task.status : TaskStatus.PENDING,
                };
            case TaskStatus.ERROR:
                return {
                    description: i18n.t('activityPage:error', 'Error'),
                    count: error,
                    date: task.latestAttempt || task.runAt,
                    className: 'spot-pill--negative',
                    isError: true,
                    status: task.status,
                };
            case TaskStatus.CANCEL:
                return {
                    description: i18n.t('activityPage:canceled', 'Canceled'),
                    count: cancel,
                    date: task.runAt,
                    className: 'spot-pill--secondary',
                    status: task.status,
                };
        }

        return undefined;
    };

    const createParentTaskDescription = (parentTask: ParentTask) => {
        return (
            <>
                <div className={classNames('spot-typography__text--body', styles.rowItem)}>
                    <NavLink
                        className={classNames('spot-link', styles.name)}
                        to={`${RouterPaths.ControlCenterPages.Activity}/bulk/${parentTask.id}`}
                        data-automation-id={'activity-item-name'}
                    >
                        <span>{parentTask.name}</span>
                    </NavLink>
                    <p className={'spot-typography__text--tertiary'} data-automation-id={'activity-total-items'}>
                        {parentTask.childrenStats?.total} items
                    </p>
                </div>
            </>
        );
    };

    const createStatus = (parentTask: ParentTask) => {
        const { description, className, count, isError, status } = getParentDescription(parentTask) || {};
        const runAllFailed = () => {
            activityPageStore.runAllFailedChildTasks(parentTask);
        };

        return (
            <>
                <div className={classNames('spot-typography__text--body', styles.status)}>
                    <span className={classNames('spot-pill', className, styles.statusPill)} data-automation-id={'activity-item-status'}>
                        {description}
                    </span>
                    <p className={'spot-typography__text--tertiary'} data-automation-id={'activity-item-status-total-items'}>
                        {count} Items
                    </p>
                    {isError && hasAccess && hasAllPracticesAccess && (
                        <span className={classNames(styles.action)}>
                            <NavLink to={'#'} className={classNames('spot-link')} onClick={runAllFailed} data-automation-id="activity-run-all-failed">
                                <Trans i18nKey="activityPage:runAllFailed">Run All Failed</Trans>
                            </NavLink>
                        </span>
                    )}
                    {status === TaskStatus.PENDING && hasAllPracticesAccess && (
                        <span className={classNames(styles.action)}>
                            <NavLink
                                to={'#'}
                                className={'spot-link'}
                                onClick={() => setCancelItemId(parentTask.id)}
                                data-automation-id="cancel-bulk-update"
                            >
                                <Trans i18nKey="activityPage:cancel">Cancel</Trans>
                            </NavLink>
                        </span>
                    )}
                </div>
            </>
        );
    };

    const createDate = (parentTask: ParentTask) => {
        const { date } = getParentDescription(parentTask) || {};
        return (
            <>
                <div className="spot-typography__text--body">
                    <span data-automation-id={'activity-item-date'} className={classNames('spot-typography__font-weight--bold', styles.date)}>
                        {moment(date).format(environment.dateFormat)}
                    </span>
                </div>
            </>
        );
    };

    const getRowId = (row: GridRowData): string => {
        const { id, type } = row as TaskEntity;
        return `${id}-${type}`;
    };

    const columns: GridColDef[] = [
        {
            field: GridColumns.Description,
            flex: 2,
            width: 150,
            renderCell: descriptionCell,
            headerName: GridColumns.Description,
            renderHeader: headerCell,
            valueGetter: (parentTask: GridValueGetterParams) => {
                return parentTask.row.name;
            },
        },
        {
            field: GridColumns.Status,
            width: 110,
            renderCell: statusCell,
            headerName: GridColumns.Status,
            renderHeader: headerCell,
            valueGetter: (parentTask: GridValueGetterParams) => {
                const { description } = getParentDescription(parentTask.row as ParentTask) || {};
                return description;
            },
        },
        {
            field: GridColumns.Date,
            flex: 1,
            minWidth: 160,
            renderCell: dateCell,
            headerName: GridColumns.Date,
            renderHeader: headerCell,
            valueGetter: (parentTask: GridValueGetterParams) => {
                const { date } = getParentDescription(parentTask.row as ParentTask) || {};
                return date;
            },
        },
    ];

    const onPageChange = (newPage: number) => {
        activityPageStore.updateGridState({ page: newPage, pageSize });
        void activityPageStore.fetchParentTasks({ page: newPage, pageSize });
    };

    const onPageSizeChange = (newPageSize: number, details: GridCallbackDetails) => {
        activityPageStore.updateGridState({ page, pageSize: newPageSize });
        void activityPageStore.fetchParentTasks({ page, pageSize: newPageSize });
    };

    return (
        <>
            <Cmp.If cond={isLoading}>
                <SpotLoading text="Loading..." />
            </Cmp.If>
            <Cmp.If cond={!isLoading}>
                <RootRoutes>
                    <Route path={`${RouterPaths.ControlCenterPages.Activity}/bulk/:id`} element={<BulkUpdateDetailsPage />} />
                    <Route path={`${RouterPaths.ControlCenterPages.Activity}/scheduled/:id`} element={<ScheduledItemDetailsPage />} />
                </RootRoutes>
                <Container className={classNames('list', styles.activityPage)}>
                    <Row>
                        <Col xs={12} data-automation-id={'activity-search-bar'}>
                            <SpotSearchbar className={styles.searchBar} onSubmit={onFilterChanged} value={searchTerm} onChange={onFilterChanged} />
                        </Col>
                    </Row>

                    <MaterialDataGrid
                        className={styles.grid}
                        loading={isTaskLoading}
                        columns={columns}
                        rows={rows}
                        rowHeight={rowHeight}
                        getRowClassName={() => styles.row}
                        headerHeight={68}
                        getRowId={getRowId}
                        hideFooterPagination={false}
                        hideFooter={false}
                        pagination
                        rowCount={parentTasks?.totalTasks}
                        page={page}
                        pageSize={pageSize}
                        rowsPerPageOptions={[25, 50, 100, 500]}
                        paginationMode="server"
                        onPageSizeChange={onPageSizeChange}
                        onPageChange={onPageChange}
                        disableColumnFilter={false}
                        disableColumnResize={false}
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        classes={{
                            columnHeadersInner: styles.columnHeadersInner,
                            columnHeader: styles.columnHeader,
                            columnSeparator: styles.columnSeparator,
                            columnHeaders: styles.columnHeaders,
                        }}
                    />
                </Container>
            </Cmp.If>
            <CancelTaskModal opened={Boolean(cancelItemId)} onChange={cancelTask} />
        </>
    );
});
