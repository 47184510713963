import React from 'react';
import { SpotButton, SpotTextInput, SpotModal } from '@enterprise/spot';
import { Col, Container, Row } from 'react-bootstrap';
import validator from 'validator';
import * as lodash from 'lodash';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

interface PageProps {
    isVisible: boolean;
    onSubmit: (string) => void;
    onClose: () => void;
}

interface PageState {
    presetName: string;
    presetNameError: object | undefined;
}

class CreatePresetDialog extends React.Component<PageProps, PageState> {
    state = {
        presetName: '',
        presetNameError: undefined,
    };

    setPresetName = val => {
        this.setState({ presetName: val });
    };

    submit = () => {
        if (this.isUndefined(this.state.presetName) || this.state.presetName.trim().length === 0) {
            this.setState({ presetNameError: { error: i18n.t('createPresetDialog:presetNameRequired', 'Search name is required'), touched: true } });
            return;
        }

        this.props.onSubmit(this.state.presetName.trim());
    };

    render() {
        const setPresetName = e => this.setPresetName(e.target.value);
        return (
            <SpotModal title="Create a Search" isVisible={this.props.isVisible} onHide={this.props.onClose} footer={this.createFooter}>
                {() => (
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div>
                                    <label className="spot-form__label">
                                        <Trans i18nKey="createPresetDialog:provideSearchName">Provide a name for your search</Trans>
                                    </label>
                                    <SpotTextInput
                                        value={this.state.presetName}
                                        onChange={setPresetName}
                                        error={this.state.presetNameError}
                                        placeholder={i18n.t('createPresetDialog:placeholder', 'ex. Clinic B Inventory')}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )}
            </SpotModal>
        );
    }

    isUndefined = value => !value || lodash.isUndefined(value) || validator.isEmpty(`${value}`);

    private createFooter = () => {
        return (
            <div style={{ textAlign: 'right', width: '100%' }}>
                <SpotButton isPrimary={true} onClick={this.submit}>
                    <Trans i18nKey="createPresetDialog:save">Save</Trans>
                </SpotButton>
            </div>
        );
    };
}

export default CreatePresetDialog;
