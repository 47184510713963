import { Inject, Service } from 'typedi';
import { BaseHttp } from '@enterprise/common';
import { environment } from '../environment/environment';
import { EmbedLink, LookerStatus } from '../core/models';
import { sortBy } from 'lodash';

export class EmbedLinkParams {
    timeFrame?: Date;
    practiceName?: string;
}

@Service()
export class AnalyticsService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getEmbedLinks(params?: EmbedLinkParams): Promise<EmbedLink[]> {
        return this.http.get(`/looker/v1/embed-link`, { params });
    }

    getDeepLinkDashboard(dashboardId): Promise<EmbedLink> {
        return this.http.get(`/looker/v1/deep-link/dashboard/${dashboardId}`);
    }

    getDeepLinkLook(lookId): Promise<EmbedLink> {
        return this.http.get(`/looker/v1/deep-link/look/${lookId}`);
    }

    async getLookerStatus(): Promise<LookerStatus> {
        const entities: LookerStatus = await this.http.get('/looker/v1/status');
        entities.connectedPractices = sortBy(entities.connectedPractices, (practice) => practice.name);

        return entities;
    }

    async getEnrollments(): Promise<Record<string, unknown>[]> {
        try {
            return await this.http.get(`${environment.PRACTICE_CONNECT.BASE_API_URL}/v1/enrollment`);
        } catch {
            return [];
        }
    }
}
