import { Inject, Service } from 'typedi';
import { BaseHttp } from '@enterprise/common';
import AppStore from '../store/AppStore';
import { OrganizationSettings, UpsertOrganizationSettings } from '../core/models/organization/settings';

@Service()
export class OrganizationSettingsService {
    constructor(@Inject('http') private readonly http: BaseHttp, private readonly appStore: AppStore) {}

    get organizationId(): string | undefined {
        const { organization } = this.appStore.token.user;
        return organization?.id;
    }

    async getSettings(): Promise<OrganizationSettings | null> {
        const data = await this.http.get(`/organizations/:orgId/settings`, { pathParams: { orgId: this.organizationId } });
        return data || null;
    }

    updateSettings(settings: UpsertOrganizationSettings): Promise<OrganizationSettings> {
        return this.http.put(`/organizations/:orgId/settings`, settings, { pathParams: { orgId: this.organizationId } });
    }
}
