import React, { FC, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { SpotButton, SpotSelectBox, SpotSelectOption } from '@enterprise/spot';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import styles from './ImportSelectHeaders.module.scss';
import { ItemsGroupByType } from '../../../core/models/organization/settings/enum';
import { useStores } from '../../../hooks';
import { FEATURES } from '@enterprise/core';
import { head } from 'lodash';

export interface ItemSheetRequestIndexes {
    salesPriceIndex: string;
    costPriceIndex?: string;
    listPriceIndex?: string;
    idexxSapIdIndex: string;
    itemIdIndex?: string;
    itemDescriptionIndex?: string;
    itemSupplierCodeIndex?: string;
}

interface ImportSelectHeadersProps {
    columnHeaders: string[];
    itemsGroupBy: ItemsGroupByType;
    isAnimana?: boolean;
    submitHandler: (values: ItemSheetRequestIndexes) => void;
}

export const NOT_INCLUDED_INDEX = '99999';

const formDefaultField = [
    {
        name: 'idexxSapIdIndex',
        transKey: 'controlCenter:import.column.idexxSapId',
        transText: 'IDEXX SAP ID',
        automationId: 'import-select-idexx-sap-id',
    },
];

const formItemIdField = [
    {
        name: 'itemIdIndex',
        transKey: 'controlCenter:import.column.itemId',
        transText: 'Item ID',
        automationId: 'import-select-item-id',
    },
];

const formDescriptionField = [
    {
        name: 'itemDescriptionIndex',
        transKey: 'controlCenter:import.column.itemDescription',
        transText: 'Item Description',
        automationId: 'import-select-item-description',
    },
];

const formSupplierCodeField = [
    {
        name: 'itemSupplierCodeIndex',
        transKey: 'controlCenter:import.column.supplierCode',
        transText: 'Supplier Code',
        automationId: 'import-select-item-supplier',
    },
];

const basePriceField = [
    {
        name: 'salesPriceIndex',
        transKey: 'controlCenter:import.column.newBasePrice',
        transText: 'New Base Price',
        automationId: 'import-select-new-sales-price',
    },
];

const customPriceFields = [
    {
        name: 'listPriceIndex',
        transKey: 'controlCenter:import.column.newListPrice',
        transText: 'New List Price',
        automationId: 'import-select-new-list-price',
    },
    {
        name: 'costPriceIndex',
        transKey: 'controlCenter:import.column.newCostPrice',
        transText: 'New Cost Price',
        automationId: 'import-select-new-cost-price',
    },
    {
        name: 'salesPriceIndex',
        transKey: 'controlCenter:import.column.newSalesPrice',
        transText: 'New Sales Price',
        automationId: 'import-select-new-sales-price',
    },
];

export const ImportSelectHeaders: FC<ImportSelectHeadersProps> = function ({
    columnHeaders,
    submitHandler,
    itemsGroupBy,
    isAnimana,
}: ImportSelectHeadersProps) {
    /*  Our version of react-final-form cannot handle strict types in the submitHandler definition
     *  and upgrading breaks a ton of stuff. This is a hacky work around to get us by.
     *  https://github.com/final-form/react-final-form/releases/tag/v5.0.0
     *  https://github.com/final-form/react-final-form/releases/tag/v6.0.0
     */
    const submitTransformer = function (values: object): void {
        // TODO: Remove this chack when TD-2945 has been completed. (Backend work to accept supplier
        // code)
        // if (isDescriptionDisabled && !isSupplierCodeDisabled) {
        //     Notifications.error('Supplier Code not supported yet.');
        // } else {
        submitHandler(values as ItemSheetRequestIndexes);
        // }
    };

    const {
        ui: { app },
    } = useStores();

    const required = (value) => (value ? undefined : true);
    const { t } = useTranslation('controlCenter', { i18n });
    const enhancedSearchWithImportEnabled = app.featureManager.isAvailable(FEATURES.ENHANCED_PRODUCT_SEARCH, true);
    const formPriceFields = isAnimana ? customPriceFields : basePriceField;
    const [isDescriptionDisabled, setIsDescriptionDisabled] = useState(false);
    const [isSupplierCodeDisabled, setIsSupplierCodeDisabled] = useState(false);

    const formFields = useMemo(() => {
        const formItemField = itemsGroupBy === ItemsGroupByType.Id ? formItemIdField : formDescriptionField;
        const formFields = formDefaultField.concat(formItemField);
        return isAnimana && enhancedSearchWithImportEnabled ? formFields.concat(formSupplierCodeField) : formFields;
    }, [itemsGroupBy, isAnimana, enhancedSearchWithImportEnabled]);

    const isFieldDisabled = (name) => {
        if (name === head(formDescriptionField)?.name) {
            return isDescriptionDisabled;
        } else if (name === head(formSupplierCodeField)?.name) {
            return isSupplierCodeDisabled;
        }

        return false;
    };

    const initialValues: Partial<ItemSheetRequestIndexes> = {
        salesPriceIndex: isAnimana ? NOT_INCLUDED_INDEX : undefined,
        costPriceIndex: isAnimana ? NOT_INCLUDED_INDEX : undefined,
        listPriceIndex: isAnimana ? NOT_INCLUDED_INDEX : undefined,
    };

    return (
        <>
            <Row className={'margin-top-4'}>
                <Col xs={1} />
                <Col xs={11}>
                    <h1 className="classifications-header">{t('controlCenter:import.selectCSVHeaders', 'Select relevant column headers')}</h1>
                </Col>
            </Row>
            <Row className={'margin-top-2'}>
                <Col xs={1} />
                <Col xs={5}>
                    <Form
                        initialValues={initialValues}
                        onSubmit={submitTransformer}
                        validate={(values: object) => {
                            if (values[head(formSupplierCodeField)?.name || '']) {
                                setIsDescriptionDisabled(true);
                                setIsSupplierCodeDisabled(false);
                            } else if (values[head(formDescriptionField)?.name || '']) {
                                setIsDescriptionDisabled(false);
                                setIsSupplierCodeDisabled(true);
                            } else {
                                setIsDescriptionDisabled(false);
                                setIsSupplierCodeDisabled(false);
                            }
                            const { salesPriceIndex, listPriceIndex, costPriceIndex } = values as ItemSheetRequestIndexes;
                            const requiredMessage = 'Required';

                            const errors: Record<string, string> = {};
                            if (
                                (isAnimana && (!salesPriceIndex || !listPriceIndex || !costPriceIndex)) ||
                                (salesPriceIndex === NOT_INCLUDED_INDEX &&
                                    costPriceIndex === NOT_INCLUDED_INDEX &&
                                    listPriceIndex === NOT_INCLUDED_INDEX)
                            ) {
                                errors.salesPriceIndex = requiredMessage;
                                errors.listPriceIndex = requiredMessage;
                                errors.costPriceIndex = requiredMessage;
                            } else if (!salesPriceIndex) {
                                errors.salesPriceIndex = requiredMessage;
                            }
                            return errors;
                        }}
                        render={({ handleSubmit, invalid, submitting }) => (
                            <form onSubmit={handleSubmit}>
                                {formFields.map((field) => (
                                    <Field key={field.name} name={field.name} validate={!isFieldDisabled(field.name) ? required : undefined}>
                                        {({ input }) => {
                                            return (
                                                <div className={'spot-form__field-group'} data-automation-id={field.automationId}>
                                                    <label className="spot-form__label">{field.transText}</label>
                                                    <SpotSelectBox
                                                        {...input}
                                                        disabled={isFieldDisabled(field.name)}
                                                        placeholder={isFieldDisabled(field.name) ? t('common:notIncluded', 'Not included') : ''}
                                                        value={isFieldDisabled(field.name) ? '' : input.value}
                                                    >
                                                        {columnHeaders.map((header, index) => {
                                                            return (
                                                                <SpotSelectOption key={header} value={index}>
                                                                    {header}
                                                                </SpotSelectOption>
                                                            );
                                                        })}
                                                    </SpotSelectBox>
                                                </div>
                                            );
                                        }}
                                    </Field>
                                ))}

                                <div className={styles.formDivider}>
                                    <hr />
                                </div>

                                {formPriceFields.map((field) => (
                                    <Field key={field.name} name={field.name}>
                                        {({ input }) => {
                                            return (
                                                <div className={'spot-form__field-group'} data-automation-id={field.automationId}>
                                                    <label className="spot-form__label">{field.transText}</label>
                                                    <SpotSelectBox {...input}>
                                                        {isAnimana && (
                                                            <SpotSelectOption key={'Not included'} value={NOT_INCLUDED_INDEX}>
                                                                {t('common:notIncluded', 'Not included')}
                                                            </SpotSelectOption>
                                                        )}
                                                        {columnHeaders.map((header, index) => {
                                                            return (
                                                                <SpotSelectOption key={header} value={index}>
                                                                    {header}
                                                                </SpotSelectOption>
                                                            );
                                                        })}
                                                    </SpotSelectBox>
                                                </div>
                                            );
                                        }}
                                    </Field>
                                ))}
                                <SpotButton
                                    type={'submit'}
                                    isPrimary={true}
                                    disabled={submitting || invalid}
                                    data-automation-id="submit-csv-select-headers"
                                >
                                    {t('controlCenter:import.selectCSVHeadersSubmit', 'Submit')}
                                </SpotButton>
                            </form>
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};
