import '../styles/board.scss';
import React, { Context } from 'react';
import { DragDropContext, Droppable, DroppableProvided, DroppableStateSnapshot, DropResult } from 'react-beautiful-dnd';
import { Column } from './Column';
import { INTENT, MAPPING_UI } from '../styles';
import { deepCopy, deserializeDraggableId, getDroppableTargetParent, sortColumnData } from '../utility';
import { DEFAULT_NEW_PRIMARY_CARD_INTENT, NEW_CHILD_CARD_ID, PLACE_HOLDER } from '../constants';
import { BoardEvent, ColumnActions, CardEvent, CardModel, ColumnModel } from '../models';
import classNames from 'classnames';
import { BoardOptions } from '../interfaces';

type EventHandler = (event: BoardEvent) => void | boolean | Promise<any>;

interface BoardProps {
    initial: ColumnModel[];
    eventHandler: EventHandler;
    options?: BoardOptions;
}

interface BoardState {
    activeColumns: ColumnModel[];
    disabled: boolean;
    primary: ColumnModel[];
    stacks: ColumnModel[];
}

export const EventHandlerContext: Context<EventHandler> = React.createContext((event: BoardEvent) => {});

export class Board extends React.Component<BoardProps, BoardState> {
    constructor(props) {
        super(props);
        this.setActiveColumns();
    }

    render() {
        const { activeColumns, disabled, stacks } = this.state;
        const {
            allowChildCardCreation = false,
            allowPrimaryCardTitleClick = false,
            columnOptions = {},
            createChildAction = undefined,
            hideDuplicateCardDescriptions = false,
        } = this.props.options || {};

        const { eventHandler } = this.props;
        const stackSets = stacks.map((stack, index) => ({
            index,
            selected: stack.title === activeColumns[1].title,
            title: stack.title,
        }));

        const board = (
            <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                    <div className={MAPPING_UI.FULL_WIDTH_DROPABLE} ref={provided.innerRef} {...provided.droppableProps}>
                        {activeColumns.map((column: any, index: number) => (
                            <Column
                                allowChildCardCreation={allowChildCardCreation}
                                allowPrimaryCardTitleClick={allowPrimaryCardTitleClick}
                                columnOptions={columnOptions}
                                createChildAction={createChildAction}
                                hideDuplicateCardDescriptions={hideDuplicateCardDescriptions}
                                key={index}
                                onCardTitleClick={this.onCardTitleClick}
                                stackHandler={this.stackHandler}
                                stackSets={!column.primary && column.stackable && stackSets}
                                {...column}
                            />
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        );

        return (
            <EventHandlerContext.Provider value={eventHandler}>
                <div className={classNames(MAPPING_UI.BOARD, disabled && MAPPING_UI.BOARD_DISABLED)}>
                    <DragDropContext onDragEnd={this.onDragEnd}>{board}</DragDropContext>
                </div>
            </EventHandlerContext.Provider>
        );
    }

    private onDragEnd = async ({ combine, destination, source, draggableId }: DropResult): Promise<void> => {
        const { primary, stacks } = this.state;
        const { disableWhileProcessing = false } = this.props.options || {};
        const primaryColumnId = primary[0].id;

        const validCombineAction = combine && !combine.draggableId.includes(PLACE_HOLDER) && primaryColumnId === combine.droppableId;
        const targetId = combine && validCombineAction ? deserializeDraggableId(combine.draggableId).cardId : null;

        const validDropDestination = destination && destination.droppableId === primaryColumnId;

        let creatingNewChild = false;
        const { allowChildCardCreation = false } = this.props.options || {};
        let droppableParentIndex: null | number = null;
        let droppableParentId: null | string = null;

        // if allowed to create children, determine if the source was dropped between existing children
        if (allowChildCardCreation && (validDropDestination || validCombineAction)) {
            if (targetId === NEW_CHILD_CARD_ID) {
                creatingNewChild = true;
            } else {
                const droppableIndex = destination ? destination.index : null;
                const droppableParent = getDroppableTargetParent(droppableIndex, primary[0].data, true);
                droppableParentIndex = droppableParent.parentIndex;
                droppableParentId = droppableParent.parentId;
                if (droppableParentId) {
                    creatingNewChild = true;
                }
            }
        }

        const stackIndex = stacks.findIndex(stack => stack.id === source.droppableId);

        const validCreateAction = validDropDestination || creatingNewChild;

        const { cardId, parentId } = deserializeDraggableId(draggableId);
        const targetParentId = droppableParentId || (combine && deserializeDraggableId(combine.draggableId).parentId) || null;

        let card: CardModel;
        let dataIndex: number;
        let childIndex: number = 0;

        if (parentId) {
            dataIndex = stacks[stackIndex].data.findIndex((data: CardModel) => data.id === parentId);
            childIndex = stacks[stackIndex].data[dataIndex].children.findIndex((child: CardModel) => child.id === cardId);
            card = stacks[stackIndex].data[dataIndex].children[childIndex];
        } else {
            dataIndex = stacks[stackIndex].data.findIndex((data: CardModel) => data.id === cardId);
            card = stacks[stackIndex].data[dataIndex];
        }

        if (!validCreateAction && !validCombineAction) {
            return;
        }

        const primaryDataCopy = deepCopy(primary[0].data);
        const secondaryDataCopy = deepCopy(stacks[stackIndex].data);

        if (validCreateAction) {
            card.intent = DEFAULT_NEW_PRIMARY_CARD_INTENT;
            let copiedCard;
            if (parentId) {
                copiedCard = deepCopy(stacks[stackIndex].data[dataIndex].children[childIndex]);
            } else {
                copiedCard = deepCopy(stacks[stackIndex].data[dataIndex]);
            }
            // The new card should not bring any children or disabled state with it
            copiedCard.isDisabled = false;
            copiedCard.children = [];
            // Old ParentId should be changed to droppable parentId to show proper mapping details on flyover
            copiedCard.parentId = targetParentId;
            if (creatingNewChild) {
                // push to the children array of the correct parent
                const targetIndex = droppableParentIndex || primary[0].data.findIndex((data: CardModel) => data.id === targetParentId);
                primary[0].data[targetIndex].children.push(copiedCard);
            } else {
                primary[0].data.push(copiedCard);
            }
            sortColumnData(primary[0]);
        }

        if (parentId) {
            // remove child from the board
            card.parentId = parentId;
            stacks[stackIndex].data[dataIndex].children.splice(childIndex, 1);

            // check if the child had a parent that was previously mapped.
            // if so, remove the parent from the board too
            const parentCard: CardModel = stacks[stackIndex].data[dataIndex];
            if (parentCard.isDisabled && parentCard.children.length === 0) {
                stacks[stackIndex].data.splice(dataIndex, 1);
            }
        } else {
            // If a card still has unmapped children, it should become disabled instead of removed from the board
            if (card.children && card.children.length > 0) {
                card.isDisabled = true;
                card.intent = INTENT.DISABLED;
            } else {
                stacks[stackIndex].data.splice(dataIndex, 1);
            }
        }
        stacks[stackIndex] = sortColumnData(stacks[stackIndex]);
        this.setActiveColumns(stackIndex);

        const action = validCreateAction ? ColumnActions.CARD_DROP_CREATE : ColumnActions.CARD_DROP_LINK;

        const cardEvent = new CardEvent({
            item: card,
            sourceColumn: source.droppableId,
            targetId,
            parentId,
            targetParentId,
        });

        const event = new BoardEvent({
            action,
            data: cardEvent,
        });

        const successfulUpdate = await this.eventHandler(event);

        if (!successfulUpdate) {
            primary[0].data = primaryDataCopy;
            stacks[stackIndex].data = secondaryDataCopy;
            this.setActiveColumns(stackIndex);
        }

        if (disableWhileProcessing) {
            this.setState({ disabled: false });
        }
    };

    private setActiveColumns = (selectedStack: number = 0): void => {
        if (!this.state) {
            const { initial } = this.props;

            initial.forEach(column => sortColumnData(column));

            const primaryColumn = initial.filter(col => col.primary);
            const stackableColumns = initial.filter(col => col.stackable);
            const orderedColumns = [...primaryColumn, stackableColumns[selectedStack]];

            /*
             * The Mapping UI was designed to avoid having to deal with translations with the exception of
             * the errors thrown below. Since we throw these errors almost immediately it should fall on the
             * application embedding the UI to handle any additional application logic / verbiage needed.
             */
            if (primaryColumn.length === 0) {
                throw new Error('Mapping UI board should contain a primary column');
            }

            if (primaryColumn.length > 1) {
                throw new Error('Mapping UI board should only contain a single primary column');
            }

            if (stackableColumns.length === 0) {
                throw new Error('Mapping UI board should contain a stackable column');
            }

            this.state = {
                activeColumns: orderedColumns,
                disabled: false,
                primary: primaryColumn,
                stacks: stackableColumns,
            };
        } else {
            const { primary, stacks } = this.state;
            stacks[selectedStack] = sortColumnData(stacks[selectedStack]);

            this.setState({
                activeColumns: [...primary, stacks[selectedStack]],
            });
        }
    };

    private stackHandler = (selectedStack: number): void => {
        this.setActiveColumns(selectedStack);
    };

    private async eventHandler(event: BoardEvent): Promise<boolean> {
        const { disableWhileProcessing = false } = this.props.options || {};

        if (disableWhileProcessing) {
            this.setState({ disabled: true });
            return this.props.eventHandler(event);
        } else {
            this.props.eventHandler(event);
            return true;
        }
    }

    private onCardTitleClick = async (isPrimary: boolean, id: string, parentId?: string) => {
        const { disableWhileProcessing = false } = this.props.options || {};

        const card = { id, parentId, isPrimary, title: '', children: [] };

        const cardEvent = new CardEvent({
            item: card,
            sourceColumn: null,
            parentId: null,
            targetId: null,
            targetParentId: null,
        });

        const event = new BoardEvent({
            action: ColumnActions.CARD_CLICK_TITLE,
            data: cardEvent,
        });

        await this.eventHandler(event);

        if (disableWhileProcessing) {
            this.setState({ disabled: false });
        }
    };
}
