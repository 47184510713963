import { Length } from 'class-validator';
import i18n from 'i18next';
import { STARTS_WITH_WHITESPACE, NotMatches } from '@enterprise/common';

export class EditableClassificationFields {
    @Length(3, 7, {
        message: ({ constraints }) =>
            i18n.t('validation:length2', {
                field: i18n.t('controlCenter:classificationsPage.classificationId', 'Classification ID'),
                min: constraints[0],
                max: constraints[1],
            }),
    })
    @NotMatches(STARTS_WITH_WHITESPACE, {
        message: () =>
            i18n.t('validation:doesNotStartWith', {
                field: i18n.t('masterData:classificationsPage.classificationId', 'Classification ID'),
                value: i18n.t('validation:chars.space', 'space'),
            }),
    })
    classId: string;

    @Length(1, 100, {
        message: ({ constraints }) =>
            i18n.t('validation:length2', {
                field: i18n.t('masterData:classificationsPage.classificationDescription', 'Description'),
                min: constraints[0],
                max: constraints[1],
            }),
    })
    @NotMatches(STARTS_WITH_WHITESPACE, {
        message: () =>
            i18n.t('validation:doesNotStartWith', {
                field: i18n.t('masterData:classificationsPage.classificationDescription', 'Description'),
                value: i18n.t('validation:chars.space', 'space'),
            }),
    })
    classDescription: string;
}
