import * as React from 'react';
import classNames from 'classnames';
import { Image } from '../helpers/Image';

interface CarouselProps {
    hideArrows?: boolean;
    hideSlidersIndicator?: boolean;
    children?: React.ReactChild[];
    content?: {
        img?: string | any;
        title?: string;
        text?: string;
        subText?: string;
    }[];
}

interface CarouselState {
    activeIndex: number;
}

export class Carousel extends React.Component<CarouselProps, CarouselState> {
    static defaultProps: CarouselProps = {};

    state: CarouselState = {
        activeIndex: 0,
    };

    get slides(): any[] {
        const { children, content } = this.props;
        const { activeIndex } = this.state;
        if (!!content && Array.isArray(content)) {
            return content.map((c, index) => <CarouselSlide key={index} index={index} activeIndex={activeIndex} content={c} />);
        }
        if (!!children) {
            return children;
        }
        return [];
    }

    goToSlide = index => {
        const { slides } = this;
        const slidesLength = slides.length;
        this.setState({
            activeIndex: index >= slidesLength ? slidesLength - 1 : index < 0 ? 0 : index,
        });
    };

    goToPrevSlide = () => {
        let index = this.state.activeIndex;
        const { slides } = this;
        const slidesLength = slides.length;

        if (index < 1) {
            index = slidesLength;
        }

        --index;

        this.setState({
            activeIndex: index,
        });
    };

    goToNextSlide = () => {
        let index = this.state.activeIndex;
        const { slides } = this;
        const slidesLength = slides.length - 1;

        if (index === slidesLength) {
            index = -1;
        }

        ++index;

        this.setState({
            activeIndex: index,
        });
    };

    render() {
        const { hideArrows, hideSlidersIndicator } = this.props;
        const { activeIndex } = this.state;
        return (
            <div className="carousel">
                {!hideArrows && <CarouselLeftArrow onClick={this.goToPrevSlide} />}

                <ul className="carousel__slides">
                    {this.slides.map((slide, index) => (
                        <div key={index} className={classNames('carousel__slide', { 'carousel__slide--active': activeIndex === index })}>
                            {React.Children.toArray(slide).map(cmp =>
                                React.isValidElement(cmp) ? React.cloneElement(cmp) : React.createElement(cmp as any),
                            )}
                        </div>
                    ))}
                </ul>

                {!hideArrows && <CarouselRightArrow onClick={this.goToNextSlide} />}

                {!hideSlidersIndicator && (
                    <ul className="carousel__indicators">
                        {this.slides.map((slide, index) => {
                            const goTo = () => {
                                this.goToSlide(index);
                            };
                            return <CarouselIndicator key={index} index={index} activeIndex={activeIndex} onClick={goTo} />;
                        })}
                    </ul>
                )}
            </div>
        );
    }
}

class CarouselLeftArrow extends React.PureComponent<{ onClick?: (e: any) => void }> {
    render() {
        return (
            <a href="#" className="carousel__arrow carousel__arrow--left" onClick={this.props.onClick}>
                <span> left </span>
            </a>
        );
    }
}

class CarouselRightArrow extends React.PureComponent<{ onClick?: (e: any) => void }> {
    render() {
        return (
            <a href="#" className="carousel__arrow carousel__arrow--right" onClick={this.props.onClick}>
                <span> right </span>
            </a>
        );
    }
}

class CarouselIndicator extends React.PureComponent<{ index: number; activeIndex: number; onClick?: (e: any) => void }> {
    render() {
        return (
            <li>
                <a
                    className={
                        this.props.index === this.props.activeIndex ? 'carousel__indicator carousel__indicator--active' : 'carousel__indicator'
                    }
                    onClick={this.props.onClick}
                />
            </li>
        );
    }
}

class CarouselSlide extends React.PureComponent<{ index: number; activeIndex: number; content?: any }> {
    render() {
        const { content } = this.props;
        return (
            <>
                {content.img && <div>{typeof content.img === 'string' ? <Image url={content.img} /> : content.img}</div>}
                {content.title && <h1>{content.title}</h1>}
                {content.text && <p className="carousel-slide__content">{content.text}</p>}
                {content.subText && (
                    <p>
                        <small className="carousel-slide__source">{content.subText}</small>
                    </p>
                )}
            </>
        );
    }
}
