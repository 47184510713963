import { ScreenSizeType, useScreenSize } from '../../../hooks';
import styles from './practiceGrid.module.scss';
import { useMemo } from 'react';

const headerSize = new Map<ScreenSizeType, string>([
    [ScreenSizeType.xs, styles.headerSm],
    [ScreenSizeType.sm, styles.headerSm],
    [ScreenSizeType.md, styles.headerLg],
    [ScreenSizeType.lg, styles.headerLg],
    [ScreenSizeType.xl, styles.headerXl],
]);

const rowSize = new Map<ScreenSizeType, string>([
    [ScreenSizeType.xs, styles.rowSm],
    [ScreenSizeType.sm, styles.rowSm],
    [ScreenSizeType.md, styles.rowLg],
    [ScreenSizeType.lg, styles.rowLg],
    [ScreenSizeType.xl, styles.rowXl],
]);

export interface GridSize {
    sizeType: ScreenSizeType;
    headerHeight: number;
    rowHeight: number;
}

export function usePracticesGridSize(): GridSize {
    const { screenSize } = useScreenSize();
    const info = useMemo<GridSize>(() => {
        return {
            sizeType: screenSize,
            headerHeight: Number(headerSize.get(screenSize)),
            rowHeight: Number(rowSize.get(screenSize)),
        };
    }, [screenSize]);

    return info;
}
