import * as React from 'react';

interface TabItemProps {
    label?: string | any;
    children: JSX.Element;
}

export class TabItem extends React.PureComponent<TabItemProps> {
    render() {
        const { children, ...rest } = this.props;
        return <div>{React.isValidElement(children) ? React.cloneElement(children, rest) : React.createElement(children as any, rest)}</div>;
    }
}
