import * as React from 'react';

interface ImageProps {
    url: string;
    width?: string;
    height?: string;
    [key: string]: any;
}

export class Image extends React.PureComponent<ImageProps> {
    render() {
        const { url, ...rest } = this.props;
        return <img src={`/assets/${url}`} {...rest} />;
    }
}
