import { Type } from 'class-transformer';
import { LinkedItem } from './LinkedItem';
import { ItemData } from './ItemData';

export class MappedItem {
    data: ItemData;
    id: string;
    parentId: string | null;
    type: string;

    @Type(() => LinkedItem)
    mapped: LinkedItem[];
    [propName: string]: any;

    constructor({ data, id, parentId, type, mapped }: Required<MappedItem>) {
        this.data = data;
        this.id = id;
        this.parentId = parentId;
        this.type = type;
        this.mapped = mapped;
    }
}
