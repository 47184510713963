import { UnitOfMeasure } from '../..';

export class InvoiceItemService {
    static typeIdentifier = 'invoice-item-service';

    displayAsVaccine?: boolean;

    isMiscellaneous?: boolean;

    unitOfMeasure: UnitOfMeasure;

    isControlledSubstance?: boolean;
}
