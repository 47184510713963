import * as React from 'react';
import { Trans } from 'react-i18next';
import { RightSidebar } from '../../../../layout/main/rightSidebar';
import ConstantsStore from '../../../../store/ConstantsStore';
import i18n from 'i18next';
import { Errors } from '../../../../core/enums/Errors';
import { Form, FormRenderProps, FormType } from '@enterprise/common';
import { Field } from 'react-final-form';
import { SpotButton, SpotSelectBox, SpotSelectOption } from '@enterprise/spot';
import { Col, Container, Row } from 'react-bootstrap';
import validator from 'validator';
import { observer } from 'mobx-react';
import { ItemsPageStore } from '../ItemsPageStore';
import MappingUpdate from './MappingUpdate';
import MappingError from './MappingError';
import { MappingTypes } from '../../../../core/enums/Mapping';
import { InvoiceItem, InvoiceItemWithVariantDetail } from '../../../../core/models/datasource/invoiceItems';
import { LookupClassification, LookupSubClassification } from '../../../../core/models/datasource/lookups/parts';
import { StoresContext } from '../../../../contexts';

interface Props {
    onClose: (areMappingProblemsResolved: boolean) => void;
    unmappedProperties: Map<string, MappingError>;
    unmappedItem?: InvoiceItemWithVariantDetail;
}

interface State {
    loadingError?: string;
    isSavingMappingUpdates: boolean;
}

@observer
class MissingMappingFlyover extends React.Component<Props, State> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    static defaultProps = {
        page: {},
        constantsStore: {},
    };

    state = {
        loadingError: undefined,
        isSavingMappingUpdates: false,
    };

    get store(): ItemsPageStore {
        return this.context.domain.itemsPageStore;
    }

    get constantsStore(): ConstantsStore {
        return this.context.domain.constantsStore;
    }

    formType = new FormType<Record<string, string>>();

    componentDidMount() {
        const siteIdsWithMappingProblems = Array.from(this.props.unmappedProperties.keys());
        this.constantsStore
            .loadClassesAndSubclassesBySite(siteIdsWithMappingProblems)
            .then((results: any) => {
                for (const site of siteIdsWithMappingProblems) {
                    if (!this.constantsStore.classesBySite.get(site)) {
                        this.props.unmappedProperties.delete(site);
                    }
                }
            })
            .catch((e) => {
                this.setState({ loadingError: i18n.t('missingMappingFlyover:loadingError', Errors.DP_REST) });
            });
    }

    updateMappings = (formValues) => {
        const mappingUpdates = new Map();

        for (const [siteId, siteErrors] of this.props.unmappedProperties.entries()) {
            const siteMappingUpdates = this.buildSiteMappingUpdates(formValues, siteId, siteErrors);
            mappingUpdates.set(siteId, siteMappingUpdates);
        }

        this.setState({ isSavingMappingUpdates: true }, () => {
            this.store
                .updateMappings(mappingUpdates)
                .then((r) => {
                    this.props.onClose(true);
                    this.setState({ isSavingMappingUpdates: false });
                })
                .catch((e) => {
                    this.setState({ isSavingMappingUpdates: false });
                    throw e;
                });
        });
    };

    buildSiteMappingUpdates = (formValues, siteId, siteErrors: MappingError) => {
        const siteMappingUpdates: MappingUpdate[] = [];

        if (siteErrors.classId) {
            const linkedClassId = formValues[`classId_${siteId}`];
            const linkedClass = this.getSiteClass(siteId, linkedClassId);
            const linkedClassDescription = linkedClass?.description || '';
            const classMappingUpdate = {
                type: MappingTypes.CLASSIFICATION,
                masterId: siteErrors.classId,
                linkedId: linkedClassId,
                description: linkedClassDescription,
            };
            siteMappingUpdates.push(classMappingUpdate);
        }

        if (siteErrors.subclassId) {
            const linkedClassId = siteErrors.mappedClassId ? siteErrors.mappedClassId : formValues[`classId_${siteId}`];
            const linkedClass = this.getSiteClass(siteId, linkedClassId);
            const linkedSubclassId = formValues[`subclassId_${siteId}`];
            const linkedSubclass = linkedClass && this.getSubclass(linkedClass, linkedSubclassId);
            const linkedSubclassDescription = linkedSubclass?.description || '';
            const subclassMappingUpdate = {
                type: MappingTypes.CLASSIFICATION,
                masterId: siteErrors.subclassId,
                masterParentId: this.getSiteItemVariant(siteId)?.classification.pimsGeneratedId,
                linkedId: linkedSubclassId,
                description: linkedSubclassDescription,
                linkedParentId: linkedClassId,
            };
            siteMappingUpdates.push(subclassMappingUpdate);
        }

        return siteMappingUpdates;
    };

    renderTitle = () => {
        return (
            <h5>
                <Trans key="missingMappingFlyover:masterData">Master Data</Trans>
            </h5>
        );
    };

    closeWithoutResolvingProblems = () => {
        this.props.onClose(false);
    };

    render() {
        const isLoading = !this.constantsStore.areClassesBySiteLoaded || this.state.isSavingMappingUpdates;
        let loadingMessageKey = 'missingMappingFlyover:loadingClinicsMappingInformation';
        let loadingMessageDefaultValue = 'Loading clinics mapping information...';
        if (isLoading && this.constantsStore.areClassesBySiteLoaded) {
            loadingMessageKey = 'missingMappingFlyover:savingMappings';
            loadingMessageDefaultValue = 'Saving mappings...';
        }
        return (
            <RightSidebar
                isLoading={isLoading}
                loadingText={i18n.t(loadingMessageKey, loadingMessageDefaultValue)}
                title={this.renderTitle()}
                close={this.closeWithoutResolvingProblems}
                modalBodyStyle={{ backgroundColor: '#fff' }}
                hideBackdrop={true}
                automationId="missing-mapping-flyover"
            >
                <>
                    {this.state.loadingError && <span>{this.state.loadingError}</span>}

                    {!this.state.loadingError && (
                        <Form data={{}} formType={this.formType} onSubmit={this.updateMappings}>
                            {({ handleSubmit, invalid, values }: FormRenderProps) => (
                                <div className="missing-mapping-page">
                                    <div className="content">
                                        <Container>
                                            <Row>
                                                <Col xs={12}>
                                                    <h1 className="header">
                                                        <Trans i18nKey="missingMappingFlyover:weAreMissingData">
                                                            We are missing master data mappings for the clinics below
                                                        </Trans>
                                                    </h1>
                                                    <div className="details">
                                                        <Trans i18nKey="missingMappingFlyover:chooseMappingsForEachClinic">
                                                            Choose mappings to apply to each clinic
                                                        </Trans>
                                                    </div>
                                                </Col>
                                            </Row>

                                            {this.renderMissingClinicsMappings(values)}

                                            <Row className="margin-top-1">
                                                <Col xs={12} className="align-right">
                                                    <SpotButton isPrimary={true} onClick={handleSubmit} disabled={invalid}>
                                                        <Trans key="common:saveChangesButton">Save Changes</Trans>
                                                    </SpotButton>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            )}
                        </Form>
                    )}
                </>
            </RightSidebar>
        );
    }

    renderMissingClinicsMappings = (formValues) => {
        const constants = this.constantsStore;
        const result: any = [];
        for (const [siteId, siteErrors] of this.props.unmappedProperties.entries()) {
            const siteName = constants.applications.find((app) => `${app.id}` === `${siteId}`)!.name;

            const component = (
                <React.Fragment key={`${siteId}-classification`}>
                    <Row className="margin-top-3">
                        <Col xs={12}>
                            <span className="siteName">{siteName}</span>
                        </Col>
                    </Row>

                    {this.renderMissingClassMappings(formValues, siteId, siteName, siteErrors)}
                </React.Fragment>
            );

            result.push(component);
        }
        return result;
    };

    renderMissingClassMappings = (formValues, siteId, siteName, siteErrors: MappingError) => {
        const constants = this.constantsStore;

        let masterClassId = siteErrors.classId;
        if (!masterClassId) {
            masterClassId = this.getSiteItemVariant(siteId)?.classification.pimsGeneratedId || masterClassId;
        }
        const masterSubclassId = siteErrors.subclassId;
        const classifications = this.store.getLookupResults(this.store.classifications);

        const masterClass = classifications.find(({ pimsGeneratedId }) => pimsGeneratedId === `${masterClassId}`);
        const masterClassDescription = masterClass?.description;
        let masterSubclassDescription;
        if (masterClass && masterSubclassId) {
            const masterSubclass = this.getSubclass(masterClass, masterSubclassId);
            masterSubclassDescription = masterSubclass?.description;
        }

        const siteClasses = constants.classesBySite.get(siteId)?.filter((clazz) => !clazz.isMapped);
        const selectedSiteClassId = siteErrors.mappedClassId ? siteErrors.mappedClassId : formValues[`classId_${siteId}`];
        let siteSubclasses: LookupSubClassification[] = [];
        if (selectedSiteClassId) {
            const siteClass = this.getSiteClass(siteId, selectedSiteClassId);
            siteSubclasses = siteClass?.subClassifications || [];
            siteSubclasses = siteSubclasses.filter((subclass) => !subclass.isMapped);
        }

        return (
            <>
                {siteErrors.classId && (
                    <>
                        <Row className="margin-top-1">
                            <Col xs={12}>
                                <span className="mappingType">
                                    <Trans i18nKey="missingMappingFlyover:classification">CLASSIFICATION</Trans>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <span className="mappingDescription">
                                    {i18n.t('missingMappingFlyover:youChoseClassication', {
                                        defaultValue: `You chose {{value}}. Select a classification at {{siteName}} clinic to map`,
                                        replace: { value: masterClassDescription, siteName },
                                    })}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <Field name={`classId_${siteId}`} validate={this.isDefined}>
                                    {({ input, meta }) => (
                                        <div>
                                            <SpotSelectBox {...input} error={meta}>
                                                {siteClasses?.map((classification) => (
                                                    <SpotSelectOption key={classification.pimsGeneratedId} value={classification.pimsGeneratedId}>
                                                        {classification.name}
                                                    </SpotSelectOption>
                                                ))}
                                            </SpotSelectBox>
                                        </div>
                                    )}
                                </Field>
                            </Col>
                        </Row>
                    </>
                )}
                {masterSubclassId && (
                    <>
                        <Row className="margin-top-1">
                            <Col xs={12}>
                                <span className="mappingType">
                                    <Trans i18nKey="missingMappingFlyover:subclassification">SUB-CLASSIFICATION</Trans>
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <span className="mappingDescription">
                                    {i18n.t('missingMappingFlyover:youChoseSubclassication', {
                                        defaultValue: `You chose {{value}}. Select a sub-classification at {{siteName}} clinic to map`,
                                        replace: { value: masterSubclassDescription, siteName },
                                    })}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8}>
                                <Field name={`subclassId_${siteId}`}>
                                    {({ input, meta }) => (
                                        <div>
                                            <SpotSelectBox {...input} error={meta} disabled={siteSubclasses.length === 0}>
                                                {siteSubclasses.map((subclass) => (
                                                    <SpotSelectOption key={subclass.pimsGeneratedId} value={subclass.pimsGeneratedId}>
                                                        {subclass.description}
                                                    </SpotSelectOption>
                                                ))}
                                            </SpotSelectBox>
                                        </div>
                                    )}
                                </Field>
                            </Col>
                        </Row>
                    </>
                )}
            </>
        );
    };

    isDefined = (value) => {
        let error;
        if (value === null || value === undefined || validator.isEmpty(`${value}`)) {
            error = i18n.t('missingMappingFlyover:selectMatchingValue', 'Please select a matching value');
        }
        return error;
    };

    getSiteClass = (siteId, classId) => {
        const constants = this.constantsStore;
        return constants.classesBySite.get(siteId)?.find(({ pimsGeneratedId }) => pimsGeneratedId === classId);
    };

    getSubclass = (classification: LookupClassification, subclassId: string) => {
        return classification.subClassifications?.find(({ pimsGeneratedId }) => pimsGeneratedId === subclassId);
    };

    getSiteItemVariant = (siteId): InvoiceItem | undefined => {
        const { unmappedItem } = this.props;
        return unmappedItem?.variants.find((variant) => String(variant.provider.siteId) === String(siteId));
    };
}

export default MissingMappingFlyover;
