import * as React from 'react';
import { SpotTextInput, SpotCheckbox, SpotHorizontalFields, SpotRadioGroup, SpotRadio, SpotSelectBox, SpotSelectOption } from '@enterprise/spot';
import { Field, useForm, useFormState } from '@enterprise/common';
import { PricingTableField } from '../../components';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { AutoCalculatePrice, InvoiceItemTypes, InvoiceItemWithVariantDetail } from '../../../../core/models/datasource';
import { isWithinRange, formatPrice, isDefined } from '../../../../core/helpers';
import { invoiceItemPriceLimits } from '../invoiceItemPriceLimits.constants';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';
import { DispensingItems } from './dispensingItems';
import { useEffect, useState } from 'react';

const { minPrice, maxPrice, maxDecimalPlaces } = invoiceItemPriceLimits;
const isWithinRangeValidator = isWithinRange(minPrice, maxPrice);

const autoCalculatePriceName = 'prices.autoCalculatePrice.id';
const roundToName = 'prices.roundTo.id';

export const PricingControls = observer(function PricingControls() {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const pricingChangePolicies = itemsPageStore.getLookupResults(itemsPageStore.pricingChangePolicies);
    const roundingPrecisions = itemsPageStore.getLookupResults(itemsPageStore.roundingPrecisions);
    const form = useForm();
    const { values } = useFormState<InvoiceItemWithVariantDetail>();
    const { itemType, prices } = values;
    const [autoCalculatePriceEnabled, setAutoCalculatePriceEnabled] = useState<boolean>();

    useEffect(() => {
        if (prices && autoCalculatePriceEnabled === undefined) {
            setAutoCalculatePriceEnabled(itemType?.id === InvoiceItemTypes.Inventory && Boolean(prices.autoCalculatePrice?.id));
        }
    }, [prices]);

    useEffect(() => {
        if (itemType?.id === InvoiceItemTypes.Service) {
            setAutoCalculatePriceEnabled(undefined);
            form.change(autoCalculatePriceName, AutoCalculatePrice.DoNotAutoCalculatePrice);
            form.change(roundToName, null);
        }
    }, [itemType?.id]);

    const onAutoCalculatePriceChange = (value: boolean) => {
        setAutoCalculatePriceEnabled(value);
        form.batch(() => {
            if (value) {
                form.change(autoCalculatePriceName, AutoCalculatePrice.Percentage);
                form.change(roundToName, prices?.roundTo?.id);
            } else {
                form.change(autoCalculatePriceName, AutoCalculatePrice.DoNotAutoCalculatePrice);
                form.change(roundToName, null);
            }
        });
    };

    return (
        <div>
            <SpotHorizontalFields>
                <Field name="prices.minimumPrice" format={formatPrice(maxDecimalPlaces)} formatOnBlur={true} validate={isWithinRangeValidator}>
                    {({ input, meta }) => (
                        <div>
                            <label className="spot-form__label">
                                <Trans i18nKey="controlCenter:itemFields.minimumPrice">Minimum Price</Trans>
                            </label>
                            <SpotTextInput
                                {...input}
                                data-automation-id="minimum-price-input"
                                type="number"
                                min="0.01"
                                step="0.01"
                                error={meta}
                                placeholder="Minimum Price"
                            />
                        </div>
                    )}
                </Field>
                <Field
                    name="prices.dispensingFee"
                    format={formatPrice(maxDecimalPlaces)}
                    formatOnBlur={true}
                    validate={(value: number) => isDefined(value) && isWithinRangeValidator(value)}
                >
                    {({ input, meta }) => (
                        <div>
                            <label className="spot-form__label">
                                <Trans i18nKey="controlCenter:itemFields.dispensingFee">Dispensing Fee</Trans>
                            </label>
                            <SpotTextInput
                                {...input}
                                data-automation-id="dispensing-fee-input"
                                type="number"
                                min="0"
                                step="0.01"
                                error={meta}
                                disabled={itemType?.id !== InvoiceItemTypes.Inventory}
                                placeholder="Dispensing Fee"
                            />
                        </div>
                    )}
                </Field>
                <Field
                    name="prices.markupPercentage"
                    format={formatPrice(maxDecimalPlaces, null)}
                    formatOnBlur={true}
                    validate={(value: number) => isDefined(value) && isWithinRangeValidator(value)}
                >
                    {({ input, meta }) => (
                        <div>
                            <label className="spot-form__label">
                                <Trans i18nKey="controlCenter:itemFields.markupPercentage">Estimate Markup</Trans>
                            </label>
                            <SpotTextInput {...input} type="number" min="0.01" step="0.01" error={meta} placeholder="Estimate Markup" />
                        </div>
                    )}
                </Field>
                <Field name="prices.pricingChangeType.id" parse={(value) => value && Number(value)}>
                    {({ input, meta }) => (
                        <div>
                            <label className="spot-form__label">
                                <Trans i18nKey="controlCenter:itemFields.allowChange">Allow Change</Trans>
                            </label>
                            <SpotSelectBox
                                {...input}
                                error={meta}
                                disabled={itemType?.id !== InvoiceItemTypes.Inventory && itemType?.id !== InvoiceItemTypes.Service}
                                isHidePlaceholder={true}
                                data-automation-id={`pricing-change-type`}
                            >
                                {pricingChangePolicies.map((policy) => (
                                    <SpotSelectOption key={policy.id} value={policy.id}>
                                        {policy.description}
                                    </SpotSelectOption>
                                ))}
                            </SpotSelectBox>
                        </div>
                    )}
                </Field>
            </SpotHorizontalFields>
            <SpotHorizontalFields>
                <SpotCheckbox
                    id={'12345'}
                    data-automation-id="auto-calculate-price"
                    disabled={itemType?.id !== InvoiceItemTypes.Inventory}
                    checked={autoCalculatePriceEnabled}
                    onChange={(event) => onAutoCalculatePriceChange(event.target.checked)}
                >
                    <Trans i18nKey="controlCenter:itemFields.autoCalculatePrice">Auto Calculate Price</Trans>
                </SpotCheckbox>
                {autoCalculatePriceEnabled && (
                    <>
                        <SpotRadioGroup isHorizontal={true}>
                            <Field name={autoCalculatePriceName} type="radio" parse={(value) => Number(value)} value={AutoCalculatePrice.Percentage}>
                                {({ input }) => (
                                    <SpotRadio
                                        data-automation-id="auto-calculate-percentage"
                                        {...input}
                                        value={AutoCalculatePrice.Percentage}
                                        disabled={itemType?.id !== InvoiceItemTypes.Inventory}
                                    >
                                        <Trans i18nKey="controlCenter:itemFields.percentage">Percentage</Trans>
                                    </SpotRadio>
                                )}
                            </Field>
                            <Field
                                name={autoCalculatePriceName}
                                type="radio"
                                parse={(value) => Number(value)}
                                value={AutoCalculatePrice.DollarAmount}
                            >
                                {({ input }) => (
                                    <SpotRadio
                                        data-automation-id="auto-calculate-dollar"
                                        {...input}
                                        value={AutoCalculatePrice.DollarAmount}
                                        disabled={itemType?.id !== InvoiceItemTypes.Inventory}
                                    >
                                        <Trans i18nKey="controlCenter:itemFields.dollarAmount">Dollar Amount</Trans>
                                    </SpotRadio>
                                )}
                            </Field>
                        </SpotRadioGroup>
                        <Field name={roundToName} parse={(value) => Number(value)}>
                            {({ input, meta }) => (
                                <div>
                                    <label
                                        className={classNames('spot-form__label', {
                                            'spot-form__label--disabled': itemType?.id !== InvoiceItemTypes.Inventory,
                                        })}
                                    >
                                        <Trans i18nKey="controlCenter:itemFields.roundTo">Round To</Trans>
                                    </label>
                                    <SpotSelectBox
                                        {...input}
                                        error={meta}
                                        disabled={itemType?.id !== InvoiceItemTypes.Inventory}
                                        data-automation-id="auto-calculate-round-to"
                                    >
                                        {roundingPrecisions.map((precision) => (
                                            <SpotSelectOption key={precision.id} value={precision.id}>
                                                {precision.description}
                                            </SpotSelectOption>
                                        ))}
                                    </SpotSelectBox>
                                </div>
                            )}
                        </Field>
                    </>
                )}
            </SpotHorizontalFields>
            <SpotHorizontalFields>
                <Field name="prices.omitDispensingFeeForMultiplePatients" type="checkbox">
                    {({ input }) => (
                        <SpotCheckbox disabled={itemType?.id !== InvoiceItemTypes.Inventory} {...input} data-automation-id="omit-dispensing-items">
                            <Trans i18nKey="controlCenter:itemFields.prices.omitDispensingFeeForMultiplePatients">
                                Omit dispensing fee for multiple patients
                            </Trans>
                        </SpotCheckbox>
                    )}
                </Field>
                <DispensingItems />
            </SpotHorizontalFields>
            <SpotHorizontalFields>
                <PricingTableField name="prices.priceBreaks" autoCalculatePriceEnabled={autoCalculatePriceEnabled} />
            </SpotHorizontalFields>
        </div>
    );
});
