import * as React from 'react';
import * as _ from 'lodash';

interface ValueProps {
    value: any;
    path: string;
    defaultValue?: any;
}

export class Value extends React.PureComponent<ValueProps> {
    render() {
        const { path, value, defaultValue } = this.props;
        return _value(value, path, defaultValue);
    }
}

export function _value(value: any, path: string, defaultValue?: any) {
    return _.at(value, [path])[0] || defaultValue;
}
