import React, { memo, useEffect, PropsWithChildren } from 'react';
import { SpotLoading } from '@enterprise/spot';
import { useNavigate } from 'react-router-dom';

type Props = PropsWithChildren<{
    to: string;
    global?: boolean;
}>;

export const RedirectToRouteComponent = memo(function RedirectToRouteComponent(props: Props) {
    const { to, global } = props;
    const navigate = useNavigate();

    useEffect(() => {
        if (global) {
            window.location.replace(to);
        } else {
            navigate(to);
        }
    }, [global]);

    return (
        <div>
            <SpotLoading text="Redirecting..." />
        </div>
    );
});
