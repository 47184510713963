import React from 'react';
import styles from './App.module.css';
import { ToastContainer } from 'react-toastify';
import { observer } from 'mobx-react';
import { ConnectRouter } from './router/ConnectRouter';
import { Cmp } from '@enterprise/common';
import { ErrorBoundary } from './pages/ErrorBoundary';
import { useEffect } from 'react';
import { useStores, useAppTheme } from './hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material';
import { HelpIcon } from './components/helpIcon/helpIcon';

export const App = observer(function AppComponent() {
    const theme = useAppTheme();
    const rootStore = useStores();
    const auth0 = useAuth0();
    const { isLoaded } = rootStore;

    useEffect(() => {
        rootStore.ui.app.setAuthClient(auth0);
    }, [auth0]);

    useEffect(() => {
        rootStore.bootstrap();

        return () => {
            rootStore.clean();
        };
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <div className={styles.enterpriseApp}>
                <Cmp.If cond={isLoaded}>
                    <ErrorBoundary>
                        <ConnectRouter />
                    </ErrorBoundary>
                </Cmp.If>
                <Cmp.If cond={!isLoaded}>Loading...</Cmp.If>
                <ToastContainer
                    className={styles.toast}
                    position="top-center"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={true}
                    closeOnClick={true}
                    rtl={false}
                    draggable={true}
                    pauseOnHover={true}
                />
                <HelpIcon />
            </div>
        </ThemeProvider>
    );
});
