import { toast, ToastOptions, ToastType } from 'react-toastify';
import React from 'react';
import { SystemToast } from '../components/toasts/systemMessageToast';
import styles from '../components/toasts/systemMessageToast.module.scss';

type SystemMessagingContent = {
    message: string;
    subMessage?: string;
};

const toastOptions: ToastOptions = {
    position: 'bottom-center',
    autoClose: 5000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    hideProgressBar: true,
    className: styles.systemMessageToast,
    bodyClassName: styles.systemMessageBody,
    closeButton: false,
};

export class SystemMessaging {
    private static instance?: SystemMessaging;

    static getInstance() {
        if (!SystemMessaging.instance) {
            SystemMessaging.instance = new SystemMessaging();
        }
        return SystemMessaging.instance;
    }

    static success(content: SystemMessagingContent) {
        return SystemMessaging.getInstance().success(content);
    }

    static error(content: SystemMessagingContent) {
        return SystemMessaging.getInstance().error(content);
    }

    success(content: SystemMessagingContent) {
        return toast(<SystemToast message={content.message} type={ToastType.SUCCESS} />, toastOptions);
    }

    error(content: SystemMessagingContent) {
        return toast(<SystemToast message={content.message} subMessage={content.subMessage} type={ToastType.ERROR} />, toastOptions);
    }
}
