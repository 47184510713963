/**
 * @class Main Service do deal with user roles authentication
 */
import { BaseUserToken } from './providers/common/baseUserToken';
import { AnonymousUserToken } from './providers/common/anonymousUserToken';
import { BaseHttp } from '../http/BaseHttp';
import { PermissionLevel } from '../../../../apps/client/src/core/enums/permissionLevel';
import { Signup, UserAvatars, UserPractices } from './models';
import { DetailedUser, Feature } from './providers/enterprise/EnterpriseUserToken';
import { BaseAuthProvider } from './providers/common/baseAuthProvider';

export class UserService<Token extends BaseUserToken = BaseUserToken> {
    /**
     * List of available Auth providers
     */

    constructor(private http: BaseHttp, private providers: BaseAuthProvider<Token>[]) {}

    USERS_PATH = 'v2/users';
    ACCESS_MANAGEMENT_PATH = 'v2/access-management/policies';

    /**
     * Goes through all registered providers, finds the first one which supports given token
     * and tries to auth using it to authenticate
     * @param accessToken
     * @return {Promise<BaseUserToken|boolean>}
     */
    async authenticate(accessToken: string): Promise<Token | undefined> {
        const provider = this.providers.find((provider) => provider.support?.(accessToken));
        if (!provider) {
            throw new Error(`Unable to find Auth Provider`);
        }

        try {
            return await provider.authenticate(accessToken);
        } catch {
            return;
        }
    }

    logout(token?: Token): void {
        if (!token) {
            this.providers.map((p) => p.logout());
            return;
        }

        const provider = this.providers.find((provider) => provider.support?.(token));

        if (!provider) {
            throw new Error(`Unable to find Auth Provider`);
        }

        provider.logout(token);
    }

    /**
     * Tries to restore user session using all registered providers one by one.
     * The first provider which will successfully be restored will be used.
     * @return {Promise<any>}
     */
    restore(accessToken?: string): Promise<Token> {
        const promises = this.providers.map((p) => p.restore(accessToken));
        // If a request fails, count that as a resolution so it will keep
        // waiting for other possible successes. If a request succeeds,
        // treat it as a rejection so Promise.all immediately bails out.
        return Promise.all(
            promises.map((p) =>
                p.then(
                    (val) => Promise.reject(val),
                    (err) => Promise.resolve(err),
                ),
            ),
        )
            .then(
                // If '.all' resolved, we've just got an array of errors.
                (errors) => Promise.reject(errors),
                // If '.all' rejected, we've got the result we wanted.
                (val) => Promise.resolve(val),
            )
            .then((newToken) => {
                return newToken;
            })
            .catch(() => {
                return new AnonymousUserToken();
            });
    }

    updateToken(token: Token): void {
        this.providers.forEach((provider) => provider.setLocalStorage(token));
    }

    setUserDisplayName(displayName: string, id: string): Promise<void> {
        return this.http.put(`${this.USERS_PATH}/:id/profile`, { displayName: displayName }, { pathParams: { id } });
    }

    setUserAvatar(avatar: string, id: string): Promise<void> {
        return this.http.put(`${this.USERS_PATH}/:id/profile`, { avatar }, { pathParams: { id } });
    }

    updateUserFeatures(features: Feature[], id: string): Promise<void> {
        return this.http.patch('/users/:id/features', features, { pathParams: { id } });
    }

    //TODO: need to add the set policy for contributor and analyst in the future
    async setUserPolicy(siteAccessType: PermissionLevel | undefined, userId: string): Promise<void> {
        if (!siteAccessType) {
            return;
        }

        //TODO: need to remove the throwing exception in the future after roles becoming not hardcoded
        // const policies = await this.http.get(this.ACCESS_MANAGEMENT_PATH);
        // if (!policies.some((obj) => obj.name === PermissionLevel.DecisionMaker)) {
        //     throw new Error(`no Decision Maker policy exist`);
        // }

        //TODO: need to remove previous role from the user in the future when we have other roles set in place
        // const decisionMakerPolicyId = policies.filter((obj) => obj.name === PermissionLevel.DecisionMaker)[0].id;
        // return this.http.post(`${this.USERS_PATH}/:id/policies`, { policies: [decisionMakerPolicyId] }, { pathParams: { id: userId } });
    }

    getUserDetails(id: string): Promise<DetailedUser> {
        return this.http.get(`${this.USERS_PATH}/:id`, { pathParams: { id } });
    }

    getUserPolicy(policyId: string): Promise<any> {
        return this.http.get(`${this.ACCESS_MANAGEMENT_PATH}/:policyId`, { pathParams: { policyId } });
    }

    getUserPracticeAccess(id: string): Promise<UserPractices> {
        return this.http.get(`${this.USERS_PATH}/:id/practice-access`, { pathParams: { id } });
    }

    addUserPracticeAccess(id: string, userPractices?: UserPractices): Promise<UserPractices> {
        return this.http.post(`${this.USERS_PATH}/:id/practice-access`, userPractices, { pathParams: { id } });
    }

    removeUserPracticeAccess(id: string, userPractices: UserPractices): Promise<UserPractices> {
        return this.http.delete(`${this.USERS_PATH}/:id/practice-access`, userPractices, { pathParams: { id } });
    }

    getUserAvatars(id: string): Promise<UserAvatars> {
        return this.http.get(`${this.USERS_PATH}/:id/avatars`, { pathParams: { id } });
    }

    signup(data: Signup, config: Record<string, unknown>): Promise<void> {
        return this.http.post(`/auth/v1/signup`, data, config);
    }
}
