import { PLACE_HOLDER } from '../constants';
import { CardModel } from '../models/Card.model';
import { ColumnModel } from '../models/Column.model';
import { DraggableStateSnapshot } from 'react-beautiful-dnd';

export function deepCopy(data: any): any {
    if (data === undefined) return data;
    return JSON.parse(JSON.stringify(data));
}

export function sortColumnData(column: ColumnModel): ColumnModel {
    if (!column) return column;
    column = updatePlaceHolders(column);
    column.data.sort((a, b) => a.title.localeCompare(b.title) - (a.placeholder ? 0.1 : 0)); // also keeps placeholders above items with the same one-character title
    column.data.forEach(card => card.children && card.children.sort((a, b) => a.title.localeCompare(b.title)));
    return column;
}

function updatePlaceHolders(column: ColumnModel): ColumnModel {
    const uniqueChars = {};
    const { data } = column;
    let i = data.length;

    while (i--) {
        const card = data[i];
        const charKey = card.title && card.title.charAt(0).toUpperCase();

        if (uniqueChars[charKey]) {
            uniqueChars[charKey]++;
        } else {
            uniqueChars[charKey] = 1;
        }

        if (card.placeholder) {
            data.splice(i, 1);
            uniqueChars[charKey]--;
        }
    }

    Object.entries(uniqueChars).forEach(sets => {
        if (sets[1] !== 0) {
            const card = new CardModel();
            card.id = PLACE_HOLDER + '-' + sets[0];
            card.placeholder = true;
            card.title = sets[0];
            data.push(card);
        }
    });

    column.uniqueCharacters = Object.keys(uniqueChars).sort((a, b) => a.localeCompare(b));
    return column;
}

export function validCombineWith(snapshot: DraggableStateSnapshot, id: string): boolean {
    if (!snapshot || !id || !snapshot.combineTargetFor) return false;
    const originatingColumnId = deserializeDraggableId(snapshot.combineTargetFor).columnId;
    return originatingColumnId !== id;
}

export interface CardKey {
    cardId: string;
    columnId: string;
    parentId: string | null;
}

export const serializeDraggableId = ({ cardId = '', columnId = '', parentId = null }: Partial<CardKey>) => {
    return JSON.stringify({
        cardId,
        columnId,
        parentId,
    });
};

export const deserializeDraggableId = (key: string) => {
    let parsedKey;
    try {
        parsedKey = JSON.parse(key || '{}');
    } catch (e) {
        parsedKey = {};
    }
    const { cardId = '', columnId = '', parentId = null } = parsedKey;
    return {
        cardId,
        columnId,
        parentId,
    } as CardKey;
};

/**
 * If a draggable is dropped between the children of a parent droppable, get the data index of that parent
 * An item's position in the data array is not necessarily the same as it's position in the UI, due to children
 * so we need to "flatten" each item, accounting for the length of each child array plus New Child placeholders
 * and determine if the provided droppableIndex (where the card was dropped) falls amongst child elements or
 * parents
 */
export const getDroppableTargetParent = (droppableIndex: number | null, primaryData: CardModel[], allowChildCardCreation: boolean) => {
    let parentIndex: number | null = null;
    let parentId: string | null = null;
    let columnPositionIndex = 0;

    if (droppableIndex === null) {
        return {
            parentIndex,
            parentId,
        };
    }

    for (let dataIndex = 0; dataIndex < primaryData.length; dataIndex++) {
        // If we've found the correct position, there is no target parent ID
        if (columnPositionIndex === droppableIndex) {
            break;
        }

        // Account for the positions of the children
        columnPositionIndex += primaryData[dataIndex].children.length;
        // If the board is allowing child to be created, we need to account for the New Child placeholder
        if (allowChildCardCreation && !primaryData[dataIndex].placeholder) {
            columnPositionIndex++;
        }

        // If the droppableIndex falls amongst the children, we can get the target parent id
        if (columnPositionIndex >= droppableIndex) {
            parentIndex = dataIndex;
            parentId = primaryData[dataIndex].id;
            break;
        }

        // increase position for next iteration
        columnPositionIndex++;
    }
    return {
        parentIndex,
        parentId,
    };
};
