import { environment } from '../environment/environment';
import { Environments } from '../core/enums/environments';
import { ContentfulClientApi, createClient, Entry } from 'contentful';

enum ContentfulEnvironments {
    NonProd = 'Non-Prod',
    Prod1 = 'Prod-1',
}

export enum ContentfulContentTypes {
    AnalyticsAdvertisement = 'analyticsAdvertisment',
}

export class ContentfulService {
    private readonly client: ContentfulClientApi;

    constructor() {
        this.client = createClient({
            accessToken: environment.CONTENTFUL.accessToken,
            environment: this.getContentfulEnvironment(),
            space: environment.CONTENTFUL.space,
        });
    }

    async getEntriesByContentType<T>(contentType: ContentfulContentTypes, query?: Record<string, unknown>): Promise<Entry<T>[]> {
        const { items } = await this.client.getEntries<T>({
            ...query,
            content_type: contentType,
        });
        return items;
    }

    private getContentfulEnvironment(): ContentfulEnvironments {
        const { APP_ENV: currentEnv } = environment;
        const shouldUseProdContentful = currentEnv === Environments.PROD.toUpperCase() || currentEnv === Environments.STAGING.toUpperCase();

        if (shouldUseProdContentful) {
            return ContentfulEnvironments.Prod1;
        }
        return ContentfulEnvironments.NonProd;
    }
}
