import { useWindowSize } from './useWindowSize.hook';
import styles from './userScreenSize.module.scss';
import { useMemo } from 'react';

export enum ScreenSizeType {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl',
}

const screenSizesOrder = {
    [ScreenSizeType.xs]: 0,
    [ScreenSizeType.sm]: 1,
    [ScreenSizeType.md]: 2,
    [ScreenSizeType.lg]: 3,
    [ScreenSizeType.xl]: 4,
};

export function useScreenSize(): { screenSize: ScreenSizeType; isUpTo: (size: ScreenSizeType) => boolean } {
    const { width = 0 } = useWindowSize();
    const screenSize = useMemo<ScreenSizeType>(() => {
        return (
            (width < Number(styles.breakpointSm) && ScreenSizeType.xs) ||
            (width < Number(styles.breakpointMd) && ScreenSizeType.sm) ||
            (width < Number(styles.breakpointLg) && ScreenSizeType.md) ||
            (width < Number(styles.breakpointXl) && ScreenSizeType.lg) ||
            ScreenSizeType.xl
        );
    }, [width]);

    const isUpTo = useMemo(() => {
        const values = new Map<ScreenSizeType, boolean>(
            Object.values(ScreenSizeType).map<[ScreenSizeType, boolean]>((type) => [type, screenSizesOrder[screenSize] < screenSizesOrder[type]]),
        );

        return (size: ScreenSizeType): boolean => {
            return Boolean(values.get(size));
        };
    }, [screenSize]);

    return { screenSize, isUpTo };
}
