import * as React from 'react';
import {SpotSvg} from '../SpotSvg';

interface SpotEditableTextProps {
    label: any;
    children: any;
}

interface SpotEditableTextState {
    isEditable: boolean;
}

export class SpotEditableText extends React.Component<SpotEditableTextProps, SpotEditableTextState> {
    state:SpotEditableTextState = {isEditable: false};
    render() {
        const {label, children} = this.props;
        const {isEditable} = this.state;

        return isEditable ? (
            children
        ) : (
            <>
                <span>{label}</span>
                <a onClick={this.toggle}>
                    <SpotSvg className={'fly-over-editable'} icon="edit" />
                </a>
            </>
        );
    }

    toggle = () => {
        const {isEditable} = this.state;
        this.setState({isEditable: !isEditable});
    };
}
