import React, { FC, ReactElement } from 'react';

interface ToastWithSubheader {
    message: string;
    subMessage: string;
}

export const ToastWithSubHeader: FC<ToastWithSubheader> = ({ message, subMessage }): ReactElement => (
    <>
        <div className="toast-message-header">{message}</div>
        <div className="toast-message-detail">{subMessage}</div>
    </>
);
