import React, { memo, ReactNode } from 'react';
import { ProTips } from '../../../components/helpers/ProTips';
import { PageHeader } from '../pageHeader';
import styles from './pageWrapper.module.scss';
import { PageFooter } from '../pageFooter';
import classNames from 'classnames';

export interface PageWrapperProps {
    className?: string;
    holderClassName?: string;
    isLoading?: boolean;
    title?: string;
    description?: string;
    children?: ReactNode;
}

export const PageWrapper = memo(function PageWrapper(props: PageWrapperProps) {
    const { children, isLoading, title, className, description, holderClassName } = props;
    return (
        <>
            <PageHeader className={styles.pageHeader} />
            <div className={classNames(styles.holder, holderClassName)}>
                <div className={classNames(styles.wrapper, 'page-wrapper', className)} data-automaton-id={'page-wrapper'}>
                    {isLoading && <ProTips />}
                    {!isLoading && (
                        <>
                            {title && <h1>{title}</h1>}
                            {description && <p className={styles.subText}>{description}</p>}
                            <div className={styles.wrapperPageContent} data-automaton-id={'wrapper-page-content'}>
                                {React.Children.toArray(children).map((c) => c)}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <PageFooter className={styles.pageFooter} />
        </>
    );
});
