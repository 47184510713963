import {
    InvoiceItem,
    InvoiceItemClassification,
    InvoiceItemInventory,
    InvoiceItemPrices,
    InvoiceItemService,
    InvoiceItemType,
    InvoiceItemTypes,
    PriceBreak,
    UnitOfMeasure,
} from '../../../datasource/invoiceItems';
import { IsAlphanumeric, IsNotEmpty, Length, Matches, ValidateIf, ValidateNested } from 'class-validator';
import i18n from 'i18next';
import { Type, TypeHelpOptions, Exclude } from 'class-transformer';
import { Default } from '@enterprise/common';

class CornerstoneInvoiceItemPrices extends InvoiceItemPrices {
    @Exclude()
    itemTypeId: string;

    constructor(values?: TypeHelpOptions) {
        super();
        this.itemTypeId = values?.newObject?.itemType?.id;
    }

    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.minimumPrice', 'Minimum Price') }),
    })
    @Default(0)
    minimumPrice: number;

    @ValidateIf((value) => Number(value.itemTypeId) === InvoiceItemTypes.Inventory)
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.dispensingFee', 'Dispensing Fee') }),
    })
    @Default(0)
    dispensingFee?: number;

    markupPercentage: number;

    priceBreaks: PriceBreak[] = [];
}

class CornerstoneInvoiceItemUnitOfMeasure extends UnitOfMeasure {
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.unitOfMeasure', 'Unit Of Measure') }),
    })
    pimsGeneratedId: string;
}

class CornerstoneInvoiceItemInventory extends InvoiceItemInventory {
    @ValidateNested()
    @Type(() => CornerstoneInvoiceItemUnitOfMeasure)
    @Default({})
    unitOfMeasure?: CornerstoneInvoiceItemUnitOfMeasure = new CornerstoneInvoiceItemUnitOfMeasure();
}

class CornerstoneInvoiceItemClassification extends InvoiceItemClassification {
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.classId', 'Class ID') }),
    })
    pimsGeneratedId: string;
}

class CornerstoneInvoiceItemSubClassification extends InvoiceItemClassification {
    pimsGeneratedId: string;
}

class CornerstoneInvoiceItemType extends InvoiceItemType {
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.itemType', 'Item Type') }),
    })
    id: InvoiceItemTypes;
}

export class CornerstoneInvoiceItem extends InvoiceItem {
    @ValidateNested()
    @Type(() => CornerstoneInvoiceItemClassification)
    @Default({})
    classification: CornerstoneInvoiceItemClassification = new CornerstoneInvoiceItemClassification();

    @Type(() => CornerstoneInvoiceItemSubClassification)
    subClassification: CornerstoneInvoiceItemSubClassification | null = null;

    @Length(1, 7, {
        message: ({ constraints }) =>
            i18n.t('validation:length2', {
                field: i18n.t('controlCenter:itemFields.id', 'Item Number'),
                min: constraints[0],
                max: constraints[1],
            }),
    })
    @IsAlphanumeric(undefined, {
        message: () => i18n.t('validation:isAlphanumeric', { field: i18n.t('controlCenter:itemFields.id', 'Item Number') }),
    })
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.id', 'Item Number') }),
    })
    pimsId: string;

    @Length(1, 40, {
        message: ({ constraints }) =>
            i18n.t('validation:length2', {
                field: i18n.t('controlCenter:itemFields.hospitalDescription', 'Hospital Description'),
                min: constraints[0],
                max: constraints[1],
            }),
    })
    @Matches(new RegExp('^[a-zA-Z0-9].*'), {
        message: () =>
            i18n.t(
                'controlCenter:validation.hospitalDescription_first_char',
                'The first character of the hospital description must be one of the following characters. 0-9 or A-Z',
            ),
    })
    @IsNotEmpty({
        message: () => i18n.t('validation:isNotEmpty', { field: i18n.t('controlCenter:itemFields.hospitalDescription', 'Hospital Description') }),
    })
    hospitalDescription: string;

    @ValidateNested()
    @Type((type) => () => new CornerstoneInvoiceItemPrices(type))
    prices: CornerstoneInvoiceItemPrices = new CornerstoneInvoiceItemPrices();

    @ValidateNested()
    @Type(() => CornerstoneInvoiceItemType)
    itemType: CornerstoneInvoiceItemType = new CornerstoneInvoiceItemType();

    @ValidateNested()
    @Type((opts?: TypeHelpOptions) => {
        switch (String(opts?.newObject.itemType.id)) {
            case String(InvoiceItemTypes.Inventory):
                return CornerstoneInvoiceItemInventory;
            case String(InvoiceItemTypes.Service):
                return InvoiceItemService;
            default:
                return Function;
        }
    })
    typeDetails: CornerstoneInvoiceItemInventory | InvoiceItemService = {};
}
