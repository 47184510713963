import * as React from 'react';

interface SpotPillSetProps {
    isVertical?: boolean;
    children: React.ReactChild[];
}

export class SpotPillSet extends React.PureComponent<SpotPillSetProps> {
    render() {
        const { children, isVertical } = this.props;
        const classSuffix = isVertical ? 'vertical' : 'horizontal';
        return (
            <ul className={`spot-pill__${classSuffix}-set`}>
                {React.Children.toArray(children).map(i => (
                    <li className={`spot-pill__${classSuffix}-set-item`}>{i}</li>
                ))}
            </ul>
        );
    }
}
