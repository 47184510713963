import React from 'react';
import { SpotButton, SpotTextInput, SpotModal } from '@enterprise/spot';
import { Col, Container, Row } from 'react-bootstrap';
import validator from 'validator';
import * as lodash from 'lodash';

interface PageProps {
    isVisible: boolean;
    onSubmit: any;
    onClose: any;
}

interface PageState {
    regionName: string;
    regionNameError: any;
}

class CreateRegionDialog extends React.Component<PageProps, PageState> {
    state = {
        regionName: '',
        regionNameError: undefined,
    };

    setRegionName = (val) => {
        this.setState({ regionName: val });
    };

    submit = () => {
        if (this.isUndefined(this.state.regionName)) {
            this.setState({ regionNameError: { error: 'Region name is required', touched: true } });
            return;
        }

        this.props.onSubmit(this.state.regionName);
    };

    render() {
        return (
            <SpotModal title="Create a Region" isVisible={this.props.isVisible} onHide={this.props.onClose} footer={this.createFooter}>
                {() => (
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div>
                                    <label className="spot-form__label">Region name</label>
                                    <SpotTextInput
                                        value={this.state.regionName}
                                        // @ts-ignore
                                        // tslint:disable-next-line
                                        onChange={(e) => this.setRegionName(e.target.value)}
                                        error={this.state.regionNameError}
                                        placeholder="ex. Central"
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )}
            </SpotModal>
        );
    }

    isUndefined = (value) => !value || lodash.isUndefined(value) || validator.isEmpty(`${value}`);

    private createFooter = () => {
        return (
            <div style={{ textAlign: 'right', width: '100%' }}>
                <SpotButton isPrimary={true} onClick={this.submit}>
                    Save
                </SpotButton>
            </div>
        );
    };
}

export default CreateRegionDialog;
