import { SpotButton, SpotModal } from '@enterprise/spot';
import * as React from 'react';
import { InvoiceItemTypes, InvoiceItemWithVariantDetail } from '../../../../core/models';
import { Trans } from 'react-i18next';
import { useFormState } from '@enterprise/common';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';
import { GroupSelectField } from '../../components';
import { LookupDispensingItem } from '../../../../core/models/datasource/lookups';
import styles from './dispensingItems.module.scss';

export const DispensingItems = observer(function DispensingItems() {
    const {
        domain: { itemsPageStore },
    } = useStores();
    const dispensingItems = itemsPageStore.getLookupResults(itemsPageStore.dispensingItems);
    const { values } = useFormState<InvoiceItemWithVariantDetail>();
    const { itemType } = values;

    const renderDispensingModalController = ({ open }) => {
        return (
            <SpotButton onClick={open} disabled={itemType?.id === InvoiceItemTypes.Service} data-automation-id="dispensing-items-button">
                <Trans i18nKey="controlCenter:itemFields.dispensingItems">Dispensing Items</Trans>
            </SpotButton>
        );
    };

    const renderDispensingModalFooter = ({ close }) => {
        return (
            <div className={styles.footer}>
                <SpotButton isPrimary={true} onClick={close} data-automation-id="dispensing-items-save-button">
                    <Trans i18nKey="common:buttons.save">Save</Trans>
                </SpotButton>
            </div>
        );
    };

    return (
        <SpotModal title="Dispensing Items" controller={renderDispensingModalController} footer={renderDispensingModalFooter}>
            {() => (
                <GroupSelectField
                    name="typeDetails.dispensingItems"
                    options={dispensingItems}
                    getOptionId={({ pimsGeneratedId }: LookupDispensingItem) => pimsGeneratedId}
                    getOptionTitle={({ pimsGeneratedId, hospitalDescription }) => `${pimsGeneratedId} - ${hospitalDescription}`}
                    dataAutomationId="dispensing-items-check-boxes"
                />
            )}
        </SpotModal>
    );
});
