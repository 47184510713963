import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ValidationSchema, registerSchema } from 'class-validator';
import { observer } from 'mobx-react';
import styles from './SignupPage.module.css';
import { Wizard } from '../../components/wizard/Wizard';
import { Step } from '../../components/wizard/Step';
import { UserStep } from './wizardSteps/UserStep';
import { ConfirmStep } from './wizardSteps/ConfirmStep';
import { Form, FormRenderProps, SchemaFormType, Transformers } from '@enterprise/common';
import { SpotSvg } from '@enterprise/spot';
import { Notifications } from '../Notifications';
import classNames from 'classnames';
import i18n from '../../i18n';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import { useStores } from '../../hooks';
import { RouterPaths } from '../../router/RouterPaths';

const signupFormValidationSchema: ValidationSchema = {
    name: 'signupPageFormSchema',
    properties: {
        username: [
            {
                type: 'isEmail',
                constraints: [],
            },
        ],
        organizationName: [
            {
                type: 'isNotEmpty',
                constraints: [],
            },
        ],
        profileFirstName: [
            {
                type: 'isNotEmpty',
                constraints: [],
            },
        ],
        profileLastName: [
            {
                type: 'isNotEmpty',
                constraints: [],
            },
        ],
        terms: [
            {
                type: 'equals',
                constraints: [true],
            },
        ],
    },
};
registerSchema(signupFormValidationSchema);

interface SignupInfo {
    organizationName?: string;
    profileFirstName?: string;
    profileLastName?: string;
    username?: string;
}

export const SignupPage = observer(function SignupPageComponent() {
    const {
        ui: { router, app },
    } = useStores();

    const { user, isAuthenticated, isLoading } = useAuth0();
    const [errors, setErrors] = useState<{ orgName: string }>();
    const [data, setData] = useState<SignupInfo>({});

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (!isAuthenticated) {
            router.push(RouterPaths.BasePages.Root);
        }

        setData({
            ...data,
            username: user?.email,
            profileFirstName: user?.given_name,
            profileLastName: user?.family_name,
        });
    }, [user, isAuthenticated, isLoading]);

    const form = useMemo(() => new SchemaFormType<SignupInfo>('signupPageFormSchema'), []);

    const signup = (values) => {
        app.signup(
            new Transformers.FunctionTransformer((data) => {
                return {
                    username: data.username,
                    organization: {
                        name: data.organizationName,
                    },
                    profile: {
                        firstName: data.profileFirstName,
                        lastName: data.profileLastName,
                    },
                };
            }).transform(values),
        )
            .then(() => app.refreshAuthorization())
            .then(() => {
                Notifications.success('Organization successfully created');
                router.push(RouterPaths.BasePages.Login);
            })
            .catch((e) => {
                let message = e;
                if (/\(username\)/g.test(message)) {
                    message = i18n.t('validation:fieldValueExists', { field: i18n.t('common:signUp.email'), value: values.username });
                }
                if (/\(name\)/g.test(message)) {
                    message = i18n.t('validation:fieldValueExists', { field: i18n.t('common:signUp.orgName'), value: values.organizationName });
                }
                if (message && message.status === 409) {
                    setErrors({
                        orgName: i18n.t('validation:orgNameConflict', 'Organization name is already in use. Please use a different name'),
                    });
                    return;
                }
                Notifications.fromException(message);
            });
    };

    const signIn = () => {
        app.logout();
    };

    return (
        <div className="sign-up-page">
            <Row className="vertical-align" style={{ minHeight: '100vh' }}>
                <Col md="12">
                    <div className={['spot-signin', styles['signup-box']].join(' ')}>
                        <div className="spot-signin__header">
                            <div className="spot-signin__header-image">
                                <SpotSvg className="spot-signin__header-image--idexx" type="logos" icon="idexx-logo.svg#idexx-logo" />
                            </div>
                        </div>
                        <div className="spot-signin__content">
                            <Form data={data} formType={form} onSubmit={signup}>
                                {({ handleSubmit, invalid, values }: FormRenderProps<SignupInfo>) => (
                                    <Wizard>
                                        <Step>
                                            {({ nextStep, prevStep }) => {
                                                return (
                                                    <div>
                                                        <UserStep />
                                                        <div className={styles['buttons-container']}>
                                                            <button
                                                                className={classNames([
                                                                    'spot-button',
                                                                    'spot-button--secondary',
                                                                    styles['signup-button'],
                                                                ])}
                                                                onClick={prevStep}
                                                            >
                                                                Back
                                                            </button>
                                                            <button
                                                                disabled={
                                                                    !values.profileFirstName ||
                                                                    !values.profileLastName ||
                                                                    !/\S+@\S+\.\S{2,}/.test(values.username || '')
                                                                }
                                                                className={classNames([
                                                                    'spot-button',
                                                                    'spot-button--primary',
                                                                    styles['signup-button'],
                                                                ])}
                                                                onClick={nextStep}
                                                            >
                                                                Next
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Step>
                                        <Step>
                                            {({ prevStep }) => {
                                                return (
                                                    <div>
                                                        <ConfirmStep orgNameError={errors?.orgName} />
                                                        <div className={styles['buttons-container']}>
                                                            <button
                                                                className={classNames([
                                                                    'spot-button',
                                                                    'spot-button--secondary',
                                                                    styles['signup-button'],
                                                                ])}
                                                                onClick={prevStep}
                                                            >
                                                                Back
                                                            </button>
                                                            <button
                                                                disabled={invalid}
                                                                className={classNames([
                                                                    'spot-button',
                                                                    'spot-button--primary',
                                                                    styles['signup-button'],
                                                                ])}
                                                                onClick={handleSubmit}
                                                            >
                                                                Create My Organization
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </Step>
                                    </Wizard>
                                )}
                            </Form>
                        </div>
                        <div className="spot-signin__footer">
                            <a href={'javascript:void(0)'} className="spot-link" onClick={signIn}>
                                Sign In
                            </a>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
});
