import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { Notifications } from '../../Notifications';
import { ImportService, LookerDashboardFilters } from '../../../services/ImportService';
import i18n from 'i18next';
import { ItemSheetRequestFields } from './ImportPage';
import { ScheduledItemSaveDetail } from '../items/modals/ScheduledItemSaveModal';
import Papa from 'papaparse';
import { BulkUpdateInvoiceItem, BulkUpdateType, InvoiceItem, InvoiceItemPrices, PriceBreak, ImportRowModel } from '../../../core/models';
import { InvoiceItemService } from '../../../services/InvoiceItemService';
import { defaultEmptyPriceBreakValues, removeEmptyPriceBreaks } from '../../../core/helpers';
import { ItemsGroupByType } from '../../../core/models/organization/settings/enum';
import { SUCCESS } from './ImportStatusTypes';
import { TaskResponse } from 'apps/client/src/core/interfaces/TaskResponse';
import { UploadCsvInfo } from 'apps/client/src/core/interfaces/UploadCsvInfo';
import { ItemSheetRequestIndexes } from './ImportSelectHeaders';
import { PatchDeltaUpdate } from '../../../core/models/datasource/invoiceItems/parts/patchDeltaUpdate';

const MINIMUM_CSV_HEADERS = 3;

@Service()
export class ImportPageStore {
    isLoading = false;

    private _importRows: ImportRowModel[] = [];

    processedFileName: string;

    originalCsvFileName: string;

    row: ItemSheetRequestFields[] = [];

    csvHeader: string[] = [];

    csvRecords: string[][] = [];

    csvFile: File | undefined;

    totalItems: number;

    totalProcessedItems: number;

    get importRows(): ImportRowModel[] {
        return toJS(this._importRows);
    }

    constructor(private readonly importService: ImportService, private readonly invoiceItemService: InvoiceItemService) {
        makeAutoObservable(this);
    }

    get itemsGroupBy(): ItemsGroupByType {
        return this.invoiceItemService.itemsGroupBy;
    }

    async downloadImportMapping() {
        const fileData = await this.importService.downloadImportFile(this.processedFileName);

        const filename = decodeURI(this.processedFileName.replace('json', 'csv'));

        const url = window.URL.createObjectURL(new Blob([fileData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
    }

    async fetchImportData(rows: ItemSheetRequestFields[]): Promise<ImportRowModel[]> {
        this._importRows = [];
        this.isLoading = true;
        this.row = rows;

        try {
            const res = await this.importService.getItemDataForCSV({ rows });
            this._importRows = this.handleNewPrices(res);
        } catch (err) {
            Notifications.error(i18n.t('controlCenter:import.errorFetchingGridData', 'An error occurred fetching grid data'));
            this.dispose();
        }
        this.isLoading = false;
        return this._importRows;
    }

    async sendImportData(row: ItemSheetRequestIndexes): Promise<undefined> {
        if (!this.csvFile) {
            Notifications.error(i18n.t('controlCenter:import.errorFetchingGridData', 'An error occurred fetching grid data'));
            this.dispose();
            return;
        }
        this._importRows = [];
        this.isLoading = true;

        try {
            const summary = await this.importService.getItemDataForCSVV2(row, this.csvFile);
            this.originalCsvFileName = summary.originalFileName;
            this.processedFileName = summary.processedFileName;
            this.totalItems = summary.totalRows;
            this.totalProcessedItems = summary.totalProcessedRows;
            this._importRows = [...(summary?.successRows || []), ...(summary?.errorRows || []), ...(summary?.warningRows || [])];
        } catch (err) {
            Notifications.error(i18n.t('controlCenter:import.errorFetchingGridData', 'An error occurred fetching grid data'));
            this.dispose();
        }

        this.isLoading = false;
        return;
    }

    async fetchDataGridFromDashboard(dashboardId: string, dashboardFilters: LookerDashboardFilters): Promise<ImportRowModel[]> {
        this.isLoading = true;

        try {
            const res = await this.importService.getItemDataForDashboard(dashboardId, dashboardFilters);
            this._importRows = this.handleNewPrices(res);
        } catch (err) {
            Notifications.error(
                i18n.t('controlCenter:gridView.errorFetchingGridDataFromDashboard', 'An error occurred fetching grid data from dashboard'),
            );
            this.dispose();
        }
        this.isLoading = false;
        return this._importRows;
    }

    private handleNewPrices(rows: ImportRowModel[]): ImportRowModel[] {
        return rows.map((row) => {
            const { salesPrice, costPrice, listPrice } = row.configurable;

            row.configurable.salesPrice = salesPrice && Number(salesPrice);
            row.configurable.costPrice = costPrice && Number(costPrice);
            row.configurable.listPrice = listPrice && Number(listPrice);

            return row;
        });
    }

    private filterSuccessItems = (data) => {
        return data.filter((item) => {
            return item?.item?.status?.type === SUCCESS || !item?.item?.status?.type;
        });
    };

    createBulkUpdateFromFile = async (update: ScheduledItemSaveDetail) => {
        try {
            const updateName = update.name || i18n.t('controlCenter:import.defaultUpdateName', 'bulk update');
            const name = `${i18n.t('controlCenter:import.tab', 'Import')}: ${updateName}`;
            await this.invoiceItemService.createBulkUpdate({
                name,
                runAt: update.date,
                sourceFileName: this.processedFileName,
                totalItems: this.totalItems,
                totalProcessedRows: this.totalProcessedItems,
            });
            Notifications.success(i18n.t('controlCenter:import.bulkUpdateSuccess', 'Successfully scheduled bulk items update'));
        } catch (err) {
            Notifications.error(i18n.t('controlCenter:import.errorCreatingBulkUpdate', 'An error occurred scheduling bulk items update'));
            throw err;
        }
    };

    createBulkUpdate = async (update: ScheduledItemSaveDetail): Promise<void> => {
        const filteredRows = this.filterSuccessItems(this._importRows);
        const invoiceItems: InvoiceItem[] = filteredRows.map(({ item, configurable }) => {
            const { salesPrice, costPrice, listPrice } = configurable;

            if (salesPrice) {
                if (!item.prices.priceBreaks?.length) {
                    item.prices.priceBreaks = [new PriceBreak()];
                }

                item.prices.priceBreaks[0].invoicePrice = salesPrice;
            }

            item.prices.costPrice = costPrice || item.prices.costPrice;
            item.prices.listPrice = listPrice || item.prices.listPrice;

            return removeEmptyPriceBreaks(defaultEmptyPriceBreakValues(item));
        });
        const items = this.getPatchUpdate(invoiceItems);

        try {
            const updateName = update.name || i18n.t('controlCenter:import.defaultUpdateName', 'bulk update');
            const name = `${i18n.t('controlCenter:import.tab', 'Import')}: ${updateName}`;
            const taskResponse = await this.invoiceItemService.createBulkUpdate({
                items,
                name,
                runAt: update.date,
                totalItems: this.totalItems,
                totalProcessedRows: this.totalProcessedItems,
            });
            await this.uploadCsvS3Data(taskResponse);
            Notifications.success(i18n.t('controlCenter:import.bulkUpdateSuccess', 'Successfully scheduled bulk items update'));
        } catch (err) {
            Notifications.error(i18n.t('controlCenter:import.errorCreatingBulkUpdate', 'An error occurred scheduling bulk items update'));
            throw err;
        }
    };

    getPatchUpdate(items: InvoiceItem[]): BulkUpdateInvoiceItem[] {
        return items.map((item): BulkUpdateInvoiceItem => {
            const { priceBreaks, costPrice, listPrice } = item.prices;
            const delta: PatchDeltaUpdate = {
                prices: {
                    costPrice,
                    listPrice,
                    priceBreaks: priceBreaks?.[0] && [{ invoicePrice: priceBreaks[0]?.invoicePrice }],
                } as InvoiceItemPrices,
            };

            return {
                provider: item.provider,
                pimsGeneratedId: item.pimsGeneratedId,
                description: item.hospitalDescription,
                update: {
                    type: BulkUpdateType.PATCH,
                    delta,
                },
            };
        });
    }

    async uploadCsvS3Data(taskResponse: TaskResponse): Promise<void> {
        const rows: ItemSheetRequestFields[] = this.row;
        const { id, runAt } = taskResponse || {};
        const name = `${id}${runAt}.csv`;
        try {
            const uploadCsvInfo: UploadCsvInfo = { rows, name };
            await this.importService.getTaskDataForS3(uploadCsvInfo);
        } catch (err) {
            throw err;
        }
    }

    parseInputFile(file: File, onError: () => void) {
        const notifyAndClearInputFile = (): void => {
            Notifications.error(i18n.t('controlCenter:import.errorParsingCSV', `Error parsing CSV, please check your formatting and try again`));
            onError();
        };

        try {
            Papa.parse(file, {
                complete: ({ data }) => {
                    const header = data.shift();
                    if (!header || header.length < MINIMUM_CSV_HEADERS) {
                        notifyAndClearInputFile();
                        return;
                    }

                    this.csvHeader = header;
                    this.csvRecords = data;
                },
                skipEmptyLines: true,
            });
        } catch (err) {
            notifyAndClearInputFile();
        }
    }

    parseInputHeaderOnly(file: File, onError: () => void) {
        const notifyAndClearInputFile = (): void => {
            Notifications.error(i18n.t('controlCenter:import.errorParsingCSV', `Error parsing CSV, please check your formatting and try again`));
            onError();
        };
        try {
            Papa.parse(file, {
                header: false,
                download: true,
                preview: 1,
                step: ({ data }, parser) => {
                    const header = data;
                    if (!header || header.length < MINIMUM_CSV_HEADERS) {
                        notifyAndClearInputFile();
                        return;
                    }
                    this.csvHeader = header;
                    this.csvFile = file;
                    parser.abort();
                },
            });
        } catch (err) {
            notifyAndClearInputFile();
        }
    }

    dispose(): void {
        this._importRows = [];
        this.csvHeader = [];
        this.csvRecords = [];
        this.csvFile = undefined;
    }
}
