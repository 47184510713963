import { Inject, Service } from 'typedi';
import { BaseHttp, Transformers } from '@enterprise/common';
import AppStore from '../store/AppStore';

export class PresetEntity {
    id: string;
    name: string;
    content: any;
}

@Service()
export class PresetService {
    constructor(@Inject('http') private readonly http: BaseHttp, private readonly appStore: AppStore) {}

    get currentUserId(): string {
        return (this.appStore.token.user as any).id;
    }

    getPresets(): Promise<any[]> {
        return this.http
            .get(`/users/:userId/presets`, { pathParams: { userId: this.currentUserId } })
            .then(data => new Transformers.CollectionTransformer(new Transformers.ToTypeTransformer(PresetEntity)).transform(data));
    }

    save(preset: PresetEntity): Promise<any> {
        return this.http
            .put(`/users/:userId/preset`, preset, { pathParams: { userId: this.currentUserId } })
            .then(data => new Transformers.ToTypeTransformer(PresetEntity).transform(data));
    }

    delete(presetId: string): Promise<any> {
        return this.http.delete(`/users/:userId/preset/:presetId`, undefined, { pathParams: { userId: this.currentUserId, presetId } });
    }
}
