import React, { PropsWithChildren, useMemo, useState } from 'react';
import styles from './itemAddPractices.module.scss';
import { observer } from 'mobx-react';
import { SpotButton, SpotModal, SpotSelectBox, SpotSelectOption } from '@enterprise/spot';
import i18n from 'i18next';
import { useStores } from '../../../../hooks';
import { SelectPracticesRegionsGrid } from './selectPracticeRegionsGrid';
import { PracticeInfo, SiteEntityInfo } from '../../../practiceConnections/MyOrganizationPageStore';
import { LabelEntity } from 'apps/client/src/core/entity/LabelEntity';
import { ScheduledItemSaveDetail, ScheduledItemSaveModal } from '../../items/modals/ScheduledItemSaveModal';

export interface ItemAddToPracticesParams {
    targetPractices: PracticeInfo[];
    originPractice: PracticeInfo;
    runAt?: Date;
    name?: string;
}

type ItemAddPracticesProps = PropsWithChildren<{
    title?: string;
    secondaryTitle?: string;
    onSubmit: (props: ItemAddToPracticesParams) => void;
    onClose?: () => void;
    sitesWithoutItems?: string[];
}>;
export const ItemAddPractices = observer(function ItemAddPracticesComponent(props: ItemAddPracticesProps) {
    const {
        domain: { practicesStore, constantsStore },
    } = useStores();

    const { labels = [], practices = [] } = practicesStore;
    const masterSite = constantsStore?.organizationSettings?.masterSite;
    const { title, secondaryTitle, sitesWithoutItems = [], onSubmit, onClose } = props;
    const activePractices = (practices as SiteEntityInfo[]).filter(({ isActive }) => isActive);
    const practicesWithItem = useMemo(() => {
        return activePractices.reduce((agg, practice) => {
            if (!sitesWithoutItems.includes(practice.id)) {
                agg.push(practice);
            }
            return agg;
        }, [] as SiteEntityInfo[]);
    }, [activePractices, sitesWithoutItems]);

    const masterSiteDetails = practicesWithItem.find(({ id }) => id === masterSite?.siteId);
    const [copyPracticeFrom, setCopyPracticeFrom] = useState<PracticeInfo | undefined>(masterSiteDetails);
    const [selectedPractices, setSelectedPractices] = useState<PracticeInfo[]>([]);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onPublish = (scheduleParams: Partial<ScheduledItemSaveDetail> = {}) => {
        setSubmitted(true);
        const { date, name } = scheduleParams;

        if (selectedPractices.length && copyPracticeFrom) {
            onSubmit({ targetPractices: selectedPractices, originPractice: copyPracticeFrom, runAt: date, name });
        }
    };

    const onSchedule = () => {
        setSubmitted(true);

        if (selectedPractices.length && copyPracticeFrom) {
            setShowScheduleModal(true);
        }
    };

    const renderFooter = () => {
        return (
            <div className={styles.footerStyles}>
                <div
                    className={styles.selectPracticesCancelButton}
                    onClick={() => {
                        onClose?.();
                    }}
                >
                    {i18n.t('common:buttons:cancel', 'Cancel')}
                </div>
                <div className={styles.addToPracticesButton}>
                    <SpotButton
                        secondary={true}
                        className={styles.footerButtonStyle}
                        onClick={onSchedule}
                        data-automation-id={'schedulePracticesForUpdateButton'}
                    >
                        <span style={{ whiteSpace: 'nowrap' }}>{i18n.t('controlCenter:itemSelectPractices:schedule', 'Schedule')}</span>
                    </SpotButton>
                    <SpotButton
                        className={styles.footerButtonStyle}
                        isPrimary={true}
                        onClick={() => onPublish()}
                        data-automation-id={'addToPracticesForUpdateButton'}
                    >
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {i18n.t('controlCenter:itemSelectPractices:addToPracticesNow', 'Add to Practices Now')}
                        </span>
                    </SpotButton>
                </div>
            </div>
        );
    };

    // Filter out only practices that need updating.
    const filterPractices = () => {
        const filtered: PracticeInfo[] = [];
        for (const siteId of sitesWithoutItems) {
            const found = activePractices.find(function (practice) {
                return practice.id === siteId;
            });
            if (found) {
                filtered.push(found);
            }
        }
        return filtered;
    };

    // Filter out only regions that have practices in the list.
    const filterRegions = (includedPractices) => {
        const filtered: LabelEntity[] = [];
        for (const region of labels) {
            for (const practiceId of region.practices) {
                const found = includedPractices.find(function (practice) {
                    return practice.rowId === practiceId;
                });
                if (found) {
                    filtered.push(region);
                    break;
                }
            }
        }
        return filtered;
    };

    const onPracticeSelectionChange = (values: PracticeInfo[]) => {
        setSelectedPractices(values);
    };

    return (
        <>
            <SpotModal title={title} secondaryTitle={secondaryTitle} isVisible={true} onHide={onClose} footer={renderFooter}>
                {() => (
                    <>
                        <div className={styles.practicesMainContainer}>
                            <div className={styles.copyItemDropdown}>
                                <label className="spot-form__label">
                                    <h5>{i18n.t('controlCenter:itemSelectPractices:copyItemFrom', 'Copy Item From')}</h5>
                                </label>

                                {submitted && !copyPracticeFrom && (
                                    <div className={styles.selectionError} data-automation-id={'dropDownNoSelectionError'}>
                                        <div className={styles.selectionErrorText}></div>
                                        {i18n.t('controlCenter:itemSelectPractices:selectionRequired', 'Selection Required')}
                                    </div>
                                )}

                                <SpotSelectBox
                                    data-automation-id={'copyFromPracticeDropdown'}
                                    onChange={(e) => {
                                        setCopyPracticeFrom(
                                            activePractices.find((p) => {
                                                return p.id === e.target.value;
                                            }),
                                        );
                                    }}
                                    disabled={false}
                                    value={copyPracticeFrom?.id}
                                    placeholder={'Select a Value'}
                                >
                                    {practicesWithItem.map((practice) => (
                                        <SpotSelectOption key={practice.id} value={practice.id}>
                                            {practice.name}
                                        </SpotSelectOption>
                                    ))}
                                </SpotSelectBox>
                            </div>
                            <hr />
                            <div className={styles.addToPracticesTitle}>
                                <h5>{i18n.t('controlCenter:itemSelectPractices:addToThesePractices', 'Add to These Practices')}</h5>
                            </div>
                            {submitted && !selectedPractices.length && (
                                <div className={styles.selectionError} data-automation-id={'practicesNoSelectionError'}>
                                    <div className={styles.selectionErrorText}></div>
                                    {i18n.t('controlCenter:itemSelectPractices:selectionRequired', 'Selection Required')}
                                </div>
                            )}
                            <SelectPracticesRegionsGrid
                                practices={filterPractices()}
                                regions={filterRegions(filterPractices())}
                                onSelectionChange={onPracticeSelectionChange}
                            />
                        </div>
                    </>
                )}
            </SpotModal>
            {showScheduleModal && (
                <ScheduledItemSaveModal
                    noButton={true}
                    defaultName={secondaryTitle}
                    onSave={onPublish}
                    title={i18n.t('controlCenter:items.scheduleItem', 'Schedule item')}
                    onClose={() => setShowScheduleModal(false)}
                />
            )}
        </>
    );
});
