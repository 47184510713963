import * as React from 'react';
import { Field } from 'react-final-form';
import { SpotCheckbox, SpotFieldError } from '@enterprise/spot';
import TosModal from '../tos/TosModal';
import { Trans } from 'react-i18next';
import i18n from '../../../i18n';

interface ConfirmStepProps {
    orgNameError?: string;
}

export class ConfirmStep extends React.PureComponent<ConfirmStepProps> {
    state = {
        showTos: false,
    };

    toggleTosModal = () => {
        this.setState({ showTos: !this.state.showTos });
    };

    render() {
        return (
            <div>
                <TosModal isVisible={this.state.showTos} onHide={this.toggleTosModal} />
                <div className="spot-typography__heading--level-3">
                    <Trans i18nKey="common:signUp.orgStepTitle">What do you want to call your Organization?</Trans>
                </div>

                <Field name="organizationName">
                    {({ input, meta }) => (
                        <div className="spot-form__field-group">
                            <label className="spot-form__label">
                                <p>
                                    <br />
                                    <Trans i18nKey="common:signUp.orgStepDescription1">Work at a multi-practice organization?</Trans>
                                    <br />
                                    <Trans i18nKey="common:signUp.orgStepDescription2">
                                        Use your Organization's name, you'll be able to name individual practices later
                                    </Trans>
                                </p>
                            </label>
                            <input className="spot-form__input" type="text" {...input} />
                            <SpotFieldError
                                meta={{
                                    error:
                                        (meta.error && i18n.t('validation:isNotEmpty', { field: i18n.t('common:signUp.orgName') })) ||
                                        this.props.orgNameError,
                                    touched: meta.touched,
                                }}
                            />
                        </div>
                    )}
                </Field>
                <Field name={'terms'} type={'checkbox'}>
                    {({ input, meta }) => (
                        <SpotCheckbox {...input}>
                            <span>
                                <Trans i18nKey="tos:agreementCheckbox">I agree to the </Trans>
                            </span>
                            <a onClick={this.toggleTosModal} href={'javascript:void(0)'} className={'spot-link'}>
                                <Trans i18nKey="tos:agreementCheckboxLink">Terms and Conditions</Trans>
                            </a>
                        </SpotCheckbox>
                    )}
                </Field>
            </div>
        );
    }
}
