import React from 'react';
import { observer } from 'mobx-react';
import { ItemsPageStore } from '../ItemsPageStore';
import ConstantsStore, { Loadable } from '../../../../store/ConstantsStore';
import { Errors } from '../../../../core/enums/Errors';
import styles from './manageItem.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { SpotFlyoverLoading } from '@enterprise/spot';
import i18n from '../../../../i18n';
import { ItemsGroupByType } from '../../../../core/models/organization/settings/enum';
import { PageHeader } from '../../../../layout/main/pageHeader';
import { RemoveScroll } from 'react-remove-scroll';
import { StoresContext } from '../../../../contexts';

interface PageProps {}

interface PageState {
    loadingError?: string;
}

@observer
export class ManageItem extends React.Component<PageProps, PageState> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    state: PageState = {
        loadingError: undefined,
    };

    get store(): ItemsPageStore {
        return this.context.domain.itemsPageStore;
    }

    get constantsStore(): ConstantsStore {
        return this.context.domain.constantsStore;
    }

    get routerStore() {
        return this.context.ui.router;
    }

    back = () => {
        this.store.clearItemState();
        this.routerStore.push('/control-center/items');
    };

    private setLoadingError() {
        this.setState({
            loadingError: Errors.DP_REST,
        });
    }

    private catchLoadingError(request: Promise<unknown>) {
        return request.catch(() => {
            this.setLoadingError();
        });
    }

    async componentDidMount() {
        this.store.clearItemState();

        await this.catchLoadingError(this.constantsStore.load([Loadable.Applications]));

        const { id, description, siteId, isSub } = this.routerStore.params;

        if (id || description) {
            const query = { id: String(id), description: String(description), practice: String(siteId), isSub: Boolean(isSub) };
            await this.catchLoadingError(this.store.fetchInvoiceItem(query));
        } else {
            this.setLoadingError();
        }
    }

    render() {
        const { isLoaded, isLoadingItem, invoiceItem } = this.store;
        const { isLoading: isLoadingConstants } = this.constantsStore;
        const isLoading = !isLoaded || isLoadingConstants || isLoadingItem || !invoiceItem;

        return (
            <RemoveScroll className={styles.holder}>
                <PageHeader className={styles.pageHeader} back={this.back} title={i18n.t('controlCenter:manageItem.title', 'Manage Item')} />
                {isLoading && <SpotFlyoverLoading size="large" text={i18n.t('common:loading', 'Loading...')} />}
                {!isLoading && this.renderContent()}
            </RemoveScroll>
        );
    }

    renderContent() {
        const { invoiceItem, itemsGroupBy } = this.store;
        const { loadingError } = this.state;

        return (
            <>
                {loadingError && <span>{this.state.loadingError}</span>}
                {!loadingError && (
                    <Container fluid={true} className={styles.container}>
                        <Row>
                            <Col
                                xs={12}
                                className={classNames('spot-typography__heading--level-1', styles.itemInfo)}
                                data-automation-id="manage-item-info"
                            >
                                {itemsGroupBy === ItemsGroupByType.Id && <span>{invoiceItem?.pimsId}</span>}
                                {itemsGroupBy === ItemsGroupByType.Id && (
                                    <span className={styles.description}>({invoiceItem?.hospitalDescription})</span>
                                )}
                                {itemsGroupBy === ItemsGroupByType.Description && <span>{invoiceItem?.hospitalDescription}</span>}
                            </Col>
                        </Row>
                        <Row className={styles.actionBar}>
                            <Col className={'spot-typography__heading--level-5'}>
                                <span>
                                    <Trans i18nKey="controlCenter:manageItem.itemExistsAtPractices" count={invoiceItem?.sites?.length}>
                                        Item Exists at {invoiceItem?.sites?.length} Practices
                                    </Trans>
                                </span>
                            </Col>
                        </Row>
                    </Container>
                )}
            </>
        );
    }
}
