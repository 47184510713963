import React, { memo, PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react';
import {
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridFilterOperatorType,
    GridSortDirection,
    GridValueGetterParams,
    MaterialDataGrid,
    GridSortModel,
    GridRowData,
    GridRowId,
    useColumnFilterHook,
    GridSelectionModel,
} from '@enterprise/material-data-grid';
import styles from './practiceAccessGrid.module.scss';
import i18n from '../../../i18n';
import classNames from 'classnames';
import { usePracticeAccessGridSize } from './usePracticeAccessGridSize.hook';
import { SpotSearchbar } from '@enterprise/spot';
import { PracticeInfo, PracticeStatus, SiteEntityInfo } from '../../practiceConnections/MyOrganizationPageStore';
import { PracticeAccessType } from '@enterprise/common';
import { LabelEntity } from '../../../core/entity/LabelEntity';
import { isEqual } from 'lodash';

export interface SelectedPracticeAccess {
    practices?: PracticeInfo[];
    regions?: LabelEntity[];
}

enum GridColumns {
    PracticeName = 'practiceName',
    RegionName = 'regionName',
}

enum FilTerType {
    Name = 1000,
}

const getPracticeName = ({ name, type }: PracticeInfo): string => {
    if (!name && type === PracticeStatus.Pending) {
        return i18n.t('myOrganization:defaultPendingPracticeName', 'Pending Practice Connection');
    }

    return String(name);
};

const NameHeader = (params: GridColumnHeaderParams & { columnType: GridColumns }): ReactElement => {
    const { columnType } = params;
    const { filterValue, onFilterChange } = useColumnFilterHook({
        id: FilTerType.Name,
        columnField: columnType,
        operator: GridFilterOperatorType.Contains,
    });

    useEffect(() => {
        onFilterChange('');
    }, [columnType]);

    return (
        <div className={styles.headerContainerName}>
            <div
                className={classNames('spot-typography__text--body', styles.headerCell)}
                onClick={(event) => {
                    // cancel default sorting action on edit fields click
                    event.stopPropagation();
                }}
            >
                <SpotSearchbar className={styles.searchBar} onChange={onFilterChange} value={filterValue} secondary={true} isSelfControlled={false} />
            </div>
        </div>
    );
};

const PracticeNameHeader = (params: GridColumnHeaderParams): ReactElement => {
    return <NameHeader {...params} columnType={GridColumns.PracticeName} />;
};

const RegionNameHeader = (params: GridColumnHeaderParams): ReactElement => {
    return <NameHeader {...params} columnType={GridColumns.RegionName} />;
};

const PracticeNameCell = (params: GridCellParams): ReactElement => {
    const practice = params.row as PracticeInfo;
    const { pimsName, labels } = params.row as SiteEntityInfo;
    const name = getPracticeName(practice);
    const labelNames = labels?.map(({ name }) => name);

    return (
        <div className={styles.cellContainer} data-automation-id="practices-access-grid-name-cell">
            <div className={styles.cellName}>
                <div className={classNames('spot-typography__text--body', styles.name)} data-automation-id="users-grid-cell-name">
                    {name}
                </div>
                <div className={styles.info}>
                    <div className={classNames('spot-typography__text--secondary', styles.text)} data-automation-id="users-grid-cell-name">
                        {pimsName}
                    </div>
                    <div className={classNames('spot-typography__text--secondary', styles.text)} data-automation-id="users-grid-cell-name">
                        {labelNames?.join(', ')}
                    </div>
                </div>
            </div>
        </div>
    );
};

const RegionsNameCell = (params: GridCellParams): ReactElement => {
    const { name, practices } = params.row as LabelEntity;

    return (
        <div className={styles.cellContainer} data-automation-id="practices-access-grid-name-cell">
            <div className={styles.cellName}>
                <div className={classNames('spot-typography__text--body', styles.name)} data-automation-id="users-grid-cell-name">
                    {name} ({practices?.length || 0})
                </div>
            </div>
        </div>
    );
};

export type UsersGridProps = PropsWithChildren<{
    practices?: PracticeInfo[];
    regions?: LabelEntity[];
    className?: string;
    isLoading?: boolean;
    type: PracticeAccessType.Region | PracticeAccessType.Practice;
    selection?: SelectedPracticeAccess;
    onSelectionChange?: (selection: SelectedPracticeAccess) => void;
    noRowsOverlay?: () => ReactElement;
}>;

export const PracticeAccessGrid = memo(function PracticeAccessGrid(props: UsersGridProps) {
    const { practices = [], regions = [], className, isLoading, type, selection, noRowsOverlay, onSelectionChange } = props;
    const { headerHeight, rowHeight } = usePracticeAccessGridSize(type);

    // update rows is required to do via state because grid should refresh the whole render area asynchronously on type change
    // (required to re-apply dynamic row height - practices/regions row height switch in mobile view mode)
    const getRows = (): (PracticeInfo | LabelEntity)[] => (type === PracticeAccessType.Region ? regions : practices);
    const [rows, setRows] = useState<(PracticeInfo | LabelEntity)[]>(getRows());
    useEffect(() => {
        setRows(getRows());
    }, [type, regions, practices]);

    const columns = useMemo<GridColDef[]>(() => {
        return [
            {
                field: GridColumns.PracticeName,
                hide: type !== PracticeAccessType.Practice,
                flex: 1,
                renderHeader: PracticeNameHeader,
                renderCell: PracticeNameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    return getPracticeName(params.row as PracticeInfo);
                },
            },
            {
                field: GridColumns.RegionName,
                hide: type !== PracticeAccessType.Region,
                flex: 1,
                renderHeader: RegionNameHeader,
                renderCell: RegionsNameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const { name } = params.row as LabelEntity;
                    return name;
                },
            },
        ];
    }, [type]);

    const defaultSortModel = useMemo<GridSortModel>(
        () => [
            {
                field: type === PracticeAccessType.Practice ? GridColumns.PracticeName : GridColumns.RegionName,
                sort: 'asc' as GridSortDirection,
            },
        ],
        [type],
    );

    const renderNoRows = (title: string) => {
        return (
            <div className={styles.noRows}>
                <span>{title}</span>
            </div>
        );
    };

    const getRowId = (row: GridRowData): string => {
        const item = row as PracticeInfo | LabelEntity;
        return `${item.id}-${item['accountId']}`;
    };

    const selectionModel = useMemo<GridRowId[] | undefined>(() => {
        return type === PracticeAccessType.Practice
            ? selection?.practices?.map((practice) => getRowId(practice))
            : selection?.regions?.map((region) => getRowId(region));
    }, [type, selection]);

    const onSelectionModelChange = (model: GridSelectionModel): void => {
        if (isEqual(selectionModel, model)) {
            return;
        }

        const selection: SelectedPracticeAccess = {
            regions: regions.filter((region) => model.includes(getRowId(region))),
            practices: practices.filter((practice) => model.includes(getRowId(practice))),
        };

        onSelectionChange?.(selection);
    };

    return (
        <>
            {(Boolean(rows.length) || !noRowsOverlay) && (
                <MaterialDataGrid
                    className={classNames(styles.holder, className)}
                    loading={isLoading}
                    columns={columns}
                    rows={rows}
                    headerHeight={headerHeight}
                    rowHeight={rowHeight}
                    getRowClassName={() => styles.row}
                    autoHeight={true}
                    selectionModel={selectionModel}
                    sortModel={defaultSortModel}
                    radioboxSelection={true}
                    onSelectionModelChange={onSelectionModelChange}
                    noResultsTitle={renderNoRows(i18n.t('myOrganization:noResultsFound', 'No Results Found'))}
                    getRowId={getRowId}
                />
            )}
            {!rows.length && noRowsOverlay?.()}
        </>
    );
});
