import React from 'react';
import { SpotIconType, SpotSvg } from '@enterprise/spot';
import styles from './notice.module.scss';
import classNames from 'classnames';

interface NoticeProps {
    icon?: NoticeIcon;
    text: string;
    className?: string;
    'data-automation-id'?: string;
}

export enum NoticeIcon {
    Bell = SpotIconType.Bell,
    InProgress = SpotIconType.InProgress,
}

export const Notice = (props: NoticeProps) => {
    const { text, icon, className, 'data-automation-id': automationId } = props;
    return (
        <div className={classNames(styles.noticeContainer, className)} data-automation-id={automationId}>
            {icon && <SpotSvg className={styles.noticeIcon} icon={String(icon)} />}
            <div className={styles.noticeText}>{text}</div>
        </div>
    );
};
