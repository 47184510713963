import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { SpotHorizontalRule, SpotButton, SpotLoading, SpotDropdown } from '@enterprise/spot';
import classNames from 'classnames';
import { RouterPaths } from '../../../router/RouterPaths';
import queryString from 'query-string';
import { Trans } from 'react-i18next';
import { environment } from '../../../environment/environment';
import i18n from 'i18next';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import styles from './dashboardsPage.module.scss';
import moment from 'moment';
import { useParams } from 'react-router';

interface LookerDashboardInfo {
    id?: number;
    filters?: string;
    inProgress?: boolean;
    title?: string;
}

const INVOICE_ITEMS_UPDATE_DASHBOARD_ID = environment.LOOKER.invoiceItemsUpdateDashboardId;

export enum DashboardState {
    PageChanged = 'page:changed',
    Start = 'dashboard:run:start',
    TileStart = 'dashboard:tile:start',
    LookStart = 'look:run:start',
    Complete = 'dashboard:run:complete',
    TileComplete = 'dashboard:tile:complete',
    LookComplete = 'look:run:complete',
    ExploreReady = 'explore:run:complete',
    PagePropertiesChanged = 'page:properties:changed',
    DashboardLoaded = 'dashboard:loaded',
    LookLoaded = 'look:loaded',
}

export enum DashboardType {
    KeyMetricsOverview = 'Key Metrics Overview',
}

const DashboardStartStates = [DashboardState.Start, DashboardState.TileStart, DashboardState.LookStart];
const DashboardCompleteStates = [DashboardState.Complete, DashboardState.TileComplete, DashboardState.LookComplete];

export const DashboardsPage = observer(function DashboardsPage() {
    const {
        domain: { analyticsPageStore },
        ui: { router },
    } = useStores();

    const {
        embedLinks = [],
        isImportFeatureEnabled,
        isEmbedLinksLoading,
        connectedPractices,
        availableDatePeriods,
        activeDashboard = {},
        updateActiveDashboard,
    } = analyticsPageStore;

    const { dashboardId, lookId } = useParams<{ dashboardId: string; lookId: string }>();
    const [isIframeLoading, setIsIframeLoading] = useState(true);
    const [currentDashboard, setCurrentDashboard] = useState<LookerDashboardInfo>({});
    const [isDashboard, setIsDashboard] = useState<boolean>(true);
    const [dashboardTitle, setDashboardTitle] = useState<string>('');
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        if (dashboardId) {
            analyticsPageStore.fetchDeepLinkForDashboardId(dashboardId);
        } else if (lookId) {
            analyticsPageStore.fetchDeepLinkForLookId(lookId);
        }
        const { practice, timeFrame } = activeDashboard;
        void analyticsPageStore.fetchEmbedLinks({ practiceName: practice?.name, timeFrame }, !dashboardId && !lookId);
    }, []);

    const isMessageFromLookerIFrame = (event: MessageEvent) => {
        const { source = '', origin = '' } = event;
        const host = activeDashboard.embedLink?.host || '';
        const lookerFrame = document.getElementById('lookerFrame') as any;
        return source === lookerFrame?.contentWindow && origin.includes(host);
    };

    const isScheduleBatchUpdateEnabled = (dashboardId?: number): boolean => {
        return dashboardId === INVOICE_ITEMS_UPDATE_DASHBOARD_ID && isImportFeatureEnabled;
    };

    const isDashboardType = (dashboardName: string = '', type: DashboardType): boolean => {
        return dashboardName.toLowerCase().startsWith(type.toLowerCase());
    };

    const setDashboardState = (dashboard, inProgress?: boolean) => {
        setCurrentDashboard({
            inProgress,
            id: dashboard && dashboard.id,
            filters: dashboard && JSON.stringify(dashboard.dashboard_filters),
            title: dashboard?.title,
        });
    };

    const getLookerIdInUrl = () => {
        const url = window.location.href;
        if (url.includes('dashboards/')) {
            return url.substring(url.indexOf('dashboards/') + 'dashboards/'.length);
        } else if (url.includes('looks/')) {
            return url.substring(url.indexOf('looks/') + 'looks/'.length);
        }
        return undefined;
    };

    const getTitleFromLookerEventData = (data) => {
        if (data?.dashboard) {
            return data?.dashbaord?.title;
        } else if (data?.look) {
            return data?.look?.title;
        } else {
            return undefined;
        }
    };

    const getIdFromLookerEventData = (data) => {
        const url = data?.page?.url;
        if (data?.page?.type === 'dashboard') {
            return url.substring(url.indexOf('dashboards/') + 'dashboards/'.length, url.indexOf('?'));
        } else if (data?.page?.type === 'look') {
            return url.substring(url.indexOf('looks/') + 'looks/'.length, url.indexOf('?'));
        } else {
            return undefined;
        }
    };

    const handleLookerMessage = (event: MessageEvent): void => {
        if (!isMessageFromLookerIFrame(event)) {
            return;
        }
        const { type, dashboard, height } = JSON.parse(event.data);
        const data = JSON.parse(event.data);
        if (type === DashboardState.PageChanged) {
            const id = getIdFromLookerEventData(data);
            const currentId = getLookerIdInUrl();
            if (data.page.type === 'dashboard' && id !== currentId) {
                window.history.pushState('none', 'Title', `/analytics/dashboards/${id}`);
            } else if (data.page.type === 'look' && id !== currentId) {
                window.history.pushState('none', 'Title', `/analytics/looks/${id}`);
            }
        }
        const currentId = getLookerIdInUrl();
        if (type === DashboardState.DashboardLoaded) {
            const dash = data.dashboard;
            const id = getTitleFromLookerEventData(data);
            if (id !== currentId && !Number(currentId)) {
                analyticsPageStore.fetchDeepLinkForDashboardId(dash.title);
            }
            setDashboardTitle(dash.title);
        }

        if (DashboardStartStates.includes(type)) {
            setDashboardState(dashboard, true);
        }
        if (DashboardCompleteStates.includes(type) || type === DashboardState.PageChanged) {
            setDashboardState(dashboard, false);
        }
        if (type === DashboardState.PagePropertiesChanged) {
            setHeight(height);
            setIsDashboard(true);
        }
        if (type === DashboardState.LookComplete || type === DashboardState.ExploreReady) {
            setIsDashboard(false);
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleLookerMessage);

        return () => {
            window.removeEventListener('message', handleLookerMessage);
        };
    }, []);

    const dashboardElements = embedLinks.map(({ name }) => ({ id: name, value: name, selected: name === activeDashboard.embedLink?.name }));
    const switchDashboard = async (dashboardName: string): Promise<void> => {
        setDashboardTitle('');
        const embedLink = embedLinks.find(({ name }) => name === dashboardName);
        await updateActiveDashboard({ ...activeDashboard, embedLink });
    };

    const practiceElements = useMemo(() => {
        return connectedPractices?.map(({ name }) => ({ id: name, value: name, selected: name === activeDashboard.practice?.name })) || [];
    }, [connectedPractices, activeDashboard.practice]);

    const changePractice = async (practiceName: string): Promise<void> => {
        if (practiceName !== activeDashboard.practice?.name) {
            const practice = connectedPractices?.find(({ name }) => name === practiceName);
            await updateActiveDashboard({ ...activeDashboard, practice });
        }
    };

    const periodElements = useMemo(() => {
        return (
            availableDatePeriods?.map((date, index) => {
                const value = moment(date).format('MMMM YYYY');
                return { date, id: index, value, selected: date === activeDashboard.timeFrame };
            }) || []
        );
    }, [availableDatePeriods, activeDashboard.timeFrame]);

    const selectedDatePeriod = Boolean(activeDashboard.timeFrame) && moment(activeDashboard.timeFrame).format('MMM YYYY');
    const changeTimeFrame = async (timeFrame?: Date): Promise<void> => {
        if (timeFrame !== activeDashboard.timeFrame) {
            await updateActiveDashboard({ ...activeDashboard, timeFrame });
        }
    };

    useEffect(() => {
        setIsIframeLoading(true);
    }, [activeDashboard.embedLink?.url]);

    const renderIFrame = (): ReactElement => {
        return (
            <>
                {(isIframeLoading || isEmbedLinksLoading) && (
                    <div className={styles.spinner}>
                        <SpotLoading text="Loading..." />
                    </div>
                )}
                {!isEmbedLinksLoading && activeDashboard.embedLink?.url && (
                    <iframe
                        id="lookerFrame"
                        src={activeDashboard.embedLink?.url}
                        height={height}
                        data-automation-id="looker-frame"
                        className={classNames([styles.lookerFrame], {
                            [styles.looker]: !isDashboard,
                            [styles.lookerHidden]: isIframeLoading,
                        })}
                        onLoad={() => {
                            setIsIframeLoading(false);
                        }}
                    />
                )}
            </>
        );
    };

    const renderFilters = (): ReactElement => {
        return (
            <div className={styles.filterBlock}>
                {practiceElements?.length > 1 && (
                    <SpotDropdown
                        title={
                            <div className={styles.filterDropdownItem}>
                                <span>{activeDashboard.practice?.name || i18n.t('common:selectValue', 'Select a value')}</span>
                            </div>
                        }
                        elements={practiceElements}
                        onChange={(value: string) => changePractice(value)}
                        className={styles.filterPracticesDropdown}
                        titleIconClassName={styles.filterDropdownIcon}
                        wrapText={true}
                        data-automation-id="filter-practices-dropdown"
                    />
                )}
                <SpotDropdown
                    title={<span className={styles.filterDropdownItem}>{selectedDatePeriod || i18n.t('common:selectValue', 'Select a value')}</span>}
                    elements={periodElements}
                    onChange={(value: number) => {
                        const timeFrame = periodElements.find(({ id }) => id === value)?.date;
                        return changeTimeFrame(timeFrame);
                    }}
                    className={styles.timeframeDropdown}
                    titleIconClassName={styles.filterDropdownIcon}
                    wrapText={false}
                    data-automation-id="filter-timeframe-dropdown"
                />
            </div>
        );
    };

    const renderScheduleUpdateButton = (): ReactElement => {
        const redirectToGridView = () => {
            const { id, filters } = currentDashboard;
            const query = { dashboardId: id, dashboardFilters: filters };
            const encodedQuery = queryString.stringify(query, { encode: true });
            router.push(`${RouterPaths.ControlCenterPages.Import}?${encodedQuery}`);
        };

        return (
            <SpotButton isPrimary={true} onClick={redirectToGridView} disabled={currentDashboard.inProgress} className={styles.scheduleButton}>
                <Trans i18nKey="common:dashboard.scheduleBatchUpdate">Schedule Batch Update</Trans>
            </SpotButton>
        );
    };

    return (
        <div className={styles.holder} data-automation-id="looker-container">
            {(!isIframeLoading || activeDashboard.embedLink?.name) && (
                <>
                    <Row className={styles.controlPanel}>
                        <Col>
                            <SpotDropdown
                                onChange={(name) => switchDashboard(name)}
                                elements={dashboardElements}
                                title={
                                    <span data-automation-id="looker-dashboard-dropdown-title">
                                        {dashboardTitle || activeDashboard.embedLink?.name || i18n.t('common:selectValue', 'Select a value')}
                                    </span>
                                }
                                className={styles.dropdown}
                                titleIconClassName={styles.dashboardDropdownIcon}
                                wrapText={true}
                                data-automation-id="looker-dashboard-dropdown"
                            />
                        </Col>

                        <Col className={styles.controlBlock}>
                            {isDashboardType(currentDashboard.title, DashboardType.KeyMetricsOverview) && renderFilters()}
                            {isScheduleBatchUpdateEnabled(currentDashboard.id) && renderScheduleUpdateButton()}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SpotHorizontalRule />
                        </Col>
                    </Row>
                </>
            )}

            <Row>
                <Col xs="12">{renderIFrame()}</Col>
            </Row>
        </div>
    );
});
