import * as React from 'react';
import { ComingSoon } from './ComingSoon';
import { FeatureToggle } from './FeatureToggle';
interface FeatureOrComingSoonProps {
    name: string;
    strict?: boolean;
    children?: React.ReactChild;
}

export class FeatureOrComingSoon extends React.Component<FeatureOrComingSoonProps> {
    render() {
        const { children, ...rest } = this.props;

        return (
            <FeatureToggle {...rest} fallback={ComingSoon}>
                {children}
            </FeatureToggle>
        );
    }
}
