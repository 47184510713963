import React, { memo } from 'react';
import { ToastType, TypeOptions } from 'react-toastify';
import styles from './systemMessageToast.module.scss';
import classNames from 'classnames';
import { SpotSvg } from '@enterprise/spot';

interface Props {
    message: string;
    type: TypeOptions;
    subMessage?: string;
}

export const SystemToast = memo(function SystemToast(props: Props) {
    const { message, subMessage, type } = props;
    const { messageClass, containerClass, icon } = getTypeParams(type);
    return (
        <div data-automaton-id="system-toast" className={classNames(messageClass, containerClass)}>
            <div className={classNames('message--content', styles.messageContent)}>
                <div className={styles.messageText}>
                    <div className={classNames('message--body', styles.messageHeader)}>{message}</div>
                    {Boolean(subMessage) && <div className="spot-typography__text--secondary">{subMessage}</div>}
                </div>
                {Boolean(!subMessage) && <SpotSvg icon="cancel" className={classNames('spot-icon', styles.closeButton)} />}
            </div>
            <div className={classNames('icon-tab', styles.icon)}>
                <svg className="spot-icon" aria-labelledby="title">
                    <title>patient</title>
                    <use href={icon} />
                </svg>
            </div>
        </div>
    );
});

function getTypeParams(type: TypeOptions): { messageClass: string; icon: string; containerClass: string } {
    if (type === ToastType.SUCCESS) {
        return {
            icon: '/assets/spot/icons/spot_icons.svg#checkmark',
            messageClass: 'spot-message priority-high--success',
            containerClass: styles.messageSuccessContainer,
        };
    }

    return {
        icon: '/assets/spot/icons/spot_icons.svg#alert-notification',
        messageClass: 'spot-message priority-high--danger',
        containerClass: styles.messageErrorContainer,
    };
}
