import React, { useEffect } from 'react';
import { FullItemFormType } from '../../formType/FullItemFormType';
import { ItemsPageStore } from '../../ItemsPageStore';
import { InvoiceItemClassification, InvoiceItemDifference, InvoiceItemWithVariantDetail } from '../../../../../core/models';
import { InvoiceItemEditType } from '../../InvoiceItemEditType.enum';
import {
    SpotCheckboxGroup,
    SpotFieldError,
    SpotRadio,
    SpotRadioGroup,
    SpotSelectBox,
    SpotSelectOption,
    SpotTextarea,
    SpotTextInput,
    SpotToggle,
} from '@enterprise/spot';
import { Field } from 'react-final-form';
import { Trans } from 'react-i18next';
import {
    AnalysisGroupType,
    LookupAnalysisGroup,
    LookupClassification,
    LookupSubClassification,
    LookupSupplier,
    LookupTax,
    LookupUnitsOfMeasure,
} from '../../../../../core/models/datasource/lookups';
import styles from './animanaControls.module.scss';
import { SubproductControls } from '../subproductControls';
import { formatPrice, isURL, isWithinNegativeRange } from '../../../../../core/helpers';
import { invoiceItemPriceLimits } from '../../invoiceItemPriceLimits.constants';
import { observer } from 'mobx-react';
import { useField, useForm, useFormState } from '@enterprise/common';
import classNames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import i18n from 'i18next';

export interface AnimanaControlsProps {
    form: FullItemFormType;
    store: ItemsPageStore;
    data: InvoiceItemDifference;
    patchData: (data: InvoiceItemDifference) => void;
    activeValueName: 'master' | string;
    disabled: boolean;
    actionType?: InvoiceItemEditType;
}

const { maxPrice, minNegativePrice, maxDecimalPlaces } = invoiceItemPriceLimits;

export const AnimanaControls = observer(function AnimanaControls(props: AnimanaControlsProps) {
    const requiredIndicator = '* ';
    const { store, disabled, data, patchData, activeValueName, actionType } = props;
    const form = useForm();
    const { values } = useFormState<InvoiceItemWithVariantDetail>();
    const { classification, itemParent } = values;
    const isSubproduct = Boolean(itemParent?.pimsGeneratedId);
    const isActive = Boolean(data?.master);
    const isNotAddingItem = actionType !== InvoiceItemEditType.New;
    const classifications: LookupClassification[] = store.getLookupResults(store.classifications, values.provider?.siteId);
    const unitsOfMeasure: LookupUnitsOfMeasure[] = store.getLookupResults(store.unitsOfMeasure, values.provider?.siteId);
    const taxes: LookupTax[] = store.getLookupResults(store.taxes, values.provider?.siteId);
    const suppliers: LookupSupplier[] = store.getLookupResults(store.suppliers, values.provider?.siteId);
    const analysisGroups = store
        .getLookupResults(store.analysisGroups, values.provider?.siteId)
        .reduce((agg, item) => agg.set(item.type, [...(agg.get(item.type) || []), item]), new Map<AnalysisGroupType, LookupAnalysisGroup[]>());
    const classField = useField<InvoiceItemClassification>('classification');
    const classMatchedById = classifications.find(({ pimsGeneratedId }) => pimsGeneratedId && pimsGeneratedId === classification?.pimsGeneratedId);
    const classMatchedByDesc = classifications.find(({ description }) => description === classification?.description);

    useEffect(() => {
        if (!classMatchedById && classMatchedByDesc) {
            form.change('classification', classMatchedByDesc);
        }
    }, [classMatchedById, classMatchedByDesc]);

    const getClassification = <T extends LookupClassification | LookupSubClassification>(
        classifications: T[] = [],
        pimsGeneratedId: string | null | undefined,
    ): T | undefined => {
        return classifications.find((classification) => classification.pimsGeneratedId === pimsGeneratedId);
    };

    const onClassificationChange = (pimsGeneratedId: string) => {
        if (!pimsGeneratedId) {
            classField.input.onChange(undefined);
            return;
        }

        const { name, description, pimsId } = getClassification(classifications, pimsGeneratedId) || {};
        classField.input.onChange({ pimsGeneratedId, pimsId, name, description });
        form.change('subClassification', undefined);
    };

    return (
        <Row className={styles.container}>
            <Col xs={8} className={styles.leftPanel}>
                <Row>
                    <Col xs={12} xl={10}>
                        <fieldset className={styles.block}>
                            <Row>
                                <Col xs={12} className={classNames('spot-form__field-group spot-form__field-group--horizontal', styles.formGroup)}>
                                    <div className={styles.halfWidth}>
                                        <SpotRadioGroup>
                                            <Field name="isActive" type="radio" value={true}>
                                                {({ input }) => <SpotRadio {...input}>Active</SpotRadio>}
                                            </Field>
                                            <Field name="isActive" type="radio" value={false}>
                                                {({ input }) => <SpotRadio {...input}>Inactive</SpotRadio>}
                                            </Field>
                                        </SpotRadioGroup>
                                        {!values.isActive && (
                                            <span className="spot-typography__text--secondary automation-field-info-inactiveWarning" role="alert">
                                                <Trans i18nKey="controlCenter:inactiveWarning">
                                                    Inactive items cannot be added to an invoice and are only visible in Animana when searching for
                                                    inactive items. Any stock, including batches for all locations that may have been associated with
                                                    that product, will be deleted. Price groups associated with the product and withdrawal times will
                                                    also be removed.
                                                </Trans>
                                            </span>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col xs={5}>
                                    <Field name="clientDescription">
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.invoiceText">Invoice Text</Trans>
                                                </label>
                                                <SpotTextInput {...input} error={meta} placeholder="" />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                                <Col xs={7}>
                                    <div>
                                        <label className="spot-form__label">
                                            {requiredIndicator}
                                            <Trans i18nKey="controlCenter:invoiceFields.productGroup">Product Group</Trans>
                                        </label>
                                        <SpotSelectBox
                                            data-automation-id={'product-group'}
                                            value={classField.input.value?.pimsGeneratedId}
                                            onChange={(event) => onClassificationChange(event.target.value)}
                                            error={{ ...classField.meta, error: classField.meta.error?.pimsGeneratedId }}
                                        >
                                            {classifications.map((classification) => (
                                                <SpotSelectOption key={classification.pimsGeneratedId} value={classification.pimsGeneratedId}>
                                                    {classification.name}
                                                </SpotSelectOption>
                                            ))}
                                        </SpotSelectBox>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col xs={6}>
                                    <Field
                                        name={'prices.priceBreaks[0].invoicePrice'}
                                        format={formatPrice(maxDecimalPlaces)}
                                        formatOnBlur={true}
                                        validate={isWithinNegativeRange(minNegativePrice, maxPrice)}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    {requiredIndicator}
                                                    <Trans i18nKey="controlCenter:invoiceFields.salesPrice">Sales Price</Trans>
                                                </label>
                                                <SpotTextInput {...input} />
                                                <SpotFieldError meta={meta} />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                                <Col xs={6}>
                                    <Field name="animanaSpecificFields.vatCode">
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.vat">VAT</Trans>
                                                </label>
                                                <SpotSelectBox {...input} error={meta}>
                                                    {taxes.map((unit) => (
                                                        <SpotSelectOption key={unit.id} value={unit.id}>
                                                            {unit.description}
                                                        </SpotSelectOption>
                                                    ))}
                                                </SpotSelectBox>
                                                <SpotFieldError meta={meta} />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col xs={5} lg={6} xl={7}>
                                    <Field name="typeDetails.unitOfMeasure.pimsGeneratedId" parse={(value) => value}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.salesUnit">Sales Unit</Trans>
                                                </label>
                                                <SpotSelectBox {...input} error={meta} disabled={isSubproduct}>
                                                    {unitsOfMeasure.map((unit) => (
                                                        <SpotSelectOption key={unit.id} value={unit.id}>
                                                            {unit.description}
                                                        </SpotSelectOption>
                                                    ))}
                                                </SpotSelectBox>
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                                <span className={styles.fieldPrefix}>*</span>
                                <Col xs={3} xl={2}>
                                    {!isSubproduct && (
                                        <Field name="animanaSpecificFields.packageFactor">
                                            {({ input, meta }) => {
                                                if (values?.animanaSpecificFields?.packageFactor) {
                                                    values.animanaSpecificFields.packageFactor = values?.typeDetails?.unitOfMeasure?.pimsGeneratedId
                                                        ? values?.animanaSpecificFields?.packageFactor
                                                        : undefined;
                                                }
                                                const packageFactorValue = !meta.active
                                                    ? values?.animanaSpecificFields?.packageFactor ||
                                                      (values?.typeDetails?.unitOfMeasure?.pimsGeneratedId ? '1.0' : '')
                                                    : false;
                                                return (
                                                    <div>
                                                        <SpotTextInput
                                                            {...input}
                                                            error={meta}
                                                            placeholder=""
                                                            value={
                                                                values?.typeDetails?.unitOfMeasure?.pimsGeneratedId
                                                                    ? packageFactorValue || input.value
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    )}
                                    {isSubproduct && (
                                        <Field name="animanaSpecificFields.orderFactor">
                                            {({ input, meta }) => {
                                                if (values?.animanaSpecificFields?.orderFactor) {
                                                    values.animanaSpecificFields.orderFactor = values?.typeDetails?.unitOfMeasure?.pimsGeneratedId
                                                        ? values?.animanaSpecificFields?.orderFactor
                                                        : undefined;
                                                }
                                                const packageFactorValue = !meta.active
                                                    ? values?.animanaSpecificFields?.orderFactor ||
                                                      (values?.typeDetails?.unitOfMeasure?.pimsGeneratedId ? '1.0' : '')
                                                    : false;
                                                return (
                                                    <div>
                                                        <SpotTextInput
                                                            {...input}
                                                            error={meta}
                                                            disabled={true}
                                                            placeholder=""
                                                            value={
                                                                values?.typeDetails?.unitOfMeasure?.pimsGeneratedId
                                                                    ? packageFactorValue || input.value
                                                                    : ''
                                                            }
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    )}
                                </Col>
                                {!isSubproduct && <span className={styles.fieldPrefix}>= package unit</span>}
                                {isSubproduct && (
                                    <span className={styles.fieldPrefix}>
                                        = {unitsOfMeasure?.find(({ id }) => String(id) === values?.animanaSpecificFields?.orderUnit)?.description}
                                    </span>
                                )}
                            </Row>
                            {!isSubproduct && (
                                <Row className="spot-form__field-group">
                                    <Col xs={5} lg={6} xl={7}>
                                        <Field name="animanaSpecificFields.packageUnit" parse={(value) => value}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <label className="spot-form__label">
                                                        <Trans i18nKey="controlCenter:invoiceFields.packageUnit">Package Unit</Trans>
                                                    </label>
                                                    <SpotSelectBox {...input} error={meta}>
                                                        {unitsOfMeasure.map((unit) => (
                                                            <SpotSelectOption key={unit.id} value={unit.id}>
                                                                {unit.description}
                                                            </SpotSelectOption>
                                                        ))}
                                                    </SpotSelectBox>
                                                </div>
                                            )}
                                        </Field>
                                    </Col>
                                    <span className={styles.fieldPrefix}>*</span>
                                    <Col xs={3} xl={2}>
                                        <Field name="animanaSpecificFields.orderFactor">
                                            {({ input, meta }) => {
                                                if (values?.animanaSpecificFields?.orderFactor) {
                                                    values.animanaSpecificFields.orderFactor = values?.animanaSpecificFields?.packageUnit
                                                        ? values.animanaSpecificFields.orderFactor
                                                        : undefined;
                                                }
                                                const orderFactorValue = !meta.active
                                                    ? values?.animanaSpecificFields?.orderFactor ||
                                                      (values?.animanaSpecificFields?.packageUnit ? '1.0' : '')
                                                    : false;
                                                return (
                                                    <div>
                                                        <SpotTextInput
                                                            {...input}
                                                            error={meta}
                                                            placeholder=""
                                                            value={values?.animanaSpecificFields?.packageUnit ? orderFactorValue || input.value : ''}
                                                        />
                                                    </div>
                                                );
                                            }}
                                        </Field>
                                    </Col>
                                    <span className={styles.fieldPrefix}>= order unit</span>
                                </Row>
                            )}
                            {!isSubproduct && (
                                <Row className="spot-form__field-group">
                                    <Col xs={5} lg={6} xl={7}>
                                        <Field name="animanaSpecificFields.orderUnit" parse={(value) => value}>
                                            {({ input, meta }) => (
                                                <div>
                                                    <label className="spot-form__label">
                                                        <Trans i18nKey="controlCenter:invoiceFields.orderUnit">Order Unit</Trans>
                                                    </label>
                                                    <SpotSelectBox {...input} error={meta}>
                                                        {unitsOfMeasure.map((unit) => (
                                                            <SpotSelectOption key={unit.id} value={unit.id}>
                                                                {unit.description}
                                                            </SpotSelectOption>
                                                        ))}
                                                    </SpotSelectBox>
                                                </div>
                                            )}
                                        </Field>
                                    </Col>
                                </Row>
                            )}
                        </fieldset>

                        <fieldset className={styles.block}>
                            <Row className="spot-form__field-group">
                                <Col xs={10}>
                                    <Field
                                        name="animanaSpecificFields.productAnalysisGroup1Name"
                                        parse={(value) => (value === undefined || value === '' ? null : value)}
                                        format={(value?: string | null) => {
                                            const group = analysisGroups
                                                .get(AnalysisGroupType.Group1)
                                                ?.find(({ name }) => name.toUpperCase() === value?.toUpperCase());
                                            return group?.name;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.orderUnit">Analysis Group 1</Trans>
                                                </label>
                                                <SpotSelectBox {...input} error={meta}>
                                                    {analysisGroups.get(AnalysisGroupType.Group1)?.map((item) => (
                                                        <SpotSelectOption key={item.id} value={item.name}>
                                                            {item.name}
                                                        </SpotSelectOption>
                                                    ))}
                                                </SpotSelectBox>
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col xs={10}>
                                    <Field
                                        name="animanaSpecificFields.productAnalysisGroup2Name"
                                        parse={(value) => (value === undefined || value === '' ? null : value)}
                                        format={(value?: string | null) => {
                                            const group = analysisGroups
                                                .get(AnalysisGroupType.Group2)
                                                ?.find(({ name }) => name.toUpperCase() === value?.toUpperCase());
                                            return group?.name;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.orderUnit">Analysis Group 2</Trans>
                                                </label>
                                                <SpotSelectBox {...input} error={meta}>
                                                    {analysisGroups.get(AnalysisGroupType.Group2)?.map((item) => (
                                                        <SpotSelectOption key={item.id} value={item.name}>
                                                            {item.name}
                                                        </SpotSelectOption>
                                                    ))}
                                                </SpotSelectBox>
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col xs={10}>
                                    <Field
                                        name="animanaSpecificFields.productAnalysisGroup3Name"
                                        parse={(value) => (value === undefined || value === '' ? null : value)}
                                        format={(value?: string | null) => {
                                            const group = analysisGroups
                                                .get(AnalysisGroupType.Group3)
                                                ?.find(({ name }) => name.toUpperCase() === value?.toUpperCase());
                                            return group?.name;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.orderUnit">Analysis Group 3</Trans>
                                                </label>
                                                <SpotSelectBox {...input} error={meta}>
                                                    {analysisGroups.get(AnalysisGroupType.Group3)?.map((item) => (
                                                        <SpotSelectOption key={item.id} value={item.name}>
                                                            {item.name}
                                                        </SpotSelectOption>
                                                    ))}
                                                </SpotSelectBox>
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col>
                                    <Field
                                        name="animanaSpecificFields.informationSheetUrl"
                                        validate={isURL}
                                        format={(value) => {
                                            if (values?.animanaSpecificFields) {
                                                values.animanaSpecificFields.informationSheetUrl = value ? value : null;
                                            }
                                            return value;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.informationSheetUrl">Product Information Sheet</Trans>
                                                </label>
                                                <SpotTextInput {...input} disabled={isSubproduct} />
                                                <SpotFieldError meta={meta} />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                        </fieldset>

                        <fieldset className={styles.block}>
                            <Row className="spot-form__field-group">
                                <Col>
                                    <Field
                                        name="animanaSpecificFields.warning"
                                        format={(value) => {
                                            if (values?.animanaSpecificFields) {
                                                values.animanaSpecificFields.warning = value ? value : null;
                                            }
                                            return value;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.warning">Warning</Trans>
                                                </label>
                                                <SpotTextInput {...input} />
                                                {/*<SpotFieldError meta={meta} />*/}
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col>
                                    <Field
                                        name="animanaSpecificFields.memo"
                                        format={(value) => {
                                            if (values?.animanaSpecificFields) {
                                                values.animanaSpecificFields.memo = value ? value : null;
                                            }
                                            return value;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.memo">Memo</Trans>
                                                </label>
                                                <SpotTextarea {...input} disabled={isSubproduct} />
                                                {/*<SpotFieldError meta={meta} />*/}
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col>
                                    <SpotCheckboxGroup>
                                        <Field name="typeDetails.isControlledSubstance" type="checkbox">
                                            {({ input }) => (
                                                <>
                                                    <div className={styles.toggleLabel}>
                                                        <Trans i18nKey={'controlCenter:invoiceFields.controlledDrug'}>Controlled Drug:</Trans>
                                                    </div>
                                                    <SpotToggle {...input} disabled={isSubproduct}>
                                                        {input.checked
                                                            ? i18n.t('controlCenter:choice.yes', 'Yes')
                                                            : i18n.t('controlCenter:choice.no', 'No')}
                                                    </SpotToggle>
                                                </>
                                            )}
                                        </Field>
                                    </SpotCheckboxGroup>
                                </Col>

                                <Col>
                                    <SpotCheckboxGroup>
                                        <Field name="animanaSpecificFields.humanDrug" type="checkbox">
                                            {({ input }) => (
                                                <>
                                                    <div className={styles.toggleLabel}>
                                                        <Trans i18nKey={'controlCenter:invoiceFields.offLicense'}>Off-license:</Trans>
                                                    </div>
                                                    <SpotToggle {...input} disabled={isSubproduct}>
                                                        {input.checked
                                                            ? i18n.t('controlCenter:choice.yes', 'Yes')
                                                            : i18n.t('controlCenter:choice.no', 'No')}
                                                    </SpotToggle>
                                                </>
                                            )}
                                        </Field>
                                    </SpotCheckboxGroup>
                                </Col>

                                <Col>
                                    <SpotCheckboxGroup>
                                        <Field name="animanaSpecificFields.euthanasia" type="checkbox">
                                            {({ input }) => (
                                                <>
                                                    <div className={styles.toggleLabel}>
                                                        <Trans i18nKey={'controlCenter:invoiceFields.euthanasia'}>Euthanasia:</Trans>
                                                    </div>
                                                    <SpotToggle {...input} disabled={isSubproduct}>
                                                        {input.checked
                                                            ? i18n.t('controlCenter:choice.yes', 'Yes')
                                                            : i18n.t('controlCenter:choice.no', 'No')}
                                                    </SpotToggle>
                                                </>
                                            )}
                                        </Field>
                                    </SpotCheckboxGroup>
                                </Col>
                            </Row>
                        </fieldset>

                        <fieldset className={styles.block}>
                            <Row className="spot-form__field-group">
                                <Col xs={6}>
                                    <Field
                                        name="animanaSpecificFields.supplierName"
                                        parse={(value) => (value === undefined || value === '' ? null : value)}
                                        format={(value?: string | null) => {
                                            const supplier = suppliers.find(({ name }) => name.toUpperCase() === value?.toUpperCase());
                                            return supplier?.name;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.supplier">Supplier</Trans>
                                                </label>
                                                <SpotSelectBox {...input} error={meta} disabled={isSubproduct}>
                                                    {suppliers.map((unit) => (
                                                        <SpotSelectOption key={unit.id} value={unit.name}>
                                                            {unit.name}
                                                        </SpotSelectOption>
                                                    ))}
                                                </SpotSelectBox>
                                                <SpotFieldError meta={meta} />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                                <Col xs={6}>
                                    <Field
                                        name="animanaSpecificFields.supplierCode"
                                        format={(value) => {
                                            if (values?.animanaSpecificFields) {
                                                values.animanaSpecificFields.supplierCode = value ? value : null;
                                            }
                                            return value;
                                        }}
                                    >
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.supplierCode">Supplier Code</Trans>
                                                </label>
                                                <SpotTextInput {...input} disabled={isSubproduct} error={meta} placeholder="" />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="spot-form__field-group">
                                <Col xs={6}>
                                    <Field name="barcode">
                                        {({ input, meta }) => (
                                            <div>
                                                <label className="spot-form__label">
                                                    <Trans i18nKey="controlCenter:invoiceFields.sellBarcode">Sell Barcode</Trans>
                                                </label>
                                                <SpotTextInput {...input} disabled={isSubproduct} error={meta} placeholder="" />
                                            </div>
                                        )}
                                    </Field>
                                </Col>
                            </Row>
                        </fieldset>
                    </Col>
                </Row>
            </Col>
            {isNotAddingItem && isActive && !disabled && (
                <Col xs={4} className={styles.rightPanel}>
                    <SubproductControls values={values} data={data} patchData={patchData} activeValueName={activeValueName} />
                </Col>
            )}
        </Row>
    );
});
