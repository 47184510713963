import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

interface ImportStartPageProps {
    uploadHandler: () => void;
}

export const ImportStartPage: FC<ImportStartPageProps> = function ({ uploadHandler }: ImportStartPageProps) {
    const { t } = useTranslation('controlCenter', { i18n });
    return (
        <>
            <Row className={'margin-top-4'}>
                <Col xs={1} />
                <Col xs={11}>
                    <h1 className="classifications-header">
                        {t('controlCenter:import.selectPageHeader', 'Update multiple items across My Organization')}
                    </h1>
                </Col>
            </Row>
            <Row>
                <Col xs={1} />
                <Col xs={6}>
                    <div className="classifications-details">
                        {t(
                            'controlCenter:import.selectPageDescription',
                            'Click Upload CSV below to select and upload a batch of items to be updated and scheduled using the Grid View.',
                        )}
                    </div>
                </Col>
                <Col xs={5} />
            </Row>
            <Row className={'margin-top-2'}>
                <Col xs={1} />
                <Col xs={11} data-automation-id="import-csv-button">
                    <ul>
                        <li onClick={uploadHandler}>
                            <a className={'spot-link'} href={'javascript:void(0);'}>
                                {t('controlCenter:import.uploadCSV', 'Upload CSV')}
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </>
    );
};
