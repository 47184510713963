import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ComingSoon } from '../../../components/helpers/ComingSoon';

interface MyOrganizationSettingsPageProps {}

export class MyOrganizationSettingsPage extends React.PureComponent<MyOrganizationSettingsPageProps> {
    render() {
        const {} = this.props;
        return (
            <div>
                <Row>
                    <Col xs="12">
                        <div className="content">
                            <ComingSoon />
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
