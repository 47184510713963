import * as React from 'react';

export interface SpotFieldsetProps {
    label?: string;
}

export class SpotFieldset extends React.PureComponent<SpotFieldsetProps & React.HTMLProps<HTMLFieldSetElement>> {
    render() {
        const { className, children, label, ...rest } = this.props;
        return (
            <fieldset className={['spot-form__fieldset', className].join(' ')} {...rest}>
                {label && <legend className="spot-form__legend">{label}</legend>}
                <div className="spot-form__field-group">{children}</div>
            </fieldset>
        );
    }
}
