import React, { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import styles from './practicesAccess.module.scss';
import i18n from '../../../i18n';
import { Image } from '../../../components/helpers/Image';
import classNames from 'classnames';
import { useStores } from '../../../hooks';
import { SpotFieldError, SpotRadioButton, SpotRadioButtonItem, SpotSvg } from '@enterprise/spot';
import { PracticeAccessType, UserPractices } from '@enterprise/common';
import { PracticeAccessGrid, SelectedPracticeAccess, usePracticeAccessGridSize } from '../practicesAccessGrid';
import { head } from 'lodash';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../../../router/RouterPaths';

type PracticeAccessProps = PropsWithChildren<{
    userPractices?: UserPractices;
    disabled?: boolean;
    onChange?: (userPractices: UserPractices) => void;
    setDisableSave?: any;
}>;

export const PracticeAccess = observer(function PracticeAccessComponent(props: PracticeAccessProps) {
    const {
        domain: { practicesStore },
    } = useStores();

    const { practices = [], labels = [], isFullLoading } = practicesStore;
    const { userPractices = {}, onChange, disabled, setDisableSave } = props;
    const { isMobileView } = usePracticeAccessGridSize();
    const [expanded, setExpanded] = useState(false);
    const [noPracticesConnected, setNoPracticesConnected] = useState(false);
    const [noLabelsConfigured, setNoLabelsConfigured] = useState(false);
    const [practicesAccessType, setPracticesAccessType] = useState(PracticeAccessType.All);
    const [selectionCache] = useState(new Map<PracticeAccessType, SelectedPracticeAccess>());
    const incompletePracticeAccess =
        ((practicesAccessType === PracticeAccessType.Practice && noPracticesConnected) ||
            (practicesAccessType === PracticeAccessType.Region && noLabelsConfigured)) &&
        expanded;
    useEffect(() => {
        setDisableSave && setDisableSave(incompletePracticeAccess);
    }, [incompletePracticeAccess]);

    useEffect(() => {
        void practicesStore.load();
    }, [practicesStore]);

    const userPracticesAccess = useMemo(() => {
        const { practices: practiceIds, regions: regionIds } = userPractices;
        const practicesAccess = {
            practices: practices.filter(({ rowId }) => practiceIds?.includes(String(rowId))),
            regions: labels.filter(({ id }) => regionIds?.includes(id)),
        };

        return practicesAccess;
    }, [userPractices, practices, labels]);

    useEffect(() => {
        const { practices: practiceIds, regions: regionIds } = userPractices;
        const accessType =
            (practiceIds?.length && PracticeAccessType.Practice) || (regionIds?.length && PracticeAccessType.Region) || PracticeAccessType.All;

        setPracticesAccessType(accessType);
    }, [userPractices]);

    useEffect(() => {
        selectionCache.set(practicesAccessType, userPracticesAccess);
    }, [practicesAccessType, userPracticesAccess]);

    const onChangeUserPracticesAccess = (type: PracticeAccessType, selectedPracticeAccess: SelectedPracticeAccess) => {
        const { practices, regions } = selectedPracticeAccess;
        const practiceIds = practices?.map(({ rowId }) => String(rowId));
        const regionIds = regions?.map(({ id }) => id);

        onChange?.({
            practices: practiceIds,
            regions: regionIds,
        });
    };

    const onChangeAccessType = (type: PracticeAccessType) => {
        setPracticesAccessType(type);
        const cacheValue = selectionCache.get(type);

        if (type === PracticeAccessType.All) {
            onChangeUserPracticesAccess(type, {});
        }

        if (type === PracticeAccessType.Practice && cacheValue?.practices?.length) {
            onChangeUserPracticesAccess(type, cacheValue);
        }

        if (type === PracticeAccessType.Region && cacheValue?.regions?.length) {
            onChangeUserPracticesAccess(type, cacheValue);
        }
    };

    const practicesAccessDescription = useMemo(() => {
        const practicesCount = practices.length;

        return new Map<PracticeAccessType, Partial<SpotRadioButtonItem<string>>>([
            [
                PracticeAccessType.All,
                {
                    label: i18n.t('myOrganization:allPractices', {
                        count: practicesCount,
                        defaultValue: `All Practices (${practicesCount})`,
                    }),
                    automationId: 'practices-access-type-selection-all-practices',
                },
            ],
            [
                PracticeAccessType.Region,
                {
                    label: i18n.t('myOrganization:specificPracticeLabel', 'Specific Practice Label'),
                    automationId: 'practices-access-type-selection-region',
                },
            ],
            [
                PracticeAccessType.Practice,
                {
                    label: i18n.t('myOrganization:individualPractice', 'Individual Practice'),
                    automationId: 'practices-access-type-selection-practice',
                },
            ],
        ]);
    }, [practices]);

    const renderTitleDescription = () => {
        let title: string | undefined = '';
        let description = '';

        if (practicesAccessType === PracticeAccessType.All) {
            title = practicesAccessDescription.get(practicesAccessType)?.label;
            description = i18n.t('myOrganization:dataForAllConnectedPractices', {
                region: '',
                defaultValue: `Data for all connected practices, current and future, is visible.`,
            });
        }

        if (practicesAccessType === PracticeAccessType.Practice) {
            const selectedPractice = head(userPracticesAccess.practices);
            if (selectedPractice) {
                title = selectedPractice.name;
            } else {
                title = practicesAccessDescription.get(practicesAccessType)?.label;
                description = i18n.t('myOrganization:connectAndSelectPractice', {
                    region: '',
                    defaultValue: `Connect and select a practice before saving.`,
                });
            }
        }

        if (practicesAccessType === PracticeAccessType.Region) {
            const selectedRegion = head(userPracticesAccess.regions);
            if (selectedRegion) {
                title = `${selectedRegion.name} (${selectedRegion.practices.length})`;
                description = i18n.t('myOrganization:dataForAllConnectedPractices', {
                    region: selectedRegion.name,
                    defaultValue: `Data for all connected ${selectedRegion.name} practices, current and future, is visible.`,
                });
            } else {
                title = practicesAccessDescription.get(practicesAccessType)?.label;
                description = i18n.t('myOrganization:configureAndSaveLabel', {
                    region: '',
                    defaultValue: `Configure and select a practice label before saving.`,
                });
            }
        }

        return (
            <>
                <span className="spot-typography__text--body" data-automaton-id={'practices-access-title'}>
                    {title}
                </span>
                <span
                    className={classNames('spot-typography__text--secondary', styles.titleDescription)}
                    data-automaton-id={'practices-access-description'}
                >
                    {description}
                </span>
            </>
        );
    };

    const emptyPracticesOverlay = (): ReactElement => {
        setNoPracticesConnected(!practices.length);
        setNoLabelsConfigured(!labels.length);

        return (
            <div className={styles.configurePractices}>
                <span className={classNames('spot-typography__heading--level-5', styles.configurePracticeText)}>
                    {practicesAccessType === PracticeAccessType.Practice && i18n.t('myOrganization:connectPracticeIn', 'Connect a Practice in')}
                    {practicesAccessType === PracticeAccessType.Region &&
                        i18n.t('myOrganization:configurePracticeLabelsIn', 'Configure Practice Labels in')}
                </span>
                <Link
                    data-automation-id="practice-access-configure-practice-in-link"
                    to={RouterPaths.MyOrganizationPages.PracticeConnections}
                    target={'_blank'}
                    className={classNames('spot-link', styles.myOrganization)}
                >
                    <svg className={'spot-icon spot-link__icon spot-link__icon--left'}>
                        <use href={'/assets/img/icons.svg#organization'} />
                    </svg>
                    {i18n.t('marketing:section.myOrganization.title', 'My organization')}
                </Link>
            </div>
        );
    };

    const renderSelectionsGrid = () => {
        return (
            <>
                {(practicesAccessType === PracticeAccessType.Region || practicesAccessType === PracticeAccessType.Practice) && (
                    <PracticeAccessGrid
                        isLoading={isFullLoading}
                        practices={practices}
                        regions={labels}
                        className={styles.grid}
                        type={practicesAccessType}
                        noRowsOverlay={emptyPracticesOverlay}
                        selection={userPracticesAccess}
                        onSelectionChange={(selection) => onChangeUserPracticesAccess(practicesAccessType, selection)}
                    />
                )}
            </>
        );
    };

    const incompletePracticeMessage = () => {
        const errorMessage =
            practicesAccessType === PracticeAccessType.Practice
                ? i18n.t('myProfile:incompletePracticeAccess_practice', 'Incomplete practice selection. Connect a practice.')
                : i18n.t('myProfile:incompletePracticeAccess_region', 'Incomplete practice selection. Configure practice labels.');

        return <SpotFieldError meta={{ error: errorMessage, name: 'incomplete-practice-access', touched: true }} />;
    };
    return (
        <div>
            <div className={incompletePracticeAccess ? styles.holderError : styles.holder} data-automation-id="practices-access">
                <div className={styles.containerMain} data-automation-id="practices-access-main">
                    <div className={styles.titleBlock}>
                        {renderTitleDescription()}
                        {!disabled && (
                            <button
                                className={classNames('spot-button', 'spot-button--link', 'spot-button--with-icon', styles.button)}
                                data-automation-id="practices-access-customize-access"
                                onClick={(event) => {
                                    event.preventDefault();
                                    setExpanded(!expanded);
                                }}
                            >
                                <SpotSvg
                                    icon={`next`}
                                    className={classNames('spot-icon spot-button__icon spot-button__icon--left', styles.caret, {
                                        [styles.expanded]: expanded,
                                    })}
                                />
                                <span className="spot-button__text">{i18n.t('myOrganization:customizeAccess', 'Customize Access')}</span>
                            </button>
                        )}
                    </div>
                    <div className={styles.imageBlock}>
                        <Image className={classNames('practices-empty-image', styles.image)} url={'img/practice.png'} />
                    </div>
                </div>
                {expanded && !disabled && (
                    <>
                        <div className={styles.containerMain} data-automation-id="practices-access-type-selection">
                            <SpotRadioButton
                                className={styles.accessTypeSelect}
                                isHorizontal={!isMobileView}
                                value={practicesAccessType}
                                items={Array.from(practicesAccessDescription.entries()).map(([value, item]) => ({
                                    ...item,
                                    value,
                                }))}
                                onChange={(accessType) => onChangeAccessType(accessType as PracticeAccessType)}
                            />
                        </div>
                        {renderSelectionsGrid()}
                    </>
                )}
            </div>
            {incompletePracticeAccess && incompletePracticeMessage()}
        </div>
    );
});
