import { Container } from 'typedi';
import * as mobxLogger from 'mobx-logger';
import { RootStore } from '../store/RootStore';
import { EnterpriseApiHttp } from './http/EnterpriseApiHttp';
import { EnterpriseAuthProvider, UserService } from '@enterprise/common';
import { environment } from '../environment/environment';
import { Services } from '@enterprise/core';

if (environment.isDev) {
    mobxLogger.enableLogging({
        action: true,
        compute: true,
        reaction: true,
        transaction: true,
    });
}

export function boot_di() {
    const getAccessToken = () => Container.get(RootStore).ui.app.getAccessToken();
    Container.set('http', new EnterpriseApiHttp({ ...environment.ENTERPRISE_API, authorization: { getAccessToken } }));
    Container.set(UserService, new UserService(Container.get('http'), [new EnterpriseAuthProvider(Container.get('http'))]));
    Container.set(Services.FeatureManager, new Services.FeatureManager([]));
    // Container.get(RootStore).bootstrapDomainStores();
}

const ioc = Container;
export default ioc;
