import {
    GridApi,
    GridCellParams,
    GridColDef,
    GridGroupingColDefOverride,
    GridGroupingColDefOverrideParams,
    GridRowId,
    gridRowsStateSelector,
    GridValueGetterParams,
    useGridSelector,
    GridComparatorFn,
} from '@enterprise/material-data-grid';
import { InvoiceItem } from '../../../../../core/models';
import { head } from 'lodash';
import React, { MutableRefObject, ReactElement, useCallback, useMemo } from 'react';
import i18n from '../../../../../i18n';
import styles from '../itemsGrid.module.scss';
import classNames from 'classnames';
import { SpotPill, SpotSvg } from '@enterprise/spot';

export enum ItemsGridColumn {
    // todo: will be used if we decide to made mobile view for this grid
    MobileView = 'mobileView',
    Id = 'pimsId',
    Description = 'hospitalDescription',
    Practice = 'provider.siteId',
    Classification = 'classification.pimsGeneratedId',
    ItemType = 'itemType.id',
    Status = 'isActive',
    SellingPrice = 'prices.listPrice',
}

enum CellType {
    Link = 'link',
    Text = 'text',
    Pill = 'pill',
}

interface HeaderProps {
    label?: string | ReactElement;
}

interface CellProps {
    type?: CellType;
}

const header = (props: HeaderProps) =>
    function Header(): ReactElement {
        const { label } = props;

        return (
            <div className={styles.headerContainer}>
                <div className={classNames('spot-typography__text--body', styles.headerCell)}>{label}</div>
            </div>
        );
    };

const cell = (props: CellProps = {}) =>
    function Cell<V, R>(params: GridCellParams<V, InvoiceItem>): ReactElement {
        const { type } = props;
        const { formattedValue, value } = params;

        const renderPill = () => {
            const isActive = (value as unknown as string) === 'Active';

            return (
                <SpotPill type={isActive ? 'positive' : 'secondary'} isOutline={true}>
                    {formattedValue}
                </SpotPill>
            );
        };

        return (
            <div className={styles.cellContainer}>
                <div
                    className={classNames(styles.cell, {
                        [styles.linkCell]: type === CellType.Link,
                        [styles.pillCell]: type === CellType.Pill,
                    })}
                >
                    {type === CellType.Pill && formattedValue ? renderPill() : <span>{formattedValue}</span>}
                </div>
            </div>
        );
    };

export interface UseItemGridColumnsParams {
    apiRef: MutableRefObject<GridApi>;
}

export const useItemGridColumns = (params: UseItemGridColumnsParams) => {
    const { apiRef } = params;
    const active = i18n.t('common:active', 'Active');
    const inActive = i18n.t('common:inactive', 'Inactive');
    const mixed = i18n.t('common:mixed', 'Mixed');

    const currencyComparator: GridComparatorFn = (v1: string, v2: string, cellParams1) => {
        const sort = apiRef.current?.getSortModel().find((item) => item.field === cellParams1.field)?.sort;
        const [v1Min, v1Max] = v1.split('-');
        const [v2Min, v2Max] = v2.split('-');
        return Number((sort === 'desc' && v1Max) || v1Min) - Number((sort === 'desc' && v2Max) || v2Min);
    };

    const isAutoGenerated = (rowId: GridRowId): boolean | undefined => {
        return apiRef.current.getRowNode(rowId)?.isAutoGenerated;
    };

    const columnNames = useMemo(
        () =>
            new Map<ItemsGridColumn, string>([
                [ItemsGridColumn.MobileView, i18n.t('controlCenter:name', 'Name')],
                [ItemsGridColumn.Id, i18n.t('controlCenter:itemId', 'Id')],
                [ItemsGridColumn.Description, i18n.t('controlCenter:practiceDescription', 'Practice Description')],
                [ItemsGridColumn.Practice, i18n.t('controlCenter:practice', 'Practice')],
                [ItemsGridColumn.Classification, i18n.t('controlCenter:classification', 'Classification')],
                [ItemsGridColumn.ItemType, i18n.t('controlCenter:itemType', 'Item Type')],
                [ItemsGridColumn.Status, i18n.t('controlCenter:status', 'Status')],
                [ItemsGridColumn.SellingPrice, i18n.t('controlCenter:sellingPrice', 'Selling Price')],
            ]),
        [],
    );

    function getGroupItems(rowId: GridRowId): InvoiceItem[] {
        const state = useGridSelector(apiRef, gridRowsStateSelector);
        const groupRowIds = apiRef.current?.getRowGroupChildren({
            groupId: rowId,
            applyFiltering: true,
            applySorting: true,
        });

        return groupRowIds.map((id) => state.idRowsLookup[id]) as InvoiceItem[];
    }

    const columns = useMemo<GridColDef<InvoiceItem>[]>(() => {
        const columns: GridColDef<InvoiceItem>[] = [
            // todo: will be used if we decide to made mobile view for this grid
            // {
            //     field: GridColumns.MobileView,
            //     hide: !isTabletView,
            //     flex: 1,
            //     renderHeader: MobileHeader,
            //     renderCell: mobileCell(props),
            //     valueGetter: (params: GridValueGetterParams) => {
            //         return getPracticeName(params.row as PracticeInfo);
            //     },
            // },
            {
                field: ItemsGridColumn.Id,
                headerName: columnNames.get(ItemsGridColumn.Id),
                width: 100,
                disableColumnMenu: false,
                renderHeader: header({ label: columnNames.get(ItemsGridColumn.Id) }),
                renderCell: cell(),
                valueGetter: ({ row, rowNode }: GridValueGetterParams<string, InvoiceItem>) => {
                    if (!rowNode.isAutoGenerated) {
                        return row.pimsId || '';
                    }

                    const items = getGroupItems(rowNode.id);
                    if (!items?.length) {
                        return '-';
                    }

                    const first = items[0];
                    const containsOne = items.every((item) => item.pimsGeneratedId === first.pimsGeneratedId);

                    if (containsOne) {
                        return first.pimsId;
                    }
                    if (items.filter((e) => e.pimsId === first.pimsId).length === items.length) {
                        return first.pimsId;
                    }
                    return '--';
                },
            },
            {
                field: ItemsGridColumn.Description,
                headerName: columnNames.get(ItemsGridColumn.Description),
                width: 442,
                disableColumnMenu: false,
                renderHeader: header({ label: columnNames.get(ItemsGridColumn.Description) }),
                renderCell: cell(),
                valueGetter: ({ row, rowNode }: GridValueGetterParams<string, InvoiceItem>) => {
                    if (!rowNode.isAutoGenerated) {
                        return row.hospitalDescription;
                    }

                    const items = getGroupItems(rowNode.id);
                    if (!items?.length) {
                        return '-';
                    }

                    const first = items[0].hospitalDescription;
                    const containsOne = items.every((item) => item.hospitalDescription === first);

                    if (containsOne) {
                        return first;
                    } else {
                        return '--';
                    }
                },
            },
            {
                field: ItemsGridColumn.Practice,
                headerName: columnNames.get(ItemsGridColumn.Practice),
                width: 360,
                disableColumnMenu: false,
                groupable: false,
                renderHeader: header({ label: columnNames.get(ItemsGridColumn.Practice) }),
                renderCell: cell(),
                valueGetter: ({ row, rowNode }: GridValueGetterParams<string, InvoiceItem>) => {
                    if (!rowNode.isAutoGenerated) {
                        return row.practiceName;
                    }

                    const items = getGroupItems(rowNode.id);
                    if (!items?.length) {
                        return '-';
                    }

                    const first = items[0].practiceName;
                    const containsOne = items.every((item) => item.practiceName === first);

                    if (containsOne) {
                        return first;
                    } else {
                        return '--';
                    }
                },
            },
            {
                field: ItemsGridColumn.Classification,
                headerName: columnNames.get(ItemsGridColumn.Classification),
                width: 360,
                disableColumnMenu: false,
                groupable: false,
                renderHeader: header({ label: columnNames.get(ItemsGridColumn.Classification) }),
                renderCell: cell(),
                valueGetter: ({ row, rowNode }: GridValueGetterParams<string, InvoiceItem>) => {
                    if (!rowNode.isAutoGenerated) {
                        return row.classification?.name;
                    }

                    const items = getGroupItems(rowNode.id);
                    if (!items?.length) {
                        return '-';
                    }

                    const first = items[0].classification?.name;
                    const containsOne = items.every((item) => item.classification?.name === first);

                    if (containsOne) {
                        return first;
                    } else {
                        return '--';
                    }
                },
            },
            {
                field: ItemsGridColumn.ItemType,
                headerName: columnNames.get(ItemsGridColumn.ItemType),
                width: 100,
                disableColumnMenu: false,
                groupable: false,
                renderHeader: header({ label: columnNames.get(ItemsGridColumn.ItemType) }),
                renderCell: cell(),
                valueGetter: ({ row, rowNode }: GridValueGetterParams<string, InvoiceItem>) => {
                    if (!rowNode.isAutoGenerated) {
                        return row.itemType?.description;
                    }

                    const items = getGroupItems(rowNode.id);
                    if (!items?.length) {
                        return '-';
                    }

                    const first = items[0].itemType.description;
                    const containsOne = items.every((item) => item.itemType.description === first);

                    if (containsOne) {
                        return first;
                    }
                    return '--';
                },
            },
            {
                field: ItemsGridColumn.Status,
                headerName: columnNames.get(ItemsGridColumn.Status),
                width: 100,
                disableColumnMenu: false,
                groupable: false,
                renderHeader: header({ label: columnNames.get(ItemsGridColumn.Status) }),
                renderCell: cell({ type: CellType.Pill }),
                valueGetter: ({ row, rowNode }: GridValueGetterParams<boolean, InvoiceItem>) => {
                    if (!rowNode.isAutoGenerated) {
                        return (row.isActive === true && active) || (row.isActive === false && inActive);
                    }

                    const items = getGroupItems(rowNode.id);

                    if (!items?.length) {
                        return '-';
                    }

                    const first = items[0].isActive;
                    const containsOne = items.every((item) => item.isActive === first);

                    if (containsOne) {
                        return first ? active : inActive;
                    }
                    return mixed;
                },
            },
            {
                field: ItemsGridColumn.SellingPrice,
                headerName: columnNames.get(ItemsGridColumn.SellingPrice),
                sortComparator: currencyComparator,
                width: 100,
                disableColumnMenu: false,
                groupable: false,
                renderHeader: header({ label: columnNames.get(ItemsGridColumn.SellingPrice) }),
                renderCell: cell(),
                valueGetter: ({ id, row, rowNode }: GridValueGetterParams<number, InvoiceItem>) => {
                    if (!rowNode.isAutoGenerated) {
                        const price = head(row.prices?.priceBreaks)?.invoicePrice || 0;
                        return !isAutoGenerated(id) && (price >= 0 ? price.toFixed(2) : `(${(price * -1).toFixed(2)})`);
                    }

                    const items = getGroupItems(rowNode.id);

                    if (!items?.length) {
                        return '-';
                    }

                    const maxItem = items.reduce(function (prev, curr) {
                        return (head(prev?.prices?.priceBreaks)?.invoicePrice || 0) > (head(curr?.prices?.priceBreaks)?.invoicePrice || 0)
                            ? prev
                            : curr;
                    });

                    const minItem = items.reduce(function (prev, curr) {
                        return (head(prev?.prices?.priceBreaks)?.invoicePrice || 0) < (head(curr?.prices?.priceBreaks)?.invoicePrice || 0)
                            ? prev
                            : curr;
                    });

                    let max = 0;
                    let min = 0;
                    if (head(maxItem?.prices?.priceBreaks)?.invoicePrice) {
                        max = head(maxItem?.prices?.priceBreaks)?.invoicePrice || 0;
                    }
                    if (head(minItem?.prices?.priceBreaks)?.invoicePrice) {
                        min = head(minItem?.prices?.priceBreaks)?.invoicePrice || 0;
                    }
                    const maxOutput = max >= 0 ? max?.toFixed(2) : `(${(max && max * -1)?.toFixed(2)})`;
                    const minOutput = min >= 0 ? min?.toFixed(2) : `(${(min && min * -1)?.toFixed(2)})`;

                    if (max === min) {
                        return maxOutput;
                    }

                    return `${minOutput} - ${maxOutput}`;
                },
            },
        ];

        return columns;
    }, [apiRef, apiRef.current]);

    const groupColumn = useCallback(
        (params: GridGroupingColDefOverrideParams): GridGroupingColDefOverride<InvoiceItem> => {
            const { fields } = params;
            const width = fields.includes(ItemsGridColumn.Description) ? 442 : 150;
            const labels = fields.reduce((agg, field) => {
                const name = columnNames.get(field as ItemsGridColumn);
                return (name && [...agg, name]) || agg;
            }, [] as string[]);
            const headerName = labels.join('/');

            return {
                width,
                headerName,
                renderHeader: header({
                    label: (
                        <>
                            {headerName}
                            <SpotSvg className={styles.titleIcon} icon="list" width="14" />
                        </>
                    ),
                }),
                filterable: true,
            };
        },
        [apiRef, apiRef.current],
    );

    return {
        isAutoGenerated,
        columnNames,
        columns,
        groupColumn,
    };
};
