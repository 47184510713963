import * as React from 'react';
import { Trans } from 'react-i18next';
import { Container, Col, Row } from 'react-bootstrap';
import { Field } from '@enterprise/common';
import { SpotRadio, SpotHorizontalFields, SpotRadioGroup } from '@enterprise/spot';

interface StatusEditFormFieldsetProps {}

export class StatusEditFormFieldset extends React.PureComponent<StatusEditFormFieldsetProps> {
    render() {
        const {} = this.props;
        return (
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div style={{ marginTop: '10px' }}>
                            <legend className="spot-form__radio-group-label common-legend">Status</legend>
                        </div>

                        <SpotHorizontalFields>
                            <SpotRadioGroup isHorizontal={true}>
                                <Field name="isActive" type="radio" value="1">
                                    {({ input }) => (
                                        <SpotRadio {...input}>
                                            <Trans key="editPricing:active">Active</Trans>
                                        </SpotRadio>
                                    )}
                                </Field>
                                <Field name="isActive" type="radio" value="0">
                                    {({ input }) => (
                                        <SpotRadio {...input}>
                                            <Trans key="editPricing:inactive">Inactive</Trans>
                                        </SpotRadio>
                                    )}
                                </Field>
                            </SpotRadioGroup>
                        </SpotHorizontalFields>
                    </Col>
                </Row>
            </Container>
        );
    }
}
