import { ActionItemEvent, CardEvent, ScrollEvent } from './events';

export enum ColumnActions {
    ACTION_MENU_CLICK_ITEM = 'ACTION_MENU_CLICK_ITEM',

    CARD_DROP_CREATE = 'CARD_DROP_CREATE',
    CARD_DROP_LINK = 'CARD_DROP_LINK',
    CARD_DROP_REMOVE = 'CARD_DROP_REMOVE',

    CARD_CLICK_CREATE = 'CARD_CLICK_CREATE',
    CARD_CLICK_TITLE = 'CARD_CLICK_TITLE',

    SCROLL_END = 'SCROLL-END',
}

export type BoardEventData = ActionItemEvent | CardEvent | ScrollEvent;

export class BoardEvent {
    action: ColumnActions;
    data: BoardEventData;

    constructor({ action, data }: Required<BoardEvent>) {
        this.action = action;
        this.data = data;
    }
}
