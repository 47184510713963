import ioc from '../services/ioc';
import { MyOrganizationPageStore } from './practiceConnections/MyOrganizationPageStore';
import { ActivityPageStore } from './controlCenter/activity/ActivityPageStore';
import { ItemsPageStore } from './controlCenter/items/ItemsPageStore';
import { AnalyticsPageStore } from './analytics/AnalyticsPageStore';
import { MasterDataPageStore } from './masterData/MasterDataPageStore';
import { ImportPageStore } from './controlCenter/import';
import { AdvertisementStore, RootStore, RouterStore, UsersStore } from '../store';
import ConstantsStore from '../store/ConstantsStore';
import AppStore from '../store/AppStore';
import { InvoiceItemStore } from '../store/invoiceItem.store';

export function registerStores() {
    const rootStore = ioc.get(RootStore);
    const appStore = ioc.get(AppStore);

    rootStore.bootstrapStores(
        {
            constantsStore: ioc.get(ConstantsStore),
            advertisementStore: ioc.get(AdvertisementStore),
            practicesStore: ioc.get(MyOrganizationPageStore),
            activityPageStore: ioc.get(ActivityPageStore),
            itemsPageStore: ioc.get(ItemsPageStore),
            analyticsPageStore: ioc.get(AnalyticsPageStore),
            masterDataPageStore: ioc.get(MasterDataPageStore),
            importPageStore: ioc.get(ImportPageStore),
            usersStore: ioc.get(UsersStore),
            invoiceItemStore: ioc.get(InvoiceItemStore),
        },
        {
            app: appStore,
            router: ioc.get(RouterStore),
        },
    );
}
