import { Inject, Service } from 'typedi';
import { BaseHttp } from '@enterprise/common';
import ConstantsStore from '../store/ConstantsStore';
import { BulkUpdatesBody, GetItemQuery, InvoiceItem, InvoiceItemResponse } from '../core/models';
import { InvoiceItemsWithVariantDetails } from '../core/transformer/InvoiceItemsWithVariantDetails';
import { isPresent } from '../core/helpers';
import { InvoiceItemWithVariantDetail } from '../core/models';
import { TaskResponse } from '../core/interfaces/TaskResponse';
import { ItemsGroupByType } from '../core/models/organization/settings/enum';
import { InvoiceItemsWithDynamicPropsToInvoiceItems } from '../core/transformer/InvoiceItemsWithDynamicPropsToInvoiceItems';
import moment from 'moment';
import { first } from 'lodash';

export const INVOICE_ITEMS_PATH = 'v2/items';

export interface ScheduleSaveOptions {
    isUpdatingExistingItem?: boolean;
    name: string;
    runAt?: Date;
}

@Service()
export class InvoiceItemService {
    constructor(@Inject('http') private readonly http: BaseHttp, private readonly constantsStore: ConstantsStore) {}

    get itemsGroupBy(): ItemsGroupByType {
        return this.constantsStore.organizationSettings?.itemsGroupBy || ItemsGroupByType.Id;
    }

    createBulkUpdate(update: BulkUpdatesBody): Promise<TaskResponse> {
        return this.http.post(`${INVOICE_ITEMS_PATH}/bulk`, update);
    }

    getItem({ id, ids, siteId }: GetItemQuery): Promise<InvoiceItemResponse> {
        return this.http.get(`${INVOICE_ITEMS_PATH}/:id`, { params: { depth: 1, siteId }, pathParams: { id: id || first(ids) } });
    }

    async getItemWithVariantDetails(query: GetItemQuery): Promise<InvoiceItemWithVariantDetail> {
        const { data } = await this.getItem(query);
        return this.createVariantDetail(data)[0];
    }

    async getItems(criteria: GetItemQuery = {}): Promise<InvoiceItemResponse> {
        const response: InvoiceItemResponse = await this.http.get(INVOICE_ITEMS_PATH, { params: criteria });

        /**
         * TODO The API should handle the logic for returning exact or partial matches.
         * An enhancement story has been created to address the API changes and remove the client
         * side filtering (https://jira.idexx.com/browse/TD-1540)
         */
        if (criteria.description && criteria.matchHospitalDescription) {
            response.data = response.data.filter((item) => item.hospitalDescription.toUpperCase() === criteria.description?.toUpperCase());
        }

        return response;
    }

    // todo: legacy search (should be removed after new grid is fully implemented)
    async getItemsWithVariantDetails(criteria: GetItemQuery = {}): Promise<InvoiceItemWithVariantDetail[]> {
        const { data } = await this.getItems(criteria);
        return this.createVariantDetail(data);
    }

    scheduleSave(
        items: Partial<InvoiceItem>[] | undefined,
        { isUpdatingExistingItem = false, name, runAt = new Date() }: ScheduleSaveOptions,
        sourceFileName: string | undefined = undefined,
    ): Promise<TaskResponse[]> {
        const payload = items && new InvoiceItemsWithDynamicPropsToInvoiceItems().transform(items);
        if (sourceFileName) {
        }
        return this.http.post<TaskResponse[]>(`${INVOICE_ITEMS_PATH}/schedule`, {
            isUpdatingExistingItem,
            items: payload,
            name,
            runAt: moment(runAt).format('YYYY-MM-DD HH:mm:ssZ'),
            sourceFileName,
        });
    }

    updateChangedProducts(): Promise<void> {
        return this.http.post<void>(`${INVOICE_ITEMS_PATH}/updateChangedProducts`);
    }

    private createVariantDetail(invoiceItems: InvoiceItem[], groupBy?: ItemsGroupByType): InvoiceItemWithVariantDetail[] {
        const transformer = new InvoiceItemsWithVariantDetails(groupBy || this.itemsGroupBy);
        const { masterSite } = this.constantsStore.organizationSettings || {};
        if (masterSite) {
            // separate items into master and nonmaster, but avoid .sort() so we maintain the original order
            const masterSiteItems = invoiceItems.filter((item) => item.provider.siteId.toString() === masterSite.siteId);
            const otherSiteItems = invoiceItems.filter((item) => item.provider.siteId.toString() !== masterSite.siteId);

            invoiceItems = masterSiteItems.concat(otherSiteItems);
        }
        const transformedItems = transformer.transform(invoiceItems);

        return this.attachSitesToInvoiceItems(transformedItems);
    }

    private attachSitesToInvoiceItems(invoiceItems: InvoiceItemWithVariantDetail[]): InvoiceItemWithVariantDetail[] {
        const { applications } = this.constantsStore;

        return invoiceItems.map((invoiceItem) => {
            const matchedSites = invoiceItem.providers.map((provider) =>
                applications.find((application) => String(application.id) === provider.siteId),
            );
            invoiceItem.sites = matchedSites.filter(isPresent);
            return invoiceItem;
        });
    }
}
