import React, { PropsWithChildren, ReactElement, useEffect, useMemo, useState } from 'react';
import {
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridFilterItem,
    GridFilterOperatorType,
    GridSortDirection,
    GridValueGetterParams,
    MaterialDataGrid,
    GridSortModel,
    GridRowParams,
    useGridApiContext,
    useGridSelector,
    gridFilterStateSelector,
    GridInitialState,
    gridSortingStateSelector,
} from '@enterprise/material-data-grid';
import styles from './usersGrid.module.scss';
import i18n from '../../../i18n';
import classNames from 'classnames';
import { useUsersGridSize } from './useUsersGridSize.hook';
import { User } from '../../../core/models';
import { RouterPaths } from '../../../router/RouterPaths';
import { Link } from 'react-router-dom';
import { SpotSearchbar } from '@enterprise/spot';
import { head } from 'lodash';
import { Image } from '../../../components/helpers/Image';
import { PermissionLevel } from '../../../core/enums/permissionLevel';
import { IEnterpriseUser } from '@enterprise/common';
import { useStores } from '../../../hooks';
import { observer } from 'mobx-react';
import { PracticeInfo } from '../../practiceConnections/MyOrganizationPageStore';
import { LabelEntity } from 'apps/client/src/core/entity/LabelEntity';

enum GridColumns {
    MobileView = 'mobileView',
    Name = 'name',
    PracticeAccess = 'practiceAccess',
    SiteAccess = 'siteAccess',
    EditUser = 'editUser',
}

enum FilTerType {
    Name = 1000,
}

interface UserPracticesRegions {
    id: string;
    user: User;
    practices?: PracticeInfo[];
    labels?: LabelEntity[];
}

// todo: this hardcoded value should addressed in other stories
const allPractices = 'All Practices';

const getUserName = ({ username, profile }: User): string => {
    const { firstName, lastName, displayName } = profile || {};
    return (profile && (displayName || `${firstName} ${lastName}`)) || username;
};

const getEditUserLink = ({ id }: User): string => {
    return `${RouterPaths.MyOrganizationPages.EditUser}/${id}`;
};

const getRowPermissionLevel = (user: User, { organization }: Partial<IEnterpriseUser>): string => {
    if (organization?.owner === user.id) {
        return PermissionLevel.SystemOwner;
    }
    return PermissionLevel.DecisionMaker;
};

function renderUserPicture({ profile }: User) {
    return (
        <div className={styles.userIcon}>
            {profile.avatar ? (
                <img src={profile.avatar} />
            ) : (
                <svg>
                    <use href={'/assets/img/icons.svg#users'} />
                </svg>
            )}
        </div>
    );
}

const MobileCell = (params: GridCellParams, currentUser: Partial<IEnterpriseUser>): ReactElement => {
    const user = params.row.user as User;
    const name = getUserName(user);

    return (
        <div className={styles.cellContainerMobile}>
            <div className={styles.cellMobile}>
                <div className={styles.cellBlockMobile}>
                    <div className={styles.mobileUserPictureContainer}>{renderUserPicture(user)}</div>
                    <div className={classNames(styles.mobileUserName)}>
                        <div className={classNames('spot-typography__heading--level-4', styles.name)} data-automation-id="users-grid-cell-name">
                            {name}
                        </div>
                    </div>
                </div>
                <div className={styles.cellBlockMobile}>
                    <div className={styles.mobileEmpty} />
                    <div className={classNames('spot-typography__text--tertiary', styles.mobileInfo)}>
                        <span className={styles.practiceAccess} data-automation-id="users-grid-cell-practice-access">
                            {generatePracticeAccessText(params)}
                        </span>
                        <span className={styles.siteAccess} data-automation-id="users-grid-cell-site-access">
                            {getRowPermissionLevel(user, currentUser)}
                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.cellEditUserMobile}>
                <EditUserCell {...params} />
            </div>
        </div>
    );
};

const NameHeader = (params: GridColumnHeaderParams & { columnType?: GridColumns }): ReactElement => {
    const { columnType = GridColumns.Name } = params;
    const apiRef = useGridApiContext();
    const { filterModel } = useGridSelector(apiRef, gridFilterStateSelector);
    const { sortModel } = useGridSelector(apiRef, gridSortingStateSelector);

    const [filterValue, setFilterValue] = useState<string>('');
    const onChange = (value = '') => {
        setFilterValue(value);

        const filter: GridFilterItem = {
            id: FilTerType.Name,
            columnField: GridColumns.Name,
            value: value || '',
            operatorValue: GridFilterOperatorType.Contains,
        };

        apiRef.current.upsertFilterItem(filter);
    };

    useEffect(() => {
        const selectedNameFilter = filterModel?.items.find(({ id }) => id === FilTerType.Name);
        setFilterValue(selectedNameFilter?.value || '');
    }, [filterModel]);

    useEffect(() => {
        const sorting = head(sortModel);

        if (columnType && columnType !== sorting?.field) {
            apiRef.current.setSortModel([{ field: columnType, sort: sorting?.sort }]);
            apiRef.current.applySorting();
        }
    }, [sortModel]);

    return (
        <div className={styles.headerContainerName}>
            <div
                className={classNames('spot-typography__text--body', styles.headerCell)}
                onClick={(event) => {
                    // cancel default sorting action on edit fields click
                    event.stopPropagation();
                }}
            >
                <SpotSearchbar className={styles.searchBar} onChange={onChange} value={filterValue} secondary={true} isSelfControlled={false} />
            </div>
        </div>
    );
};

const NameCell = (params: GridCellParams): ReactElement => {
    const user = params.row.user as User;
    const name = getUserName(user);

    return (
        <div className={styles.cellContainer}>
            {renderUserPicture(user)}
            <div className={styles.cellName}>
                <div className={classNames('spot-typography__heading--level-4', styles.name)} data-automation-id="users-grid-cell-name">
                    {name}
                </div>
            </div>
        </div>
    );
};

const PracticeAccessHeader = (): ReactElement => {
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>{/* add column header here*/}</div>
        </div>
    );
};

const PracticeAccessCell = (practiceAccess: string): ReactElement => {
    return (
        <div className={styles.cellContainer}>
            <div className={classNames('spot-typography__text--body', styles.cell)}>
                <span data-automation-id="users-grid-cell-practice-access">{practiceAccess}</span>
            </div>
        </div>
    );
};

const SiteAccessHeader = (): ReactElement => {
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>{/* add column header here*/}</div>
        </div>
    );
};

const SiteAccessCell = (params: GridCellParams, currentUser: Partial<IEnterpriseUser>): ReactElement => {
    const user = params.row.user as User;
    const siteAccessLevel = getRowPermissionLevel(user, currentUser);
    return (
        <div className={styles.cellContainer}>
            <div className={classNames('spot-typography__text--body', styles.cell)}>
                <span data-automation-id="users-grid-cell-site-access">{siteAccessLevel}</span>
            </div>
        </div>
    );
};

const EditUserHeader = (): ReactElement => {
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>{/* add column header here*/}</div>
        </div>
    );
};

const EditUserCell = (params: GridCellParams, currentUser: Partial<IEnterpriseUser>): ReactElement => {
    return (
        <div className={styles.cellContainer}>
            {userCanEdit(params, currentUser) && (
                <div className={classNames('spot-typography__text--body', styles.cellEditUser)}>
                    <Link
                        onClick={(event) => event.preventDefault()}
                        data-automation-id="users-grid-edit-user-link"
                        to={getEditUserLink(params.row.user as User)}
                        className={`spot-link spot-link--disabled'`}
                    >
                        {i18n.t('myOrganization:edit', 'Edit')}
                    </Link>
                </div>
            )}
        </div>
    );
};

const userCanEdit = (params: GridCellParams | GridRowParams, currentUser: Partial<IEnterpriseUser>): boolean => {
    const user = params.row.user as User;
    const loggedInUserIsOwner = currentUser.id === currentUser.organization?.owner;
    const userRowPermission = getRowPermissionLevel(user, currentUser);
    return loggedInUserIsOwner || userRowPermission !== PermissionLevel.SystemOwner;
};

const practicesAccess = (user: User, practices: PracticeInfo[], labels: LabelEntity[]) => {
    const practiceIds: number[] = [];
    const regionIds: string[] = [];
    for (const access of user.userPracticesAccess) {
        if (access.labelId) {
            regionIds.push(access.labelId);
        }
        if (access.practiceId) {
            practiceIds.push(access.practiceId);
        }
    }

    const practiceAccess = {
        practices: practices.filter(({ rowId }) => practiceIds?.includes(rowId)),
        regions: labels.filter(({ id }) => regionIds?.includes(id)),
    };
    return practiceAccess;
};

const generatePracticeAccessText = (params) => {
    const user = params.row.user as User;
    const practices = params.row.practices as PracticeInfo[];
    const labels = params.row.labels as LabelEntity[];
    let practiceAccess = !practices || !labels ? '' : `${allPractices} (${practices.length})`;
    const practiceRegionAccess = practicesAccess(user, practices, labels);

    if (practiceRegionAccess.regions.length > 0) {
        practiceAccess = '';
        for (const region of practiceRegionAccess.regions) {
            practiceAccess += `${region.name} (${region?.practices?.length || 0})`;
        }
    } else if (practiceRegionAccess.practices.length > 0) {
        practiceAccess = '';
        for (const practice of practiceRegionAccess.practices) {
            practiceAccess += `${practice.name} `;
        }
    }
    return PracticeAccessCell(practiceAccess);
};

export type UsersGridProps = PropsWithChildren<{
    users: User[];
    className?: string;
    currentUser: Partial<IEnterpriseUser>;
}>;

export const UsersGrid = observer(function UsersGrid(props: UsersGridProps) {
    const {
        ui: { router },
        domain: { practicesStore },
    } = useStores();
    const { users, className, currentUser } = props;
    const { practices = [], labels = [], isFullLoading } = practicesStore;

    const { headerHeight, rowHeight, isMobileView } = useUsersGridSize();
    useEffect(() => {
        void practicesStore.load();
    }, [practicesStore]);

    const rows = useMemo<UserPracticesRegions[]>((): UserPracticesRegions[] => {
        const data: UserPracticesRegions[] = [];
        for (const user of users) {
            data.push({ id: user.id, user, practices, labels });
        }
        return data;
    }, [users, labels, practices, isFullLoading]);
    const columns = useMemo<GridColDef[]>(
        () => [
            {
                field: GridColumns.MobileView,
                hide: !isMobileView,
                flex: 1,
                renderHeader: NameHeader,
                renderCell: (params: GridCellParams) => {
                    return MobileCell(params, currentUser);
                },
                MobileCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const user = params.row.user as User;
                    // is being sorted by this value
                    return `${getUserName(params.row.user as User)} ${user.username}`;
                },
            },
            {
                field: GridColumns.Name,
                hide: isMobileView,
                flex: 1.5,
                renderHeader: NameHeader,
                renderCell: NameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const user = params.row.user as User;
                    // is being filtered and sorted by this value
                    return `${getUserName(params.row.user as User)} ${user.username}`;
                },
            },
            {
                field: GridColumns.PracticeAccess,
                hide: isMobileView,
                flex: 1,
                hideSortIcons: true,

                renderHeader: PracticeAccessHeader,
                renderCell: (params: GridCellParams) => {
                    return generatePracticeAccessText(params);
                },
                valueGetter: (params: GridValueGetterParams) => {
                    return generatePracticeAccessText(params);
                },
            },
            {
                field: GridColumns.SiteAccess,
                hide: isMobileView,
                flex: 1,
                hideSortIcons: true,
                renderHeader: SiteAccessHeader,
                renderCell: (params: GridCellParams) => {
                    return SiteAccessCell(params, currentUser);
                },
                valueGetter: (params: GridValueGetterParams) => {
                    const user = params.row.user as User;
                    return getRowPermissionLevel(user, currentUser);
                },
            },
            {
                field: GridColumns.EditUser,
                hide: isMobileView,
                width: 70,
                hideSortIcons: true,
                renderHeader: EditUserHeader,
                renderCell: (params: GridCellParams) => {
                    return EditUserCell(params, currentUser);
                },
            },
        ],
        [isMobileView],
    );

    const initialSortModel = useMemo<GridSortModel>(
        () => [
            {
                field: isMobileView ? GridColumns.MobileView : GridColumns.Name,
                sort: 'asc' as GridSortDirection,
            },
        ],
        [],
    );

    const initialState: GridInitialState = {
        sorting: { sortModel: initialSortModel },
    };

    const onRowClick = (param: GridRowParams): void => {
        const to = getEditUserLink(param.row as User);
        userCanEdit(param, currentUser) && router.push(to);
    };

    const renderNoRows = (title: string) => {
        return (
            <div className={classNames(styles.noRows, { [styles.scrollable]: !isMobileView })}>
                <Image className={styles.noRowsImage} url={'img/noResultsDog.png'} />
                <span>{title}</span>
            </div>
        );
    };

    const rowStyles = (param: GridRowParams) => {
        return userCanEdit(param, currentUser) ? styles.row : styles.rowNoHover;
    };
    return (
        <MaterialDataGrid
            className={className}
            columns={columns}
            rows={rows}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            getRowClassName={rowStyles}
            onRowClick={onRowClick}
            autoHeight={true}
            initialState={initialState}
            noRowsTitle={renderNoRows(i18n.t('myOrganization:noRows', 'No Rows'))}
            noResultsTitle={renderNoRows(i18n.t('myOrganization:noResultsFound', 'No Results Found'))}
            disableSelectionOnClick={true}
        />
    );
});
