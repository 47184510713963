/*
 * Setting static variables on each page does not allow us to construct nested page url's and will
 * result in an error. For instance if we had a static url on the Master Data Page and attempted to
 * import that variable into Classifications Mapping Page to add '/classifications' to it we would
 * get the following error: "Cannot access 'MasterDataPage' before initialization"
 */

const Root = '/';
const Login = '/login';
const Logout = '/logout';
const Signup = '/signup';
const BasePages = {
    Root,
    Login,
    Logout,
    Signup,
};

// CONTROL CENTER
const ControlCenter = '/control-center';
const Import = ControlCenter + '/import';
const Items = ControlCenter + '/items';
const Activity = ControlCenter + '/activity';

const EditItem = Items + '/edit';
const NewItem = Items + '/new-item';

const ControlCenterPages = {
    ControlCenter,
    Import,
    Items,
    Activity,
    EditItem,
    NewItem,
};

// MASTER DATA
const MasterData = '/master-data';
const ClassificationsMappingPage = MasterData + '/classifications';
const ClassificationsMappingCreatePage = ClassificationsMappingPage + '/new';
const ClassificationsMappingDetailsPage = ClassificationsMappingPage + '/details';
const InvoiceItemsMappingPage = MasterData + '/invoice-items';
const HistoryMappingPage = MasterData + '/history';
const MasterDatabaseMappingPage = ClassificationsMappingPage + '/database';
const FileUploadMappingPage = ClassificationsMappingPage + '/fileUpload';

const MasterDataPages = {
    MasterData,
    ClassificationsMappingCreatePage,
    ClassificationsMappingPage,
    ClassificationsMappingDetailsPage,
    HistoryMappingPage,
    InvoiceItemsMappingPage,
    MasterDatabaseMappingPage,
    FileUploadMappingPage,
};

// MY ORGANIZATIONS
const MyOrganization = '/my-organization';
const MyPractices_DEPRECATED = '/my-practices';
const PracticeConnections = MyOrganization + '/practice-connections';
const ConnectPractice = PracticeConnections + '/connect-practice';
const UserManagement = MyOrganization + '/user-management';
const EditUser = `${UserManagement}/edit-user`;

const MyOrganizationPages = {
    MyOrganization,
    MyPractices_DEPRECATED,
    PracticeConnections,
    ConnectPractice,
    UserManagement,
    EditUser,
};

// ANALYTICS
const Analytics = '/analytics';
const AnalyticsDashboardsPage = Analytics + '/dashboards';
const AnalyticsLooksPage = Analytics + '/looks';

const AnalyticsPages = {
    Analytics,
    AnalyticsDashboardsPage,
    AnalyticsLooksPage,
};

// MY PROFILE
const MyProfile = '/my-profile';
const MyProfileEdit = MyProfile + '/edit';

const MyProfilePages = {
    MyProfile,
    MyProfileEdit,
};

const Cornerstone = '/cornerstone';

export const RouterPaths = {
    BasePages,
    ControlCenterPages,
    MasterDataPages,
    MyOrganizationPages,
    AnalyticsPages,
    MyProfilePages,
    Cornerstone,
};
