import * as React from 'react';
import { BaseSpotComponent, SpotInputProps } from './common';
import { SpotFieldError } from './SpotFieldError';

export interface SpotToggleProps extends SpotInputProps {
    automationId?: string;
}

export class SpotToggle extends BaseSpotComponent<SpotToggleProps> {
    render() {
        const { inputAutomationId, automationId, className, name, error, children, id, ...rest } = this.props;
        return (
            <span className={className}>
                <input id={this.idProp} name={name} data-automation-id={inputAutomationId} {...rest} type="checkbox" className="spot-toggle" />
                <label className="spot-toggle-label" data-automation-id={automationId} htmlFor={this.idProp}>
                    {children}
                </label>
                {error && <SpotFieldError meta={error} />}
            </span>
        );
    }
}
