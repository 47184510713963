import * as React from 'react';
import { Container } from 'react-bootstrap';

export default class BasicLayout extends React.Component {
    render() {
        const { children } = this.props;

        return (
            <div className="base-layout">
                <Container fluid={true}>{children}</Container>
            </div>
        );
    }
}
