import * as React from 'react';
import { SpotSvg } from './SpotSvg';

export class SpotFlyoverLoading extends React.PureComponent<{ text?: string; size?: 'large' | 'small' }> {
    render() {
        const { text, size } = this.props;
        const sizeClass = size ? `spot-loading-spinner--${size}` : '';
        return (
            <div className={'spot-flyover-loading'}>
                <div>
                    <SpotSvg className={`spot-icon spot-loading-spinner ${sizeClass}`} icon="spinner" />
                </div>
                <span>{text}</span>
            </div>
        );
    }
}
