import * as React from 'react';
import { Trans } from 'react-i18next';
import { Container, Col, Row } from 'react-bootstrap';
import { Field } from '@enterprise/common';
import { SpotRadio, SpotCheckbox, SpotTextInput, SpotHorizontalFields, SpotRadioGroup } from '@enterprise/spot';
import { MARKUP_TYPE_DOWN, MARKUP_TYPE_UP, PRICE_TYPE_BASE, PRICE_TYPE_MINIMUM } from '../constants';
import { formatPrice } from '../../../../../core/helpers';
import { invoiceItemPriceLimits } from '../../invoiceItemPriceLimits.constants';

const { maxDecimalPlaces } = invoiceItemPriceLimits;

interface PricingEditFormFieldsetProps {
    markupType: string;
}

export class PricingEditFormFieldset extends React.PureComponent<PricingEditFormFieldsetProps> {
    render() {
        const { markupType } = this.props;
        const isMarkUpTypeDown = markupType === MARKUP_TYPE_DOWN;
        return (
            <Container fluid={true}>
                <Row>
                    <Col xs={12}>
                        <div style={{ marginTop: '10px' }}>
                            <legend className="spot-form__radio-group-label common-legend">Price Type</legend>
                        </div>

                        <SpotHorizontalFields>
                            <SpotRadioGroup isHorizontal={true}>
                                <Field name="priceType" type="radio" value={PRICE_TYPE_BASE}>
                                    {({ input, meta }) => (
                                        <SpotRadio {...input}>
                                            <Trans key="editPricing:basePrice">Base</Trans>
                                        </SpotRadio>
                                    )}
                                </Field>
                                <Field name="priceType" type="radio" value={PRICE_TYPE_MINIMUM}>
                                    {({ input, meta }) => (
                                        <SpotRadio {...input}>
                                            <Trans key="editPricing:minimumPrice">Minimum Pricing</Trans>
                                        </SpotRadio>
                                    )}
                                </Field>
                            </SpotRadioGroup>
                        </SpotHorizontalFields>

                        <div>
                            <legend className="spot-form__radio-group-label common-legend">MassUp/MassDown</legend>
                        </div>

                        <SpotHorizontalFields>
                            <SpotRadioGroup isHorizontal={true}>
                                <Field name="markupType" type="radio" value={MARKUP_TYPE_UP}>
                                    {({ input, meta }) => (
                                        <SpotRadio {...input}>
                                            <Trans key="editPricing:markUp">MarkUp</Trans>
                                        </SpotRadio>
                                    )}
                                </Field>
                                <Field name="markupType" type="radio" value={MARKUP_TYPE_DOWN}>
                                    {({ input, meta }) => (
                                        <SpotRadio {...input}>
                                            <Trans key="editPricing:markDown">MarkDown</Trans>
                                        </SpotRadio>
                                    )}
                                </Field>
                            </SpotRadioGroup>
                        </SpotHorizontalFields>

                        <SpotHorizontalFields>
                            <Field name="percent" format={formatPrice(maxDecimalPlaces)} formatOnBlur={true}>
                                {({ input, meta }) => (
                                    <div>
                                        <label className="spot-form__label">
                                            <Trans key="editPricing:percent">Percent</Trans>
                                        </label>
                                        <SpotTextInput {...input} error={meta} type="number" step="1" placeholder="XX %" />
                                    </div>
                                )}
                            </Field>
                            <Field name="minimumAmount" format={formatPrice(maxDecimalPlaces)} formatOnBlur={true}>
                                {({ input, meta }) => (
                                    <div>
                                        <label className="spot-form__label">
                                            <Trans key="editPricing:minimumAmount">Minimum Amount</Trans>
                                        </label>
                                        <SpotTextInput {...input} error={meta} type="number" step="0.1" placeholder="$XXX.XX" />
                                    </div>
                                )}
                            </Field>
                        </SpotHorizontalFields>

                        <Field name="isIncludeZero" type="checkbox">
                            {({ input, meta }) => (
                                <SpotCheckbox
                                    {...input}
                                    disabled={isMarkUpTypeDown}
                                    checked={isMarkUpTypeDown ? false : input.checked}
                                    value={isMarkUpTypeDown ? 'false' : input.value}
                                >
                                    <Trans key="editPricing:includeZero">Include Zero</Trans>
                                </SpotCheckbox>
                            )}
                        </Field>
                    </Col>
                </Row>
            </Container>
        );
    }
}
