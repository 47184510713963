import * as React from 'react';

interface StackDropDownCaretProps {
    className?: string;
}

export const StackDropDownCaret: React.FunctionComponent<StackDropDownCaretProps> = props => {
    return (
        <svg className={props.className} xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
            <path
                d="M15.903 1.881L14.093.096A.32.32 0 0 0 13.858 0a.32.32 0 0 0-.234.096L8
            5.624 2.394.096A.333.333 0 0 0 2.151 0a.333.333 0 0 0-.243.096L.098 1.881a.312.312
             0 0 0-.098.23c0 .09.032.167.097.23l7.67 7.563A.333.333 0 0 0 8.01 10a.333.333
              0 0 0 .243-.096l7.65-7.562a1.07 1.07 0 0 0 .068-.116.253.253 0 0 0 .029-.115.293.293
               0 0 0-.03-.134.365.365 0 0 0-.067-.096z"
            />
        </svg>
    );
};
