export enum INTENT {
    DANGER = 'danger',
    DEFAULT = 'default',
    PRIMARY = 'primary',
    WARNING = 'warning',
    DISABLED = 'disabled',
}

const CSS_PREFIX = 'mapping-ui__';

export const MAPPING_UI = {
    // BOARD
    BOARD: CSS_PREFIX + 'board',
    BOARD_DISABLED: CSS_PREFIX + 'board--disabled',

    // CARDS
    CARD: CSS_PREFIX + 'card',
    CARD_BOTTOM: CSS_PREFIX + 'card--bottom',
    CARD_CONTAINER: CSS_PREFIX + 'card--container',
    CARD_CONTAINER_CHILD: CSS_PREFIX + 'card--container--child',
    CARD_CONTENT: CSS_PREFIX + 'card--content',
    CARD_DESCRIPTION: CSS_PREFIX + 'card--description',
    CARD_HANDLE: CSS_PREFIX + 'card--handle',
    CARD_HANDLE_GRIP: CSS_PREFIX + 'card--handle-grip',
    CARD_HANDLE_PARENT: CSS_PREFIX + 'card--handle--parent',
    CARD_HANDLE_CHILD: CSS_PREFIX + 'card--handle--child',
    CARD_ID: CSS_PREFIX + 'card--id',
    CARD_IS_DRAGGING: CSS_PREFIX + 'card--dragging',
    CARD_TARGETED: CSS_PREFIX + 'card--targeted',
    CARD_IS_PARENT: CSS_PREFIX + 'card--parent',
    CARD_IS_CHILD: CSS_PREFIX + 'card--child',
    CARD_IS_FINAL_CHILD: CSS_PREFIX + 'card--child--final',
    CARD_IS_CREATE_CHILD: CSS_PREFIX + 'card--child--create',
    CARD_IS_DISABLED: CSS_PREFIX + 'card--disabled',
    CARD_IS_TITLE_CLICKABLE: CSS_PREFIX + 'card--title-clickable',
    CARD_CHILD_CONTENT: CSS_PREFIX + 'card-child--content',
    CARD_CREATE_TITLE: CSS_PREFIX + 'card--child--create-title',
    CARD_CREATE_ICON: CSS_PREFIX + 'card--child--create-icon',

    // COLUMNS
    COLUMN: CSS_PREFIX + 'column',
    COLUMN_COLLECTION_CHAR: CSS_PREFIX + 'column--collection-char',
    COLUMN_DROPPABLE_ADD: CSS_PREFIX + 'column--droppable-addition',
    COLUMN_HEADER: CSS_PREFIX + 'column-header',
    COLUMN_HEADER_ACTIONS: CSS_PREFIX + 'column-header--actions',
    COLUMN_HEADER_ACTION_MENU_ICON: CSS_PREFIX + 'column-header--actions-menu-icon',
    COLUMN_HEADER_ACTION_MENU_ITEM: CSS_PREFIX + 'column-header--actions-menu-item',
    COLUMN_HEADER_TITLE: CSS_PREFIX + 'column-header--title',
    COLUMN_HR: 'horizontal-rule',
    COLUMN_LETTER_BAR: CSS_PREFIX + 'column--letter-bar',
    COLUMN_SCROLLABLE: CSS_PREFIX + 'column--scrollable',

    // DROPPABLE
    FULL_WIDTH_DROPABLE: CSS_PREFIX + 'droppable--full-width',

    // STACK DROP DOWN
    STACK_DROP_DOWN: CSS_PREFIX + 'stack-drop-down',
    STACK_DROP_DOWN_CARET: CSS_PREFIX + 'stack-drop-down--caret-down',
};
