import React, { memo, ReactNode, useMemo, useRef } from 'react';
import { ProTips } from '../../../components/helpers/ProTips';
import { PageHeader } from '../pageHeader';
import styles from './flexPageWrapper.module.scss';
import { PageFooter } from '../pageFooter';
import classNames from 'classnames';
import { useWindowSize } from '../../../hooks';
import { max } from 'lodash';

export interface PageWrapperProps {
    className?: string;
    isLoading?: boolean;
    children?: ReactNode;
    header?: ReactNode | string;
    footer?: ReactNode;
    back?: () => void;
}

export const FlexPageWrapper = memo(function FlexPageWrapper(props: PageWrapperProps) {
    const { header, footer, back, children, isLoading, className } = props;
    const footerRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();
    const footerHeight = useMemo(() => {
        const footerMinHeight = Number(styles.footerMinHeight);

        if (!footerRef.current) {
            return;
        }

        return max([footerRef.current.offsetHeight, footerMinHeight]);
    }, [width, footerRef.current]);

    return (
        <div className={styles.wrapperContainer} data-automaton-id={'page-wrapper-container'}>
            {header && (
                <div className={styles.headerContainer} data-automaton-id={'page-wrapper-header-container'}>
                    <PageHeader back={back} title={header} className={styles.pageHeader} data-automaton-id={'page-wrapper-header'} />
                </div>
            )}
            <div className={classNames(styles.pageContent, className)} data-automaton-id={'page-wrapper-main-content'}>
                {isLoading && <ProTips />}
                {!isLoading && children}
            </div>
            {footer && (
                <div className={styles.footerContainer} style={{ height: footerHeight }} data-automaton-id={'page-wrapper-footer-container'}>
                    <PageFooter ref={footerRef} className={styles.pageFooter} data-automaton-id={'page-wrapper-footer'}>
                        {footer}
                    </PageFooter>
                </div>
            )}
        </div>
    );
});
