import * as React from 'react';
import classNames from 'classnames';
import { BaseSpotComponent, SpotInputProps } from './common';
import { SpotSvg } from '../SpotSvg';
import { SpotFieldError } from './SpotFieldError';

export interface SelectProps {
    isHidePlaceholder?: boolean;
    placeholder?: string;
}

export class SpotSelectBox extends BaseSpotComponent<SelectProps & SpotInputProps & React.HTMLProps<HTMLSelectElement>> {
    render() {
        const { className, error, id, children, style, placeholder, isHidePlaceholder, disabled, ...rest } = this.props;
        return (
            <span style={style}>
                <div className={classNames('spot-form__select', className)}>
                    <select className="spot-form__select-input" {...rest} id={this.idProp} disabled={disabled}>
                        {!isHidePlaceholder && <option value={''}>{placeholder ? placeholder : 'Select a value'}</option>}
                        {children}
                    </select>
                    <div className="spot-form__select-inner" />
                    <span className="spot-form__select-open-indicator">
                        <SpotSvg className="spot-form__select-open-icon" icon="caret-down" />
                    </span>
                </div>
                {error && <SpotFieldError meta={{ ...error, name: rest.name }} />}
            </span>
        );
    }
}

export class SpotSelectOption extends React.PureComponent<React.HTMLProps<HTMLOptionElement>> {
    render() {
        const { className, children, ...rest } = this.props;
        return (
            <option className={['spot-form__select-option', className].join(' ')} {...rest}>
                {children}
            </option>
        );
    }
}
