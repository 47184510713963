import * as React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import { RightSidebar } from '../../../layout/main/rightSidebar';
import { environment } from '../../../environment/environment';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, Container } from 'react-bootstrap';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { ItemUpdateChildRow } from '../components/ItemUpdateChildRow';
import { TaskEntity, TaskErrorMessage } from '../../../core/entity/TaskEntity';
import styles from './ActivityPage.module.scss';
import { useStores } from '../../../hooks';
import { useEffect } from 'react';

export const ScheduledItemDetailsPage = observer(function ScheduledItemDetailsPage() {
    const {
        domain: { activityPageStore },
        ui: { router },
    } = useStores();

    const { task } = activityPageStore;
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (!id) {
            return;
        }

        activityPageStore.fetchTask(id);

        return () => {
            activityPageStore.clearTask();
        };
    }, [id]);

    const back = () => {
        router.push('/control-center/activity');
    };

    const getTaskStatus = (errorMessage?: TaskErrorMessage[]): TaskErrorMessage[] | undefined => {
        try {
            return errorMessage && ([] as TaskErrorMessage[]).concat(errorMessage);
        } catch (error) {
            console.error(error);
        }

        return;
    };

    const showScheduledItemContent = (scheduledItem: TaskEntity) => {
        const { children, errorMessage } = scheduledItem;
        const statuses = getTaskStatus(errorMessage);

        return (
            <Card>
                <Card.Body>
                    <Container>
                        {children?.map((child, index) => {
                            const { siteId } = child.payload.provider;
                            const statusInfo = statuses?.find(({ provider }) => provider?.siteId === siteId);
                            const status = statusInfo?.status || (statusInfo?.error && TaskStatus.ERROR) || scheduledItem.status;
                            const childErrors = errorMessage?.find(({ provider }) => !provider?.siteId || provider?.siteId === siteId);
                            const siteInformation = activityPageStore.applications.find((app) => app.id === siteId);
                            const practiceName = siteInformation !== undefined ? siteInformation.name : siteId;
                            const key = `${siteId}_${index}`;

                            return (
                                <ItemUpdateChildRow
                                    key={key}
                                    id={siteId}
                                    description={practiceName}
                                    status={status}
                                    details={childErrors && [childErrors]}
                                    className={styles.detailsRow}
                                />
                            );
                        })}
                    </Container>
                </Card.Body>
            </Card>
        );
    };

    return (
        <RightSidebar isLoading={!task} close={back} title="Details" closeOnClickOutside={true}>
            <div className="bulk-updates-details-page">
                {task && (
                    <>
                        <div className={styles.bulkUpdateDetailsText} data-automation-id={'activity-item-details-text'}>
                            <ul>
                                <li>
                                    <span className="spot-typography__font-weight--bold">
                                        <Trans i18nKey="activityPage:name">Name</Trans>
                                    </span>
                                    : {task.name}
                                </li>
                                <li>
                                    <span className="spot-typography__font-weight--bold">
                                        <Trans i18nKey="activityPage:id">Id</Trans>
                                    </span>
                                    : {task.id}
                                </li>
                                <li>
                                    <span className="spot-typography__font-weight--bold">
                                        <Trans i18nKey="activityPage:status">Status</Trans>
                                    </span>
                                    : {task.status}
                                </li>
                                <li>
                                    <span className="spot-typography__font-weight--bold">
                                        <Trans i18nKey="activityPage:scheduledExecutionDate">Scheduled execution date</Trans>
                                    </span>
                                    :{moment(task.runAt).format(environment.dateFormat)}
                                </li>
                                <li>
                                    <span className="spot-typography__font-weight--bold">
                                        <Trans i18nKey="activityPage:latestAttemptDate">Latest attempt date</Trans>
                                    </span>
                                    :{moment(task.latestAttempt).format(environment.dateFormat)}
                                </li>
                            </ul>
                        </div>
                        {showScheduledItemContent(task)}
                        {task.errorMessage && (
                            <div className={styles.bulkUpdateDetailsText} data-automation-id={'activity-item-details-text'}>
                                <ul>
                                    <li>
                                        <span className="spot-typography__font-weight--bold">
                                            <Trans i18nKey="activityPage:statusMessage">Status message</Trans>
                                        </span>
                                        : {JSON.stringify(task.errorMessage)}
                                    </li>
                                </ul>
                            </div>
                        )}
                    </>
                )}
            </div>
        </RightSidebar>
    );
});
