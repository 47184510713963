import { ItemData } from './ItemData';

export const NO_PARENT_ID = 'NO_PARENT_ID';
export const NO_MAPPED_ID = 'NO_MAPPED_ID';

export class LinkedItem {
    data: ItemData;
    isApproved: boolean;
    linkedId: string;
    practiceId: string;
    pimsType: string;
    pimsParentId: string | null | undefined;

    constructor({ data, isApproved, linkedId, practiceId, pimsType, pimsParentId }: Required<LinkedItem>) {
        this.data = data;
        this.isApproved = isApproved;
        this.linkedId = linkedId;
        this.practiceId = practiceId;
        this.pimsType = pimsType;
        this.pimsParentId = pimsParentId || NO_PARENT_ID;
    }
}
