import * as React from 'react';
import { Container } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { RouterStore } from '../../../store';
import { MasterDataPageStore, SetupTypes } from '../MasterDataPageStore';
import { ClassificationsSetupPage } from './ClassificationsSetupPage';
import { ProTips } from '../../../components/helpers/ProTips';
import { ClassificationsBoardPage } from './ClassificationsBoardPage';
import { RouterPaths } from '../../../router/RouterPaths';
import { Route } from 'react-router-dom';
import { ClassificationDetailsPage } from './ClassificationDetailsPage';
import { StoresContext } from '../../../contexts';
import { RootRoutes } from '../../../router';

@observer
export class ClassificationsMappingPage extends React.Component {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    get store(): MasterDataPageStore {
        return this.context.domain.masterDataPageStore;
    }

    get routerStore(): RouterStore {
        return this.context.ui.router;
    }

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        await this.store.load();
    }

    componentWillUnmount() {
        this.store.dispose();
    }

    pageContents() {
        const { areClassificationsMapped } = this.store;

        if (areClassificationsMapped === undefined) {
            return null;
        }

        if (areClassificationsMapped) {
            return (
                <>
                    <RootRoutes>
                        <Route path={RouterPaths.MasterDataPages.ClassificationsMappingCreatePage} element={<ClassificationDetailsPage />} />
                        <Route path={RouterPaths.MasterDataPages.ClassificationsMappingDetailsPage} element={<ClassificationDetailsPage />} />
                    </RootRoutes>
                    <ClassificationsBoardPage />
                </>
            );
        }

        return <ClassificationsSetupPage submit={this.setupClassifications} />;
    }

    setupClassifications = (setupType) => {
        if (setupType === SetupTypes.OUR_CLASSIFICATIONS) {
            this.store.useDefaultClassesAndSubclassesAsMaster();
        } else if (setupType === SetupTypes.EXISTING_DATABASE) {
            this.routerStore.push(RouterPaths.MasterDataPages.MasterDatabaseMappingPage);
        }
    };

    render() {
        const isLoaded = this.store.isLoaded;
        return (
            <Container className={'master-data-page'}>
                {!isLoaded && <ProTips />}
                {isLoaded && this.pageContents()}
            </Container>
        );
    }
}
