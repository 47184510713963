import React, { createRef } from 'react';
import { observer } from 'mobx-react';
import { EnterpriseUserToken } from '@enterprise/common';
import { Header } from '../header';
import { StoresContext } from '../../../contexts';

@observer
export class MainLayout extends React.Component {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    scrollContainerElement = createRef<HTMLDivElement>();
    logout = () => {
        const { ui } = this.context;
        ui.app.logout();
    };

    render() {
        const { children } = this.props;
        const {
            ui: { app },
        } = this.context;

        const menuItems = app.getAvailableMenuItems();

        let orgName = '';
        if ((app.token as EnterpriseUserToken).user.organization) {
            orgName = (app.token as EnterpriseUserToken).user.organization.name;
        }
        return (
            <div className="main-layout">
                <Header
                    navigation={menuItems}
                    user={{
                        username: app.token.getDisplayName(),
                        orgName,
                        avatar: app.token?.user?.profile?.avatar,
                    }}
                    onLogout={this.logout}
                />
                <div className="main">
                    <div id="connect-page-content" className="connect-page-content" ref={this.scrollContainerElement}>
                        <div id="scroll-to-here" />
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}
