import React, { FC, ReactElement, Fragment } from 'react';
import { StackSet } from './Column';
import { StackDropDown } from './StackDropDown';
import { ColumnOptions } from '../interfaces';
import { MAPPING_UI } from '../styles';
import { ActionMenu } from './ActionMenu';

interface ColumnHeaderProps {
    columnOptions?: ColumnOptions;
    isPrimary: boolean;
    stackHandler: (number) => void;
    stackSets?: StackSet[];
    title?: string;
}

interface HeaderActions {
    id: string;
    element: ReactElement;
}

enum MENU_ID {
    ACTIONS = 'actionMenu',
}

export const ColumnHeader: FC<ColumnHeaderProps> = ({ columnOptions, isPrimary, stackHandler, stackSets, title }) => {
    const titleComponent = function(): ReactElement {
        if (!stackSets || stackSets.length === 1) {
            return <h5>{title}</h5>;
        }
        return <StackDropDown stackHandler={stackHandler} stackSets={stackSets} />;
    };

    const headerActions = function(): HeaderActions[] {
        if (!columnOptions) {
            return [];
        }
        // filter and search actions can be added here later
        const components: HeaderActions[] = [];
        if (isPrimary && columnOptions.primaryActionMenu) {
            components.push({
                id: MENU_ID.ACTIONS,
                element: <ActionMenu actions={columnOptions.primaryActionMenu} sourceColumn={'primary'} />,
            });
        }
        return components;
    };

    return (
        <div className={MAPPING_UI.COLUMN_HEADER}>
            <div className={MAPPING_UI.COLUMN_HEADER_TITLE}>{titleComponent()}</div>
            <div className={MAPPING_UI.COLUMN_HEADER_ACTIONS}>
                {headerActions().map(({ id, element }) => (
                    <Fragment key={id}>{element}</Fragment>
                ))}
            </div>
        </div>
    );
};
