export interface ValidationResult {
    [field: string]: ValidationResult;
}

export interface FormTypeProps<T> {
    initialValues?: Partial<T>;
}

export class FormType<T, TEntity = T> {
    name = 'default';

    onChange(value: T) {
        // implementation should be in inherited classes
    }

    buildForm(data?: T): FormTypeProps<T> {
        return {
            initialValues: data,
        };
    }

    getEntity(value: T | Record<string, any>): TEntity {
        return value as TEntity;
    }

    validate(value: T): Promise<ValidationResult> {
        return Promise.resolve({});
    }
}
