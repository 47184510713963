import * as React from 'react';
import lodash from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { ItemsPageStore } from './ItemsPageStore';
import { Notifications } from '../../Notifications';
import i18n from 'i18next';
import ItemSearchBar from './filters/itemSearchBar';
import PresetsSidebar from './filters/PresetsSidebar';
import CreatePresetDialog from './filters/CreatePresetDialog';
import { PresetEntity } from '../../../services/PresetService';
import ItemFilters from './filters/ItemFilters';
import { StoresContext } from '../../../contexts';
import ConstantsStore from '../../../store/ConstantsStore';
import AppStore from 'apps/client/src/store/AppStore';
import { FEATURES } from '@enterprise/core';
import { GetItemQuery, InvoiceItem } from '../../../core/models';

interface PageProps {
    searchItems: any;
    searchItemsForAutoComplete?: (query: GetItemQuery) => Promise<InvoiceItem[]>;
}

interface PageState {
    currentFilter: any;
    selectedPresetId: any;
    isShowPresetsSidebar: boolean;
    isShowCreatePresetModal: boolean;
}

const CLASSIFICATION_TRIGGERS = ['class: ', 'class:'];

@observer
export class ItemSearchPanel extends React.Component<PageProps, PageState> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    get itemsPageStore(): ItemsPageStore {
        return this.context.domain.itemsPageStore;
    }

    get constantsStore(): ConstantsStore {
        return this.context.domain.constantsStore;
    }

    get appStore(): AppStore {
        return this.context.ui.app;
    }

    state: PageState = {
        currentFilter: { searchValue: '' },
        selectedPresetId: undefined,
        isShowPresetsSidebar: false,
        isShowCreatePresetModal: false,
    };

    componentDidMount = () => {
        this.setState(
            {
                currentFilter: this.itemsPageStore.filter || { searchValue: '' },
            },
            this.refreshSearch,
        );
    };

    isValidSearch = (value, type) => {
        const valueLength = value.trim().length;

        let validationError;
        if (valueLength === 0) {
            validationError = i18n.t('validation:searchNotEmpty', {
                field: i18n.t('controlCenter:itemSearch.id', 'ID'),
            });
        }

        if (validationError) {
            Notifications.warn(validationError);
            return false;
        }
        return true;
    };

    refreshSearch = () => {
        if (this.state?.currentFilter?.searchValue.length > 0 && this.enhancedSearchUiEnabled) {
            this.searchItems();
        }
    };

    searchItems = () => {
        // On intial load, the type is not available to the filter. We check it here to see if it is set or not, and not
        // expicity set it. This will likely only happen on the first search after a refresh of the page.
        const searchFilter = this.state.currentFilter;
        const searchQuery = { searchType: searchFilter.searchType, searchValue: searchFilter.searchValue };

        const trigger = CLASSIFICATION_TRIGGERS.find((trigger) => {
            if (searchFilter.searchValue?.toLowerCase().includes(trigger)) {
                return trigger;
            }
            return undefined;
        });
        if (searchFilter.searchValue.toLowerCase().includes(trigger) && this.enhancedSearchEnabled) {
            searchQuery.searchValue = searchFilter.searchValue.substring(trigger?.length);
            searchQuery.searchType = 'classification';
        }

        if (!this.state.currentFilter.searchType && !searchQuery.searchType) {
            this.setState({ currentFilter: { ...this.state.currentFilter, searchType: this.itemsPageStore.itemsGroupBy } });
            searchFilter.searchType = this.itemsPageStore.itemsGroupBy;
        }

        const { searchType = this.itemsPageStore.itemsGroupBy, searchValue, classId } = searchFilter;
        if (!classId && !this.isValidSearch(searchValue, searchType)) {
            return;
        }

        const query = {
            ...lodash.mapValues(this.enhancedSearchEnabled ? searchQuery : searchFilter, (val) => (!lodash.isNil(val) ? val.toString() : null)),
            ...this.getSearchValueParams(),
        };

        this.props.searchItems(query);
    };

    getSearchValueParams(value: string = this.state.currentFilter.searchValue): {} {
        const { searchType = this.itemsPageStore.itemsGroupBy } = this.state.currentFilter;
        return searchType === 'id' ? { id: value } : { description: value };
    }

    updateFilter = (update, callback?) => {
        const newFilter = {
            ...this.state.currentFilter,
            ...update,
        };
        this.setState({ currentFilter: newFilter }, callback);
        this.itemsPageStore.filter = newFilter;
    };

    updateFilterAndSearch = (update) => {
        this.updateFilter(update, this.searchItems);
    };

    savePreset = (presetName) => {
        const newPreset = new PresetEntity();
        newPreset.name = presetName;
        newPreset.content = this.state.currentFilter;
        this.itemsPageStore.savePreset(newPreset).then(this.showCreatePresetModal);
    };

    applyPreset = (presetId) => {
        const preset = this.itemsPageStore.presets.find((p) => p.id === presetId);
        if (preset) {
            this.setState({
                selectedPresetId: presetId,
                currentFilter: preset.content,
            });
        }
    };

    showPresetsSidebar = () => {
        this.setState({ isShowPresetsSidebar: !this.state.isShowPresetsSidebar });
    };

    showCreatePresetModal = () => {
        this.setState({ isShowCreatePresetModal: !this.state.isShowCreatePresetModal });
    };

    private get enhancedSearchEnabled(): boolean {
        return this.appStore.featureManager.isAvailable(FEATURES.ENHANCED_PRODUCT_SEARCH, true);
    }

    private get enhancedSearchUiEnabled(): boolean {
        return this.appStore.featureManager.isAvailable(FEATURES.ENHANCED_SEARCH_UI, true);
    }

    render() {
        const { searchType = this.itemsPageStore.itemsGroupBy, classId = '', ...rest } = this.state.currentFilter;

        const currentFilter = {
            ...rest,
            searchType,
            classId,
        };

        return (
            <>
                {this.state.isShowPresetsSidebar && <PresetsSidebar close={this.showPresetsSidebar} applyPreset={this.applyPreset} />}

                {this.state.isShowCreatePresetModal && (
                    <CreatePresetDialog isVisible={true} onSubmit={this.savePreset} onClose={this.showCreatePresetModal} />
                )}

                <Row>
                    <Col xs={12}>
                        <ItemSearchBar
                            classifications={this.itemsPageStore.getLookupResults(this.itemsPageStore.classifications)}
                            presets={this.itemsPageStore.presets}
                            submit={this.searchItems}
                            currentFilter={currentFilter}
                            updateFilter={this.updateFilter}
                            selectedPresetId={this.state.selectedPresetId}
                            applyPreset={this.applyPreset}
                            viewAllPresets={this.showPresetsSidebar}
                            searchItemsForAutoComplete={this.props.searchItemsForAutoComplete}
                        />
                    </Col>
                </Row>
                {this.enhancedSearchUiEnabled ? (
                    <Row style={{ marginTop: '-20px' }}></Row>
                ) : (
                    <Row style={{ marginTop: '-20px' }}>
                        <Col xs={12}>
                            <ItemFilters
                                applications={this.constantsStore.applications}
                                itemTypes={this.itemsPageStore.getLookupResults(this.itemsPageStore.itemTypes)}
                                currentFilter={currentFilter}
                                updateFilter={this.updateFilterAndSearch}
                                savePreset={this.showCreatePresetModal}
                                enhancedSearch={this.enhancedSearchEnabled}
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}
