import { AutoCalculatePrice, PriceBreakUnit, PricingChangePolicies, RoundingPrecisions } from '../enums';

export const DEFAULT_PRICING_DECIMALS = 2;

class AutoCalculatePriceNormalized {
    id: AutoCalculatePrice;
}

class BreakUnitNormalized {
    id: PriceBreakUnit;
}

class PricingChangeTypeNormalized {
    id: PricingChangePolicies;
}

class RoundToNormalized {
    id: RoundingPrecisions;
}

export class PriceBreak {
    breakAmount: number;

    invoiceMarkup: number;

    invoicePrice: number;
}

export class InvoiceItemPrices {
    autoCalculatePrice: AutoCalculatePriceNormalized;

    breakUnitNormalized?: BreakUnitNormalized;

    decimals?: number = DEFAULT_PRICING_DECIMALS;

    dispensingFee?: number;

    markupPercentage?: number;

    minimumPrice?: number;

    omitDispensingFeeForMultiplePatients: boolean;

    priceBreaks?: PriceBreak[];

    costPrice?: number;

    listPrice?: number;

    pricingChangeType: PricingChangeTypeNormalized;

    roundTo: RoundToNormalized;
}
