import React, { memo } from 'react';
import Modal from '@mui/material/Modal';
import styles from './cancelTaskModal.module.scss';
import i18n from '../../../../i18n';
import classNames from 'classnames';
import { SpotButton } from '@enterprise/spot';

export enum CancelTaskModalResponse {
    Yes = 'yes',
    No = 'no',
}

interface CancelTaskModalProps {
    opened?: boolean;
    onChange: (result: CancelTaskModalResponse) => void;
}

export const CancelTaskModal = memo(function NavigationGuard(props: CancelTaskModalProps) {
    const { opened = false, onChange } = props;

    return (
        <Modal open={opened}>
            <div className={styles.container} data-automation-id={'unsaved-changes-nav-guard'}>
                <div className="spot-modal">
                    <div className="spot-modal__header">
                        <div className="spot-modal__titles">
                            <h2 className="spot-modal__title">
                                {i18n.t('activityPage:wouldLikeToCancel', 'Are you sure you would like to cancel?')}
                            </h2>
                        </div>
                    </div>
                    <div className="spot-modal__content">
                        <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top" />
                        <div className="spot-modal__content-wrapper">
                            <div className="spot-modal__copy"> {i18n.t('activityPage:unsavedWarning', 'Task will be permanently canceled.')}</div>
                        </div>
                        <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom" />
                    </div>
                    <div className={classNames('spot-modal__footer', styles.footer)}>
                        <SpotButton onClick={() => onChange(CancelTaskModalResponse.Yes)}>{i18n.t('common:yes', 'Yes')}</SpotButton>
                        <SpotButton isPrimary={true} onClick={() => onChange(CancelTaskModalResponse.No)}>
                            {i18n.t('common:no', 'No')}
                        </SpotButton>
                    </div>
                </div>
            </div>
        </Modal>
    );
});
