import { EventEmitter } from 'events';
import { Service } from 'typedi';
import { WorkerInterface, WorkerMessage } from './workers/WorkerInterface';
import { Exceptions } from '@enterprise/common';

@Service()
export class NotificationService extends EventEmitter {
    private workers: { name: string; worker: WorkerInterface }[] = [];

    constructor() {
        super();
    }

    runWorker(name: string, worker: WorkerInterface) {
        if (this.hasWorker(name)) {
            throw new Exceptions.DuplicateEntityException(`Worker with name ${name} already exist`);
        }
        if (!worker.onMessage) {
            worker.onMessage = (e: WorkerMessage) => {
                const eventName = `${name}_${e.name || 'tick'}`;
                this.emit(eventName, e.subject || e);
            };
        }
        worker.start();
        this.workers.push({ name, worker });
    }

    hasWorker(name: string): boolean {
        return !!this.workers.find((i) => i.name === name);
    }

    stopWorker(name: string) {
        const worker = this.workers.find((i) => i.name === name);
        if (!worker) {
            throw new Exceptions.NotFoundException(`Worker with name ${name} not found`);
        }
        worker.worker.stop();
        this.workers = this.workers.filter((i) => i.name !== name);
    }
}
