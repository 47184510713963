import { Transformers, Utils } from '@enterprise/common';
import { InvoiceItem, InvoiceItemChild, InvoiceItemWithVariantDetail } from '../models/datasource/invoiceItems';
import { ItemsGroupByType, ItemsGroupByTypeKeys } from '../models/organization/settings/enum';
import { ProviderDetails } from '../models/datasource';
import { Datasources } from '../enums/datasources';

interface DiffKeyParts {
    pimsGeneratedId: string;
    siteId: string;
}

type InvoiceItemDiff = { [key: string]: Partial<InvoiceItem> };

export class InvoiceItemsWithVariantDetails {
    private readonly groupBy: ItemsGroupByType;

    constructor(groupBy: ItemsGroupByType = ItemsGroupByType.Id) {
        this.groupBy = groupBy;
    }

    static createDiffKey({ pimsGeneratedId }: Partial<InvoiceItem>, siteId: string): string {
        return JSON.stringify({ pimsGeneratedId, siteId });
    }

    static splitDiffKey(diffKey: string): DiffKeyParts {
        const { pimsGeneratedId, siteId } = JSON.parse(diffKey);
        return { pimsGeneratedId, siteId };
    }

    transform(invoiceItems: InvoiceItem[]): InvoiceItemWithVariantDetail[] {
        const result: InvoiceItemWithVariantDetail[] = [];
        const processedItems = new Set<string>();
        const groupByKey = this.getGroupByKey();

        invoiceItems.forEach((invoiceItem) => {
            let processedItemKey = (this.groupBy === ItemsGroupByType.Id && invoiceItem[groupByKey]) || invoiceItem[groupByKey].toLowerCase();

            if (invoiceItem.itemParent) {
                processedItemKey += '-sub';
            }

            if (processedItems.has(processedItemKey)) {
                return;
            }

            const similarItems = invoiceItems.filter((compareItem) => {
                if (this.groupBy === ItemsGroupByType.Id) {
                    return compareItem[groupByKey] === invoiceItem[groupByKey];
                }
                if (invoiceItem.itemParent) {
                    return compareItem[groupByKey].toLowerCase() === invoiceItem[groupByKey].toLowerCase() && compareItem.itemParent;
                }
                return compareItem[groupByKey].toLowerCase() === invoiceItem[groupByKey].toLowerCase() && !compareItem.itemParent;
            });

            const diff = this.createDiff(similarItems, invoiceItem);

            result.push({
                ...invoiceItem,
                diff,
                providers: similarItems.map((similarItem) => similarItem.provider),
                variants: similarItems,
            });

            processedItems.add(processedItemKey);
        });

        return new Transformers.CollectionTransformer(
            new Transformers.ToTypeTransformer<InvoiceItemWithVariantDetail>(InvoiceItemWithVariantDetail),
        ).transform(result);
    }

    private createDiff(similarItems: InvoiceItem[], invoiceItem: InvoiceItem): InvoiceItemDiff {
        return similarItems.reduce((accumulator, { provider, ...similarItem }) => {
            const invoiceItemCopy = { ...invoiceItem };
            invoiceItemCopy.provider = new ProviderDetails();

            invoiceItemCopy.hospitalDescription = invoiceItemCopy.hospitalDescription?.toLowerCase();
            similarItem.hospitalDescription = similarItem.hospitalDescription?.toLowerCase();

            const difference =
                (invoiceItemCopy.childItems?.length || 0) > (similarItem.childItems?.length || 0)
                    ? Utils.difference<Partial<InvoiceItem>>(similarItem, invoiceItemCopy)
                    : Utils.difference<Partial<InvoiceItem>>(invoiceItemCopy, similarItem);

            if (difference.childItems && invoiceItemCopy.childItems?.length === similarItem.childItems?.length) {
                (difference.childItems as InvoiceItemChild[]).forEach((child, index) => {
                    if (Object.keys(difference.childItems?.[index] || {}).length === 1) {
                        delete difference.childItems?.[index];
                    }
                });
            }

            this.removeExemptVariantProperties(difference, provider, { value: similarItem, other: invoiceItemCopy });
            if (Object.keys(difference).length !== 0) {
                const diffKey = InvoiceItemsWithVariantDetails.createDiffKey(similarItem, provider.siteId);
                accumulator[diffKey] = difference;
            }
            return accumulator;
        }, {});
    }

    private getGroupByKey(): ItemsGroupByTypeKeys {
        if (this.groupBy === ItemsGroupByType.Id) {
            return ItemsGroupByTypeKeys.PimsGeneratedId;
        }
        return ItemsGroupByTypeKeys.HospitalDescription;
    }

    /**
     * Remove item properties that should not be considered for variant calculations
     * @param difference - will be updated as a side-effect
     * @param provider
     */
    private removeExemptVariantProperties = (
        difference: Partial<InvoiceItem>,
        provider: ProviderDetails,
        items: { value: Partial<InvoiceItem>; other: Partial<InvoiceItem> },
    ): void => {
        // If we are not grouping by pimsGeneratedId, then we don't expect or care if these ids differ
        if (this.getGroupByKey() !== ItemsGroupByTypeKeys.PimsGeneratedId) {
            delete difference[ItemsGroupByTypeKeys.PimsGeneratedId];
        }

        if (provider?.name.toLowerCase() === Datasources.ANIMANA.toLowerCase()) {
            // Animana classifications share name/descriptions, not pimsIds
            if (difference.classification) {
                delete difference.classification.pimsGeneratedId;
                delete difference.classification.pimsId;

                if (Object.keys(difference.classification).length === 0) {
                    delete difference.classification;
                }
            }

            // Animana parent items do not share pimsGenerated ids
            if (difference.itemParent) {
                // Animana subproducs with different parents are not considered variants
                delete difference.itemParent.hospitalDescription;

                delete difference.itemParent.pimsGeneratedId;

                if (Object.keys(difference.itemParent).length === 0) {
                    delete difference.itemParent;
                }
            }

            // Animana child items do not share pimsGenerated ids and provider
            if (difference.childItems) {
                difference.childItems = difference.childItems.reduce<InvoiceItemChild[]>((agg, { pimsGeneratedId, provider, ...child }) => {
                    if (Object.keys(child).length) {
                        agg.push(child as InvoiceItemChild);
                    }

                    return agg;
                }, []);

                if ((difference.childItems as InvoiceItemChild[]).every((child) => !child)) {
                    delete difference.childItems;
                }
            }

            if (difference.animanaSpecificFields) {
                const { value, other } = items;

                delete difference.animanaSpecificFields.productAnalysisGroup1Uuid;
                delete difference.animanaSpecificFields.productAnalysisGroup2Uuid;
                delete difference.animanaSpecificFields.productAnalysisGroup3Uuid;

                if (value.animanaSpecificFields?.supplierName?.toUpperCase() === other.animanaSpecificFields?.supplierName?.toUpperCase()) {
                    delete difference.animanaSpecificFields.supplierName;
                }
                if (
                    value.animanaSpecificFields?.productAnalysisGroup1Name?.toUpperCase() ===
                    other.animanaSpecificFields?.productAnalysisGroup1Name?.toUpperCase()
                ) {
                    delete difference.animanaSpecificFields.productAnalysisGroup1Name;
                }
                if (
                    value.animanaSpecificFields?.productAnalysisGroup2Name?.toUpperCase() ===
                    other.animanaSpecificFields?.productAnalysisGroup2Name?.toUpperCase()
                ) {
                    delete difference.animanaSpecificFields.productAnalysisGroup2Name;
                }
                if (
                    value.animanaSpecificFields?.productAnalysisGroup3Name?.toUpperCase() ===
                    other.animanaSpecificFields?.productAnalysisGroup3Name?.toUpperCase()
                ) {
                    delete difference.animanaSpecificFields.productAnalysisGroup3Name;
                }

                if (Object.keys(difference.animanaSpecificFields).length === 0) {
                    delete difference.animanaSpecificFields;
                }
            }
        }
    };
}
