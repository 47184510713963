import { EnterpriseUserToken } from './EnterpriseUserToken';
import { BaseHttp } from '../../../http';
import { updateHubspotIdentity } from '../../../utils';
import { BaseAuthProvider } from '../common/baseAuthProvider';

const STORAGE_KEY_NAME = 'EnterpriseAuthProvider';
const LANGUAGE_KEY_NAME = 'i18nlocale';

export class EnterpriseAuthProvider implements BaseAuthProvider<EnterpriseUserToken> {
    /**
     * @type BaseHttp
     */
    http;

    /**
     * @param {BaseHttp} http
     */
    constructor(http: BaseHttp) {
        this.http = http;
    }

    /**
     *
     * @param {EnterpriseUserToken} token
     */
    support(token: EnterpriseUserToken | string): boolean {
        return true;
    }

    setLocalStorage(token: EnterpriseUserToken): void {
        window.localStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(token));
        if (token.user?.profile) {
            const locale = token.user.profile.locale || 'en-US';
            window.localStorage.setItem(LANGUAGE_KEY_NAME, locale);
        }
    }

    /**
     *
     * @param token
     * @return Promise{EnterpriseUserToken|Error}
     */
    async authenticate(token: EnterpriseUserToken | string): Promise<EnterpriseUserToken | undefined> {
        if (token instanceof EnterpriseUserToken) {
            return token;
        }

        const config = {
            headers: { Authorization: `Bearer ${token}` },
            suppressNotifications: true,
        };

        try {
            const userData = await this.http.get(`/auth/v1/user`, config);

            if (!userData) {
                return;
            }

            updateHubspotIdentity({
                email: userData.user?.username || undefined,
                firstName: userData.user?.profile?.firstName || undefined,
                lastName: userData.user?.profile?.lastName || undefined,
                id: userData.user?.id || undefined,
                pricingTier: userData.user?.organization?.pricingTier?.name,
            });

            const newToken = new EnterpriseUserToken(userData);
            this.setLocalStorage(newToken);
            return newToken;
        } catch (err) {
            return err;
        }
    }

    logout(): void {
        window.localStorage.removeItem(STORAGE_KEY_NAME);
        window.localStorage.removeItem(LANGUAGE_KEY_NAME);
        updateHubspotIdentity({});
    }

    restore(): Promise<EnterpriseUserToken | undefined> {
        const userData = window.localStorage.getItem(STORAGE_KEY_NAME);
        if (!userData) {
            return Promise.reject();
        }

        try {
            const token = new EnterpriseUserToken(JSON.parse(userData));

            updateHubspotIdentity({
                email: token.user?.username || undefined,
                firstName: token.user?.profile?.firstName || undefined,
                lastName: token.user?.profile?.lastName || undefined,
                id: token.user?.id || undefined,
                pricingTier: token.user?.organization?.pricingTier?.name,
            });

            return Promise.resolve(token);
        } catch (e) {
            throw new Error('Unable to parse token JSON');
        }
    }
}
