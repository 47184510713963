import React, { memo, PropsWithChildren, ReactElement, useMemo } from 'react';
import {
    GridCellParams,
    GridColDef,
    GridColumnHeaderParams,
    GridRowParams,
    GridSelectionModel,
    GridValueGetterParams,
    MaterialDataGrid,
    GridRowData,
} from '@enterprise/material-data-grid';
import { PracticeInfo, PracticeStatus, SiteEntityInfo } from '../MyOrganizationPageStore';
import styles from './practiceGrid.module.scss';
import { SpotButton, SpotPill, SpotToggle } from '@enterprise/spot';
import i18n from '../../../i18n';
import classNames from 'classnames';
import { usePracticesGridSize } from './usePracticesGridSize.hook';
import { ScreenSizeType } from '../../../hooks';
import { head } from 'lodash';

enum GridColumns {
    MobileView = 'mobileView',
    Name = 'name',
    PIMS = 'pims',
    Labels = 'labels',
    PracticeStatus = 'practiceStatus',
}

const getPracticeName = ({ name, type }: PracticeInfo): string => {
    if (!name && type === PracticeStatus.Pending) {
        return i18n.t('myOrganization:defaultPendingPracticeName', 'Pending Practice Connection');
    }

    return String(name);
};

const MobileHeader = (params: GridColumnHeaderParams): ReactElement => {
    // filter by practice name should goes here
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>
                {i18n.t('myOrganization:practiceName', 'Practice Name')}
            </div>
        </div>
    );
};

const syncButtonVisible = (groupBy: string | undefined, masterId: string | undefined, practice: SiteEntityInfo): boolean => {
    return groupBy === 'description' && practice.id === masterId && practice.isEnabled && practice.isActive;
};

const mobileCell = (gridProps: PracticesGridProps) => {
    const { isSyncPending, onSyncClick, masterSiteId, groupBy } = gridProps;

    const StatusCell = practiceStatusCell(gridProps);

    const buttonTitle = isSyncPending ? i18n.t('myOrganization:syncProductsPending', 'Sync Pending') : i18n.t('myOrganization:syncProducts', 'Sync');

    return function MobileCell(params: GridCellParams): ReactElement {
        const name = getPracticeName(params.row as PracticeInfo);
        const { city, state, pimsName, labels } = params.row as SiteEntityInfo;
        const practiceInfo = [city, state].filter((item) => item).join(', ');
        const labelNames = labels?.map(({ name }) => name);

        const isSyncAvailable = syncButtonVisible(groupBy, masterSiteId, params.row);

        return (
            <div className={styles.cellContainer}>
                <div className={styles.cellMobile}>
                    <div className={classNames(styles.mobilePracticeName)}>
                        <div className={classNames('spot-typography__heading--level-4', styles.name)} data-automation-id="practices-grid-cell-name">
                            {name}{' '}
                            {isSyncAvailable && (
                                <SpotButton
                                    onClick={onSyncClick}
                                    disabled={isSyncPending}
                                    className={styles.refreshButton}
                                    btnSize={'small'}
                                    isPrimary={true}
                                    icon={'refresh-redo'}
                                >
                                    {buttonTitle}
                                </SpotButton>
                            )}
                        </div>
                        {Boolean(practiceInfo) && (
                            <span
                                className={classNames('spot-typography__text--tertiary', styles.practiceInfo)}
                                data-automation-id="practices-grid-cell-practice-info"
                            >
                                {practiceInfo}
                            </span>
                        )}
                    </div>
                    <div className={classNames('spot-typography__text--body', styles.mobileInfo)}>
                        <span className={styles.pims} data-automation-id="practices-grid-cell-pims-name">
                            {pimsName}
                        </span>
                        {Boolean(labelNames?.length) && (
                            <span className={styles.labels} data-automation-id="practices-grid-cell-region-name">
                                {labelNames?.join(', ')}
                            </span>
                        )}
                    </div>
                    <StatusCell {...params} />
                </div>
            </div>
        );
    };
};

const NameHeader = (params: GridColumnHeaderParams): ReactElement => {
    // filter by practice name should goes here
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>
                {i18n.t('myOrganization:practiceName', 'Practice Name')}
            </div>
        </div>
    );
};

const desktopCell = (props: PracticesGridProps) => {
    const { isSyncPending, onSyncClick, groupBy, masterSiteId } = props;

    const buttonTitle = isSyncPending ? i18n.t('myOrganization:syncProductsPending', 'Sync Pending') : i18n.t('myOrganization:syncProducts', 'Sync');

    return function NameCell(params: GridCellParams): ReactElement {
        const name = getPracticeName(params.row as PracticeInfo);
        const { city, state } = params.row as SiteEntityInfo;
        const practiceInfo = [city, state].filter((item) => item).join(', ');

        const isSyncAvailable = syncButtonVisible(groupBy, masterSiteId, params.row);

        return (
            <div className={styles.cellContainer}>
                <div className={styles.cellName}>
                    <div className={classNames('spot-typography__heading--level-4', styles.name)} data-automation-id="practices-grid-cell-name">
                        {name}{' '}
                        {isSyncAvailable && (
                            <SpotButton
                                onClick={onSyncClick}
                                disabled={isSyncPending}
                                className={styles.refreshButton}
                                btnSize={'small'}
                                isPrimary={true}
                                icon={'refresh-redo'}
                            >
                                {buttonTitle}
                            </SpotButton>
                        )}
                    </div>
                    {Boolean(practiceInfo) && (
                        <div
                            className={classNames('spot-typography__text--tertiary', styles.practiceInfo)}
                            data-automation-id="practices-grid-cell-practice-info"
                        >
                            {practiceInfo}
                        </div>
                    )}
                </div>
            </div>
        );
    };
};

const PimsHeader = (params: GridColumnHeaderParams): ReactElement => {
    // filter by pims should goes here
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>{i18n.t('myOrganization:pims', 'PIMS')}</div>
        </div>
    );
};

const PimsCell = (params: GridCellParams): ReactElement => {
    const { pimsName } = params.row as SiteEntityInfo;

    return (
        <div className={styles.cellContainer}>
            <div className={classNames('spot-typography__text--body', styles.cell)}>
                <span data-automation-id="practices-grid-cell-pims-name">{pimsName}</span>
            </div>
        </div>
    );
};

const LabelsHeader = (params: GridColumnHeaderParams): ReactElement => {
    // filter by labels should goes here
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>{i18n.t('myOrganization:region', 'Region')}</div>
        </div>
    );
};
const LabelsCell = (params: GridCellParams): ReactElement => {
    const { labels } = params.row as SiteEntityInfo;
    const labelNames = labels?.map(({ name }) => name);

    return (
        <div className={styles.cellContainer}>
            <div className={classNames('spot-typography__text--body', styles.cell)}>
                {Boolean(labelNames?.length) && <span data-automation-id="practices-grid-cell-region-name">{labelNames?.join(', ')}</span>}
            </div>
        </div>
    );
};

const PracticeStatusHeader = (params: GridColumnHeaderParams): ReactElement => {
    // filter by practice status should goes here
    return (
        <div className={styles.headerContainer}>
            <div className={classNames('spot-typography__text--body', styles.headerCell)}>
                {i18n.t('myOrganization:connectionStatus', 'Connection Status')}
            </div>
        </div>
    );
};

const practiceStatusCell = ({ onActiveToggle }: PracticesGridProps) =>
    function PracticeStatusCell(params: GridCellParams): ReactElement {
        const practice = params.row as SiteEntityInfo;
        const { name, type, isActive, isAgentWorking, isDbWorking } = practice;

        const renderAppActiveSwitch = () => {
            const onChange = () => onActiveToggle?.(practice);
            return (
                <SpotToggle
                    className={styles.toggle}
                    checked={isActive}
                    onChange={onChange}
                    automationId={`toggle-active_${name.replace(/ /g, '')}`}
                />
            );
        };

        return (
            <div className={styles.cellContainer} data-automation-id="practices-grid-cell-connection-status">
                {type === PracticeStatus.ControlCenter && (
                    <>
                        {!isAgentWorking && <SpotPill color={styles.connectionErrorColor}>{i18n.t('myOrganization:offline', 'Offline')}</SpotPill>}
                        {isAgentWorking && !isDbWorking && (
                            <SpotPill color={styles.connectionErrorColor}>{i18n.t('myOrganization:databaseDown', 'Database Down')}</SpotPill>
                        )}
                        {isAgentWorking && isDbWorking && onActiveToggle && (
                            <>
                                {renderAppActiveSwitch()}
                                <div className={classNames('spot-typography__text--body', styles.cell)}>
                                    {i18n.t('myOrganization:controlCenter', 'Control Center')}
                                </div>
                            </>
                        )}
                    </>
                )}
                {type === PracticeStatus.AnalyticsOnly && (
                    <div className={classNames('spot-typography__text--body', styles.cell)}>
                        {i18n.t('myOrganization:analyticsOnly', 'Analytics Only')}
                    </div>
                )}
                {type === PracticeStatus.Pending && (
                    <SpotPill color={styles.connectionPendingColor}>{i18n.t('myOrganization:connectionPending', 'Connection Pending')}</SpotPill>
                )}
            </div>
        );
    };

export type PracticesGridProps = PropsWithChildren<{
    practices: PracticeInfo[];
    className?: string;
    isLoading?: boolean;
    onActiveToggle?: (practice: PracticeInfo) => void;
    onSelectionChange?: (practice: PracticeInfo[]) => void;
    checkboxSelection?: boolean;
    isSyncPending: boolean;
    onSyncClick?: () => void;
    masterSiteId?: string;
    groupBy?: string;
}>;

export const PracticesGrid = memo(function PracticesGrid(props: PracticesGridProps) {
    const { practices, className, isLoading, onSelectionChange, checkboxSelection, isSyncPending, masterSiteId, groupBy } = props;
    const { sizeType, headerHeight, rowHeight } = usePracticesGridSize();

    const isTabletView = sizeType !== ScreenSizeType.xl;
    const columns = useMemo<GridColDef[]>(
        () => [
            {
                field: GridColumns.MobileView,
                hide: !isTabletView,
                flex: 1,
                renderHeader: MobileHeader,
                renderCell: mobileCell(props),
                valueGetter: (params: GridValueGetterParams) => {
                    return getPracticeName(params.row as PracticeInfo);
                },
            },
            {
                field: GridColumns.Name,
                hide: isTabletView,
                flex: 2,
                renderHeader: NameHeader,
                renderCell: desktopCell(props),
                valueGetter: (params: GridValueGetterParams) => {
                    return getPracticeName(params.row as PracticeInfo);
                },
            },
            {
                field: GridColumns.PIMS,
                hide: isTabletView,
                width: 230,
                renderHeader: PimsHeader,
                renderCell: PimsCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const { pimsName } = params.row as SiteEntityInfo;
                    return pimsName;
                },
            },
            {
                field: GridColumns.Labels,
                hide: isTabletView,
                flex: 1,
                renderHeader: LabelsHeader,
                renderCell: LabelsCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const { labels } = params.row as SiteEntityInfo;
                    return head(labels)?.name;
                },
            },
            {
                field: GridColumns.PracticeStatus,
                hide: isTabletView,
                width: 210,
                renderHeader: PracticeStatusHeader,
                renderCell: practiceStatusCell(props),
                valueGetter: (params: GridValueGetterParams) => {
                    const { type, isActive } = params.row as SiteEntityInfo;
                    return `${type}-${isActive}`;
                },
            },
        ],
        [isTabletView, isSyncPending, masterSiteId, groupBy],
    );

    const getRowClassName = (params: GridRowParams): string => {
        const { type, isAgentWorking, isDbWorking } = params.row as SiteEntityInfo;
        const classes = [styles.row];

        if (type === PracticeStatus.ControlCenter && (!isAgentWorking || !isDbWorking)) {
            classes.push(styles.rowError);
        }

        return classNames(classes);
    };

    const isRowSelectable = (params: GridRowParams): boolean => {
        const { type } = params.row as PracticeInfo;
        return type === PracticeStatus.AnalyticsOnly || type === PracticeStatus.ControlCenter;
    };

    const getRowId = (row: GridRowData): string => {
        const { id, accountId } = row as PracticeInfo;
        return `${id}-${accountId}`;
    };

    const onSelectionModelChange = (selectionModel: GridSelectionModel): void => {
        const selectedPractices = practices.filter((practice) => selectionModel.includes(getRowId(practice)));

        onSelectionChange?.(selectedPractices);
    };

    return (
        <MaterialDataGrid
            loading={isLoading}
            className={className}
            columns={columns}
            rows={practices}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            checkboxSelection={checkboxSelection}
            getRowClassName={getRowClassName}
            isRowSelectable={isRowSelectable}
            onSelectionModelChange={onSelectionModelChange}
            autoHeight={true}
            getRowId={getRowId}
            disableSelectionOnClick={true}
        />
    );
});
