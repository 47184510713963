import * as React from 'react';
import { SpotSvg } from './SpotSvg';

export interface SpotModalProps {
    title?: any;
    secondaryTitle?: any;
    isVisible?: boolean;
    hideClose?: boolean;
    controller?: (SpotModalControlProps) => JSX.Element;
    children?: (SpotModalControlProps) => JSX.Element;
    content?: React.ComponentClass<SpotModalControlProps> | React.FunctionComponent<SpotModalControlProps>;
    footer?: React.ComponentClass<SpotModalControlProps> | React.FunctionComponent<SpotModalControlProps> | any;
    onShow?: () => void;
    onHide?: () => void;
    className?: string;
}

export interface SpotModalControlProps {
    open: () => void;
    close: () => void;
}

interface SpotModalState {
    isOpen: boolean;
}

export class SpotModal extends React.Component<SpotModalProps, SpotModalState> {
    state: SpotModalState = {
        isOpen: false,
    };
    open = () => {
        this.setState({ isOpen: true });
    };

    close = () => {
        this.setState({ isOpen: false });
        if (this.props.onHide) {
            this.props.onHide();
        }
    };

    toggle = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    get isVisible(): boolean {
        const { isOpen } = this.state;
        const { isVisible } = this.props;
        if (isVisible !== undefined && isVisible !== null) {
            return isVisible;
        }
        return isOpen;
    }

    render() {
        const { footer, controller, className } = this.props;
        return (
            <span className={className} style={{ display: 'inline-block' }}>
                {!!controller && controller({ open: this.open, close: this.close })}
                {this.isVisible && (
                    <div className="spot-modal__overlay spot-modal__overlay--visible">
                        <div className="spot-modal">
                            {this.renderHeader()}
                            <div className="spot-modal__content">
                                <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top" />
                                <div className="spot-modal__content-wrapper">
                                    <div className="spot-modal__copy">{this.renderContent()}</div>
                                </div>
                                <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom" />
                            </div>
                            {footer && <div className="spot-modal__footer">{this.renderFooter()}</div>}
                        </div>
                    </div>
                )}
            </span>
        );
    }

    private renderHeader() {
        const { title, hideClose, secondaryTitle } = this.props;
        return (
            <div className="spot-modal__header">
                {secondaryTitle && (
                    <div className="spot-modal__titles">
                        <div className="spot-modal__secondary-title">
                            {typeof secondaryTitle === 'string' && <h3>{secondaryTitle}</h3>}
                            {typeof secondaryTitle !== 'string' && secondaryTitle}
                        </div>
                        <div className="spot-modal__title">
                            {typeof title === 'string' && <h2 className="spot-modal__title">{title}</h2>}
                            {typeof title !== 'string' && title}
                        </div>
                    </div>
                )}
                {!secondaryTitle && (
                    <div className="spot-modal__titles">
                        {typeof title === 'string' && <h2 className="spot-modal__title">{title}</h2>}
                        {typeof title !== 'string' && title}
                    </div>
                )}
                {!hideClose && (
                    <button className="spot-modal__header-cancel-button" onClick={this.close}>
                        <SpotSvg icon="cancel" width="16px" height="16px" />
                    </button>
                )}
            </div>
        );
    }

    private renderContent() {
        const { children, content } = this.props;

        if (content) {
            const Content = content;
            return <Content open={this.open} close={this.close} />;
        }
        if (typeof children === 'function') {
            return children({ open: this.open, close: this.close });
        }
        return null;
    }

    private renderFooter() {
        const { footer } = this.props;

        if (typeof footer === 'function') {
            return footer({ open: this.open, close: this.close });
        }
        if (footer) {
            const Footer = footer;
            return <Footer open={this.open} close={this.close} />;
        }
        return null;
    }
}
