import React, { memo, PropsWithChildren } from 'react';
import styles from './pageHeaderNavigation.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { PageNavigationItem } from '../../../router';
import { useStores } from '../../../hooks';

export type PageHeaderNavigationProps = PropsWithChildren<{
    className?: string;
    items: PageNavigationItem[];
}>;

export const PageHeaderNavigation = memo<PageHeaderNavigationProps>(function PageHeaderNavigation(props: PageHeaderNavigationProps) {
    const { className, items = [] } = props;
    const rootStore = useStores();
    const menuItems = items.filter(({ hidden }) => !hidden?.(rootStore));

    return (
        <div className={classNames(styles.holder, className)}>
            <ul className={classNames('spot-tabs__list', styles.container)}>
                {menuItems.map(({ to, title, automationId }) => (
                    <li key={to} className={classNames('spot-tabs__tab', styles.tab)}>
                        <NavLink
                            to={to}
                            className={({ isActive }) => classNames('spot-tabs__link', styles.navLink, { 'spot-tabs__link--active': isActive })}
                            data-automaton-id={automationId}
                        >
                            {title}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </div>
    );
});
