import * as React from 'react';
import classNames from 'classnames';
import { BaseSpotComponent, SpotInputProps } from './common';
import { SpotFieldError } from './SpotFieldError';

class SpotTextInputComponent extends BaseSpotComponent<SpotInputProps & React.HTMLProps<HTMLInputElement>> {
    render() {
        const { className, error, id, type, forwardedRef, ...rest } = this.props;
        return (
            <span>
                <input className={classNames('spot-form__input', className)} type={type || 'text'} {...rest} ref={forwardedRef} id={this.idProp} />
                {error && <SpotFieldError meta={error} />}
            </span>
        );
    }
}

export const SpotTextInput = React.forwardRef<HTMLInputElement, SpotInputProps & React.HTMLProps<HTMLInputElement>>(function SpotTextInput(
    props,
    ref,
) {
    return <SpotTextInputComponent {...(props as SpotInputProps)} forwardedRef={ref} />;
});
