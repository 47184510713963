import React, { memo } from 'react';
import styles from './navigationDialogueBox.module.scss';
import { SpotButton } from '@enterprise/spot';
import classNames from 'classnames';
import i18n from 'i18next';

interface DialogueProps {
    cancel;
    confirm;
}
export const NavigationDialogueBox = memo(function NavigationDialogueBox(props: DialogueProps) {
    return (
        <div className="spot-modal">
            <div className="spot-modal__header">
                <div className="spot-modal__titles">
                    <h2 className="spot-modal__title">{i18n.t('common:navigationGuard.leavePage', 'Leave Page?')}</h2>
                </div>
                <button className="spot-modal__header-cancel-button">
                    <svg className="spot-icon spot-modal__header-cancel-button-icon" aria-labelledby="title">
                        <title>{i18n.t('common:navigationGuard.cancel', 'cancel')}</title>
                        <use href="/dist/icons/spot_icons.svg#cancel" />
                    </svg>
                </button>
            </div>
            <div className="spot-modal__content">
                <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--top" />
                <div className="spot-modal__content-wrapper">
                    <div className="spot-modal__copy"> {i18n.t('common:navigationGuard.unsavedWarning', 'Unsaved changes will be lost.')}</div>
                </div>
                <div className="spot-modal__content-scroll-shadow-mask spot-modal__content-scroll-shadow-mask--bottom" />
            </div>
            <div className={classNames('spot-modal__footer', styles.footer)}>
                <SpotButton onClick={props.cancel} className={styles.stayButton}>
                    {i18n.t('common:navigationGuard.stay', 'Stay')}
                </SpotButton>
                <SpotButton isPrimary={true} onClick={props.confirm} className={styles.leaveButton}>
                    {i18n.t('common:navigationGuard.leave', 'Leave')}
                </SpotButton>
            </div>
        </div>
    );
});
