import { BaseUserToken } from '../common';

export interface Profile {
    displayName?: string;
    firstName?: string;
    lastName?: string;
    role?: UserRoles[];
    avatar?: string;
    collectProfileInformationHidden?: boolean;
    locale?: string;
}

export enum PricingTierType {
    CORE = 'Core',
    PLUS = 'Plus',
    PRO = 'Pro',
    PREMIUM = 'Premium',
    ENTERPRISE = 'Enterprise',
}

export enum UserRoles {
    User = 'USER',
    SuperAdmin = 'SUPER_ADMIN',
    // should be removed after check permissions ia CASL.js is implemented
    SystemOwner = 'SYSTEM_OWNER',
}

export interface EnterprisePricingTier {
    id: number;
    name: PricingTierType;
    lookerGroupId: number;
}

export interface EnterpriseOrganization {
    id: string;
    name: string;
    organizationId?: string;
    pricingTier: EnterprisePricingTier;
    accounts?: string[];
    owner?: string;
}

export interface Feature<TCriteria = Record<string, unknown>> {
    name: string;
    criteria?: TCriteria;
    isActive?: boolean;
}

export interface IEnterpriseUser {
    username: string;
    organization: EnterpriseOrganization;
    roles: UserRoles[];
    id: string;
    profile?: Profile;
    isActive?: boolean;
    FEATURES: Feature[];
}

export interface Organization extends EnterpriseOrganization {
    practices: string[];
}

export interface DetailedUser extends IEnterpriseUser {
    organization: Organization;

    policies: string[];
}

export interface UserPolicy {
    id: string;

    name: string;
}

export interface EnterpriseUserTokenParams {
    user: IEnterpriseUser;
}

export class EnterpriseUserToken extends BaseUserToken<IEnterpriseUser> {
    constructor(data: EnterpriseUserTokenParams) {
        super(data.user);
    }

    hasRole(roleName = '*'): boolean {
        // if (!this.user.isActive) {
        //     return false;
        // }

        if (roleName === UserRoles.SystemOwner) {
            return this.isOwner();
        }

        if (!this.user?.roles) {
            return false;
        }
        return roleName === '*' || this.user.roles.indexOf(roleName as UserRoles) !== -1;
    }

    getDisplayName(): string {
        if (this.user?.profile?.displayName) {
            return this.user.profile.displayName;
        } else if (this.user?.profile?.firstName && this.user?.profile?.lastName) {
            return `${this.user.profile.firstName} ${this.user.profile.lastName}`;
        }
        return this.user?.username || super.getDisplayName();
    }

    private isOwner(): boolean {
        const ownerId = this.user?.organization?.owner;
        return Boolean(ownerId && this.user?.id === ownerId);
    }
}
