import * as React from 'react';
import { SpotSvg } from './SpotSvg';
import classNames from 'classnames';
import { ChangeEvent, KeyboardEvent } from 'react';

interface SpotSearchbarProps {
    value?: string;
    onChange?: (value: string) => void;
    onSubmit?: (value: string) => void;
    onClick?: () => void;
    isDisabled?: boolean;
    isSelfControlled: boolean;
    secondary?: boolean;
    className?: string;
}

interface SpotSearchbarState {
    currentValue: string;
}

export class SpotSearchbar extends React.Component<SpotSearchbarProps, SpotSearchbarState> {
    static defaultProps = {
        isSelfControlled: true,
    };

    state: SpotSearchbarState = {
        currentValue: this.props.value || '',
    };

    get value(): string {
        return this.state.currentValue;
    }

    onInputChanged = (e: ChangeEvent<HTMLInputElement>): void => {
        const newValue = e.target.value;
        this.setState({ currentValue: newValue });
        this.props.onChange?.(newValue);
    };

    onInputKeyPress = (e: KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            const { currentValue } = this.state;
            this.props.onSubmit?.(currentValue);
        }
    };

    submit = (): void => {
        const { currentValue } = this.state;
        this.props.onSubmit?.(currentValue);
    };

    render() {
        const value = this.props.isSelfControlled ? this.state.currentValue : this.props.value;
        return (
            <div
                className={classNames(
                    'spot-form',
                    'spot-search-bar',
                    { 'spot-search-bar--low-priority': this.props.secondary },
                    this.props.className,
                )}
            >
                <input
                    className="spot-form__input spot-search-bar__input"
                    type="text"
                    autoComplete="off"
                    data-automation-id={'search-bar-text'}
                    id="search-bar-text"
                    value={value}
                    onChange={this.onInputChanged}
                    onKeyPress={this.onInputKeyPress}
                    onClick={this.props.onClick}
                    disabled={this.props.isDisabled}
                />

                {this.props.children && this.props.children}

                <button
                    data-automation-id={'search-bar-button'}
                    className="spot-search-bar__search-button"
                    type="button"
                    onClick={this.submit}
                    disabled={this.props.isDisabled}
                >
                    <SpotSvg className={'spot-icon'} icon="search" width="12px" height="12px" />
                </button>
            </div>
        );
    }
}
