import React, { ChangeEvent, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { Field } from '@enterprise/common';
import { SiteAccess } from './siteAccess';
import { PermissionLevel } from '../../../core/enums/permissionLevel';

export type SiteAccessFiledProps = PropsWithChildren<{
    name: string;
    disabled?: boolean;
    userId: string;
}>;

export const SiteAccessField = observer(function SiteAccessFiledComponent({ name, disabled, userId }: SiteAccessFiledProps) {
    return (
        <Field name={name} data-automation-id="site-access-field">
            {({ input: { value, onChange } }) => (
                <SiteAccess
                    disabled={disabled}
                    permissionLevel={value as PermissionLevel}
                    userId={userId}
                    onChange={(userRole: PermissionLevel) => onChange(userRole as unknown as ChangeEvent)}
                />
            )}
        </Field>
    );
});
