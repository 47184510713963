import * as React from 'react';
import moment, { Moment } from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { useMemo } from 'react';

type MuiDateTimePickerProps = DateTimePickerProps<Moment, Moment>;

export interface SpotDatePickerProps extends Omit<MuiDateTimePickerProps, 'value' | 'onChange'> {
    value?: Moment | Date;
    onChange: (date?: Moment) => void;
}

export const SpotDatePicker = (props: SpotDatePickerProps) => {
    const { value, onChange, PopperProps, ...datePickerProps } = props;
    const date = useMemo(() => (value || null) && moment(value), [value]);

    const handleChange = (newValue?: Moment | null) => {
        onChange(newValue || undefined);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                {...datePickerProps}
                value={date}
                onChange={handleChange}
                disableOpenPicker={false}
                PopperProps={{
                    placement: 'bottom-end',
                    ...PopperProps,
                }}
            />
        </LocalizationProvider>
    );
};
