import { useState, useEffect } from 'react';

export type WindowSize = {
    width: number;
    height: number;
};

export const useWindowSize = (): WindowSize => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<WindowSize>({
        height: window.innerHeight || 0,
        width: window.innerWidth || 0,
    });

    useEffect(() => {
        // Handler to call on window resize
        const handleResize = () => {
            // Set window width/height to state
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, [window]);

    return windowSize;
};
