import { BaseHttp, BaseHttpOptions, HttpException, InvalidCredentialsException } from '@enterprise/common';
import { environment } from '../../environment/environment';
import { Notifications } from '../../pages/Notifications';

interface EnterpriseApiAuthOptions {
    getAccessToken: () => Promise<string | undefined>;
}

export interface EnterpriseApiHttpOptions extends BaseHttpOptions {
    authorization: EnterpriseApiAuthOptions;
}

export class EnterpriseApiHttp extends BaseHttp {
    private readonly authOptions: EnterpriseApiAuthOptions;

    constructor({ authorization, ...options }: EnterpriseApiHttpOptions) {
        super(options);
        this.authOptions = authorization;
    }

    /**
     *
     * @param config
     * @return {Promise}
     */
    async request<T>(config: BaseHttpOptions = {}): Promise<T> {
        const accessToken = await this.authOptions.getAccessToken();
        if (accessToken) {
            config.headers = config.headers || {};
            if (!config.headers.Authorization) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
        }

        return super.request<T>(config).catch((e) => {
            // todo: should passes via constructor arguments
            if (config.url?.startsWith(environment.PRACTICE_CONNECT.BASE_API_URL)) {
                return Promise.reject(e);
            }

            if (config.suppressNotifications && e.data && e.data.status === 401) {
                return Promise.resolve() as unknown as Promise<T>;
            }
            return Promise.reject(wrapExceptions(e));
        });
    }
}

function wrapExceptions(e: HttpException) {
    if (e instanceof InvalidCredentialsException) {
        Notifications.fromException(e);
    }
    return e;
}
