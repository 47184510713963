import { Service } from 'typedi';
import { RouterPaths } from './RouterPaths';
import { RedirectContext } from '../core/enums/RedirectContext';
import { RedirectBrokerError } from './RedirectBrokerError';
import { FEATURES } from '@enterprise/core';
import AppStore from '../store/AppStore';

const redirects = [
    { feature: FEATURES.ANALYTICS, destination: RouterPaths.AnalyticsPages.AnalyticsDashboardsPage },
    { feature: FEATURES.CONTROL_CENTER, destination: RouterPaths.ControlCenterPages.Items },
    { feature: FEATURES.MASTER_DATA, destination: RouterPaths.MasterDataPages.ClassificationsMappingPage },
];

@Service()
export class RedirectBroker {
    constructor(private readonly appStore: AppStore) {}

    async getRedirectTarget(context?: RedirectContext): Promise<string> {
        switch (context) {
            case RedirectContext.ENTRY:
                return this.getEntryRedirect();
            default:
                throw RedirectBrokerError.unableToDetermineRedirect(`Unknown context: ${context}`);
        }
    }

    protected async getEntryRedirect(): Promise<string> {
        return Promise.resolve(this.getRedirectFromFeature());
    }

    protected getRedirectFromFeature(): string {
        const { FEATURES: userFeatures } = this.appStore.token.user;

        const redirect = redirects.find(({ feature }) => {
            return userFeatures?.some(({ name, isActive }) => feature === name && isActive);
        });

        return redirect?.destination ?? RouterPaths.MyOrganizationPages.MyOrganization;
    }
}
