import * as React from 'react';
import { Field } from 'react-final-form';
import { SpotFieldError } from '@enterprise/spot';
import { Trans } from 'react-i18next';
import i18n from '../../../i18n';

export class UserStep extends React.PureComponent {
    render() {
        return (
            <div>
                <div className="spot-typography__heading--level-3">
                    <Trans i18nKey="common:signUp.userStepTitle">Let's setup your account</Trans>
                </div>
                <div className="spot-typography__heading--level-4">
                    <Trans i18nKey="common:signUp.enterName">Please enter your name</Trans>
                </div>

                <Field name="profileFirstName">
                    {({ input, meta }) => (
                        <div className="spot-form__field-group">
                            <label className="spot-form__label">
                                <Trans i18nKey="common:signUp.firstName">First name</Trans>
                            </label>
                            <input className="spot-form__input" type="text" {...input} />
                            <SpotFieldError
                                meta={{
                                    error: meta.error && i18n.t('validation:isNotEmpty', { field: i18n.t('common:signUp.firstName') }),
                                    touched: meta.touched,
                                }}
                            />
                        </div>
                    )}
                </Field>
                <Field name="profileLastName">
                    {({ input, meta }) => (
                        <div className="spot-form__field-group">
                            <label className="spot-form__label">
                                <Trans i18nKey="common:signUp.lastName">Last name</Trans>
                            </label>
                            <input className="spot-form__input" type="text" {...input} />
                            <SpotFieldError
                                meta={{
                                    error: meta.error && i18n.t('validation:isNotEmpty', { field: i18n.t('common:signUp.lastName') }),
                                    touched: meta.touched,
                                }}
                            />
                        </div>
                    )}
                </Field>
                <Field name="username">
                    {({ input, meta }) => (
                        <div className="spot-form__field-group">
                            <label className="spot-form__label">
                                <Trans i18nKey="common:signUp.email">Email</Trans>
                            </label>
                            <input className="spot-form__input" type="text" {...input} />
                            <SpotFieldError
                                meta={{
                                    error: meta.error && i18n.t('validation:isNotEmail'),
                                    touched: meta.touched,
                                }}
                            />
                        </div>
                    )}
                </Field>
            </div>
        );
    }
}
