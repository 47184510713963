import { GridColDef, DataGridPremiumProps } from '@mui/x-data-grid-premium';

export const defaultColumnDef: Partial<GridColDef> = {
    disableColumnMenu: true,
};

export const defaultDataGridProps: Partial<DataGridPremiumProps> = {
    hideFooterPagination: true,
    hideFooter: true,
    columnBuffer: 10,
    disableColumnResize: true,
};
