import React, { useContext, useMemo } from 'react';
import { Routes } from 'react-router-dom';
import { UNSAFE_RouteContext as RouteContext } from 'react-router';

export const RootRoutes = (props) => {
    const ctx = useContext(RouteContext);

    const value = useMemo(
        () => ({
            ...ctx,
            matches: [],
        }),
        [ctx],
    );

    return (
        <RouteContext.Provider value={value}>
            <Routes {...props} />
        </RouteContext.Provider>
    );
};
