import { ProviderDetails } from '../../../datasource';
import { AnimanaInvoiceItem } from './AnimanaInvoiceItem';
import { DatasourceFullSiteInfo } from '../../../datasource/sites';

export class AnimanaInvoiceItemWithVariantDetail extends AnimanaInvoiceItem {
    diff: {
        [key: string]: Partial<AnimanaInvoiceItem>;
    } = {};

    providers: ProviderDetails[];

    sites?: DatasourceFullSiteInfo[];

    variants: AnimanaInvoiceItem[] = [];
}
