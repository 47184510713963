import { validate } from 'class-validator';
import { FormType, FormTypeProps, ValidationResult } from './FormType';
import { ToTypeTransformer, ToPlainTransformer } from '../transformers';
import { ValidationError } from 'class-validator';

export class EntityFormType<T extends object, TEntity extends object = T> extends FormType<T, TEntity> {
    constructor(protected TCreator: new () => TEntity | TEntity) {
        super();
    }

    buildForm(data: T): FormTypeProps<T> {
        const initialValues = new ToPlainTransformer().transform(data);
        return super.buildForm(initialValues);
    }

    getEntity(value: T): TEntity {
        return new ToTypeTransformer(this.TCreator).transform(value);
    }

    async validate(value: T): Promise<ValidationResult> {
        const transformed = new ToTypeTransformer(this.TCreator).transform(value);
        const errors = await validate(transformed);
        return this.formatErrors(errors);
    }

    private formatErrors(errors: ValidationError[]): ValidationResult {
        return errors.reduce((acc, cur) => {
            if (cur.children?.length) {
                acc[cur.property] = this.formatErrors(cur.children);
            } else {
                acc[cur.property] = cur.constraints && Object.values(cur.constraints).shift();
            }
            return acc;
        }, {});
    }
}
