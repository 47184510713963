import { Service } from 'typedi';
import { makeAutoObservable } from 'mobx';
import { MappingService } from '../../services/MappingService';
import { Notifications } from '../Notifications';
import i18n from 'i18next';
import { MappingTypes } from '../../core/enums/Mapping';
import {
    linkedItemFromEvent,
    mappedItemFromEvent,
    responseToPrimaryColumn,
    responseToStackableColumns,
} from '../../core/transformer/MappingDataTransformer';
import { OrganizationService } from '../../services/OrganizationService';
import { LinkedItem, MappedItem, NO_PARENT_ID } from '../../core/entity/mapping';
import { SiteEntity } from '../../core/entity/SiteEntity';
import { applyApplicationFilters, filterActiveApplications, filterManageableApplications } from '../../core/filters/Applications';
import queryString from 'query-string';
import { RouterPaths } from '../../router/RouterPaths';
import { RootStore } from '../../store/RootStore';
import { ActionItemEvent, BoardEvent, CardEvent, CardModel, ColumnActions, ColumnModel, INTENT } from '@idexx/cfs-mapping-ui';
import { ClassificationsActionMenuItems } from './classifications/ClassificationsBoardPage';
import { EditableClassificationFields } from './classifications/editable/entity/EditableClassificationFields';
import { MappingFileStatus } from '../../core/entity/mapping/MappingFileStatus';
import { MappingFileUploadLink } from '../../core/entity/mapping/MappingFileUploadLink';
import { CommandIntervalWorker } from '../../services/workers/CommandIntervalWorker';
import { NotificationService } from '../../services/NotificationService';
import { MappingFileProcessingStatus } from '../../core/enums/MappingFileProcessingStatus';
import { MappingFileStatusUpdateCommand } from './MappingFileStatusUpdateCommand';
import { sortColumnData } from '../../../../../packages/mapping-ui/src/utility';

export enum SetupTypes {
    OUR_CLASSIFICATIONS = 'ourClassifications',
    UPLOAD_FILE = 'uploadFile',
    EXISTING_DATABASE = 'existingDatabase',
}

export interface UnlinkOptions {
    mappingId: string;
    mappingParentId?: string;
    siteId: string;
    linkedId: string;
    pimsParentId: string;
    description: string;
}

@Service()
export class MasterDataPageStore {
    isLoaded = false;

    areClassificationsMapped?: boolean;

    mappedClassifications?: ColumnModel;

    unmappedClassifications?: ColumnModel[];

    mappableSites: SiteEntity[] = [];

    classification?: MappedItem;

    applications: any[];

    isLoadingClassification = false;

    isDeletingClassification = false;

    isUnlinkingClassification = false;

    mappingFileStatus?: MappingFileStatus;

    isUploadingMappingFile = false;

    isProcessingMappingFile = false;

    mappingFileProcessingError?: string;

    constructor(
        private readonly mappingService: MappingService,
        private organizationService: OrganizationService,
        private readonly rootStore: RootStore,
        private readonly notificationService: NotificationService,
    ) {
        makeAutoObservable(this, {
            mappableSites: false,
            deleteExistingMappingFileStatus: false,
            buildMappingFileUploadFormData: false,
            startListeningForMappingFileStatus: false,
            stopListeningForMappingFileStatus: false,
            unlinkClassification: false,
            filterOtherMappedElements: false,
            updateBoardStateAfterUnlink: false,
        });
    }

    async load() {
        this.isLoaded = false;
        try {
            this.areClassificationsMapped = await this.checkIfClassificationsMapped();
            if (this.areClassificationsMapped) {
                await this.collectBoardData();
            }
        } catch (e) {}
        this.isLoaded = true;
    }

    async loadMappableSites() {
        this.isLoaded = false;
        try {
            this.areClassificationsMapped = await this.checkIfClassificationsMapped();
            if (!this.areClassificationsMapped) {
                this.mappableSites = await this.fetchMappableSites();
            }
        } catch (e) {}
        this.isLoaded = true;
    }

    async checkIfClassificationsMapped(): Promise<boolean> {
        try {
            return await this.mappingService.areClassificationsMapped();
        } catch (e) {
            Notifications.error(
                i18n.t('masterData:classificationsPage.errorWhileFetchingClassifications', 'Failed to load classification mapping information'),
            );
            return false;
        }
    }

    async loadMappingFileStatus(type: MappingTypes) {
        this.mappingFileStatus = undefined;
        try {
            this.mappingFileStatus = await this.mappingService.getMappingFileStatus(type);
            if (
                this.mappingFileStatus &&
                (this.mappingFileStatus.status == MappingFileProcessingStatus.PENDING ||
                    this.mappingFileStatus.status == MappingFileProcessingStatus.PROCESSING)
            ) {
                this.startListeningForMappingFileStatus();
            }
        } catch (e) {
            // ignore this, file status may take some time to appear
        }
    }

    async collectBoardData(): Promise<void> {
        this.isLoaded = false;
        try {
            const activeSites = await this.organizationService.getApplications();
            const getByTypeResponse = await this.mappingService.getByType(MappingTypes.CLASSIFICATION);
            const getMappingStatusByTypeResponse = await this.mappingService.getMappingStatusByType(MappingTypes.CLASSIFICATION);

            this.mappedClassifications = responseToPrimaryColumn(getByTypeResponse);
            this.unmappedClassifications = responseToStackableColumns(getMappingStatusByTypeResponse, activeSites);
        } catch (e) {
            Notifications.error(
                i18n.t('masterData:classificationsPage.errorWhileFetchingClassifications', 'Failed to load classification mapping information'),
            );
        }
        this.isLoaded = true;
    }

    async silentlyCollectBoardData(): Promise<void> {
        try {
            const activeSites = await this.organizationService.getApplications();
            const getByTypeResponse = await this.mappingService.getByType(MappingTypes.CLASSIFICATION);
            const getMappingStatusByTypeResponse = await this.mappingService.getMappingStatusByType(MappingTypes.CLASSIFICATION);

            this.mappedClassifications = responseToPrimaryColumn(getByTypeResponse);
            this.unmappedClassifications = responseToStackableColumns(getMappingStatusByTypeResponse, activeSites);
        } catch (e) {
            Notifications.error(
                i18n.t('masterData:classificationsPage.errorWhileFetchingClassifications', 'Failed to load classification mapping information'),
            );
        }
        // This variable flip will re-render the board without requiring a page reload
        this.isLoaded = false;
        this.isLoaded = true;
    }

    async createNewMaster(mappedItem: MappedItem): Promise<MappedItem> {
        const newMaster = await this.mappingService.createNewMaster(mappedItem);
        await this.rootStore.domain.constantsStore.reload();
        this.updateBoardStateAfterCreatingMaster(mappedItem);
        return newMaster;
    }

    async createNewMasterFromFormValues(values: EditableClassificationFields, type: MappingTypes, parentId?: string): Promise<void> {
        try {
            const newMasterObject = new MappedItem({
                data: {
                    description: values.classDescription,
                },
                id: values.classId,
                mapped: [],
                parentId: parentId || null,
                type,
            });
            await this.createNewMaster(newMasterObject);
            const message = !parentId
                ? i18n.t('masterData:classificationsPage.createNewClassificationSuccess', 'Successfully created new Master Classification')
                : i18n.t('masterData:classificationsPage.createNewSubClassificationSuccess', 'Successfully created new Master Subclassification');
            Notifications.success(`${message}: ${values.classDescription}`);
        } catch (err) {
            Notifications.error(
                i18n.t('masterData:classificationsPage.createNewClassificationError', 'Failed to create a new Master Classification'),
            );
        }
    }

    private updateBoardStateAfterCreatingMaster(newMaster: MappedItem) {
        if (!this.mappedClassifications) {
            return;
        }

        const parentId = newMaster.parentId ? newMaster.parentId : undefined;
        const newCard = this.createCard(newMaster.id, newMaster.data.description || '', parentId);
        newCard.intent = INTENT.PRIMARY;

        if (newMaster.parentId) {
            const parentCard = this.mappedClassifications.data.find((card) => card.id === newMaster.parentId);
            const isExisted = Boolean(parentCard?.children?.find((child) => child.id == newCard.id));

            if (parentCard && !isExisted) {
                parentCard.children.push(newCard);
            }
        } else {
            const isExisted = Boolean(this.mappedClassifications.data?.find((card) => card.id == newCard.id));

            if (!isExisted) {
                this.mappedClassifications.data.push(newCard);
            }
        }

        this.mappedClassifications = { ...sortColumnData(this.mappedClassifications) };
    }

    async processMappingEvent(event: BoardEvent, type: MappingTypes): Promise<boolean> {
        const { action, data } = event;

        if (data instanceof CardEvent) {
            return this.processCardEvent(action, data, type);
        }

        if (data instanceof ActionItemEvent) {
            this.processActionItemEvent(action, data, type);
            return true;
        }
        return false;
    }

    async loadExistingMapping(type: MappingTypes, id: string, parentId?: string | null): Promise<MappedItem> {
        return (await this.mappingService.getMapping(type, id, parentId))[0];
    }

    async updateExistingMapping(existingItem: MappedItem, linkedItem: LinkedItem): Promise<MappedItem> {
        if (Array.isArray(existingItem.mapped)) {
            existingItem.mapped.push(linkedItem);
        } else {
            existingItem.mapped = [linkedItem];
        }
        return this.mappingService.patchMapping(existingItem);
    }

    async useDefaultClassesAndSubclassesAsMaster(): Promise<boolean> {
        this.isLoaded = false;
        try {
            await this.mappingService.useDefaultClassesAndSubclassesAsMaster();
            await this.reloadConstants();
            Notifications.success(
                i18n.t('masterData:classificationsPage.setDefaultClassificationsAsMaster', 'Successfully set default classifications as master'),
            );
            this.areClassificationsMapped = true;
            await this.collectBoardData();
        } catch (e) {
            Notifications.error(i18n.t('masterData:classificationsPage.failedToUseDefaultClassifications', 'Failed to use default classifications'));
            this.isLoaded = true;
            return false;
        }
        this.isLoaded = true;
        return true;
    }

    async useApplicationClassesAndSubclassesAsMaster(siteId: string): Promise<boolean> {
        this.isLoaded = false;
        try {
            await this.mappingService.useApplicationClassesAndSubclassesAsMaster(siteId);
            await this.reloadConstants();
            Notifications.success(
                i18n.t(
                    'masterData:classificationsPage.setApplicationClassificationsAsMaster',
                    'Successfully set master classifications from application',
                ),
            );
            this.areClassificationsMapped = true;
        } catch (e) {
            Notifications.error(
                i18n.t(
                    'masterData:classificationsPage.failedToUseApplicationClassificationsAsMaster',
                    'Failed to set master classifications from application',
                ),
            );
            // Since it's possible that some of the mappings we successful, we should check and update the state
            this.areClassificationsMapped = await this.checkIfClassificationsMapped();
            this.isLoaded = true;
            return false;
        }
        this.isLoaded = true;
        return true;
    }

    async getClassification(id: string, parentId?: string): Promise<any> {
        this.isLoadingClassification = true;
        try {
            this.classification = undefined;
            this.applications = await this.organizationService.getApplications();
            const mappings = await this.mappingService.getMapping(MappingTypes.CLASSIFICATION, id, parentId);
            this.classification = mappings[0];
            this.isLoadingClassification = false;
        } catch (e) {
            this.isLoadingClassification = false;
            throw e;
        }
    }

    async deleteClassification(classification: MappedItem): Promise<boolean> {
        let deleteSuccessful = true;
        this.isDeletingClassification = true;
        const name = classification.data?.name || classification.data?.description || classification.id;
        try {
            await this.mappingService.deleteMapping(classification);
            await this.silentlyCollectBoardData();
            Notifications.success(
                i18n.t('masterData:classificationsPage.deleteClassificationSuccess', {
                    classificationName: name,
                    defaultValue: `${name} Deleted`,
                }),
            );
        } catch {
            deleteSuccessful = false;
            Notifications.error(
                i18n.t('masterData:classificationsPage.deleteClassificationError', {
                    classificationName: name,
                    defaultValue: `Failed to delete ${name}`,
                }),
            );
        }
        this.isDeletingClassification = false;
        return deleteSuccessful;
    }

    async isAvailableClassificationId(id: string, parentId?: string): Promise<boolean> {
        const mappings = await this.mappingService.getMapping(MappingTypes.CLASSIFICATION, id, parentId);
        return mappings.length === 0;
    }

    async fetchMappableSites(): Promise<SiteEntity[]> {
        this.isLoaded = false;
        try {
            const applications = await this.organizationService.getApplications();
            return applyApplicationFilters(applications, [filterManageableApplications, filterActiveApplications]);
        } catch {
            Notifications.error(i18n.t('common:errors.getApplicationsError', 'An error has occurred while trying to fetch applications list'));
        }
        this.isLoaded = true;
        return [];
    }

    async deleteExistingMappingFileStatus() {
        try {
            return await this.mappingService.deleteMappingFileStatus(MappingTypes.CLASSIFICATION);
        } catch {
            // ignore, mapping file status may not exist yet
        }
        return true;
    }

    async uploadClassificationsMappingFile(file: File): Promise<boolean> {
        this.isUploadingMappingFile = true;

        let uploadLink: MappingFileUploadLink;
        try {
            uploadLink = await this.mappingService.getMappingFileUploadLink(MappingTypes.CLASSIFICATION);
        } catch (e) {
            Notifications.error(i18n.t('masterData:classificationsPage.failedToFetchUploadLink'));
            this.isUploadingMappingFile = false;
            return false;
        }

        const formData = this.buildMappingFileUploadFormData(uploadLink, file);

        try {
            await this.mappingService.uploadMappingFile(uploadLink.url, formData);
        } catch (e) {
            Notifications.error(i18n.t('masterData:classificationsPage.failedToUploadMappingFile'));
            this.isUploadingMappingFile = false;
            return false;
        }

        this.isUploadingMappingFile = false;
        return true;
    }

    buildMappingFileUploadFormData(uploadLink: MappingFileUploadLink, file: File): FormData {
        const formData = new FormData();
        Object.keys(uploadLink.fields).forEach((fieldName) => {
            const fieldValue = uploadLink.fields[fieldName];
            formData.set(fieldName, fieldValue);
        });
        formData.set('file', file); // this should go last
        return formData;
    }

    startListeningForMappingFileStatus() {
        this.isProcessingMappingFile = true;
        this.mappingFileProcessingError = undefined;

        if (!this.notificationService.hasWorker('mappingFile')) {
            this.notificationService.runWorker('mappingFile', new CommandIntervalWorker(5000, new MappingFileStatusUpdateCommand()));

            this.notificationService.on('mappingFile_tick', (mappingFile) => {
                this.mappingFileStatus = mappingFile;

                if (!this.mappingFileStatus) {
                    return;
                }

                if (this.mappingFileStatus.status == MappingFileProcessingStatus.SUCCESS) {
                    this.isProcessingMappingFile = false;
                    this.mappingFileProcessingError = undefined;
                    this.mappingFileStatus = undefined;
                    this.stopListeningForMappingFileStatus();
                    this.reloadConstants();
                    this.load();
                } else if (this.mappingFileStatus.status == MappingFileProcessingStatus.ERROR) {
                    this.isProcessingMappingFile = false;
                    this.mappingFileProcessingError =
                        (this.mappingFileStatus && this.mappingFileStatus.errorMessage) ||
                        i18n.t('masterData:classificationsPage.failedToProcessMappingFile');
                    this.mappingFileStatus = undefined;
                    this.stopListeningForMappingFileStatus();
                }
            });
        }
    }

    stopListeningForMappingFileStatus() {
        if (this.notificationService.hasWorker('mappingFile')) {
            this.notificationService.stopWorker('mappingFile');
        }
    }

    async unlinkClassification(params: UnlinkOptions) {
        this.isUnlinkingClassification = true;
        try {
            const mappingsById = await this.mappingService.getMapping(MappingTypes.CLASSIFICATION, params.mappingId, params.mappingParentId);
            const mapping = mappingsById[0];
            mapping.mapped = this.filterOtherMappedElements(mapping.mapped, params.siteId, params.linkedId, params.pimsParentId);
            await this.mappingService.dropLinked(mapping);
            await this.getClassification(params.mappingId, params.mappingParentId);
            await this.updateBoardStateAfterUnlink(params);
        } catch (e) {
            Notifications.error(i18n.t('common:classificationDetails.failedToUnlinkClassification'));
        }
        this.isUnlinkingClassification = false;
    }

    filterOtherMappedElements(mapped: LinkedItem[], siteId: string, linkedId: string, pimsParentId: string) {
        return mapped.filter((linked) => linked.practiceId === siteId && linked.linkedId === linkedId && linked.pimsParentId === pimsParentId);
    }

    async updateBoardStateAfterUnlink(params: UnlinkOptions) {
        const column = this.unmappedClassifications?.find((column) => column.id === params.siteId);
        if (!column) {
            return;
        }

        const wasSubclassUnlinked = params.pimsParentId && params.pimsParentId !== NO_PARENT_ID;
        let unlinkedCard;
        if (wasSubclassUnlinked) {
            unlinkedCard = await this.getUnlinkedSubclassCard(column, params);
        } else {
            unlinkedCard = this.getUnlinkedClassCard(column, params.linkedId, params.description);
        }

        this.setCardEnabled(unlinkedCard, true);
        sortColumnData(column);
    }

    private async getUnlinkedSubclassCard(column: ColumnModel, params: UnlinkOptions) {
        let parentClassCard = column.data.find((card) => card.id === params.pimsParentId);
        if (!parentClassCard) {
            const pimsClassDescription = await this.getPimsClassDescription(params.siteId, params.pimsParentId);
            parentClassCard = this.createCard(params.pimsParentId, pimsClassDescription);
            column.data.push(parentClassCard);
            this.setCardEnabled(parentClassCard, false);
        }

        let subclassCard = parentClassCard.children?.find((child) => child.id == params.linkedId);
        if (!subclassCard) {
            subclassCard = this.createCard(params.linkedId, params.description, params.pimsParentId);
            parentClassCard.children.push(subclassCard);
        }
        return subclassCard;
    }

    private async getPimsClassDescription(siteId: string, classId: string): Promise<string> {
        await this.rootStore.domain.constantsStore.loadClassesAndSubclassesBySite([siteId]);
        const pimsClasses = this.rootStore.domain.constantsStore.classesBySite.get(siteId);
        const pimsClass = pimsClasses?.find((classification) => classification.pimsId === classId);
        return pimsClass?.description || '';
    }

    private getUnlinkedClassCard(column: ColumnModel, linkedId: string, description: string) {
        let classCard = column.data.find((card) => card.id === linkedId);
        if (!classCard) {
            classCard = this.createCard(linkedId, description);
            column.data.push(classCard);
        }
        return classCard;
    }

    private createCard(id: string, description: string, parentId?: string): CardModel {
        return {
            id,
            title: description,
            description,
            children: [],
            parentId,
        };
    }

    private setCardEnabled(card: CardModel, isEnabled: boolean) {
        card.intent = isEnabled ? INTENT.DEFAULT : INTENT.DISABLED;
        card.isDisabled = !isEnabled;
    }

    async reloadConstants() {
        return await this.rootStore.domain.constantsStore.reload();
    }

    dispose() {
        this.areClassificationsMapped = undefined;
        this.mappedClassifications = undefined;
        this.unmappedClassifications = undefined;
        this.mappableSites = [];
        this.classification = undefined;
        this.mappingFileStatus = undefined;
        this.isUploadingMappingFile = false;
        this.isProcessingMappingFile = false;
        this.mappingFileProcessingError = undefined;
        this.stopListeningForMappingFileStatus();
    }

    private async processCardEvent(action: ColumnActions, cardEvent: CardEvent, type: MappingTypes): Promise<boolean> {
        try {
            if (action === ColumnActions.CARD_DROP_CREATE) {
                const mappedItem = mappedItemFromEvent(cardEvent, type);
                await this.createNewMaster(mappedItem);
                return true;
            }

            if (action === ColumnActions.CARD_DROP_LINK && cardEvent.targetId) {
                const linkedItem = linkedItemFromEvent(cardEvent, type);
                const objectToUpdate = await this.loadExistingMapping(type, cardEvent.targetId, cardEvent.targetParentId);

                if (!objectToUpdate) {
                    return false;
                }
                await this.updateExistingMapping(objectToUpdate, linkedItem);
                return true;
            }

            if (action === ColumnActions.CARD_CLICK_TITLE) {
                const { id, isPrimary, parentId } = cardEvent.item;

                if (!isPrimary) {
                    return true;
                }

                if (id === ClassificationsActionMenuItems.CREATE_SUBCLASS) {
                    const query = { parentId };
                    const urlQuery = queryString.stringify(query, { encode: true });
                    this.rootStore.ui.router.push(`${RouterPaths.MasterDataPages.ClassificationsMappingCreatePage}?${urlQuery}`);
                } else if (id) {
                    const query = { id, parentId };
                    const urlQuery = queryString.stringify(query, { encode: true });
                    this.rootStore.ui.router.push(`${RouterPaths.MasterDataPages.ClassificationsMappingDetailsPage}?${urlQuery}`);
                }

                return true;
            }
        } catch (err) {
            Notifications.error(i18n.t('masterData:classificationsPage.boardUpdateError'));
        }
        return false;
    }

    private processActionItemEvent(action: ColumnActions, { id, sourceColumn }: ActionItemEvent, type: MappingTypes) {
        if (id === ClassificationsActionMenuItems.CREATE_CLASS) {
            this.rootStore.ui.router.push(RouterPaths.MasterDataPages.ClassificationsMappingCreatePage);
        }
    }
}
