import React from 'react';
import { useMemo } from 'react';
import { InvoiceItem } from '../../../../core/models';
import { GridCellParams, GridSelectionModel, GRID_CHECKBOX_SELECTION_FIELD, MaterialDataGrid, MuiEvent } from '@enterprise/material-data-grid';
import styles from './itemsGrid.module.scss';
import classNames from 'classnames';
import { GridState, ItemEditQuery, PaginationModel, SelectionModel } from '../../../../store/invoiceItem.store';
import { ItemsGridToolbar } from './toollbar';
import { customColumnMenu } from './columnMenu';
import { ItemsGridColumn, useItemGrid } from './hooks';
import { BulkUpdateFlyoverType } from '../ItemsPageStore';
import { isEqual } from 'lodash';

export interface ItemResultsProps {
    className?: string;
    invoiceItems?: InvoiceItem[];
    onInvoiceItemsLoading?: () => void;
    grouping?: ItemsGridColumn[];
    selection?: SelectionModel;
    pagination?: PaginationModel;
    onSelectionChange?: (params: SelectionModel) => void;
    onEdit?: (params: { query: ItemEditQuery }) => void;
    onBulkActionChange?: (params: { type: BulkUpdateFlyoverType }) => void;
    loading?: boolean;
    columnsState?: GridState;
    onColumnsStateChange?: (state: GridState) => void;
}

export const ItemsGrid = (props: ItemResultsProps) => {
    const {
        invoiceItems = [],
        onInvoiceItemsLoading,
        onEdit,
        className,
        grouping,
        selection,
        pagination,
        onSelectionChange,
        onBulkActionChange,
        loading,
        columnsState: colState,
        onColumnsStateChange,
    } = props;

    const defaultState = {
        groupingModel: props.grouping,
        visibilityModel: props.grouping?.reduce((agg, item) => {
            return { ...agg, [item]: false };
        }, {} as Record<number | string, boolean | undefined>),
    };
    const columnsState = colState || defaultState;

    const { apiRef, columns, groupColumn, getRowId, getQuery, isTabletView, state } = useItemGrid({ columnsState, onColumnsStateChange });
    const {
        pinnedColumns,
        onPinnedColumnsChange,
        onColumnWidthChange,
        columnVisibilityModel,
        onColumnVisibilityModelChange,
        sortModel,
        onSortModelChange,
        rowGroupingModel,
        onRowGroupingModelChange,
        onColumnOrderChange,
    } = state;

    const headerHeight = 68;
    const rowHeight = 52;
    const scrollLoadArea = rowHeight * 5;

    const idToInvoiceItems = useMemo(() => {
        return new Map<string, InvoiceItem>(invoiceItems.map((item) => [getRowId(item), item]));
    }, [invoiceItems]);

    const selectionModel = useMemo(() => {
        const selectedIds = selection?.all ? idToInvoiceItems.keys() : selection?.items?.keys();

        return selection?.all && selection?.excluded
            ? ((selectedIds && [...selectedIds]) || []).filter((id) => !selection?.excluded?.has(id))
            : (selectedIds && [...selectedIds]) || [];
    }, [idToInvoiceItems, selection]);

    const onSelectionModelChange = (selectionModel: GridSelectionModel) => {
        if (!onSelectionChange) {
            return;
        }

        const items = selectionModel.reduce((agg, id) => {
            const invoiceItem = idToInvoiceItems.get(id as string);
            if (invoiceItem) {
                agg.set(id as string, invoiceItem);
            }

            return agg;
        }, new Map<string, InvoiceItem>());
        onSelectionChange({ items });
    };

    const onSelectionAllChange = (params: { value: boolean }) => {
        onSelectionChange?.({ ...selection, all: params.value });
    };

    const onExitBulkMode = () => {
        onSelectionChange?.({ all: false, excluded: undefined, items: undefined });
    };

    const onCellClick = (params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
        if (params.field === GRID_CHECKBOX_SELECTION_FIELD) {
            return;
        }

        const query = getQuery(params);

        if (!query) {
            return;
        }

        event.preventDefault();
        onEdit?.({ query });
    };

    const getRowClassName = () => {
        return styles.row;
    };

    const toolbar = () => {
        const defaultState = {
            groupingModel: grouping,
            visibilityModel: grouping?.reduce((agg, item) => {
                return { ...agg, [item]: false };
            }, {} as Record<number | string, boolean | undefined>),
        };
        const onColumnsReset = () => {
            onColumnsStateChange?.(defaultState);
        };

        return (
            <ItemsGridToolbar
                selection={selection}
                pagination={pagination}
                onSelectionAllChange={onSelectionAllChange}
                onExitBulkMode={onExitBulkMode}
                onAddToPractices={() => onBulkActionChange?.({ type: BulkUpdateFlyoverType.AddToPractices })}
                isResetVisible={!isEqual(columnsState, defaultState)}
                onColumnsReset={onColumnsReset}
            />
        );
    };

    const onRowsScrollEnd = () => {
        const total = pagination?.total || 0;
        if (invoiceItems.length < total) {
            onInvoiceItemsLoading?.();
        }
    };

    return (
        <MaterialDataGrid
            rowCount={pagination?.total || 0}
            onRowsScrollEnd={onRowsScrollEnd}
            scrollEndThreshold={scrollLoadArea}
            keepNonExistentRowsSelected
            className={classNames(styles.holder, className)}
            data-automation-id="select-all-data-grid"
            loading={loading}
            rows={invoiceItems}
            columns={columns}
            apiRef={apiRef}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            // TODO: turned on on smaller screens - has impact on performance on small screens
            autoHeight={isTabletView}
            rowGroupingColumnMode="multiple"
            checkboxSelection
            getRowId={getRowId}
            getRowClassName={getRowClassName}
            selectionModel={selectionModel}
            onSelectionModelChange={onSelectionModelChange}
            groupingColDef={groupColumn}
            onCellClick={onCellClick}
            disableSelectionOnClick={true}
            disableColumnFilter={false}
            disableColumnResize={false}
            pinnedColumns={pinnedColumns}
            onPinnedColumnsChange={onPinnedColumnsChange}
            onColumnWidthChange={onColumnWidthChange}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={onColumnVisibilityModelChange}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            rowGroupingModel={rowGroupingModel}
            onRowGroupingModelChange={(model) => onRowGroupingModelChange(model as ItemsGridColumn[])}
            onColumnOrderChange={onColumnOrderChange}
            components={{
                Toolbar: toolbar,
                ColumnMenu: customColumnMenu({ selection, onBulkActionChange }),
            }}
            classes={{
                groupingCriteriaCell: styles.groupingCriteriaCell,
                columnHeaders: styles.columnHeaders,
                columnHeader: styles.columnHeader,
                pinnedColumnHeaders: styles.pinnedColumnHeaders,
                pinnedColumns: styles.pinnedColumns,
            }}
        />
    );
};
