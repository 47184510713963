import { EntityFormType, FormTypeProps, Transformers } from '@enterprise/common';
import ConstantsStore from '../../../../store/ConstantsStore';
import { InvoiceItem, InvoiceItemTax, InvoiceItemWithDynamicProps, InvoiceItemWithVariantDetail } from '../../../../core/models';

export interface FormValues extends InvoiceItem {
    usageTaxes: Partial<InvoiceItemTax>[];
    salesTaxes: Partial<InvoiceItemTax>[];
}

export class FullItemFormType<
    T extends Partial<InvoiceItem> = Partial<InvoiceItemWithDynamicProps>,
    TEntity extends InvoiceItem = InvoiceItemWithVariantDetail,
> extends EntityFormType<T, TEntity> {
    constants: ConstantsStore;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    constructor(protected TCreator: new () => TEntity) {
        super(TCreator);
    }

    buildForm(data: T): FormTypeProps<T> {
        const initialValues = new Transformers.ToPlainTransformer().transform(data);
        return super.buildForm(initialValues);
    }

    getEntity(value: FormValues & T): TEntity {
        const { usageTaxes, salesTaxes, ...newValue } = value;
        const usage = usageTaxes?.map((taxes) => ({ ...(taxes as InvoiceItemTax), isUsageTax: true })) || [];
        const sales = salesTaxes?.map((taxes) => ({ ...(taxes as InvoiceItemTax), isUsageTax: false })) || [];

        newValue.taxes = [...usage, ...sales];
        return super.getEntity(newValue as T);
    }
}
