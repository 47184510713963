import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { OrganizationService } from '../../services/OrganizationService';
import { Notifications } from '../Notifications';
import { SiteEntity } from '../../core/entity/SiteEntity';
import { filterManageableApplications, filterReadOnlyApplications } from '../../core/filters/Applications';
import { RootStore } from '../../store/RootStore';
import { FEATURES, Services } from '@enterprise/core';
import { Datasources } from '../../core/enums/datasources';
import { Account } from '../../core/models/organization';
import { PendingPractice } from '../../core/models/enrollment';
import i18n from 'i18next';
import { LabelType } from '../../core/enums/labelTypes';
import { LabelEntity } from '../../core/entity/LabelEntity';
import { NotificationService } from '../../services/NotificationService';
import { CommandIntervalWorker } from '../../services/workers/CommandIntervalWorker';
import { FuncCommand } from '../../services/workers/FuncCommand';

export enum PracticeStatus {
    ControlCenter = 'controlCenter',
    Pending = 'pending',
    AnalyticsOnly = 'analyticsOnly',
}

export interface SiteEntityInfo extends SiteEntity {
    type?: PracticeStatus;
}

export interface PendingPracticeInfo extends PendingPractice {
    type?: PracticeStatus;
}

export type PracticeInfo = SiteEntityInfo | PendingPracticeInfo;

@Service()
export class MyOrganizationPageStore {
    isLoaded = false;

    isFullLoading = false;

    allApplications: SiteEntity[] = [];

    applications: SiteEntity[] = [];

    readOnlyApplications: SiteEntity[] = [];

    application?: SiteEntity;

    _labels?: LabelEntity[] = [];

    pendingPractices: PendingPractice[] = [];

    connectPracticeEmbedUrl?: string;

    practiceConnectionAccount?: Account;

    selectedPractices: PracticeInfo[] = [];

    get practices(): PracticeInfo[] {
        return [
            ...toJS(this.applications).map((item) => ({ ...item, type: this.getPracticeType(item) })),
            ...toJS(this.readOnlyApplications).map((item) => ({
                ...item,
                type: item.isLookerAvailable ? PracticeStatus.AnalyticsOnly : PracticeStatus.Pending,
            })),
            ...toJS(this.pendingPractices).map((item) => ({ ...item, type: PracticeStatus.Pending })),
        ];
    }

    get labels(): LabelEntity[] | undefined {
        return toJS(this._labels);
    }

    constructor(
        private readonly service: OrganizationService,
        private readonly rootStore: RootStore,
        private featureManager: Services.FeatureManager,
        private readonly notificationService: NotificationService,
    ) {
        makeAutoObservable(this);

        if (!this.notificationService.hasWorker('appsMonitor')) {
            notificationService.runWorker(
                'appsMonitor',
                new CommandIntervalWorker(
                    60000,
                    new FuncCommand(async () => {
                        if (rootStore.ui.app.isAuthenticated) {
                            await this.fetchApplications();
                        }
                    }),
                ),
            );
        }
    }

    private getPracticeType(item: SiteEntity): PracticeStatus {
        if (!item.accountId && !item.isLookerAvailable) {
            return PracticeStatus.Pending;
        }

        if (!item.accountId && item.isLookerAvailable) {
            return PracticeStatus.AnalyticsOnly;
        }

        return PracticeStatus.ControlCenter;
    }

    async load(): Promise<void> {
        this.isFullLoading = true;
        this.selectedPractices = [];
        await this.fetchApplications();
        await this.fetchLabels(LabelType.Regions);
        await this.fetchPendingPractices();
        this.isFullLoading = false;
    }

    get unreachableApps(): number {
        const items = this.allApplications?.filter((application) => !application.isActive || !application.isAgentWorking || !application.isDbWorking);
        return items.length;
    }

    setSelectedPractices(practices: PracticeInfo[]) {
        this.selectedPractices = practices;
    }

    async fetchApplications(): Promise<void> {
        this.isLoaded = false;
        const allApplications = await this.service.getApplications();
        this.allApplications = allApplications;
        this.applications = filterManageableApplications(allApplications);
        this.readOnlyApplications = filterReadOnlyApplications(allApplications);
        this.isLoaded = true;
    }

    async fetchLabels(labelType?: LabelType): Promise<void> {
        this.isLoaded = false;
        this._labels = await this.service.getLabels(labelType);
        this.isLoaded = true;
    }

    async fetchPendingPractices(): Promise<PendingPractice[]> {
        this.isLoaded = false;
        try {
            const accounts = await this.service.getCurrentUserAccounts();
            this.pendingPractices = await this.service.getPendingPractices(accounts.map((acc) => acc.id));
            this.isLoaded = true;
            return this.pendingPractices;
        } catch (err) {
            this.isLoaded = true;
            Notifications.error(i18n.t('myOrganization:pendingPracticeError', 'Failed to check for pending site connections'));
            return [];
        }
    }

    async setApplicationActiveStatus(app): Promise<void> {
        await this.service.setApplicationActiveStatus(app.id, !app.isActive);
        await this.fetchApplications();
        await this.rootStore.domain.constantsStore.reload();
    }

    async assignLabel(labelId: string, practiceIds: string[]): Promise<void> {
        try {
            const existingLabelIds = this.labels?.map(({ id }) => id);
            if (existingLabelIds) {
                await this.service.removeLabelRelations([{ labels: existingLabelIds, practices: practiceIds }]);
            }

            await this.service.assignLabel([{ labels: [labelId], practices: practiceIds }]);
            Notifications.success(`Successfully assigned a region`);
        } catch (err) {
            Notifications.error('Failed to assign a region');
            return;
        }

        await this.fetchApplications();
    }

    async createAndAssignLabel(labelType: LabelType, labelName: string, practiceIds: string[]): Promise<boolean> {
        let newLabel: LabelEntity;
        try {
            newLabel = await this.service.createLabel(labelName, labelType);
        } catch (err) {
            Notifications.fromException(err);
            return await Promise.reject(err);
        }

        await this.assignLabel(newLabel.id, practiceIds);
        await this.fetchLabels(LabelType.Regions);
        return true;
    }

    dispose(): void {
        this.applications = [];
        this.application = undefined;
        this._labels = [];
        this.pendingPractices = [];
    }

    get isConnectPracticeFeatureEnabled(): boolean {
        return this.featureManager.isAvailable(FEATURES.CONNECT_PRACTICE, true);
    }

    get isPracticesConnectedOrPending(): boolean {
        return this.practices.length > 0;
    }

    async getConnectPracticeEmbedLink(): Promise<string> {
        // Some day this will be updated to support more than just datapoint enrollment
        const firstDpAccount = (await this.service.getCurrentUserAccounts()).find((acc) => acc.source === Datasources.DATAPOINT);

        if (!firstDpAccount) {
            throw new Error('Connecting a new practice requires an existing Datapoint connection');
        }

        this.practiceConnectionAccount = firstDpAccount;

        return this.service.getConnectPracticeEmbedLink(firstDpAccount.id).then((url) => {
            const { enrollmentFormUrl } = url;
            this.connectPracticeEmbedUrl = enrollmentFormUrl;
            return enrollmentFormUrl;
        });
    }

    async savePracticeEnrollment(requestId: string, enrollmentSlug: string) {
        if (!this.practiceConnectionAccount) {
            throw new Error('Connecting a new practice requires an existing Datapoint connection');
        }

        return this.service.savePracticeEnrollment(this.practiceConnectionAccount.id, requestId, enrollmentSlug);
    }
}
