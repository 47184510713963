import { ItemAddToPracticesParams } from '../../components/ItemAddPractice/ItemAddPractices';
import { InvoiceItem, InvoiceItemInventory, InvoiceItemWithVariantDetail } from '../../../../core/models';
import { SiteEntityInfo } from '../../../practiceConnections/MyOrganizationPageStore';
import i18n from 'i18next';
import { NotificationContent, Notifications } from '../../../Notifications';
import { ItemsGroupByType } from '../../../../core/models/organization/settings/enum';
import { ToastWithSubHeader } from '../../../../components/toasts/ToastWithSubheader';
import { useState } from 'react';
import { useStores } from '../../../../hooks';
import { BulkUpdateFlyoverType } from '../ItemsPageStore';

interface AddToPracticesParams {
    practicesWithoutItems?: string[];
    itemsKeys?: Set<string>;
}

interface BulkUpdateParams {
    type?: BulkUpdateFlyoverType;
    invoiceItems?: InvoiceItem[];
    selectedItems?: InvoiceItemWithVariantDetail[];
}

export const useItemBulkAction = () => {
    const {
        domain: { itemsPageStore, invoiceItemStore },
    } = useStores();

    const { itemsGroupBy, applications } = itemsPageStore;
    const { items = [], selectedItems = [] } = invoiceItemStore;
    const [addToPracticesParams = {}, setAddToPracticesParams] = useState<AddToPracticesParams>();
    const [bulkUpdateParams = {}, setBulkUpdateParams] = useState<BulkUpdateParams>();

    const getAddItemToPracticesMessage = (params: { practiceCount: number; isScheduledUpdate: boolean }): NotificationContent => {
        const { practiceCount, isScheduledUpdate } = params;
        const message = isScheduledUpdate
            ? i18n.t('controlCenter:addItemsToPracticesSuccessfullyScheduled', {
                  count: practiceCount,
                  defaultValue: `Add Items to ${practiceCount} Practice(s) Successfully Scheduled`,
              })
            : i18n.t('controlCenter:addingItemsToAdditionalPractices', {
                  count: practiceCount,
                  defaultValue: `Adding Items to ${practiceCount} Additional Practice(s)`,
              });

        const subMessage = i18n.t('controlCenter:viewUpdateStatusInActivityLog', {
            defaultValue: `View the update's status in the activity log.`,
        });

        return ToastWithSubHeader({ message, subMessage });
    };

    const getItemKey = (item: InvoiceItem) => (itemsGroupBy === ItemsGroupByType.Description && item.hospitalDescription) || item.pimsGeneratedId;

    const onAddToPracticesAction = () => {
        const itemsKeys = new Set<string>();
        const groupedItems = new Map<string, InvoiceItem[]>();

        const childItems = selectedItems.filter(({ itemParent }) => itemParent?.pimsGeneratedId);

        if (childItems.length) {
            const message = i18n.t(
                'controlCenter:toAddSelectedSubProductToAdditionalPractices',
                'To add selected sub-product(s) to additional practices, use the main product(s). That will push out any updates to the main product, including linked sub-products.',
            );
            Notifications.error(message);
            setAddToPracticesParams(undefined);
            return;
        }

        selectedItems.forEach((item) => {
            const group = groupedItems.get(item.provider.siteId) || [];
            groupedItems.set(item.provider.siteId, [...group, item]);
            itemsKeys.add(getItemKey(item));
        });

        const practicesWithoutItems = applications.reduce((agg, { id: siteId }) => {
            if ((groupedItems.get(siteId)?.length || 0) < itemsKeys.size) {
                return [...agg, siteId];
            }
            return agg;
        }, [] as string[]);

        if (applications.length === practicesWithoutItems.length) {
            const message = i18n.t(
                'controlCenter:allInvoiceItemsCouldNotBeFoundInClinic',
                'All invoice items could not be found in at least one clinic',
            );
            Notifications.error(message);
            setAddToPracticesParams(undefined);
            return;
        }

        if (!practicesWithoutItems.length) {
            const message = i18n.t('controlCenter:invoiceItemsExistInAllAvailableClinics', 'Invoice items exist in all available clinics');
            Notifications.error(message);
            setAddToPracticesParams(undefined);
            return;
        }

        setAddToPracticesParams({ practicesWithoutItems, itemsKeys: itemsKeys });
    };

    const onAddToPracticesSubmit = async (params: ItemAddToPracticesParams) => {
        const { originPractice, targetPractices, runAt, name } = params;
        const keyGroupedItems = items.reduce((agg, item) => {
            const itemKey = getItemKey(item);
            const group = agg.get(itemKey) || [];
            return agg.set(itemKey, [...group, item]);
        }, new Map<string, InvoiceItem[]>());

        const createItems = [...keyGroupedItems.keys()].reduce((agg, itemKey) => {
            if (!addToPracticesParams?.itemsKeys?.has(itemKey)) {
                return agg;
            }

            const masterItem: InvoiceItem | undefined = keyGroupedItems.get(itemKey)?.find(({ provider }) => provider.siteId === originPractice.id);
            if (!masterItem) {
                return agg;
            }

            const existInPractices = keyGroupedItems.get(itemKey)?.map(({ provider }) => provider.siteId) || [];
            const newItems = targetPractices
                .filter(({ id }) => !existInPractices.includes(id))
                .map((practice) => ({
                    pimsId: masterItem.pimsId,
                    pimsGeneratedId: masterItem.pimsGeneratedId,
                    hospitalDescription: masterItem.hospitalDescription,
                    clientDescription: masterItem.clientDescription,
                    itemType: masterItem.itemType,
                    itemParent: masterItem.itemParent,
                    childItems: masterItem.childItems,
                    provider: (practice as SiteEntityInfo).provider,
                    masterSiteId: originPractice.id,
                    barcode: masterItem.barcode,
                    typeDetails: { isControlledSubstance: (masterItem?.typeDetails as InvoiceItemInventory).isControlledSubstance },
                }));

            return [...agg, ...newItems];
        }, [] as Partial<InvoiceItem>[]);

        await invoiceItemStore.saveItems({
            items: createItems,
            updateExistingItems: false,
            runAt,
            name,
            message: getAddItemToPracticesMessage({ practiceCount: targetPractices.length, isScheduledUpdate: Boolean(runAt) }),
        });
        setAddToPracticesParams(undefined);
    };

    const onAddToPracticesCancel = () => {
        setAddToPracticesParams(undefined);
    };

    const onBulkUpdateAction = (type: BulkUpdateFlyoverType) => {
        const selectedItemsWithVariantDetail = selectedItems.map((item) => ({ ...item, providers: [item.provider] } as InvoiceItemWithVariantDetail));
        setBulkUpdateParams({ type, invoiceItems: selectedItems, selectedItems: selectedItemsWithVariantDetail });
    };

    const onBulkUpdateCancel = () => {
        setBulkUpdateParams(undefined);
    };

    return {
        addToPractices: {
            ...addToPracticesParams,
            action: onAddToPracticesAction,
            submit: onAddToPracticesSubmit,
            cancel: onAddToPracticesCancel,
        },
        bulkUpdate: {
            ...bulkUpdateParams,
            action: onBulkUpdateAction,
            cancel: onBulkUpdateCancel,
        },
    };
};
