import * as React from 'react';
import classNames from 'classnames';
import { BaseSpotComponent, SpotInputProps } from './common';
import { SpotFieldError } from './SpotFieldError';

export class SpotTextarea extends BaseSpotComponent<SpotInputProps & React.HTMLProps<HTMLTextAreaElement>> {
    render() {
        const { className, error, id, ...rest } = this.props;
        return (
            <span>
                <textarea className={classNames('spot-form__textarea', className)} {...rest} id={this.idProp} />
                {error && <SpotFieldError meta={error} />}
            </span>
        );
    }
}
