import React from 'react';
import { PortalWithState } from 'react-portal';

import styles from './chooseAvatarDialog.module.scss';

import i18n from 'i18next';
import { ScreenSizeType, useScreenSize } from '../../hooks';
import classNames from 'classnames';

import { SpotDropdown, SpotIconType, SpotSvg } from '@enterprise/spot';

interface ChooseAvatarDialogProps {
    onChange: (avatar: string) => void;
    items: {
        path: string;
        isSelected: boolean;
    }[];
}

export function ChooseAvatarDialog(props: ChooseAvatarDialogProps) {
    const { isUpTo } = useScreenSize();
    const isMobile = isUpTo(ScreenSizeType.sm);

    const dropDownAvatars = props.items.map((avatar) => ({
        value: <img src={avatar.path} />,
        id: avatar.path,
        className: styles.singleAvatar,
        selected: avatar.isSelected,
    }));

    const createModalContent = (close) => (
        <div className={styles.overlay}>
            <div className={styles.header}>
                <h2>Avatar</h2>
                <SpotSvg onClick={close} className={styles.closeIcon} icon={String(SpotIconType.Close)} />
            </div>

            <div className={styles.avatarsList}>
                {props.items.map((item, idx) => (
                    <div
                        onClick={() => {
                            props.onChange(item.path);
                            close();
                        }}
                        className={classNames(styles.avatarItem, { [styles.avatarItemSelected]: item.isSelected })}
                        key={idx}
                    >
                        <img src={item.path} />
                    </div>
                ))}
            </div>
        </div>
    );

    return isMobile ? (
        <PortalWithState closeOnOutsideClick closeOnEsc>
            {({ openPortal, closePortal, portal }) => (
                <>
                    <a
                        href="#"
                        onClick={(e) => {
                            e.preventDefault();
                            openPortal(e);
                        }}
                        className={classNames('spot-link')}
                    >
                        {i18n.t('myProfile:select', 'Select')} <SpotSvg className={styles.downIcon} icon={String(SpotIconType.CaretDown)} />
                    </a>
                    {portal(createModalContent(closePortal))}
                </>
            )}
        </PortalWithState>
    ) : (
        <SpotDropdown
            className={styles.avatarDropdown}
            onChange={props.onChange}
            elements={dropDownAvatars}
            title={
                <a href="#" onClick={(e) => e.preventDefault()} className={classNames('spot-link')}>
                    {i18n.t('myProfile:select', 'Select')}
                </a>
            }
        />
    );
}
