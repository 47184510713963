import { INTENT } from '../styles';

export const DEFAULT_NEW_PRIMARY_CARD_INTENT = INTENT.PRIMARY;

export const NEW_CHILD_CARD_ID = 'create-new-child-card-id';

export const PLACE_HOLDER = 'placeholder';

export const SCROLL_DURATION = 300;
export const SCROLL_OFFSET = 15;
