export class ItemData {
    description?: string | null;
    name?: string | null;
    siteId?: string;
    status?: number;

    constructor({ description, name, siteId, status }: Partial<ItemData>) {
        this.description = description;
        this.name = name;
        this.siteId = siteId;
        this.status = status;
    }
}
