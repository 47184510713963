import React, { memo } from 'react';
import Modal from '@mui/material/Modal';
import styles from './navigationGuard.module.scss';
import { NavigationDialogueBox } from './navigationDialogueBox';
import ReactRouterPrompt from 'react-router-prompt';

interface Props {
    when?: boolean;
}

export const NavigationGuard = memo(function NavigationGuard(props: Props) {
    const { when } = props;

    return (
        <ReactRouterPrompt when={Boolean(when)}>
            {({ isActive, onConfirm, onCancel }) => (
                <Modal open={isActive}>
                    <div className={styles.navGuard} data-automation-id={'unsaved-changes-nav-guard'}>
                        <NavigationDialogueBox cancel={onCancel} confirm={onConfirm} />
                    </div>
                </Modal>
            )}
        </ReactRouterPrompt>
    );
});
