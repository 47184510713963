import { Inject, Service } from 'typedi';
import { BaseHttp } from '@enterprise/common';
import { MappingTypes } from '../core/enums/Mapping';
import { MappedItem } from '../core/entity/mapping';
import { SiteMapping } from '../core/entity/mapping/SiteMapping';
import { MappingFileStatus } from '../core/entity/mapping/MappingFileStatus';
import { MappingFileUploadLink } from '../core/entity/mapping/MappingFileUploadLink';
import Axios from 'axios';
import AppStore from '../store/AppStore';

@Service()
export class MappingService {
    constructor(@Inject('http') private readonly http: BaseHttp, private readonly appStore: AppStore) {}

    areClassificationsMapped(): Promise<boolean> {
        return this.http.get(`/v2/mapping/isTypeMapped/:type`, { pathParams: { type: MappingTypes.CLASSIFICATION } });
    }

    createNewMaster(data: MappedItem): Promise<MappedItem> {
        return this.http.post(`/v2/mapping/:type/:id`, data, { pathParams: { type: data.type, id: data.id } });
    }

    getByType(type: MappingTypes): Promise<MappedItem[]> {
        return this.http.get(`/v2/mapping/:type`, { pathParams: { type } });
    }

    getUnmappedByType(type: MappingTypes): Promise<MappedItem[][]> {
        return this.http.get(`/v2/mapping/:type/unmapped`, { pathParams: { type } });
    }

    getMappingStatusByType(type: MappingTypes): Promise<SiteMapping[]> {
        return this.http.get(`/v2/mapping/:type/with-status`, { pathParams: { type } });
    }

    getMappingFileUploadLink(type: MappingTypes): Promise<MappingFileUploadLink> {
        return this.http.get(`/v2/mapping/mapping-file-upload-link/:type`, { pathParams: { type } });
    }

    uploadMappingFile(url: string, formData: FormData): Promise<any> {
        return Axios.create().post(url, formData);
    }

    deleteMappingFileStatus(type: MappingTypes): Promise<MappingFileStatus> {
        return this.http.delete(`/v2/mapping/mapping-file-status/:id/:type`, undefined, { pathParams: { id: this.currentUserOrgId, type } });
    }

    getMappingFileStatus(type: MappingTypes): Promise<MappingFileStatus> {
        return this.http.get(`/v2/mapping/mapping-file-status/:type`, { pathParams: { type } });
    }

    updateMappingFileStatus(status: MappingFileStatus): Promise<any> {
        return this.http.put(`/v2/mapping/mapping-file-status/:orgId`, status, { pathParams: { orgId: this.currentUserOrgId } });
    }

    patchMapping<T>(data: MappedItem): Promise<T> {
        return this.http.patch<T>(`/v2/mapping/:type/:id`, data, { pathParams: { type: data.type, id: data.id } });
    }

    getMappingForSite(type: string, siteId: string, mappingId: string, mappingParentId?: string, pimsParentId?: string): Promise<string> {
        return this.http.get(`/v2/mapping/mappingForSite`, { params: { type, siteId, mappingId, mappingParentId, pimsParentId } });
    }

    getMapping(type: MappingTypes, id: string, parentId?: string | null): Promise<MappedItem[]> {
        const params = (parentId && { parentId }) || {};
        return this.http.get(`/v2/mapping/:type/:id`, { params, pathParams: { type, id } });
    }

    updateMapping(type: MappingTypes, id: string, mapping: MappedItem): Promise<any> {
        return this.http.patch(`/v2/mapping/:type/:id`, mapping, { pathParams: { type, id } });
    }

    useDefaultClassesAndSubclassesAsMaster(): Promise<boolean> {
        return this.http.post(`/v2/mapping/useDefaultClassesAndSubclassesAsMaster`);
    }

    useApplicationClassesAndSubclassesAsMaster(siteId: string): Promise<boolean> {
        return this.http.post(`/v2/mapping/use-application-classes-and-subclasses-as-master/:siteId`, null, { pathParams: { siteId } });
    }

    dropLinked(mapping: MappedItem): Promise<MappedItem> {
        return this.http.delete(`/v2/mapping/:type/:id/drop-linked`, mapping, { pathParams: { id: mapping.id, type: mapping.type } });
    }

    deleteMapping({ type, id, parentId }: MappedItem) {
        return this.http.delete(`/v2/mapping/:type/:id`, undefined, { params: { parentId }, pathParams: { id, type } });
    }

    get currentUserOrgId(): string | null {
        const org = (this.appStore.token.user as any).organization;
        return org ? org.id || org : null;
    }
}
