import { CommandInterface } from '../../services/workers/CommandIntervalWorker';
import { MappingService } from '../../services/MappingService';
import ioc from '../../services/ioc';
import { MappingTypes } from '../../core/enums/Mapping';

export class MappingFileStatusUpdateCommand implements CommandInterface {
    private readonly mappingService: MappingService;

    constructor() {
        this.mappingService = ioc.get(MappingService);
    }

    async execute(options?: any): Promise<any> {
        return await this.mappingService.getMappingFileStatus(MappingTypes.CLASSIFICATION);
    }
}
