import React, { MouseEvent } from 'react';
import { Card } from 'react-bootstrap';
import { Container, Col, Row } from 'react-bootstrap';
import { SpotCheckbox } from '@enterprise/spot';
import ConstantsStore from '../../../store/ConstantsStore';
import { CollapsiblePanel } from '../../../components/CollapsiblePanel';
import { ItemCardDetails } from './ItemCardDetails';
import { InvoiceItemWithVariantDetail } from '../../../core/models/datasource/invoiceItems/InvoiceItemWithVariantDetail';
import { Trans } from 'react-i18next';
import { InvoiceItem } from '../../../core/models/datasource/invoiceItems';
import { InvoiceItemEditType } from '../items/InvoiceItemEditType.enum';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';

interface ItemCardProps {
    item: InvoiceItemWithVariantDetail;
    className?: string;
    onEditClicked?: (params: { item?: InvoiceItem; editType?: InvoiceItemEditType }) => void;
    onSelected?: (item: InvoiceItemWithVariantDetail) => void;
    constantStore?: ConstantsStore;
    isSelected?: boolean;
}

export const ItemCard = observer(function ItemCard(props: ItemCardProps) {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const { item, className, isSelected, constantStore } = props;
    const isSub = Boolean(item.itemParent);

    const onSelected = () => {
        props.onSelected?.(item);
    };

    const renderManageContent = () => {
        const onEditClick = (event: MouseEvent, item: InvoiceItem, type?: InvoiceItemEditType) => {
            event.stopPropagation();
            props.onEditClicked?.({ item, editType: type });
        };
        return (
            <>
                <small className="spot-typography__text–secondary">
                    <Trans i18nKey="controlCenter:manage">Manage</Trans>:
                </small>
                <button
                    className="spot-button spot-button--secondary spot-button--with-icon item-card-manage-item"
                    type="button"
                    onClick={(event) => onEditClick(event, item)}
                    data-automation-id="form-view-button"
                >
                    <svg className={'spot-icon spot-button__icon spot-button__icon--left'}>
                        <use href={'/assets/img/icons.svg#form'} />
                    </svg>
                    <span className="spot-button__text">
                        <Trans i18nKey="controlCenter:formView">Form View</Trans>
                    </span>
                </button>
                {itemsPageStore.isAllPracticesGridEditorFeatureEnabled && (
                    <button
                        className="spot-button spot-button--secondary spot-button--with-icon item-card-manage-item"
                        type="button"
                        onClick={(event) => onEditClick(event, item, InvoiceItemEditType.Grid)}
                        data-automation-id="grid-view-button"
                    >
                        <svg className={'spot-icon spot-button__icon spot-button__icon--left'}>
                            <use href={'/assets/img/icons.svg#grid'} />
                        </svg>
                        <span className="spot-button__text">
                            <Trans i18nKey="controlCenter:gridView">Grid View</Trans>
                        </span>
                    </button>
                )}
            </>
        );
    };

    const renderDetails = (isSub) => {
        return (
            <>
                {!itemsPageStore.isCreatingNewItem && (
                    <ItemCardDetails
                        item={item}
                        onEditClicked={(item) => props.onEditClicked?.({ item })}
                        masterSite={constantStore?.organizationSettings?.masterSite}
                        matchSubLabel={isSub}
                    />
                )}
            </>
        );
    };

    return (
        <div className={['item-card-component', className].join(' ')}>
            <Card>
                <Card.Body>
                    <CollapsiblePanel expandedContent={renderDetails(isSub)}>
                        {({ toggle }) => (
                            <div className="item-card-preview" onClick={toggle} data-automation-id="item-expand">
                                <Container fluid={true}>
                                    <Row className="align-items-center">
                                        <Col xl={7}>
                                            <Row className="align-items-center">
                                                <Col xs={1}>
                                                    <SpotCheckbox
                                                        className={'item-card-row-checkbox'}
                                                        id={`is-selected-${item.pimsGeneratedId}`}
                                                        defaultChecked={isSelected}
                                                        onClick={onSelected}
                                                    />
                                                </Col>
                                                <Col md={11} lg={6}>
                                                    <div>{item.hospitalDescription}</div>
                                                    <small className="spot-typography__text–secondary">{item.pimsId || item.pimsGeneratedId}</small>
                                                </Col>
                                                <Col md={{ offset: 1, span: 3 }} lg={{ offset: 0, span: 2 }}>
                                                    <div className={'item-card-component--secondary'}>{item.itemType.description}</div>
                                                </Col>
                                                <Col xs={3}>
                                                    <div className={'item-card-component--secondary'}>
                                                        {item.itemParent ? <Trans i18nKey={'controlCenter:items.subproduct'}>Subproduct</Trans> : ''}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={{ offset: 1 }} xl={{ offset: 0, span: 5 }}>
                                            {renderManageContent()}
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        )}
                    </CollapsiblePanel>
                </Card.Body>
            </Card>
        </div>
    );
});
