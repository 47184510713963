import React from 'react';
import { InvoiceItem, InvoiceItemChild, InvoiceItemDifference, InvoiceItemWithVariantDetail } from '../../../../../core/models/datasource';
import { SpotSvg } from '@enterprise/spot';
import { Trans } from 'react-i18next';
import { differenceWith, isEqual } from 'lodash';
import i18n from 'i18next';
import styles from './subproductControls.module.scss';
import { Notice, NoticeIcon } from '../../../../../components/helpers/notice';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useStores } from '../../../../../hooks';
import { ItemEditQuery } from '../../../../../store/invoiceItem.store';

interface InvoiceItemChildDifference {
    [key: string]: InvoiceItemChild[];
}

export interface SubproductControlsProps {
    values: Partial<InvoiceItemWithVariantDetail>;
    data: InvoiceItemDifference;
    patchData: (data: InvoiceItemDifference) => void;
    activeValueName: 'master' | string;
}

export const SubproductControls = observer(function SubproductControls(props: SubproductControlsProps) {
    const {
        domain: { invoiceItemStore },
        ui: { router },
    } = useStores();

    const { values, data, activeValueName, patchData } = props;
    const { practice } = router.params as { practice: string };

    const removePimsGeneratedIdAndProvider = ({ pimsGeneratedId, provider, ...item }: InvoiceItemChild): InvoiceItemChild => {
        return item as InvoiceItemChild;
    };

    const getChildItemsDiff = (master: Partial<InvoiceItem>, variants: InvoiceItemDifference): InvoiceItemChildDifference => {
        const masterChildItems = master.childItems?.map(removePimsGeneratedIdAndProvider) || [];
        const res = Object.keys(variants).reduce<InvoiceItemChildDifference>((agg, key) => {
            const variantChildItems = variants[key].childItems?.map(removePimsGeneratedIdAndProvider) || [];
            return {
                ...agg,
                [key]: differenceWith(masterChildItems, variantChildItems, isEqual),
            };
        }, {});

        return res;
    };

    const copyAbsentChildItems = (childItemsDiff: InvoiceItemChildDifference) => {
        const updatedChildItems = Object.keys(childItemsDiff).reduce<InvoiceItemDifference>((agg, key) => {
            return {
                ...agg,
                [key]: { childItems: [...(data[key].childItems || []), ...childItemsDiff[key]] },
            };
        }, {});

        patchData(updatedChildItems);
    };

    const { master, ...variants } = data;
    const isSubproduct = Boolean(values.itemParent?.pimsGeneratedId);
    const hasSubProducts = Boolean(values.childItems?.length);
    const childItemsDiff = getChildItemsDiff(master, variants);
    const hasSubproductsDiff = Boolean(Object.values(childItemsDiff).find((items) => Boolean(items.length)));
    const hasSubproductsCopied = Boolean(
        Object.values(variants).find(({ childItems }) => Boolean(childItems?.find((child) => !child.pimsGeneratedId))),
    );
    const onEditClick = (params: { description?: string; isSub: boolean }) => {
        const query: ItemEditQuery = {
            ...params,
            practice,
        };
        invoiceItemStore.openItemEditForm({ query, newWindow: true });
    };

    return (
        <>
            {isSubproduct && (
                <div>
                    <p className={styles.header}>
                        <Trans i18nKey={'controlCenter:subItemPrompt'}>This item is a subproduct of:</Trans>
                    </p>
                    <button
                        className={classNames('spot-button spot-button--link spot-button--small', styles.link)}
                        onClick={() => onEditClick({ description: values.itemParent?.hospitalDescription, isSub: false })}
                    >
                        {values.itemParent?.hospitalDescription}
                    </button>
                </div>
            )}
            {!isSubproduct && hasSubProducts && (
                <>
                    <div className="spot-form__field-group">
                        <p className={styles.header} data-automation-id={'item-subproduct-header'}>
                            <Trans i18nKey={'controlCenter:itemPrompt'}>This item has subproducts:</Trans>
                        </p>
                        {values.childItems?.map((childItem: InvoiceItemChild) => (
                            <div key={`${childItem.hospitalDescription}`} data-automation-id={'item-subproduct-item'}>
                                <button
                                    disabled={!childItem.pimsGeneratedId}
                                    className={classNames('spot-button spot-button--link spot-button--small', styles.link)}
                                    onClick={() => onEditClick({ description: childItem.hospitalDescription, isSub: true })}
                                >
                                    {childItem.hospitalDescription}
                                </button>
                            </div>
                        ))}
                    </div>

                    {activeValueName === 'master' && (
                        <>
                            {hasSubproductsDiff && (
                                <a
                                    onClick={() => {
                                        copyAbsentChildItems(childItemsDiff);
                                    }}
                                    href="javascript:void(0)"
                                    className={classNames('spot-link', styles.link)}
                                    data-automation-id="add-subproducts-to-all-variants"
                                >
                                    <SpotSvg icon="add" className="spot-icon spot-link__icon spot-link__icon--left" />
                                    <Trans i18nKey="controlCenter:addSubproductsToAllVariants">Add these subproducts to all variants</Trans>
                                </a>
                            )}
                            {!hasSubproductsDiff && hasSubproductsCopied && (
                                <Notice
                                    icon={NoticeIcon.InProgress}
                                    data-automation-id="added-to-variants-notification"
                                    text={i18n.t('controlCenter:subproductsAddedToAllVariants', {
                                        defaultValue: 'Subproducts ({{count}}) added to all variants – this update will be saved upon item publish.',
                                        count: values.childItems?.length,
                                    })}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
});
