import { InvoiceItemDispensingItem } from './InvoiceItemDispensingItem';
import { ControlledSubstanceSchedule } from '../enums';

class ControlledSubstanceScheduleNormalized {
    id: ControlledSubstanceSchedule;
}

class InvoiceItemLocation {
    description?: string;

    pimsGeneratedId?: string;

    pimsId?: string;

    quantityOnHand?: number;
}

export class UnitOfMeasure {
    description?: string;

    pimsGeneratedId?: string;

    pimsId?: string;
}

export class InvoiceItemInventory {
    static typeIdentifier = 'invoice-item-inventory';

    allowAllDispensing?: boolean;

    controlledSubstanceScheduleNormalized?: ControlledSubstanceScheduleNormalized;

    dispensingItems?: InvoiceItemDispensingItem[];

    displayAsVaccine?: boolean;

    hasMsds?: boolean;

    isControlledSubstance?: boolean;

    isMiscellaneous?: boolean;

    isHazardous?: boolean;

    locations?: InvoiceItemLocation[];

    maintainQoH?: boolean;

    unitOfMeasure?: UnitOfMeasure;
}
