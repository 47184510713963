import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { Notifications } from '../../../../Notifications';
import i18n from 'i18next';
import { GetItemQuery, InvoiceItem } from '../../../../../core/models';
import { useEffect } from 'react';
import { useStores } from '../../../../../hooks';
import { toJS } from 'mobx';
import { SearchBar } from '../../../../../components/mui/searchBar';

interface PageProps {
    searchItems: (query: GetItemQuery) => Promise<void>;
    searchItemsForAutoComplete?: (query: GetItemQuery) => Promise<InvoiceItem[]>;
}

const CLASSIFICATION_TRIGGERS = ['class: ', 'class:'];

export const ItemSearchPanel = observer(function ItemSearchPanel(props: PageProps) {
    const {
        domain: { itemsPageStore, constantsStore },
    } = useStores();

    const { searchItems, searchItemsForAutoComplete } = props;
    const currentFilter = toJS(itemsPageStore.filter) || { searchValue: '' };
    const { searchValue, searchType, classId } = currentFilter;

    useEffect(() => {
        if (searchValue?.length) {
            search();
        }
    }, []);

    const isValidSearch = (value) => {
        const valueLength = value.trim().length;
        const validationError =
            valueLength === 0 &&
            i18n.t('validation:searchNotEmpty', {
                field: i18n.t('controlCenter:itemSearch.id', 'ID'),
            });

        if (validationError) {
            Notifications.warn(validationError);
            return false;
        }
        return true;
    };

    const search = () => {
        const searchQuery = { searchType: searchType, searchValue: searchValue };

        const trigger = CLASSIFICATION_TRIGGERS.find((trigger) => {
            if (searchValue?.toLowerCase().includes(trigger)) {
                return trigger;
            }
            return undefined;
        });

        if (trigger && searchValue?.toLowerCase().startsWith(trigger)) {
            searchQuery.searchValue = searchValue.substring(trigger?.length);
            searchQuery.searchType = 'classification';
        }

        if (!classId && !isValidSearch(searchQuery.searchValue)) {
            return;
        }

        searchItems(searchQuery);
    };

    const updateFilter = (update) => {
        itemsPageStore.filter = update;
    };

    return (
        <>
            <Row>
                <Col xs={12}>
                    <SearchBar
                        disabled={constantsStore.isLoading}
                        isSelfControlled={false}
                        value={currentFilter.searchValue} //hard coded 'description' or 'id' to filter new SearchBar search results with description or id
                        onChange={(searchValue) => updateFilter({ searchValue })}
                        // onClick={this.showPresets} //keep this prop for future functionality
                        onSubmit={search}
                        searchItemsForAutoComplete={searchItemsForAutoComplete}
                    />
                </Col>
            </Row>
        </>
    );
});
