import * as React from 'react';
import { Step } from './Step';

export interface WizardProps {
    initialValues?: any;
    children: React.ReactChild[];
    onSubmit?: (values: any) => void;
}

export interface WizardState {
    values: any;
    index: number;
}

export class Wizard extends React.PureComponent<WizardProps, WizardState> {
    state: WizardState = {
        index: 0,
        values: {
            ...this.props.initialValues,
        },
    };
    static Step = props => <Step {...props} />;

    _prevStep = () => {
        if (this.state.index <= 0) {
            return;
        }
        this.setState(prevState => ({
            index: prevState.index - 1,
        }));
    };

    _nextStep = () => {
        if (this.state.index >= this.props.children.length - 1) {
            return;
        }

        this.setState(prevState => ({
            index: prevState.index + 1,
        }));
    };

    _onChangeValue = (name, value) => {
        this.setState(prevState => ({
            values: {
                ...prevState.values,
                [name]: value,
            },
        }));
    };

    _onSubmit = () => {
        if (this.props.onSubmit) {
            this.props.onSubmit(this.state.values);
        }
    };

    render() {
        const { children } = this.props;
        const enabledSteps = React.Children.toArray(children).filter(child => !(child as any).props.disabled);

        return enabledSteps.map((c, i) => {
            return i === this.state.index
                ? React.cloneElement(c as any, {
                      currentIndex: this.state.index,
                      prevStep: this._prevStep,
                      nextStep: this._nextStep,
                      onChangeValue: this._onChangeValue,
                      onSubmit: this._onSubmit,
                      isLast: this.state.index === this.props.children.length - 1,
                      values: this.state.values,
                  })
                : null;
        });
    }
}
