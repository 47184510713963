import React from 'react';
import { SpotSelectBox, SpotSelectOption, SpotCheckboxGroup, SpotCheckbox, SpotHorizontalFields, SpotButton, SpotModal } from '@enterprise/spot';
import { Form, Field, FormType, FormRenderProps } from '@enterprise/common';
import { FullItemFormType } from '../formType/FullItemFormType';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import ConstantsStore from '../../../../store/ConstantsStore';
import { InvoiceItemsWithVariantDetails } from '../../../../core/transformer/InvoiceItemsWithVariantDetails';
import { DatasourceFullSiteInfo } from '../../../../core/models/datasource/sites';
import { ProviderDetails } from '../../../../core/models';

interface VariantsControlsProps {
    constants: ConstantsStore;
    existingVariantsIds: string[];
    selectedVariant?: string;
    disabled?: boolean;
    onVariantChanged?: (name) => void;
    onVariantAdded?: (name, copyFrom?) => void;
    form: FullItemFormType;
}

interface SiteWithVariant extends DatasourceFullSiteInfo {
    diffKey: string;
}

interface SortedSites {
    sitesWithVariant: SiteWithVariant[];
    sitesWithoutVariant: DatasourceFullSiteInfo[];
}

interface VariantData {
    copyFrom: string;
    variantProviders: ProviderDetails[];
}

export class VariantsControls extends React.PureComponent<VariantsControlsProps> {
    form = new FormType<VariantData>();

    data: VariantData = { copyFrom: 'master', variantProviders: [] };

    render() {
        const { constants, disabled, selectedVariant } = this.props;
        const { sitesWithVariant, sitesWithoutVariant } = this.sortSites();
        return (
            <div>
                <div>
                    <div style={{ display: 'inline-block', width: '50%' }}>
                        <label className="spot-form__label">
                            <Trans i18nKey="variantsControls:variant">Variant:&nbsp;</Trans>
                        </label>
                        {sitesWithVariant.length === 0 && (
                            <label className="spot-form__label--disabled">
                                <Trans i18nKey="variantsControls:noVariantsAvailable">No variants available</Trans>
                            </label>
                        )}
                        {sitesWithVariant.length > 0 && (
                            <SpotSelectBox
                                onChange={this.onVariantSelected}
                                disabled={disabled}
                                value={selectedVariant}
                                style={{ display: 'inline-block' }}
                                placeholder={i18n.t('variantsControls:selectVariantToModify', 'Select a Variant to Modify')}
                            >
                                {sitesWithVariant.map(({ diffKey, name }) => (
                                    <SpotSelectOption key={diffKey} value={diffKey}>
                                        {name}
                                    </SpotSelectOption>
                                ))}
                            </SpotSelectBox>
                        )}
                    </div>
                    <div style={{ display: 'inline-block', width: '50%', textAlign: 'right' }}>
                        <SpotModal title="Add New Variant" controller={this.renderModalController}>
                            {({ close }) => (
                                <div>
                                    <Form formType={this.form} data={this.data} onSubmit={this.onVariantsAdded}>
                                        {({ handleSubmit }: FormRenderProps<VariantData>) => (
                                            <div>
                                                <div>
                                                    <SpotHorizontalFields>
                                                        <div>
                                                            <label className="spot-form__label">
                                                                <Trans i18nKey="variantsControls:selectClinicsForVariant">
                                                                    Select Clinics for the Variant
                                                                </Trans>
                                                            </label>

                                                            {sitesWithoutVariant.length === 0 && (
                                                                <label className="spot-form__label--disabled">
                                                                    <Trans i18nKey="variantsControls:noClinicsForVariants">
                                                                        No clinics available to add variant. Check My Organization to ensure the
                                                                        desired clinic is online
                                                                    </Trans>
                                                                </label>
                                                            )}
                                                            {sitesWithoutVariant.length > 0 && (
                                                                <SpotCheckboxGroup isHorizontal={true}>
                                                                    {sitesWithoutVariant.map((app) => (
                                                                        <Field
                                                                            key={app.id}
                                                                            name="variantProviders"
                                                                            type="checkbox"
                                                                            value={app.provider}
                                                                        >
                                                                            {({ input }) => (
                                                                                <SpotCheckbox {...input} value={input.value?.name}>
                                                                                    {app.name}
                                                                                </SpotCheckbox>
                                                                            )}
                                                                        </Field>
                                                                    ))}
                                                                </SpotCheckboxGroup>
                                                            )}
                                                        </div>
                                                    </SpotHorizontalFields>
                                                </div>
                                                <div>
                                                    <SpotHorizontalFields>
                                                        <div>
                                                            <label className="spot-form__label">
                                                                <Trans i18nKey="variantsControls:copyFrom">Copy from</Trans>
                                                            </label>
                                                            <Field name="copyFrom">
                                                                {({ input, meta }) => (
                                                                    <SpotSelectBox {...input}>
                                                                        <SpotSelectOption value="master">Master</SpotSelectOption>
                                                                        {constants.applications.filter(this.checkSiteContainsVariant).map((app) => (
                                                                            <SpotSelectOption key={app.id} value={app.id}>
                                                                                {app.name}
                                                                            </SpotSelectOption>
                                                                        ))}
                                                                    </SpotSelectBox>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </SpotHorizontalFields>
                                                </div>
                                                <div>
                                                    <span style={{ width: '50%', display: 'inline-block' }}>
                                                        <a onClick={close}>Cancel</a>
                                                    </span>
                                                    <span
                                                        style={{
                                                            width: '50%',
                                                            display: 'inline-block',
                                                            textAlign: 'right',
                                                        }}
                                                    >
                                                        <SpotButton
                                                            data-automation-id={'variant-controls-create-button'}
                                                            onClick={this.onCreateClicked(handleSubmit, close)}
                                                            disabled={!sitesWithoutVariant?.length}
                                                        >
                                                            Create
                                                        </SpotButton>
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </Form>
                                </div>
                            )}
                        </SpotModal>
                    </div>
                </div>
            </div>
        );
    }

    private checkSiteContainsVariant = (site: DatasourceFullSiteInfo): string | undefined => {
        const { existingVariantsIds } = this.props;
        return existingVariantsIds.find((variantId) => {
            const { siteId } = InvoiceItemsWithVariantDetails.splitDiffKey(variantId);
            return siteId === site.id;
        });
    };

    private sortSites(): SortedSites {
        const { constants } = this.props;

        const sortedSites: SortedSites = {
            sitesWithVariant: [],
            sitesWithoutVariant: [],
        };

        if (!constants.applications.length) {
            return sortedSites;
        }

        return constants.applications
            .filter((app) => app.isActive)
            .reduce((agg, cur) => {
                const siteWithVariant = this.checkSiteContainsVariant(cur);
                if (siteWithVariant) {
                    agg.sitesWithVariant.push({
                        ...cur,
                        diffKey: siteWithVariant,
                    });
                } else {
                    agg.sitesWithoutVariant.push(cur);
                }
                return agg;
            }, sortedSites);
    }

    private onCreateClicked = (handleSubmit, close) => {
        return () => {
            handleSubmit();
            setTimeout(close, 500);
        };
    };

    private renderModalController = ({ open }) => {
        return (
            <a onClick={open}>
                <Trans i18nKey="variantsControls:addVariant">Add a variant</Trans>
            </a>
        );
    };

    private onVariantSelected = (event) => {
        const variantName = event.target.value;
        const { onVariantChanged } = this.props;
        if (variantName && onVariantChanged) {
            onVariantChanged(variantName);
        }
    };

    private onVariantsAdded = (data) => {
        data.variantProviders.forEach((site) => {
            if (this.props.onVariantAdded) {
                this.props.onVariantAdded(site, data.copyFrom);
            }
        });
    };
}
