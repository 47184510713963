import i18n from 'i18next';
import validator from 'validator';
import { InvoiceItem } from '../models/datasource';

export const isURL = (val) => {
    if (!val) {
        return;
    }

    const isNotPositiveFloatError = i18n.t('validation:isURL', {
        defaultValue: `Value must be an URL`,
    });

    if (
        !validator.isURL(val, {
            allow_underscores: true,
            allow_trailing_dot: true,
            allow_protocol_relative_urls: true,
            allow_fragments: true,
            require_tld: false,
            require_protocol: true,
            require_host: true,
            require_valid_protocol: true,
        })
    ) {
        return isNotPositiveFloatError;
    }

    return;
};

const isPositiveFloat = (val) => {
    const isNotPositiveFloatError = i18n.t('validation:isPositiveFloat', {
        defaultValue: `Value must be a positive number`,
    });

    if (isNull(val) || isUndefined(val) || !validator.isFloat(`${val}`, { min: 0 })) {
        return isNotPositiveFloatError;
    }
    return undefined;
};

const isWithinRangeNumber = (val: number, min: number, max: number) => {
    const priceOutOfRangeError = i18n.t('validation:valueWithinRange', {
        defaultValue: `Value must be between ${min} - ${max}`,
        max,
        min,
    });

    if (val < min || val > max) {
        return priceOutOfRangeError;
    }

    return undefined;
};

export const isWithinRange = (min: number, max: number) => {
    return (val: number) => isPositiveFloat(val) || isWithinRangeNumber(val, min, max);
};

export const isWithinNegativeRange = (min: number, max: number) => {
    return (val: number) => isWithinRangeNumber(val, min, max);
};

export const formatPrice = (maxDecimalPlaces: number, emptyValue: string | number | undefined | null = undefined) => {
    return (value: string | number | undefined | null): number | string | undefined | null => {
        if (validator.isEmpty(`${value}`)) {
            return value;
        }

        if (value === undefined || value === emptyValue) {
            return emptyValue;
        }

        return Number(Number(value).toFixed(maxDecimalPlaces));
    };
};

export const isUndefined = (value) => value === undefined || validator.isEmpty(`${value}`);

export const isNull = (value) => value === null;

export const isDefined = (val?: number | string) => !isUndefined(val) && !isNull(val);

export function removeEmptyPriceBreaks(invoiceItem: InvoiceItem): InvoiceItem {
    // in order to allow the user to delete additional price breaks, we must remove price breaks without break amounts
    if (invoiceItem.prices.priceBreaks) {
        invoiceItem.prices.priceBreaks = invoiceItem.prices.priceBreaks.filter((priceBreak, index) => index === 0 || Boolean(priceBreak.breakAmount));
    }
    return invoiceItem;
}

export function defaultEmptyPriceBreakValues(invoiceItem: InvoiceItem): InvoiceItem {
    if (invoiceItem.prices.priceBreaks) {
        invoiceItem.prices.priceBreaks.map((priceBreak, index) => {
            if (!priceBreak.invoicePrice && index === 0) {
                priceBreak.invoicePrice = 0;
            }
            if (!priceBreak.invoiceMarkup) {
                priceBreak.invoiceMarkup = 0;
            }
            if (!priceBreak.breakAmount) {
                priceBreak.breakAmount = 0;
            }
        });
    }
    return invoiceItem;
}
