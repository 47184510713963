import { validate } from 'class-validator';
import { FormProps } from 'react-final-form';
import { FormType, ValidationResult } from './FormType';
import { FunctionTransformer, ToPlainTransformer } from '../transformers';

export class SchemaFormType<T> extends FormType<T> {
    constructor(private schemaName) {
        super();
    }

    buildForm(data: T): Partial<FormProps> | any {
        const initialValues = new ToPlainTransformer().transform(data);
        return { initialValues };
    }

    getEntity(value: T): T {
        return value;
    }

    async validate(value): Promise<ValidationResult> {
        try {
            const errors = await validate(this.schemaName, value);
            return new FunctionTransformer((data) => {
                const result = {};
                (data as any[]).forEach((error) => {
                    result[error.property] = error.constraints[Object.keys(error.constraints)[0]];
                });
                return result;
            }).transform(errors);
        } catch (error) {
            throw error;
        }
    }
}
