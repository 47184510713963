import { AsyncQueue } from 'async';
import queue from 'async/queue';

type TaskMethod = () => Promise<void>;
interface QueueTask<L> {
    name: L;
    task: TaskMethod;
}

interface Options {
    taskQueueConcurrency?: number;
}

export const DEFAULT_TASK_QUEUE_CONCURRENCY = 5;

export class QueueService<L> {
    private loadable: Map<L, TaskMethod> = new Map();

    private readonly taskQueueConcurrency: number;

    private queue: AsyncQueue<QueueTask<L>> = queue(async ({ name, task }: QueueTask<L>, callback) => {
        console.debug(`Queue Service - processing task: ${name}`);
        try {
            await task();
        } catch (err) {
            console.debug(`Error while attempting to execute task`, err);
        }
        callback();
    }, this.taskQueueConcurrency);

    constructor(options: Options = {}) {
        const { taskQueueConcurrency = DEFAULT_TASK_QUEUE_CONCURRENCY } = options;
        this.taskQueueConcurrency = taskQueueConcurrency;
    }

    load(toLoad: L[]): Promise<void> {
        toLoad.forEach((name: L) => {
            const task = this.loadable.get(name);
            if (task) {
                this.queue.push({ name, task });
            }
        });

        return this.queue.drain();
    }

    register(name: L, taskMethod: TaskMethod): QueueService<L> {
        this.loadable.set(name, taskMethod);
        return this;
    }
}
