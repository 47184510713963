export enum TaskType {
    BULK_ITEM_UPDATE_PARENT_V2 = 'BULK_ITEM_UPDATE_PARENT_V2',
    BULK_ITEM_UPDATE_V2 = 'BULK_ITEM_UPDATE_V2',
    SCHEDULED_ITEM_V2 = 'SCHEDULED_ITEM_V2',
    BULK_ITEM_UPDATE_PARENT_V3 = 'BULK_ITEM_UPDATE_PARENT_V3',
    BULK_ITEM_UPDATE_V3 = 'BULK_ITEM_UPDATE_V3',
    SCHEDULED_ITEM_V3 = 'SCHEDULED_ITEM_V3',
    SCHEDULED_ITEM_V3_PARENT = 'SCHEDULED_ITEM_V3_PARENT',
    INDEX_SYNC_V3 = 'INDEX_SYNC_V3',
    INDEX_SYNC_V3_PARENT = 'INDEX_SYNC_V3_PARENT',
}
