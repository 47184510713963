import React from 'react';
import i18n from 'i18next';

interface PageProps {
    handler: any;
    options: any;
    plainOptions: any;
    valueFieldName: any;
    displayFieldName: any;
    value: any;
    placeholder: any;
    placeholderValue: any;
    disabledValues: Set<unknown>;
    isDisabled: any;
    isOmitPlaceholder: any;
    style: any;
}

class StatelessSelect extends React.Component<PageProps> {
    static defaultProps = {
        options: [],
        plainOptions: [],
        valueFieldName: null,
        displayFieldName: null,
        value: null,
        placeholder: null,
        placeholderValue: null,
        disabledValues: new Set(),
        isDisabled: false,
        isOmitPlaceholder: false,
        style: undefined,
    };

    isDisabledValue = (value) => {
        if (!this.props.disabledValues) {
            return false;
        }

        return this.props.disabledValues.has(value);
    };

    createOptions = () =>
        this.props.options.map((option) => {
            const value = option[this.props.valueFieldName];
            const isDisabled = this.isDisabledValue(value);
            return (
                <option key={value} className="spot-form__select-option" value={value} disabled={isDisabled}>
                    {option[this.props.displayFieldName]}
                </option>
            );
        });

    createPlainOptions = () =>
        this.props.plainOptions.map((option) => {
            const isDisabled = this.isDisabledValue(option);
            return (
                <option key={option} className="spot-form__select-option" value={option} disabled={isDisabled}>
                    {option}
                </option>
            );
        });

    render() {
        const { value } = this.props;
        return (
            <div className="spot-form__select" style={this.props.style}>
                <select
                    className="spot-form__select-input"
                    disabled={this.props.isDisabled}
                    value={value}
                    // tslint:disable-next-line jsx-no-lambda
                    onChange={(e) => this.props.handler(e.target.value)}
                >
                    {!this.props.isOmitPlaceholder && (
                        <option className="spot-form__select-option" value={this.props.placeholderValue || ''}>
                            {this.props.placeholder || i18n.t('statelessSelect:selectValue', 'Select a value')}
                        </option>
                    )}
                    {this.props.options && this.createOptions()}
                    {this.props.plainOptions && this.createPlainOptions()}
                </select>
                <div className="spot-form__select-inner" />
                <span className="spot-form__select-open-indicator" />
            </div>
        );
    }
}

export default StatelessSelect;
