import styles from './practiceAccessGrid.module.scss';
import { useMemo } from 'react';
import { ScreenSizeType, useScreenSize } from '../../../hooks';
import { PracticeAccessType } from '@enterprise/common';

const headerSize = new Map<ScreenSizeType, string>([
    [ScreenSizeType.xs, styles.headerXs],
    [ScreenSizeType.sm, styles.headerSm],
    [ScreenSizeType.md, styles.headerMd],
    [ScreenSizeType.lg, styles.headerLg],
    [ScreenSizeType.xl, styles.headerXl],
]);

const rowPracticeSize = new Map<ScreenSizeType, string>([
    [ScreenSizeType.xs, styles.rowXs],
    [ScreenSizeType.sm, styles.rowSm],
    [ScreenSizeType.md, styles.rowMd],
    [ScreenSizeType.lg, styles.rowLg],
    [ScreenSizeType.xl, styles.rowXl],
]);

const rowRegionSize = new Map<ScreenSizeType, string>([
    [ScreenSizeType.xs, styles.rowXl],
    [ScreenSizeType.sm, styles.rowXl],
    [ScreenSizeType.md, styles.rowXl],
    [ScreenSizeType.lg, styles.rowXl],
    [ScreenSizeType.xl, styles.rowXl],
]);

export interface UsersGridSize {
    headerHeight: number;
    rowHeight: number;
    isMobileView: boolean;
}

const rowSize = new Map<PracticeAccessType, Map<ScreenSizeType, string>>([
    [PracticeAccessType.Practice, rowPracticeSize],
    [PracticeAccessType.Region, rowRegionSize],
]);

export const usePracticeAccessGridSize = (
    type: PracticeAccessType.Region | PracticeAccessType.Practice = PracticeAccessType.Practice,
): UsersGridSize => {
    const { screenSize, isUpTo } = useScreenSize();
    return useMemo<UsersGridSize>(() => {
        return {
            headerHeight: Number(headerSize.get(screenSize)),
            rowHeight: Number(rowSize.get(type)?.get(screenSize)),
            isMobileView: isUpTo(ScreenSizeType.xl),
        };
    }, [screenSize, type]);
};
