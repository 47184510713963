import { ColumnEvent } from './ColumnEvent';
import { CardModel } from '../../models';

export class CardEvent extends ColumnEvent {
    item: CardModel;
    parentId?: string | null;
    targetId: string | null;
    targetParentId?: string | null;

    constructor(data: CardEvent) {
        super(data);
        const { item, parentId, targetId, targetParentId } = data;
        this.item = item;
        this.parentId = parentId;
        this.targetId = targetId;
        this.targetParentId = targetParentId;
    }
}
