import local from './local.json';
import dev from './dev.json';
import dev2 from './dev2.json';
import qa from './qa.json';
import qa2 from './qa2.json';
import stage from './staging.json';
import prod from './prod.json';
import { ICache } from '@auth0/auth0-react';

type ConfigInfo = {
    name: string;
    prefixes: string[];
    config: typeof prod & { AUTH0: { CACHE?: ICache } };
};

const envMap: ConfigInfo[] = [
    {
        name: 'dev',
        prefixes: ['dev'],
        config: dev,
    },
    {
        name: 'dev2',
        prefixes: ['dev2'],
        config: dev2,
    },
    {
        name: 'qa',
        prefixes: ['qa'],
        config: qa,
    },
    {
        name: 'qa2',
        prefixes: ['qa2'],
        config: qa2,
    },
    {
        name: 'staging',
        prefixes: ['staging'],
        config: stage,
    },
    {
        name: 'prod',
        prefixes: ['www', 'enterprisemanager'],
        config: prod,
    },
];

const getConfig = () => {
    let matchKey;
    // @ts-ignore
    const managerObject = window.ENTERPRISE_MANAGER;
    if (managerObject && managerObject.env) {
        matchKey = managerObject.env;
    } else {
        matchKey = window.location.hostname.split('.')[0];
    }
    const config = envMap.find((sub) => {
        return sub.prefixes.some((prefix) => prefix === matchKey);
    }) || {
        name: 'local',
        prefixes: ['localhost'],
        config: local,
    };

    if (window['Cypress']) {
        config.config.AUTH0 = window['Cypress'].config('auth0');
    }

    return config;
};

export const envConfig = getConfig() as ConfigInfo;
