import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { TaskService, TasksQuery } from '../../../services/TaskService';
import { Notifications } from '../../Notifications';
import { ParentTask, TaskEntity, TasksPaginatedResponse } from '../../../core/entity/TaskEntity';
import { OrganizationService } from '../../../services/OrganizationService';
import { NotificationService } from '../../../services/NotificationService';
import { CommandIntervalWorker } from '../../../services/workers/CommandIntervalWorker';
import { TaskCommand } from '../../../services/workers/TaskCommand';
import { TaskType } from '../../../core/enums/TaskType';
import { findIndex, isEqual } from 'lodash';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { InvoiceItemService } from '../../../services/InvoiceItemService';
import AppStore from '../../../store/AppStore';

export interface TasksItemsState {
    grid: {
        pageSize?: number;
    };
}

export interface TasksGridState {
    pageSize?: number;
    page?: number;
}

@Service()
export class ActivityPageStore {
    isLoaded: boolean = false;
    isTaskLoading: boolean = false;

    private _parentTasks?: TasksPaginatedResponse;
    private _task?: ParentTask;

    private _isSyncPending: boolean = false;
    private _page?: number;

    applications: any[] = [];

    get parentTasks(): TasksPaginatedResponse | undefined {
        return toJS(this._parentTasks);
    }

    get task(): ParentTask | undefined {
        return toJS(this._task);
    }

    get isSyncPending(): boolean {
        return toJS(this._isSyncPending);
    }

    get gridState(): TasksGridState | undefined {
        return {
            ...toJS(this.appStore.userState?.tasks?.grid),
            page: toJS(this._page),
        };
    }

    constructor(
        private readonly service: TaskService,
        private readonly orgService: OrganizationService,
        private readonly notificationService: NotificationService,
        private readonly itemService: InvoiceItemService,
        private appStore: AppStore,
    ) {
        makeAutoObservable(this);
    }

    updateGridState = (state: TasksGridState) => {
        this._page = state.page;

        const currentState = toJS(this.appStore.userState?.tasks);
        if (isEqual(currentState?.grid, state)) {
            return;
        }

        const newGridState = { tasks: { ...currentState, grid: state } };
        this.appStore.updateUserState(newGridState);
    };

    async load() {
        await this.fetchOrgApplications();
        this.isLoaded = true;
    }

    async fetchParentTasks(query: TasksQuery): Promise<void> {
        this.isTaskLoading = true;
        try {
            this._parentTasks = await this.service.getParentTasks(query);
            this._isSyncPending = this._parentTasks.tasks.some(
                (task) =>
                    (/Syncing \w+/gi.test(task.name) || task.name === 'Site Sync') && [TaskStatus.PENDING, TaskStatus.RUNNING].includes(task.status),
            );

            void this.getNotifications(query);
        } finally {
            this.isTaskLoading = false;
        }
    }

    async syncChangedProducts(): Promise<void> {
        this._isSyncPending = true;
        await this.itemService.updateChangedProducts();
    }

    async getNotifications(query: TasksQuery): Promise<void> {
        if (this.notificationService.hasWorker('tasks')) {
            this.notificationService.removeAllListeners('tasks_tick');
            this.notificationService.stopWorker('tasks');
        }

        this.notificationService.runWorker('tasks', new CommandIntervalWorker(120000, new TaskCommand({ query })));
        this.notificationService.on('tasks_tick', (updates: ParentTask[]) => {
            this._parentTasks = this.parentTasks && {
                ...this.parentTasks,
                tasks: this.parentTasks.tasks.map((item) => {
                    return updates.find(({ id }) => id === item.id) || item;
                }),
            };
        });
    }

    async fetchTask(id: string, page: number | undefined = undefined, limit: number | undefined = undefined, taskType?: TaskType): Promise<void> {
        this._task = undefined;
        const parentTask = this.parentTasks?.tasks.find((item) => String(item.id) === id && (!taskType || item.type === taskType));
        this._task = await this.service.getChildrenTasks({ parentTask, page, pageSize: limit });
    }

    async fetchChildrenTasks(parentTask: TaskEntity, page, pageSize): Promise<void> {
        this._task = undefined;

        this._task = await this.service.getChildrenTasks({ parentTask, page, pageSize });
    }

    clearTask(): void {
        this._task = undefined;
    }

    async runAllFailedChildTasks(task: TaskEntity): Promise<void> {
        const taskIds = await this.service.runFailedTasks(task.id);

        await this.updateTasks(taskIds);
        Notifications.success(`Scheduled ${taskIds.length} task(s) for execution`);
    }

    async runTasks(ids: number[]): Promise<void> {
        await this.service.runTasks(ids);
        await this.updateTasks(ids);
        Notifications.success(`Scheduled ${ids.length} task(s) for execution`);
    }

    private async updateTasks(ids: number[]): Promise<void> {
        const tasks = await this.service.getParentTasks();
        const updatedTasks = this.parentTasks?.tasks && [...this.parentTasks?.tasks];

        tasks.tasks.forEach((item) => {
            const index = findIndex(updatedTasks, ({ id }) => id === item.id);
            updatedTasks?.splice(index, 1, item);
        });

        this._parentTasks = this.parentTasks && updatedTasks && { ...this.parentTasks, tasks: updatedTasks };
    }

    async cancelTasks(ids: number[]): Promise<void> {
        try {
            await this.service.cancelTasks(ids);
            await this.updateTasks(ids);
        } catch (error) {
            Notifications.error(error.message);
            await this.updateTasks(ids);
        }
    }

    async fetchOrgApplications(): Promise<any> {
        this.applications = await this.orgService.getApplications();
    }

    clearTasks() {
        this._parentTasks = undefined;
        this._task = undefined;
        this._page = undefined;
        this.notificationService.removeAllListeners('tasks_tick');
        if (this.notificationService.hasWorker('tasks')) {
            this.notificationService.stopWorker('tasks');
        }
    }

    dispose() {
        this.clearTasks();
    }
}
