import * as React from 'react';
import { StoresContext } from '../../contexts';
import AppStore from '../../store/AppStore';
import { observer } from 'mobx-react';
import { MyOrganizationPageStore } from '../../pages/practiceConnections/MyOrganizationPageStore';
import { RouterPaths } from '../../router/RouterPaths';
import { Navigate } from 'react-router-dom';

interface FeatureToggleProps {
    name: string;
    strict?: boolean;
    children?: React.ReactChild;
    fallback?: any;
}

@observer
export class FeatureToggle extends React.Component<FeatureToggleProps> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    public get appStore(): AppStore {
        return this.context.ui.app;
    }

    public get practicesStore(): MyOrganizationPageStore {
        return this.context.domain.practicesStore;
    }

    private get isFeatureAvailable(): boolean {
        const { name, strict } = this.props;

        return this.appStore.featureManager.isAvailable(name, strict);
    }

    render() {
        const { name, strict, children, fallback, ...rest } = this.props;

        if (!this.context || !this.practicesStore.isLoaded) {
            return children;
        }

        if (!this.isFeatureAvailable) {
            return <Navigate to={RouterPaths.MyOrganizationPages.PracticeConnections} replace />;
        }

        if (this.isFeatureAvailable) {
            return children;
        }

        if (!fallback) {
            return null;
        }
        return React.isValidElement(fallback) ? React.cloneElement(fallback, rest) : React.createElement(fallback as any, rest);
    }
}
