import React, { ReactNode } from 'react';
import styles from './advertisementCard.module.scss';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { SpotSvg } from '@enterprise/spot';

interface Props {
    bodyContent: ReactNode;
    headerText: string;
    imgSrc: string;
    isDismissible?: boolean;
    linkElements: JSX.Element;
    onClose?: () => void;
}

export const AdvertisementCard: React.FC<Props> = ({ bodyContent, headerText, imgSrc, isDismissible, linkElements, onClose }: Props) => {
    const { t } = useTranslation('common', { i18n });

    const onCloseHandler = () => {
        if (onClose) {
            onClose();
        }
    };

    const getCloseButton = (): JSX.Element => {
        return (
            <div className={styles.closeIconContainer} id={'gtm-advertisement-close-container'} onClick={onCloseHandler}>
                <SpotSvg className={styles.closeIcon} id={'gtm-advertisement-close-icon'} icon={'close'} />
            </div>
        );
    };

    return (
        <div className={styles.card}>
            {isDismissible && getCloseButton()}
            <div className={styles.textAndImage}>
                <div className={styles.text}>
                    <div className={styles.header}>{headerText}</div>
                    <div className="spot-typography__text--body">{bodyContent}</div>
                </div>
                <div className={styles.image}>
                    <img
                        style={{ width: '100%' }}
                        alt={t('advertisements.advertisementImgAltText', { defaultValue: 'Advertisement Image' })}
                        src={imgSrc}
                    />
                </div>
            </div>
            <div className={styles.links}>{linkElements}</div>
        </div>
    );
};
