import React, { ChangeEventHandler, memo, MouseEventHandler, PropsWithChildren, useEffect, useRef } from 'react';
import { SpotInputProps, useSpot } from './common';
import { SpotSvg } from '../SpotSvg';
import { SpotFieldError } from './SpotFieldError';

export interface SpotCheckboxProps extends SpotInputProps {
    isSmall?: boolean;
    onClick?: MouseEventHandler<HTMLInputElement>;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    indeterminate?: boolean;
}

export const SpotCheckbox = memo(function SpotCheckbox(props: PropsWithChildren<SpotCheckboxProps>) {
    const { className, isSmall, name, error, children, id, indeterminate, ['data-automation-id']: automationId, ...rest } = props;
    const { idProp } = useSpot(props);
    const checkRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (checkRef.current) {
            checkRef.current.indeterminate = Boolean(indeterminate);
        }
    }, [indeterminate, checkRef.current]);

    return (
        <span onClick={(event) => event.stopPropagation()}>
            <label className={['spot-form__checkbox', isSmall ? 'spot-form__checkbox--small' : '', className].join(' ')} htmlFor={idProp}>
                <input
                    id={idProp}
                    name={name}
                    ref={checkRef}
                    {...rest}
                    type="checkbox"
                    className="spot-form__checkbox-input"
                    data-automation-id={automationId}
                />
                <span className="spot-form__checkbox-inner">
                    <span className="spot-form__checkbox-visual">
                        <SpotSvg className="spot-form__checkbox-checked-icon" icon="checkmark" />
                        <SpotSvg className="spot-form__checkbox-indeterminate-icon" icon="checkbox-indeterminate" />
                    </span>
                    <span className="spot-form__checkbox-label"> {children} </span>
                </span>
            </label>
            {error && <SpotFieldError meta={error} />}
        </span>
    );
    // }
});
