import * as React from 'react';
import { SpotSvg } from '@enterprise/spot';

interface InfoBarProps {
    title: string;
    icon?: string;
    isError?: boolean;
    onIconClick?: () => void;
}

export class InfoBar extends React.PureComponent<InfoBarProps> {
    render() {
        const { title, icon, isError, onIconClick } = this.props;
        const backgroundColor = !isError ? '#09ABB9' : '#F08579';
        return (
            <div style={{ backgroundColor, height: '40px', color: '#fff', marginRight: '-16px' }}>
                <h2 style={{ color: '#fff', width: '80%', display: 'inline-block', paddingLeft: '15px' }}>{title}</h2>
                {icon && (
                    <div style={{ textAlign: 'right', width: '20%', display: 'inline-block' }}>
                        <a onClick={onIconClick}>
                            <SpotSvg icon={icon} width="12px" height="12px" style={{ fill: '#fff' }} />
                        </a>
                    </div>
                )}
            </div>
        );
    }
}
