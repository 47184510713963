import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import i18n from '../../i18n';
import { useStores } from '../../hooks';
import { useAuth0 } from '@auth0/auth0-react';
import { RouterPaths } from '../../router/RouterPaths';

export const Logout = observer(function LogoutComponent() {
    const rootStore = useStores();
    const { isLoading } = useAuth0();
    const redirect = async () => {
        await rootStore.bootstrap();
        rootStore.ui.router.push(RouterPaths.BasePages.Root);
    };

    useEffect(() => {
        rootStore.clean();

        if (isLoading) {
            return;
        }

        redirect();
    }, [isLoading]);

    return <div>{i18n.t('common:loading', 'Loading...')}</div>;
});
