import * as React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { RightSidebar } from '../../../layout/main/rightSidebar';
import { SpotSvg } from '@enterprise/spot';
import { TaskStatus } from '../../../core/enums/TaskStatus';
import { NavLink, useParams } from 'react-router-dom';
import { TaskEntity } from '../../../core/entity/TaskEntity';
import i18n from 'i18next';
import classNames from 'classnames';
import styles from './ActivityPage.module.scss';
import { useEffect, useState } from 'react';
import { useStores } from '../../../hooks';
import { TablePagination } from '@mui/material';

export const BulkUpdateDetailsPage = observer(function BulkUpdateDetailsPage() {
    const {
        domain: { activityPageStore },
        ui: { router },
    } = useStores();
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [page, setPage] = useState(0);
    const { task } = activityPageStore;
    const { id } = useParams<{ id: string }>();
    const [expandedUpdates, setExpandedUpdates] = useState(new Set<number>());

    useEffect(() => {
        if (!id) {
            return;
        }

        void activityPageStore.fetchTask(id, page, rowsPerPage);

        return () => {
            activityPageStore.clearTask();
        };
    }, [id, page, rowsPerPage]);

    const back = () => {
        router.push('/control-center/activity');
    };

    const runAllFailed = () => {
        if (task) {
            void activityPageStore.runAllFailedChildTasks(task);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createHeader = () => {
        const bulkUpdate = task;

        if (!bulkUpdate) {
            return <></>;
        }

        return (
            <Container className={styles.bulkUpdateDetailsPageHeader}>
                <Row>
                    <Col xs={9} className="spot-typography__text--body" data-automation-id={'activity-item-bulk-details-header'}>
                        <h4 className={styles.title}>{bulkUpdate.name}</h4>
                        <p className={classNames('spot-typography__text--tertiary', styles.itemsCount)}>
                            {(bulkUpdate as TaskEntity).children?.length} items
                        </p>
                    </Col>
                    {(bulkUpdate as TaskEntity).hasChildrenToRetry() && (
                        <Col xs={2} className="spot-typography__text--body">
                            <NavLink to={'#'} className={'spot-link'} onClick={runAllFailed}>
                                Run All Failed
                            </NavLink>
                        </Col>
                    )}
                </Row>
            </Container>
        );
    };

    const createChildDescription = ({ id, status, name, errorMessage }: TaskEntity) => {
        let statusText;
        let className;
        let isError = false;
        if (status === TaskStatus.PENDING) {
            statusText = i18n.t('activityPage:pending', 'Pending');
            className = 'spot-pill--warning';
        } else if (status === TaskStatus.QUEUED) {
            statusText = i18n.t('activityPage:queued', 'Queued');
            className = 'spot-pill--warning';
        } else if (status === TaskStatus.RUNNING) {
            statusText = i18n.t('activityPage:running', 'Running');
            className = 'spot-pill--warning';
        } else if (status === TaskStatus.SUCCESS) {
            statusText = i18n.t('activityPage:success', 'Success');
            className = 'spot-pill--positive';
        } else if (status === TaskStatus.ERROR) {
            statusText = i18n.t('activityPage:error', 'Error');
            className = 'spot-pill--negative';
            isError = true;
        } else if (status === TaskStatus.CANCEL) {
            statusText = i18n.t('activityPage:canceled', 'Canceled');
            className = 'spot-pill--secondary';
        }

        const isChildExpanded = expandedUpdates.has(id);
        const iconName = isChildExpanded ? 'caret-up' : 'caret-down';
        const runChild = () => activityPageStore.runTasks([id]);
        const onToggleClick = () => {
            if (isChildExpanded) {
                expandedUpdates.delete(id);
            } else {
                expandedUpdates.add(id);
            }

            setExpandedUpdates(new Set<number>(expandedUpdates));
        };

        return (
            <>
                <Card>
                    <Card.Body>
                        <Container>
                            <Row data-automation-id={'activity-item-details-text'}>
                                <Col xs={6} className="spot-typography__text--body">
                                    <span data-automation-id={'activity-item-bulk-child-name'}>{name}</span>
                                </Col>
                                <Col xs={3} className="spot-typography__text--body" style={{ textAlign: 'center' }}>
                                    <span
                                        data-automation-id={'activity-item-bulk-child-status'}
                                        className={'spot-pill ' + className + ' status-pill'}
                                    >
                                        {statusText}
                                    </span>
                                </Col>
                                {isError && (
                                    <>
                                        <Col xs={2} className="spot-typography__text--body">
                                            <NavLink to={'#'} className={'spot-link'} onClick={runChild}>
                                                Try Again
                                            </NavLink>
                                        </Col>
                                        <Col xs={1} className="spot-typography__text--body" style={{ textAlign: 'right' }}>
                                            <p>
                                                <SpotSvg onClick={onToggleClick} icon={iconName} className="spot-link__icon" />
                                            </p>
                                        </Col>
                                    </>
                                )}
                            </Row>
                        </Container>
                    </Card.Body>
                </Card>
                {isChildExpanded && (
                    <Card className={styles.taskErrorMessage}>
                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col xs={12} className={classNames('spot-typography__text--body')}>
                                        {Boolean(errorMessage) &&
                                            errorMessage?.map((item, index) => {
                                                return Object.keys(item).map((key) => {
                                                    if (key?.toLowerCase() === 'status' || key?.toLowerCase() === 'provider') {
                                                        return;
                                                    }

                                                    return (
                                                        <div key={`${key}_${index}`}>
                                                            <span className="spot-typography__font-weight--bold">{key}</span>:{' '}
                                                            {JSON.stringify(item[key])}
                                                        </div>
                                                    );
                                                });
                                            })}
                                        {!errorMessage && 'N/A'}
                                    </Col>
                                </Row>
                            </Container>
                        </Card.Body>
                    </Card>
                )}
            </>
        );
    };

    return (
        <RightSidebar
            isLoading={!task}
            close={back}
            closeOnClickOutside={true}
            header={createHeader}
            modalBodyStyle={{ margin: '0px', padding: '0px' }}
        >
            <div className={styles.detailsBody}>
                <div className={styles.detailsContent}>
                    {task?.children?.map((childUpdate, index) => {
                        const key = `${index}_${childUpdate.id}`;
                        return (
                            <div key={key} className="align-items-center justify-content-center">
                                <div>{createChildDescription(childUpdate)}</div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.paginationFooter}>
                    <TablePagination
                        classes={{ root: styles.paginationFooterRoot }}
                        component="div"
                        count={task?.childrenStats?.total || 0}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            </div>
        </RightSidebar>
    );
});
