import React, { memo } from 'react';

import styles from './helpIcon.module.scss';
import { SpotIconType, SpotSvg } from '@enterprise/spot';
import classNames from 'classnames';
import { environment } from '../../environment/environment';

export const HelpIcon = memo(function HelpIcon() {
    return (
        <div className={styles.container}>
            <a href={environment.CONTACT_US_PAGE_URL}>
                <SpotSvg icon={SpotIconType.Help} className={classNames('spot-icon', styles.icon)} />
            </a>
        </div>
    );
});
