import React, { useEffect } from 'react';
import i18n from 'i18next';
import { Col, Row } from 'react-bootstrap';
import { SpotHorizontalRule } from '../../../../../packages/spot/src/components/SpotHorizontalRule';
import styles from './myProfile.module.scss';
import { EnterpriseUserToken, IEnterpriseUser, PracticeAccessType } from '@enterprise/common';
import { observer } from 'mobx-react';
import { FlexPageWrapper } from '../../layout/main/flexPageWrapper';
import { kebabCase } from 'lodash';
import { RouterPaths } from '../../router/RouterPaths';
import classNames from 'classnames';
import { Link, Route } from 'react-router-dom';
import { useStores } from '../../hooks';
import { PermissionLevel } from '../../core/enums/permissionLevel';
import { EditMyProfilePage } from './editMyProfilePage';
import { RootRoutes } from '../../router';

export const MyProfilePage = observer(function MyProfilePage() {
    const {
        ui: { app, router },
        domain: { usersStore, practicesStore },
    } = useStores();

    const { userPracticesAccess, isLoadingUserDetails } = usersStore;
    const { practices = [], labels = [] } = practicesStore;

    useEffect(() => {
        usersStore.fetchUserAccess(user.id);
    }, []);

    function userStatus(key: string, value: string[]) {
        return (
            <div className={styles.profileInfo} data-automaton-id={'profile-information'}>
                <div className={styles.userField} data-automaton-id={`profile-field-${kebabCase(key)}`}>
                    {key.toUpperCase()}
                </div>
                <div className={styles.fieldValue} data-automaton-id={`${kebabCase(key)}-field-value`}>
                    {value.length && value.map((v, key) => <div key={v}>{v}</div>)}
                    {!value.length && i18n.t('myProfile:none', 'None')}
                </div>
            </div>
        );
    }

    function nameAndRole(firstName: string | undefined, role: string | undefined = '') {
        return (
            <div className={styles.nameAndRole} data-automaton-id={'name-and-role'}>
                <h4 className={styles.displayName} data-automaton-id={'display-name'}>
                    {firstName}
                </h4>
            </div>
        );
    }

    function getUserRole(user: IEnterpriseUser): PermissionLevel {
        if (user.organization.owner === user.id) {
            return PermissionLevel.SystemOwner;
        }
        return PermissionLevel.DecisionMaker;
    }

    function getPracticeAccess(): string[] {
        const practicesAccess = userPracticesAccess?.practices;
        const regionsAccess = userPracticesAccess?.regions;

        const accessType =
            (regionsAccess?.length && PracticeAccessType.Region) ||
            (practicesAccess?.length && PracticeAccessType.Practice) ||
            PracticeAccessType.All;
        if (accessType === PracticeAccessType.Region) {
            const regionNames = labels.filter(({ id }) => regionsAccess?.includes(id)).map(({ name }) => name);
            return regionNames;
        }

        if (accessType === PracticeAccessType.Practice) {
            const practiceNames = practices.filter(({ rowId }) => practicesAccess?.includes(String(rowId))).map(({ name }) => name);
            return practiceNames;
        }

        return [i18n.t('myProfile:all', 'All')];
    }

    const user = (app.token as EnterpriseUserToken).user;
    const userName = user.username;
    const displayName = app.token.getDisplayName();
    const userRole = getUserRole(user);
    const title = i18n.t('myProfile:title', 'My Profile');
    const avatar = user?.profile?.avatar;

    useEffect((): void => {
        usersStore.fetchUserDetails(user.id);
    }, [user.id]);

    const renderProfileInfo = () => {
        return (
            <FlexPageWrapper header={title} className={styles.holder} isLoading={isLoadingUserDetails}>
                <Row className={styles.container}>
                    <Col xs="12" xl={{ span: 10, offset: 1 }}>
                        <Row>
                            <Col>
                                <div className={styles.myProfileHeader}>
                                    <h1 className={classNames(styles.myProfileTitle, 'spot-typography__heading--level-3')}>{title}</h1>
                                    <Link
                                        className={classNames(styles.editLink, 'spot-link')}
                                        data-automation-id={'edit-profile-link'}
                                        to={RouterPaths.MyProfilePages.MyProfileEdit}
                                    >
                                        {i18n.t('myProfile:editLink', 'Edit')}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <SpotHorizontalRule />
                                <div className={styles.profilePageContent} data-automaton-id={'profile-page-content'}>
                                    <div className={styles.userIcon} data-automaton-id={'user-icon'}>
                                        {avatar ? (
                                            <img src={avatar} />
                                        ) : (
                                            <svg>
                                                <use href={'/assets/img/icons.svg#users'} />
                                            </svg>
                                        )}
                                    </div>
                                    <div className={styles.profileInformation} data-automaton-id={'profile-information'}>
                                        <div className={styles.userInfo} data-automaton-id={'user-information'}>
                                            {nameAndRole(displayName)}
                                            {userStatus('USER NAME', [userName])}
                                        </div>
                                        <div className={styles.accessInfo} data-automaton-id={'access-information'}>
                                            {userStatus('PRACTICE ACCESS', getPracticeAccess())}
                                            {userStatus('SITE ACCESS', [userRole])}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FlexPageWrapper>
        );
    };

    return (
        <RootRoutes>
            <Route path={RouterPaths.MyProfilePages.MyProfile} element={renderProfileInfo()} />
            <Route
                path={RouterPaths.MyProfilePages.MyProfileEdit}
                element={<EditMyProfilePage back={() => router.push(RouterPaths.MyProfilePages.MyProfile)}></EditMyProfilePage>}
            />
        </RootRoutes>
    );
});
