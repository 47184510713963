import * as React from 'react';
import Dropdown from './Dropdown';
import DropdownItem from './DropdownItem';
import StatelessSelect from './StatelessSelect';
import { SpotSearchbar } from '@enterprise/spot';
import { PresetEntity } from '../../../../services/PresetService';
import { observer } from 'mobx-react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { LookupClassification } from '../../../../core/models/datasource/lookups/parts';
import { SearchBar } from '../../../../components/mui/searchBar';
import { FEATURES } from '@enterprise/core';
import AppStore from '../../../../store/AppStore';
import { StoresContext } from '../../../../contexts';
import ConstantsStore from '../../../../store/ConstantsStore';
import { GetItemQuery, InvoiceItem } from '../../../../core/models';

const NO_FILTER_SELECTED = null;

interface PageProps {
    classifications: LookupClassification[];
    submit: any;
    currentFilter: any;
    updateFilter: any;
    selectedPresetId?: any;
    applyPreset?: any;
    presets?: PresetEntity[];
    viewAllPresets?: any;
    searchItemsForAutoComplete?: (query: GetItemQuery) => Promise<InvoiceItem[]>;
}

interface PageState {
    classId: string | undefined;
    subclassId: string | undefined;
}

@observer
class ItemSearchBar extends React.Component<PageProps, PageState> {
    static defaultProps = {
        classifications: [],
        presets: [],
    };

    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    get appStore(): AppStore {
        return this.context.ui.app;
    }

    get constantsStore(): ConstantsStore {
        return this.context.domain.constantsStore;
    }

    private get enhancedSearchUiEnabled(): boolean {
        return this.appStore.featureManager.isAvailable(FEATURES.ENHANCED_SEARCH_UI, true);
    }

    classesDropRef;

    savedSearchesDropdownRef;

    state = {
        classId: undefined,
        subclassId: undefined,
    };

    showPresets = () => {
        this.savedSearchesDropdownRef.handleToggleClick();
    };

    submit = (value) => {
        this.savedSearchesDropdownRef.close();
        this.props.submit(value);
    };

    render() {
        const { currentFilter, updateFilter } = this.props;
        const updateSearchValue = (searchValue) => updateFilter({ searchValue });

        return (
            <>
                {this.enhancedSearchUiEnabled ? (
                    <SearchBar
                        disabled={this.constantsStore.isLoading}
                        isSelfControlled={false}
                        value={currentFilter.searchValue} //hard coded 'description' or 'id' to filter new SearchBar search results with description or id
                        onChange={updateSearchValue}
                        // onClick={this.showPresets} //keep this prop for future functionality
                        onSubmit={this.submit}
                        searchItemsForAutoComplete={this.props.searchItemsForAutoComplete}
                    />
                ) : (
                    <SpotSearchbar
                        isSelfControlled={false}
                        value={currentFilter.searchValue}
                        onChange={updateSearchValue}
                        onClick={this.showPresets}
                        onSubmit={this.submit}
                    >
                        {this.createSearchTypeDropdown()}
                        {this.createClassesDropdown()}
                    </SpotSearchbar>
                )}
                {this.createSavedSearchesDropdown()}
            </>
        );
    }

    createSearchTypeDropdown() {
        const { currentFilter, updateFilter } = this.props;
        const updateSearchType = (searchType) => updateFilter({ searchType });

        return (
            <Dropdown
                isSearchBarDropdown={true}
                title={i18n.t('itemSearchBar:searchType', 'Search Type')}
                value={currentFilter.searchType}
                style={{ width: '150px' }}
                isSameValueSelectionClearsValue={false}
                onChanged={updateSearchType}
            >
                <DropdownItem label={i18n.t('itemSearchBar:id', 'ID')} value={'id'} key={1}>
                    <span>
                        <Trans i18nKey="itemSearchBar:id">ID</Trans>
                    </span>
                </DropdownItem>
                <DropdownItem label={i18n.t('itemSearchBar:description', 'Description')} value={'description'} key={2}>
                    <span>
                        <Trans i18nKey="itemSearchBar:description">Description</Trans>
                    </span>
                </DropdownItem>
            </Dropdown>
        );
    }

    createClassesDropdown() {
        const { classifications, currentFilter, updateFilter } = this.props;
        const currentClass = classifications.find((classification) => classification.pimsGeneratedId === `${currentFilter.classId}`);
        const currentSubclass = currentClass
            ? currentClass.subClassifications?.find((subclass) => subclass.pimsGeneratedId === `${currentFilter.subclassId}`)
            : undefined;

        if (this.classesDropRef) {
            let label = i18n.t('itemSearchBar:class', 'Class');
            if (currentClass) {
                label = currentSubclass ? `${currentClass.description} / ${currentSubclass.description}` : currentClass.description;
            }
            this.classesDropRef.setLabel(label);
        }

        const setClassId = (classId) => {
            const classification = classifications.find(({ pimsGeneratedId }) => pimsGeneratedId === classId);
            updateFilter({ classId, className: classification?.name, subclassId: null, subclassName: null });
        };

        const setSubclassId = (subclassId) => {
            const subClassification = currentClass?.subClassifications?.find(({ pimsGeneratedId }) => pimsGeneratedId === subclassId);
            updateFilter({ subclassId, subclassName: subClassification?.name });
        };

        return (
            <Dropdown
                isSearchBarDropdown={true}
                title={i18n.t('itemSearchBar:class', 'Class')}
                isSelfControlled={true}
                style={{ width: '230px' }}
                ref={(r) => {
                    this.classesDropRef = r;
                }}
                contentWidth="400px"
                isAlignContentLeft={false}
                data-automation-id={'class-filter-dropdown'}
            >
                <DropdownItem>
                    <div>
                        <StatelessSelect
                            key="classId"
                            handler={setClassId}
                            placeholder={i18n.t('itemSearchBar:selectClass', 'Select a class')}
                            placeholderValue={NO_FILTER_SELECTED}
                            options={classifications}
                            valueFieldName="pimsGeneratedId"
                            displayFieldName="description"
                            value={currentFilter.classId}
                        />
                        {currentFilter.classId && (
                            <StatelessSelect
                                key="subclassId"
                                handler={setSubclassId}
                                placeholder={i18n.t('itemSearchBar:selectSubclass', 'Select a subclass')}
                                placeholderValue={NO_FILTER_SELECTED}
                                options={currentClass?.subClassifications || []}
                                valueFieldName="pimsGeneratedId"
                                displayFieldName="description"
                                value={currentFilter.subclassId}
                            />
                        )}
                    </div>
                </DropdownItem>
            </Dropdown>
        );
    }

    createSavedSearchesDropdown() {
        return (
            <Dropdown
                value={this.props.selectedPresetId}
                style={{ width: '150px' }}
                onChanged={this.props.applyPreset}
                isShowContentOnly={true}
                ref={(r) => {
                    this.savedSearchesDropdownRef = r;
                }}
                contentWidth="300px"
                contentStyle={{ marginTop: '-25px', marginLeft: '25px' }}
            >
                <DropdownItem key="01" isHeading={true}>
                    <div className="spot-typography__font-weight--bold">
                        <Trans i18nKey="itemSearchBar:savedSearches">Saved Searches</Trans>
                        <a href="#" className="spot-link" style={{ marginLeft: '15px' }} onClick={this.props.viewAllPresets}>
                            <Trans i18nKey="itemSearchBar:viewAll">View All</Trans>
                        </a>
                    </div>
                </DropdownItem>
                {Boolean(this.props.presets?.length) && <DropdownItem key={'divider'} isDivider={true} />}
                {this.props.presets?.map((preset) => {
                    return (
                        <DropdownItem label={preset.name} value={preset.id} key={preset.id}>
                            <span>{preset.name}</span>
                        </DropdownItem>
                    );
                })}
            </Dropdown>
        );
    }
}

export default ItemSearchBar;
