import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { Col, Row } from 'react-bootstrap';
import styles from './editUserPage.module.scss';
import { observer } from 'mobx-react';
import { FlexPageWrapper } from '../../../layout/main/flexPageWrapper';
import { SpotVerticalRule } from '../../../../../../packages/spot/src/components/SpotVerticalRule';
import { Form, Field, FormApi, FormType, UserRoles, UserPractices } from '@enterprise/common';
import classNames from 'classnames';
import { SpotHorizontalRule } from '@enterprise/spot';
import { Link, useParams } from 'react-router-dom';
import { SpotButton, SpotTextInput, SpotSvg, SpotFlyoverLoading } from '@enterprise/spot';
import { RouterPaths } from '../../../router/RouterPaths';
import { useStores } from '../../../hooks';
import { SystemMessaging } from '../../systemMessaging';
import { ToastType } from 'react-toastify';
import { SystemToast } from '../../../components/toasts/systemMessageToast';
import { NavigationGuard } from '../../../components/navigationGuards/navigation.guard';
import { PracticeAccessField } from '../practicesAccess';
import { ChooseAvatarDialog } from '../../../components/avatars/chooseAvatarDialog';
import { SiteAccessField } from '../siteAccessModule';
import { PermissionLevel } from '../../../core/enums/permissionLevel';

interface UserData {
    displayName: string;
    userPractices?: UserPractices;
    permissionLevel?: PermissionLevel;
}

type EditUserPageProps = PropsWithChildren<{
    back: () => void;
}>;

export const EditUserPage = observer(function EditUserPage(props: EditUserPageProps) {
    const {
        ui: { app },
        domain: { usersStore },
    } = useStores();

    const { back } = props;
    const { token } = app;
    const { userId } = useParams<{ userId: string }>();
    const [hasError, setError] = useState(false);
    const [hasUnsaved, setUnsaved] = useState(false);
    const { userPracticesAccess, isLoadingUserDetails, user: userDetails, userPermissionLevel, isSavingUser } = usersStore;
    const [updatedAvatar, setAvatar] = useState('');
    let defaultAvatar;

    const updateUserDetails = async (userId: string) => {
        const userDetails = await usersStore.fetchUserDetails(userId);

        if (userDetails) {
            setAvatar(userDetails.profile?.avatar || '');

            defaultAvatar = userDetails.profile?.avatar;
        }
    };

    const loadUser = async (userId: string): Promise<void> => {
        try {
            updateUserDetails(userId);
        } catch (err) {
            console.error('Unable to find detailed user info', err);
        }
    };

    useEffect((): void => {
        if (!userId) {
            return;
        }

        void loadUser(userId);
        void usersStore.fetchUserAccess(userId);
        void usersStore.fetchUserAvatars(userId);
    }, [userId]);

    const formType = useMemo(() => new FormType<UserData>(), []);

    const title = i18n.t('editUser:editPageTitle', 'Edit User');
    const isSuperAdminOrSystemOwner = token.hasRole(UserRoles.SuperAdmin) || token.hasRole(UserRoles.SystemOwner);

    const { userAvatars } = usersStore;
    const avatarsDropdownItems = userAvatars || [];

    if (!userDetails) {
        return null;
    }
    const userName = usersStore.user?.username;
    const defaultDisplayName = usersStore.userDisplayName;
    // const totalPracticeCount = sumBy(practicesStore.practices, 'practices.length');

    let submit;
    const initialValues: UserData = {
        displayName: defaultDisplayName,
        userPractices: userPracticesAccess,
        permissionLevel: userPermissionLevel,
    };

    async function saveUserDetails({ displayName, permissionLevel, userPractices }: UserData, form: FormApi<UserData>) {
        if (isSavingUser) {
            return;
        }

        const { modified } = form.getState();
        if (
            ((permissionLevel !== null || displayName !== defaultDisplayName || defaultAvatar !== updatedAvatar) && !app.userIsSaving) ||
            (modified?.userPractices && userPractices)
        ) {
            try {
                await usersStore.updateUser({ id: userDetails.id, displayName, permissionLevel, avatar: updatedAvatar, userPractices });
                setUnsaved(false);
                SystemMessaging.success({ message: 'User Updates Saved' });
                back();
            } catch (err) {
                setError(true);
                throw err;
            }
        }
    }

    function validateDisplayName(val: string) {
        setUnsaved(Boolean(val !== defaultDisplayName));
        if (val === null || val === undefined || val === '') {
            return i18n.t('editUser:displayNameNotNullMessage', 'Display Name field cannot be null');
        }
        if (val.length < 5 || val.length > 50) {
            return i18n.t('editUser:displayNameCharNumberMessage', 'Display Name must be between 5 and 50 characters');
        }
        return undefined;
    }

    const onAvatarChange = async (avatar) => {
        setAvatar(avatar);
    };

    const editUserFooter = (
        <div className={styles.editUserFooter}>
            <div className={styles.footerLinks}>
                <Link
                    data-automation-id={'edit-user-cancel'}
                    className={classNames('spot-link', styles.footerCancel)}
                    to={`${RouterPaths.MyOrganizationPages.UserManagement}`}
                >
                    {i18n.t('editUser:cancel', 'Cancel')}
                </Link>
            </div>
            <SpotButton
                isPrimary={true}
                data-automation-id={'edit-user-save'}
                className={classNames('spot-button--large', styles.footerSave)}
                onClick={(event) => {
                    submit(event);
                }}
            >
                {i18n.t('editUser:save', 'Save')}
            </SpotButton>
            <div className={styles.footerSpacer} />
        </div>
    );

    // Currently disable the view for non super admin or admin
    // This should be handle in the user table later
    if (!isSuperAdminOrSystemOwner) {
        return null;
    }

    return (
        <>
            {isSavingUser && (
                <div className={styles.loadingOverlay}>
                    <SpotFlyoverLoading text={'Saving...'} size={'large'} />
                </div>
            )}
            <FlexPageWrapper header={title} footer={editUserFooter} back={() => back()} className={styles.holder} isLoading={isLoadingUserDetails}>
                {Boolean(hasError) && (
                    <div className={styles.errorMessage}>
                        <SystemToast
                            message={'Unable to Save User Updates'}
                            subMessage={'Something went wrong, Please try saving again.'}
                            type={ToastType.ERROR}
                        />
                    </div>
                )}
                <Row className={styles.container}>
                    <Form
                        formType={formType}
                        initialValues={initialValues}
                        onSubmit={saveUserDetails}
                        render={({ handleSubmit }) => {
                            submit = handleSubmit;
                            return (
                                <form id="editUserForm" style={{ width: '100%' }}>
                                    <div className={styles.displayNameHeader} data-automaton-id={'edit-user-display-name-header'}>
                                        {defaultDisplayName}
                                    </div>
                                    <Col xs={12} className={styles.editUserPageContent}>
                                        <Col xs={12} md={5} lg={4} className={styles.userInformation}>
                                            <h4 className={styles.columnTitle} data-automaton-id={'edit-user-information-title'}>
                                                {i18n.t('editUser:userInformation', 'User Information')}
                                            </h4>
                                            <div className={styles.avatarSection} data-automaton-id={'edit-user-avatar-section'}>
                                                <div>
                                                    <p className={styles.avatarTitle} data-automaton-id={'avatar-title'}>
                                                        {i18n.t('myProfile:avatar', 'Avatar')}
                                                    </p>
                                                    <p className={styles.avatarSubTitle} data-automaton-id={'avatar-subtitle'}>
                                                        {i18n.t('myProfile:avatarSubtitle', 'Visible to others in your organization')}
                                                    </p>
                                                    {userAvatars && <ChooseAvatarDialog items={avatarsDropdownItems} onChange={onAvatarChange} />}
                                                </div>

                                                <div className={styles.profileImage} data-automaton-id={'profile-image'}>
                                                    {updatedAvatar ? (
                                                        <img src={updatedAvatar} />
                                                    ) : (
                                                        <svg>
                                                            <use href={'/assets/img/icons.svg#users'} />
                                                        </svg>
                                                    )}
                                                </div>
                                            </div>
                                            <div className={styles.displayNameSection}>
                                                <p className={styles.displayNameTitle} data-automaton-id={'edit-user-display-name-title'}>
                                                    * {i18n.t('editUser:displayName', 'Display Name')}
                                                </p>
                                                <div className={styles.displayNameInput}>
                                                    <Field
                                                        className="spot-form__input"
                                                        type="text"
                                                        name="displayName"
                                                        data-automaton-id={'edit-user-display-name-input'}
                                                        defaultValue={defaultDisplayName}
                                                        validate={validateDisplayName}
                                                    >
                                                        {({ input, meta }) => (
                                                            <div style={{ width: '100%' }}>
                                                                <SpotTextInput {...input} error={meta} />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className={styles.userNameSection}>
                                                <p className={styles.userNameTitle} data-automaton-id={'edit-user-name-title'}>
                                                    {i18n.t('editUser:userNameTitle', 'USER NAME')}
                                                </p>
                                                <p className={styles.userName} data-automaton-id={'edit-user-user-name'}>
                                                    {userName}
                                                </p>
                                            </div>
                                        </Col>
                                        <div className={styles.verticalLine}>
                                            <SpotVerticalRule />
                                        </div>
                                        <div className={styles.horizontalLine}>
                                            <SpotHorizontalRule />
                                        </div>
                                        <Col xs={12} md={7} lg={8} className={styles.permissions}>
                                            <h4 className={styles.columnTitle} data-automaton-id={'edit-user-permissions-title'}>
                                                {i18n.t('editUser:permissionsTitle', 'Permissions')}
                                            </h4>
                                            <p className={styles.permissionColumnSectionHeader} data-automaton-id={'edit-user-practice-access-title'}>
                                                {i18n.t('editUser:practiceAccessTitle', 'Practice Access')}
                                            </p>
                                            <PracticeAccessField
                                                name="userPractices"
                                                disabled={
                                                    !(token.hasRole(UserRoles.SystemOwner) || token.hasRole(UserRoles.SuperAdmin)) ||
                                                    userId === token.user.id
                                                }
                                            />

                                            <p className={styles.permissionColumnSectionHeader} data-automaton-id={'edit-user-site-access-title'}>
                                                {i18n.t('editUser:siteAccessTitle', 'Site Access')}
                                            </p>
                                            <div className={styles.siteAccessInfo}>
                                                <div className={styles.infoIconBox}>
                                                    <SpotSvg icon="info-2" className={'spot-icon'} fill={'white'} />
                                                </div>
                                                <div className={styles.siteAccessInfoText}>
                                                    {i18n.t(
                                                        'editUser:siteAccessText',
                                                        'Site Access is in context of the practice access. This means that a user given access to a single practice will only see financial data or item management capabilities for that practice.',
                                                    )}
                                                </div>
                                            </div>
                                            <SiteAccessField disabled={false} userId={String(userId)} name="permissionLevel" />
                                        </Col>
                                    </Col>
                                    <NavigationGuard when={hasUnsaved} />
                                </form>
                            );
                        }}
                    />
                </Row>
            </FlexPageWrapper>
        </>
    );
});
