import {
    InvoiceItemClassification,
    InvoiceItemDiscount,
    InvoiceItemInventory,
    InvoiceItemLabProfileRequest,
    InvoiceItemPrices,
    InvoiceItemService,
    InvoiceItemSpecialAction,
    InvoiceItemTax,
    InvoiceItemType,
    InvoiceItemParent,
    InvoiceItemChild,
} from './parts';
import { ProviderDetails } from '../';

export interface Status {
    type: string;
    message: string;
}

export interface Displayable {
    name: string;
    type: string;
}

export class InvoiceItem {
    barcode: string;

    childItems?: InvoiceItemChild[];

    classification: InvoiceItemClassification;

    clientDescription: string;

    discounts: InvoiceItemDiscount[];

    hospitalDescription: string;

    isActive = true;

    itemParent?: InvoiceItemParent;

    itemType: InvoiceItemType;

    labProfileRequest?: InvoiceItemLabProfileRequest;

    pimsGeneratedId: string;

    pimsId: string;

    prices: InvoiceItemPrices;

    provider: ProviderDetails;

    specialActions: InvoiceItemSpecialAction[];

    subClassification: InvoiceItemClassification | null;

    taxes: InvoiceItemTax[];

    typeDetails: InvoiceItemInventory | InvoiceItemService;

    practiceName?: string;

    supplierCode?: string;

    status?: Status;

    displayable?: Displayable;

    animanaSpecificFields?: {
        supplierCode?: string;

        packageFactor?: number;

        packageUnit?: string;

        orderFactor?: number;

        orderUnit?: string;

        vatCode?: string;

        supplierName?: string;

        productAnalysisGroup1Uuid?: string;

        productAnalysisGroup2Uuid?: string;

        productAnalysisGroup3Uuid?: string;

        productAnalysisGroup1Name?: string;

        productAnalysisGroup2Name?: string;

        productAnalysisGroup3Name?: string;

        warning?: string;

        humanDrug?: boolean;

        euthanasia?: boolean;

        memo?: string;

        informationSheetUrl?: string;
    };
}
