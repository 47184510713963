import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { AdvertisementCard } from '../../components/advertisements/advertisementCard';
import { SpotButton } from '@enterprise/spot';
import { AnalyticsAdvertisement } from '../../store/advertisement.store';
import { PricingTiers } from '../../core/enums/pricingTiers';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks';

type Props = PropsWithChildren<{
    name: PricingTiers;
}>;

export const AnalyticsAdvertisementViewer = observer(function AnalyticsAdvertisementViewer({ name }: Props) {
    const { domain } = useStores();
    const { advertisementStore } = domain;

    const [advertisement, setAdvertisement] = useState<AnalyticsAdvertisement | null>();

    const loadAdvertisement = async (): Promise<void> => {
        try {
            const randomAdvertisement = await advertisementStore.getRandomAdForPricingTier(name);
            if (randomAdvertisement) {
                setAdvertisement(randomAdvertisement);
            }
        } catch (err) {
            console.error('Unable to load analytics advertisements', err);
        }
    };

    const handleAdvertisementClosed = (): void => {
        setAdvertisement(null);
        advertisementStore.dismissDisplayableAds();
    };

    useEffect((): void => {
        loadAdvertisement();
    }, [name]);

    if (!advertisement) {
        return null;
    }

    const createLinkElements = (): JSX.Element => {
        return (
            <>
                <a href={advertisement.learnMoreLink} className={'spot-link advertisement-link'} rel={'noopener noreferrer'} target={'_blank'}>
                    <Trans i18nKey="common:analyticsAdvertisement.learnMore">Learn More</Trans>
                </a>

                <SpotButton
                    isPrimary
                    onClick={(): void => {
                        window.open(advertisement.upgradeLink, '_blank');
                    }}
                >
                    <Trans i18nKey="common:analyticsAdvertisement.upgrade">Upgrade</Trans>
                </SpotButton>
            </>
        );
    };

    const advertisementBody = documentToReactComponents(advertisement.advertisementBody);
    const advertisementLinks = createLinkElements();

    return (
        <div className={'analyticsAdvertisement'} id={advertisement.pricingTier}>
            <AdvertisementCard
                bodyContent={advertisementBody}
                headerText={advertisement.header}
                imgSrc={advertisement.advertisementImage.fields.file.url}
                isDismissible={true}
                linkElements={advertisementLinks}
                onClose={handleAdvertisementClosed}
            />
        </div>
    );
});
