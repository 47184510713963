interface HubspotCredentials {
    email?: string;
    firstName?: string;
    lastName?: string;
    id?: string;
    pricingTier?: string;
}

// Window appears unused, but leverages TS's interface declaration merging to define variables set in global scripts
declare global {
    interface Window {
        _hsq: {
            push: (arg: (string | HubspotCredentials)[]) => void;
        };
        HubSpotConversations: {
            widget: {
                refresh: () => void;
            };
        };
    }
}

const hubspotIdentityStorageKey = 'hubspotUserMetadata';

// Attempt to update the Hubspot widget with user metadata but allow it to fail silently if the global script is missing
// Session Storage is used so the widget can pick the metadata up later if the GTM script is not loaded yet
export const updateHubspotIdentity = (metadata: HubspotCredentials): void => {
    sessionStorage.setItem(hubspotIdentityStorageKey, JSON.stringify(metadata));
    if (window._hsq) {
        try {
            window._hsq.push(['identify', metadata]);
            window._hsq.push(['trackPageView']);
            setTimeout(() => {
                window.HubSpotConversations.widget.refresh();
            }, 1000);
        } catch (err) {}
    }
};
