import React, { useState } from 'react';
import i18n from '../../../i18n';
import styles from './practiceLabels.module.scss';
import { SpotDropdown, SpotDropdownElement } from '@enterprise/spot';
import CreateRegionDialog from '../../controlCenter/CreateRegionDialog';
import { observer } from 'mobx-react';
import { useStores } from '../../../hooks';
import { LabelType } from '../../../core/enums/labelTypes';

const NEW_REGION_ID = 'ApplicationsPage.NEW_REGION_ID';

export const PracticeLabels = observer(function PracticeLabels() {
    const { domain } = useStores();
    const { practicesStore } = domain;
    const { labels = [], selectedPractices } = practicesStore;
    const [showCreateLabelModal, setShowCreateLabelModal] = useState(false);

    const options: SpotDropdownElement[] = labels.map(({ id, name }) => ({
        id: String(id),
        value: name,
    }));

    if (options.length) {
        options.push({ id: 'divider', isDivider: true });
    }

    options.push({
        id: NEW_REGION_ID,
        value: i18n.t('myOrganization:createRegion', 'Create a Region'),
        isDivider: false,
        className: 'spot-dropdown__content-item--single-line',
    });

    const assignLabel = async ({ labelId, name, labelType }: { labelId?: string; name?: string; labelType: LabelType }): Promise<void> => {
        const selectedPracticeIds = selectedPractices.map(({ rowId }) => rowId.toString());
        const labelName = name || 'undefined name';
        if (labelId) {
            await practicesStore.assignLabel(labelId, selectedPracticeIds);
        } else {
            await practicesStore.createAndAssignLabel(labelType, labelName, selectedPracticeIds);
        }
    };

    const onChange = (labelId: string, labelType = LabelType.Regions): void => {
        if (NEW_REGION_ID === labelId) {
            setShowCreateLabelModal(true);
        } else {
            void assignLabel({ labelId, labelType });
        }
    };

    const createAndAssignLabel = async (name: string, labelType = LabelType.Regions): Promise<void> => {
        await assignLabel({ labelType, name });
        setShowCreateLabelModal(false);
    };

    return (
        <>
            <div className={styles.holder}>
                <div className={styles.assignRegion}>
                    <SpotDropdown
                        title={i18n.t('myOrganization:assignRegion', 'ASSIGN A REGION')}
                        titleClassName={styles.title}
                        className={styles.dropdown}
                        elements={options}
                        onChange={onChange}
                        data-automation-id="practice-labels-assign-region"
                    />
                </div>

                {showCreateLabelModal && (
                    <CreateRegionDialog isVisible={true} onSubmit={createAndAssignLabel} onClose={() => setShowCreateLabelModal(false)} />
                )}
            </div>
        </>
    );
});
