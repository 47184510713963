import styles from './demoDashboard.module.scss';
import * as React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { SpotLoading, SpotSvg } from '@enterprise/spot';
import classNames from 'classnames';
import { EmbedLink } from '../../../core/models';
import { observer } from 'mobx-react';
import { DashboardState } from './dashboardsPage';
import { Image } from '../../../components/helpers/Image';
import { Trans } from 'react-i18next';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import { RouterPaths } from '../../../router/RouterPaths';
import { environment } from '../../../environment/environment';
import { useStores } from '../../../hooks';
import { useEffect, useMemo, useState } from 'react';

interface ExtendedEmbedLink extends EmbedLink {
    isLoaded: boolean;
    isDashboard: boolean;
    height: number;
}

const { ENROLLMENT_URL: PRACTICE_ENROLLMENT_URL } = environment.PRACTICE_CONNECT;

export const DemoDashboardPage = observer(function DemoDashboardPage() {
    const [embedLinks, setEmbedLinks] = useState<Record<string, ExtendedEmbedLink>>({});

    const {
        domain: { analyticsPageStore },
    } = useStores();

    const { embedLinks: links, enrolments, isEnrolmentsLoading, isEmbedLinksLoading } = analyticsPageStore;

    useEffect(() => {
        void analyticsPageStore.fetchEmbedLinks();
        void analyticsPageStore.fetchEnrollments();
    }, []);

    useEffect(() => {
        if (!links) {
            return;
        }

        const embedLinks = links.reduce((acc, item) => ({ ...acc, [item.name]: item }), {});
        setEmbedLinks(embedLinks);
    }, [links]);

    const enrollmentStatus = useMemo(() => {
        return Boolean(enrolments?.length);
    }, [enrolments]);

    const loaded = (name: string) => () => {
        const embedLink = embedLinks[name];
        setEmbedLinks({ ...embedLinks, [name]: { ...embedLink, isLoaded: true } });
    };

    const isMessageFromLookerIFrame = (event) => {
        const { source = '', origin = '' } = event;
        const hosts = Object.values(embedLinks).map((link) => link.host);
        const lookerFrame = document.getElementById('lookerFrame0') as HTMLIFrameElement;
        return source === lookerFrame?.contentWindow && hosts.some((host) => origin.includes(host));
    };

    const handleLookerMessage = (event): void => {
        if (!isMessageFromLookerIFrame(event)) {
            return;
        }

        try {
            const { type, dashboard } = JSON.parse(event.data);

            if (!dashboard) {
                return;
            }

            const embedLink = embedLinks[dashboard.title];
            if (type === DashboardState.PagePropertiesChanged) {
                setEmbedLinks({
                    ...embedLinks,
                    [dashboard.title]: { ...embedLink, isDashboard: true, height: event.currentTarget.innerHeight },
                });
            }
            if (type === DashboardState.LookComplete || type === DashboardState.ExploreReady) {
                setEmbedLinks({ ...embedLinks, [dashboard.title]: { ...embedLink, isDashboard: false } });
            }
        } catch {
            return;
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleLookerMessage);

        return () => {
            window.removeEventListener('message', handleLookerMessage);
        };
    }, []);

    const renderConnectionBanner = () => {
        return (
            <div
                className={classNames('spot-message', 'priority-high--warning', styles.industryBenchmarksBanner, {
                    [styles.priorityHighTeal]: !enrollmentStatus,
                })}
            >
                <div className="message--content">
                    <div className="message--body">
                        <h4 className={styles.bannerHeader}>
                            {enrollmentStatus
                                ? i18n.t('common:dashboard.practiceConnectionIsPending', 'Your practice connection is pending.')
                                : i18n.t(
                                      'common:dashboard.practiceConnectionHasNotStarted',
                                      'Connect a practice to compare against national benchmarks.',
                                  )}
                        </h4>
                        {enrollmentStatus ? (
                            <Link className={styles.connectionDetails} to={RouterPaths.MyOrganizationPages.PracticeConnections}>
                                <Trans i18nKey="common:dashboard.connectionDetails">Connection Details</Trans>
                            </Link>
                        ) : (
                            <a
                                className={classNames(styles.connectPractice, 'spot-button', 'spot-button--secondary', 'spot-button--small')}
                                href={PRACTICE_ENROLLMENT_URL}
                            >
                                <Trans i18nKey="common:dashboard.connectPractice">Connect Practice</Trans>
                            </a>
                        )}
                    </div>
                </div>
                <div className={classNames('icon-tab', { [styles.tealIcon]: !enrollmentStatus })}>
                    <SpotSvg icon={!enrollmentStatus ? 'add' : 'in-progress'} className="spot-icon" />
                </div>
            </div>
        );
    };

    const isLoaded = Object.values(embedLinks).every((link) => link.isLoaded);

    const iFrames = Object.values(embedLinks).map((link, key) => (
        <div className={styles.demoAnalyticsWrapper} key={key}>
            <iframe
                id={`lookerFrame${key}`}
                src={link.url}
                height={link.height}
                data-automation-id="looker-frame"
                className={classNames('looker-frame', {
                    'look-iframe': !link.isDashboard,
                    'looker-frame-hidden': !isLoaded,
                })}
                onLoad={loaded(link.name)}
            />
        </div>
    ));

    return (
        <div className={styles.industryBenchmarks} data-automation-id="demo-dashboard-page">
            {!isEnrolmentsLoading && renderConnectionBanner()}
            <Container fluid>
                <Row className={styles.industryBenchmarks} sm={1} md={1}>
                    <Col lg>
                        <h2 className={styles.lookerTitle}>
                            <Trans i18nKey="common:dashboard.demoTitle">How does my practice compare to similar practices?</Trans>
                        </h2>
                    </Col>
                    <Col lg>
                        <Image className={styles.buckets} url="img/buckets.png" />
                        <h3>
                            <Trans i18nKey="common:dashboard.demoBucketsTitle">Practice size is determined using clinical visit count.</Trans>
                        </h3>

                        <p>
                            <Trans i18nKey="common:dashboard.demoBucketsDesc">
                                We group practices into size buckets so that your practice is compared against similarly sized practices.
                            </Trans>
                        </p>
                    </Col>
                    <Col lg>
                        <Image className={styles.compare} url="img/compare.png" />
                        <h3>
                            <Trans i18nKey="common:dashboard.demoComparisonTitle">Compare your practice to national benchmarks</Trans>
                        </h3>

                        <p>
                            <Trans i18nKey="common:dashboard.demoComparisonDesc">as soon as your pending connection is completed.</Trans>
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12">
                        {(!isLoaded || isEmbedLinksLoading) && (
                            <div className={'looker-spinner'}>
                                <SpotLoading text="Loading..." />
                            </div>
                        )}
                        {iFrames}
                    </Col>
                </Row>
            </Container>
        </div>
    );
});
