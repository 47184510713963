import { Service } from 'typedi';
import { io, Socket } from 'socket.io-client';
import { envConfig } from '../environment/config';
import { BaseUserToken, EnterpriseUserToken, EnterpriseUserTokenParams, IEnterpriseUser } from '@enterprise/common';
import AppStore from '../store/AppStore';
import { reaction } from 'mobx';

const UNAUTHORIZED = 401;

enum WebsocketStateEvent {
    Connect = 'connect',
    Error = 'error',
    Disconnect = 'disconnect',
    Close = 'close',
}

enum EnterpriseWebsocketEvent {
    UpdateSession = 'updateSession',
}

@Service()
export class WebsocketManagerStore {
    private socket?: Socket;

    private get token(): BaseUserToken<IEnterpriseUser> | undefined {
        return this.appStore.token;
    }

    constructor(private readonly appStore: AppStore) {
        reaction(
            () => {
                return this.appStore.token;
            },
            () => {
                this.authenticate();
            },
        );
    }

    openSocketConnection(): void {
        if (this.socket) {
            return;
        }

        this.socket = io(`${envConfig.config.ENTERPRISE_API_BASEURL}`, {
            path: '/websockets',
            transports: ['websocket'],
        });

        this.socket.on(EnterpriseWebsocketEvent.UpdateSession, this.refreshToken);
        this.socket.on(WebsocketStateEvent.Connect, this.authenticate);
        this.socket.on(WebsocketStateEvent.Error, this.errorHandler);
        this.socket.on(WebsocketStateEvent.Disconnect, this.disconnectHandler);
        this.socket.on(WebsocketStateEvent.Close, this.disconnectHandler);
    }

    disconnect(): void {
        this.socket?.off(WebsocketStateEvent.Connect);
        this.socket?.off(WebsocketStateEvent.Error);
        this.socket?.off(WebsocketStateEvent.Disconnect);
        this.socket?.off(WebsocketStateEvent.Close);
        this.socket?.off(EnterpriseWebsocketEvent.UpdateSession);
        this.socket?.disconnect();
        this.socket = undefined;
    }

    private authenticate = (): void => {
        // Since auth0 is not imediately loaded, we need to wait here till it is.
        if (!this.appStore.isLoaded) {
            setTimeout(() => {
                this.authenticate();
            }, 500);
            return;
        }
        if (!this.appStore.isAuthenticated) {
            this.socket?.emit('logout');
            return;
        }
        this.socket?.emit('authenticate', this.token);
        console.log('auth', this.token);
    };

    private errorHandler = (error): void => {
        console.error('error', error);

        if (error?.error?.status === UNAUTHORIZED) {
            console.log('auth token should be restored/refreshed');
            void this.appStore.restoreToken();
        }
    };

    private disconnectHandler = (reason): void => {
        console.log('disconnect', reason);
        console.error(reason);
    };

    private refreshToken = (token: EnterpriseUserTokenParams): void => {
        if (!this.appStore.isAuthenticated) {
            return;
        }

        const updatedToken = new EnterpriseUserToken({ ...this.token, ...token });
        this.appStore.updateToken(updatedToken);
    };
}
