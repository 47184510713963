import React, { memo, PropsWithChildren, ReactElement, useMemo, useState, useEffect } from 'react';
import {
    GridCellParams,
    GridColDef,
    GridRowId,
    GridSelectionModel,
    GridSortDirection,
    GridSortModel,
    GridValueGetterParams,
    MaterialDataGrid,
} from '@enterprise/material-data-grid';
import { LabelEntity } from 'apps/client/src/core/entity/LabelEntity';
import { PracticeInfo, PracticeStatus } from '../../../../practiceConnections/MyOrganizationPageStore';
import styles from './selectPracticeRegionsGrid.module.scss';
import classNames from 'classnames';
import i18n from 'i18next';
import { PracticeAccessType } from '@enterprise/common';
import { SpotSearchbar } from '@enterprise/spot';
import { Image } from '../../../../../components/helpers/Image';
import { SelectionGridState } from './selectIonGridState';
import { head } from 'lodash';

enum GridColumns {
    PracticeName = 'practiceName',
    RegionName = 'regionName',
}

const getPracticeName = ({ name, type }: PracticeInfo): string => {
    if (!name && type === PracticeStatus.Pending) {
        return i18n.t('myOrganization:defaultPendingPracticeName', 'Pending Practice Connection');
    }

    return String(name);
};

export type PracticeRegionGridProps = PropsWithChildren<{
    practices?: PracticeInfo[];
    regions?: LabelEntity[];
    isLoading?: boolean;
    onSelectionChange: (value: SelectionGridState) => void;
    selectionGridState: SelectionGridState;
}>;

export const SelectPracticesRegionsGrid = memo(function SelectPracticesRegionsGrid(props: PracticeRegionGridProps) {
    const { practices = [], regions = [], isLoading = false, selectionGridState, onSelectionChange } = props;
    const practiceSortModel = useMemo<GridSortModel>(
        () => [
            {
                field: GridColumns.PracticeName,
                sort: 'asc' as GridSortDirection,
            },
        ],
        [PracticeAccessType.Practice],
    );

    const regionSortModel = useMemo<GridSortModel>(
        () => [
            {
                field: GridColumns.RegionName,
                sort: 'asc' as GridSortDirection,
            },
        ],
        [PracticeAccessType.Region],
    );

    const PracticeNameCell = (params: GridCellParams): ReactElement => {
        const practice = params.row as PracticeInfo;
        const name = getPracticeName(practice);

        return (
            <div className={styles.cellContainer} data-automation-id="bulk-update-select-practices-grid-name-cell">
                <div className={styles.cellName}>
                    <div className={classNames('spot-typography__text--body', styles.name)} data-automation-id="select-practices-cell-name">
                        {name}
                    </div>
                </div>
            </div>
        );
    };

    const RegionNameCell = (params: GridCellParams): ReactElement => {
        const { name } = params.row as LabelEntity;
        return (
            <div className={styles.cellContainer} data-automation-id="bulk-update-select-regions-grid-name-cell">
                <div className={styles.cellName}>
                    <div className={classNames('spot-typography__text--body', styles.name)} data-automation-id="select-practices-cell-name">
                        {name}
                    </div>
                </div>
            </div>
        );
    };

    const AdditionalItemsCell = (params: GridCellParams): ReactElement => {
        const { name } = params.row as LabelEntity;
        return (
            <div className={styles.cellContainer} data-automation-id="bulk-update-select-all-practices-grid-name-cell">
                <div className={styles.cellName}>
                    <div className={classNames('spot-typography__text--body', styles.name)} data-automation-id="select-practices-cell-name">
                        {name}
                    </div>
                </div>
            </div>
        );
    };

    const practiceColumns = useMemo<GridColDef[]>(() => {
        return [
            {
                field: GridColumns.PracticeName,
                flex: 1,
                renderCell: PracticeNameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    return getPracticeName(params.row as PracticeInfo);
                },
            },
        ];
    }, []);

    const regionColumns = useMemo<GridColDef[]>(() => {
        return [
            {
                field: GridColumns.RegionName,
                flex: 1,
                renderCell: RegionNameCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const { name } = params.row as LabelEntity;
                    return name;
                },
            },
        ];
    }, []);

    const additionalColumns = useMemo<GridColDef[]>(() => {
        return [
            {
                field: 'name',
                flex: 1,
                renderCell: AdditionalItemsCell,
                valueGetter: (params: GridValueGetterParams) => {
                    const { name } = params.row as LabelEntity;
                    return name;
                },
            },
        ];
    }, []);

    const [practiceRows, setPracticeRows] = useState<PracticeInfo[]>(practices);
    const [regionRows, setRegionRows] = useState<LabelEntity[]>(regions);
    const [showSelectAll, setShowSelectAll] = useState<boolean>(true);
    const { selectAll, searchTerm, selectedGridPractices = [], selectedGridRegions = [] } = selectionGridState;

    useEffect(() => {
        if (!searchTerm) {
            setPracticeRows(practices);
            setRegionRows(regions);
            setShowSelectAll(true);
            return;
        }

        const newPractices = practices.filter(({ name }) => {
            return name.toLowerCase().includes(searchTerm.toLowerCase());
        });

        const newRegions = regions.filter(({ name }) => {
            return name.toLowerCase().includes(searchTerm.toLowerCase());
        });

        setShowSelectAll(false);
        setPracticeRows(newPractices);
        setRegionRows(newRegions);
    }, [searchTerm]);

    useEffect(() => {
        if (selectAll) {
            onSelectionChange({ ...selectionGridState, selectedPractices: practices });
            return;
        }

        const selectedRegionPractices = selectedGridRegions.reduce((agg, { practices: regionPractices }) => {
            const selected = practices.filter(({ rowId }) => regionPractices.find((id) => String(id) === String(rowId)));
            return [...agg, ...selected];
        }, [] as PracticeInfo[]);
        const selectedPractices = new Set<PracticeInfo>([...selectedGridPractices, ...selectedRegionPractices]);

        onSelectionChange({ ...selectionGridState, selectedPractices: Array.from(selectedPractices) });
    }, [selectedGridRegions, selectedGridPractices, selectAll]);

    const handleSearch = (value: string) => {
        onSelectionChange({ ...selectionGridState, searchTerm: value });
    };

    const onChangeRegions = (selectionModel: GridSelectionModel) => {
        const newState: SelectionGridState = { ...selectionGridState };
        const selected = new Set<LabelEntity>(selectedGridRegions);

        regionRows.forEach((region) => {
            if (selectionModel.includes(region.id)) {
                selected.add(region);
            } else {
                selected.delete(region);
            }
        });

        newState.selectedGridRegions = Array.from(selected);
        if (newState.selectedGridRegions.length) {
            newState.selectAll = false;
        }

        onSelectionChange(newState);
    };

    const onChangePractices = (selectionModel: GridSelectionModel) => {
        const newState: SelectionGridState = { ...selectionGridState };
        const selected = new Set<PracticeInfo>(selectedGridPractices);

        practiceRows.forEach((practice) => {
            if (selectionModel.includes(practice.id)) {
                selected.add(practice);
            } else {
                selected.delete(practice);
            }
        });

        newState.selectedGridPractices = Array.from(selected);
        if (newState.selectedGridPractices.length) {
            newState.selectAll = false;
        }

        onSelectionChange(newState);
    };

    const onChangeSelectAll = (selectionModel: GridSelectionModel) => {
        const isAllSelected = Boolean(head(selectionModel));
        const newState: SelectionGridState = { ...selectionGridState };
        newState.selectAll = isAllSelected;

        if (newState.selectAll) {
            newState.selectedGridPractices = [];
            newState.selectedGridRegions = [];
        }

        onSelectionChange(newState);
    };

    const practiceSelectionModel = useMemo<GridRowId[] | undefined>(() => {
        return practiceRows.filter(({ id }) => selectedGridPractices.find((item) => item.id === id)).map(({ id }) => id);
    }, [selectedGridPractices, practiceRows]);

    const selectRegionModel = useMemo<GridRowId[] | undefined>(() => {
        return regionRows.filter(({ id }) => selectedGridRegions.find((item) => item.id === id)).map(({ id }) => id);
    }, [selectedGridRegions, regionRows]);

    const selectAllModel = selectAll ? [1] : [];

    return (
        <>
            <div className={styles.searchBarContainer} data-automation-id={'bulkUpdatePracticeRegionsSearchBar'}>
                <SpotSearchbar
                    className={classNames(styles.searchBar, 'spot-form spot-search-bar spot-search-bar--low-priority')}
                    onChange={handleSearch}
                    value={searchTerm}
                    secondary={false}
                />
            </div>
            <div>
                {!Boolean(practiceRows.length) && !Boolean(regionRows.length) && (
                    <div className={styles.noPracticesFound} data-automation-id="bulk-update-no-practices-regions-found">
                        <Image className={styles.noPracticesImage} url={'img/noResultsDog.png'} />
                        <span className={styles.noPracticesText}>
                            {i18n.t('controlCenter:itemSelectPractices:noPracticesFound', 'No Practices Found')}
                        </span>
                    </div>
                )}
                {Boolean(practiceRows.length) && showSelectAll && (
                    <div data-automation-id={'bulk-update-select-all-data-grid'}>
                        <MaterialDataGrid
                            className={classNames(styles.holder)}
                            loading={isLoading}
                            columns={additionalColumns}
                            rows={[{ name: `All Practices where the Item Exists (${practiceRows.length})`, id: 1 }]}
                            headerHeight={0}
                            rowHeight={60}
                            getRowClassName={() => styles.row}
                            autoHeight={true}
                            selectionModel={selectAllModel}
                            checkboxSelection={true}
                            onSelectionModelChange={onChangeSelectAll}
                        />
                    </div>
                )}
                {Boolean(regionRows.length) && (
                    <div data-automation-id={'bulk-update-select-regions-data-grid'}>
                        <div className={styles.gridTitles}>{i18n.t('controlCenter:itemSelectPractices:regions', 'REGIONS')}</div>
                        <MaterialDataGrid
                            className={classNames(styles.holder)}
                            loading={isLoading}
                            columns={regionColumns}
                            rows={regionRows}
                            headerHeight={0}
                            rowHeight={50}
                            getRowClassName={() => styles.row}
                            autoHeight={true}
                            selectionModel={selectRegionModel}
                            sortModel={regionSortModel}
                            checkboxSelection={true}
                            onSelectionModelChange={onChangeRegions}
                        />
                    </div>
                )}
                {Boolean(practiceRows.length) && (
                    <div data-automation-id={'bulk-update-select-practices-data-grid'}>
                        <div className={styles.gridTitles}>
                            {i18n.t('controlCenter:itemSelectPractices:individualPractices', 'INDIVIDUAL PRACTICES')}
                        </div>
                        <MaterialDataGrid
                            className={classNames(styles.holder)}
                            loading={isLoading}
                            columns={practiceColumns}
                            rows={practiceRows}
                            headerHeight={0}
                            rowHeight={60}
                            getRowClassName={() => styles.row}
                            autoHeight={true}
                            selectionModel={practiceSelectionModel}
                            sortModel={practiceSortModel}
                            checkboxSelection={true}
                            onSelectionModelChange={onChangePractices}
                        />
                    </div>
                )}
            </div>
        </>
    );
});
