export enum PracticeAccessType {
    All = 'all',
    Practice = 'practice',
    Region = 'region',
}

export interface UserPractices {
    practices?: string[];
    regions?: string[];
}
