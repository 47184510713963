import React, { memo, ReactNode } from 'react';
import classNames from 'classnames';
import { Image } from '../../../components/helpers/Image';
import styles from './rightSidebar.module.scss';
import { Automatable } from '../../../core/interfaces/Automatable';
import { SpotFlyoverLoading } from '@enterprise/spot';
import { RemoveScroll } from 'react-remove-scroll';

export interface RightSidebarProps extends Automatable {
    isLoading?: boolean;
    title?: ReactNode | string;
    header?: () => JSX.Element | null;
    hideBackdrop?: boolean;
    isSmall?: boolean;
    close?: () => void;
    modalHeaderBackground?: string;
    modalBodyStyle?: any;
    loadingText?: string;
    className?: string;
    children?: ReactNode;
    closeOnClickOutside?: boolean;
}

export const RightSidebar = memo(function RightSidebar(props: RightSidebarProps) {
    const {
        children,
        isLoading,
        hideBackdrop,
        title,
        close,
        modalHeaderBackground,
        isSmall,
        header,
        loadingText,
        automationId,
        className,
        modalBodyStyle,
        closeOnClickOutside,
        ...rest
    } = props;

    const renderTitle = () => {
        if (!title) {
            return null;
        }
        return React.isValidElement(title) ? (
            <div className={styles.title}>{React.cloneElement(title)}</div>
        ) : (
            <div className={styles.title}>
                <h1>{title}</h1>
            </div>
        );
    };

    return (
        <>
            {!hideBackdrop && <div className={styles.backdrop} onClick={() => closeOnClickOutside && close?.()} />}
            <RemoveScroll
                className={classNames(styles.modal, { [styles.smallModal]: Boolean(isSmall) }, className)}
                data-automation-id={automationId}
            >
                {close && (
                    <div className={styles.closeContainer}>
                        <a className={styles.close} onClick={close}>
                            <Image url="img/close.svg" />
                        </a>
                    </div>
                )}
                {isLoading && <SpotFlyoverLoading text={loadingText} size={'large'} />}
                {!isLoading && (
                    <>
                        <div className={styles.header} style={{ backgroundColor: modalHeaderBackground || '' }}>
                            {header && header()}
                            {!header && title && renderTitle()}
                        </div>
                        <div className={styles.body} style={modalBodyStyle}>
                            {children && React.isValidElement(children)
                                ? React.cloneElement(children, rest)
                                : React.createElement(children as any, rest)}
                        </div>
                    </>
                )}
            </RemoveScroll>
        </>
    );
});
