import React, { ReactElement, useEffect } from 'react';
import i18n from '../../i18n';
import { Col, Row } from 'react-bootstrap';
import styles from './practiceConnections.module.scss';
import { FlexPageWrapper } from '../../layout/main/flexPageWrapper';
import { PracticesGrid } from './practicesGrid';
import { PracticeInfo } from './MyOrganizationPageStore';
import { Image } from '../../components/helpers/Image';
import { Link, Route } from 'react-router-dom';
import { RouterPaths } from '../../router/RouterPaths';
import { SpotHorizontalRule, SpotSvg } from '@enterprise/spot';
import ConnectPracticeFlyover from '../connectPractice/connectPracticeFlyover';
import { Notice, NoticeIcon } from '../../components/helpers/notice';
import { PracticeLabels } from './practiceLabels';
import { PageHeaderNavigation } from '../../layout/main/pageHeaderNavigation';
import { useStores } from '../../hooks';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { SubMenuType } from '../../store';
import { UserRoles } from '@enterprise/common';
import { RootRoutes } from '../../router';

export const PracticeConnectionsPage = observer(function PracticeConnectionsPage() {
    const {
        domain: { practicesStore, activityPageStore, constantsStore },
        ui: { router, app },
    } = useStores();
    const { isLoaded, isFullLoading, practices = [], isConnectPracticeFeatureEnabled, selectedPractices } = practicesStore;
    const { isSyncPending: isSyncTaskPending, isLoaded: isTasksLoaded, isTaskLoading } = activityPageStore;
    const { token } = app;
    const loggedInUserIsOwner = token?.hasRole(UserRoles.SystemOwner) || token?.hasRole(UserRoles.SuperAdmin);

    const isSyncPending = isTasksLoaded && isSyncTaskPending;

    const masterSiteId = constantsStore.organizationSettings?.masterSite?.siteId;
    const groupBy = constantsStore.groupsBy;

    useEffect(() => {
        void practicesStore.load();
        void activityPageStore.load();
        void constantsStore.load();
        void activityPageStore.fetchParentTasks({});
    }, [practicesStore, activityPageStore, constantsStore]);

    useEffect(() => {
        const interval =
            (isSyncPending || undefined) &&
            setInterval(() => {
                if (isSyncPending && !isTaskLoading) {
                    void activityPageStore.fetchParentTasks({});
                }
            }, 1000 * 10); // 10 seconds interval

        return () => {
            if (interval != undefined) {
                clearInterval(interval);
            }
        };
    }, [isSyncPending, isTaskLoading, activityPageStore]);

    const onSyncClick = (): Promise<void> => {
        return activityPageStore.syncChangedProducts();
    };

    const onActiveToggle = (app: PracticeInfo): Promise<void> => {
        return practicesStore.setApplicationActiveStatus(app);
    };

    const renderConnectPracticeLink = (): ReactElement => {
        return (
            <Link
                data-automation-id="connect-practice-link"
                to={RouterPaths.MyOrganizationPages.ConnectPractice}
                className="spot-link spot-link--large"
            >
                <SpotSvg icon="add" className="spot-icon spot-link__icon spot-link__icon--left" />
                {i18n.t('myOrganization:newConnection', 'New Connection')}
            </Link>
        );
    };

    const renderAddPractice = (): ReactElement => {
        const message = loggedInUserIsOwner
            ? i18n.t('myOrganization:getStarted', 'Get started by connecting a practice to your account')
            : i18n.t('myOrganization:noPractices', 'No Practices Connected');
        return (
            <Col xs={12} data-automation-id="add-practice-cta">
                <div className={styles.practicesEmpty} data-automation-id="add-practice-cta">
                    <Image className={styles.practicesEmptyImage} url={'img/practice.png'} />
                    <div className={classNames('spot-typography__heading--level-5', styles.practicesEmptyText)}>{message}</div>
                    {loggedInUserIsOwner && renderConnectPracticeLink()}
                </div>
            </Col>
        );
    };

    const renderNoPracticesConnected = (): ReactElement => {
        return (
            <Col xs={12} data-automation-id="add-practice-cta">
                <div className={styles.practicesEmpty} data-automation-id="add-practice-cta">
                    <Image className={styles.practicesEmptyImage} url={'img/practice.png'} />
                    <div className={classNames('spot-typography__heading--level-5', styles.practicesEmptyText)}>
                        {i18n.t('myOrganization:noPracticesConnected', 'No Practices Connected')}
                    </div>
                </div>
            </Col>
        );
    };

    const closeConnectPracticeFlyover = () => {
        router.replace(RouterPaths.MyOrganizationPages.PracticeConnections);
    };

    const showAddPractice = isConnectPracticeFeatureEnabled && isLoaded;
    const showNoPracticesConnected = !isConnectPracticeFeatureEnabled && isLoaded;
    const pageMenu = app.getSubMenuItems(SubMenuType.OrganizationManagement);

    return (
        <FlexPageWrapper header={<PageHeaderNavigation items={pageMenu} />} className={styles.holder} isLoading={isFullLoading}>
            <Row className={styles.container} data-automation-id="practice-connections">
                {isConnectPracticeFeatureEnabled && (
                    <RootRoutes>
                        <Route
                            path={RouterPaths.MyOrganizationPages.ConnectPractice}
                            element={<ConnectPracticeFlyover onClose={closeConnectPracticeFlyover} page={practicesStore} />}
                        />
                    </RootRoutes>
                )}

                {!practices.length && showAddPractice && renderAddPractice()}
                {!practices.length && showNoPracticesConnected && renderNoPracticesConnected()}
                {Boolean(practices.length) && (
                    <Col xs={12} data-automation-id="organization-practices" className={styles.practicesBlock}>
                        <div className={styles.titleBlock}>
                            <div className="spot-typography__heading--level-3" data-automation-id="organization-practices-heading">
                                {i18n.t('myOrganization:practices', 'Practices')}
                                {Boolean(practices?.length > 1) && <> ({practices?.length})</>}
                            </div>
                            {isConnectPracticeFeatureEnabled && loggedInUserIsOwner && (
                                <div className={styles.connectPractice}>{renderConnectPracticeLink()}</div>
                            )}
                        </div>

                        <SpotHorizontalRule />
                        <Notice
                            className={styles.notificationAnalytics}
                            icon={NoticeIcon.Bell}
                            text={i18n.t(
                                'myOrganization:newPracticeDelayNotice',
                                'Analytics for new practices may not be available for up to 24 hours after verification.',
                            )}
                        />
                        <PracticesGrid
                            isSyncPending={isSyncPending}
                            masterSiteId={masterSiteId}
                            groupBy={groupBy}
                            onSyncClick={onSyncClick}
                            isLoading={!isLoaded}
                            practices={practices}
                            className={styles.grid}
                            onActiveToggle={onActiveToggle}
                            onSelectionChange={(selected) => practicesStore.setSelectedPractices(selected)}
                            checkboxSelection={loggedInUserIsOwner}
                        />
                        {Boolean(selectedPractices?.length) && <PracticeLabels />}
                    </Col>
                )}
            </Row>
        </FlexPageWrapper>
    );
});
