import * as React from 'react';

export interface StepProps {
    currentIndex?: number;
    isLast?: boolean;
    prevStep?: () => void;
    nextStep?: () => void;
    onChangeValue?: (name: string, value: any) => void;
    onSubmit?: (values: any) => void;
    values?: any;
    children?: any;
    disabled?: boolean;
}

export class Step extends React.PureComponent<StepProps> {
    render() {
        const { children, currentIndex, isLast, prevStep, nextStep, onChangeValue, onSubmit, values } = this.props;

        return children({
            onChangeValue,
            values,
            prevStep,
            nextStep,
            currentIndex,
            isLast,
            onSubmit,
        });
    }
}
