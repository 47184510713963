import React, { useState } from 'react';
import classNames from 'classnames';
import { SpotSvg } from '@enterprise/spot';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

export interface DeleteWidgetProps {
    additionalClasses?: string[];
    automationId?: string;
    onConfirmDelete: () => void;
}

export const DeleteWidget = ({ additionalClasses = [], automationId, onConfirmDelete }: DeleteWidgetProps) => {
    const [isActive, setIsActive] = useState(false);
    const { t } = useTranslation('common', { i18n });

    const showOptions = () => {
        setIsActive(true);
    };

    const hideOptions = () => {
        setIsActive(false);
    };

    const confirmDeleteHandler = () => {
        onConfirmDelete();
        hideOptions();
    };

    return (
        <div className={classNames('delete-widget', additionalClasses)} data-automation-id={automationId}>
            <div className={classNames('delete-widget-action', { clickable: !isActive })} onClick={showOptions}>
                <SpotSvg icon="delete" className={classNames('delete-widget-icon', { clickable: !isActive })} />
                <span>{t('common:delete', { defaultValue: 'Delete' })}</span>
            </div>
            <div className={classNames('delete-widget-options')} hidden={!isActive}>
                <span className={classNames({ clickable: isActive })} onClick={confirmDeleteHandler} data-automation-id={`${automationId}-yes`}>
                    {t('common:yes', { defaultValue: 'Yes' })}
                </span>
                <span className={'delete-widget-link-divider'}>|</span>
                <span className={classNames({ clickable: isActive })} onClick={hideOptions} data-automation-id={`${automationId}-no`}>
                    {t('common:no', { defaultValue: 'No' })}
                </span>
            </div>
        </div>
    );
};
