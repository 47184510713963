import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { cloneDeep } from 'lodash';
import { autorun, IReactionDisposer, toJS } from 'mobx';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { ItemsPageStore } from '../ItemsPageStore';
import { RightSidebar } from '../../../../layout/main/rightSidebar';
import { Field, FieldState, Form, FormRenderProps, FormSpy, Transformers } from '@enterprise/common';
import { SpotButton, SpotSelectBox, SpotSelectOption, SpotTextInput } from '@enterprise/spot';
import { SideTab } from '../../../../components/tab/SideTab';
import { TabItem } from '../../../../components/tab/TabItem';
import { OverviewControls } from '../formsControls/OverviewControls';
import { PricingControls } from '../formsControls/PricingControls';
import ConstantsStore, { Loadable } from '../../../../store/ConstantsStore';
import { TaxDiscControls } from '../formsControls/TaxDiscControls';
import { FormValues, FullItemFormType } from '../formType/FullItemFormType';
import { InfoBar } from '../../components/InfoBar';
import { VariantsControls } from '../formsControls/VariantsControls';
import { ScheduledItemSaveModal } from '../modals/ScheduledItemSaveModal';
import { Errors } from '../../../../core/enums/Errors';
import { Trans } from 'react-i18next';
import MissingMappingFlyover from '../mapping/MissingMappingFlyover';
import i18n from 'i18next';
import MappingError from '../mapping/MappingError';
import { Notifications } from '../../../Notifications';
import {
    InvoiceItem,
    InvoiceItemData,
    InvoiceItemDifference,
    InvoiceItemParent,
    InvoiceItemWithDynamicProps,
    InvoiceItemWithVariantDetail,
} from '../../../../core/models/datasource/invoiceItems';
import { InvoiceItemsWithVariantDetails } from '../../../../core/transformer/InvoiceItemsWithVariantDetails';
import { RouterPaths } from '../../../../router/RouterPaths';
import { LookupItemTypes } from '../../../../core/models/datasource/lookups/parts';
import { AnimanaControls } from '../formsControls/animanaControls/animanaControls';
import { InvoiceItemEditType } from '../InvoiceItemEditType.enum';
import { NavLink, useParams } from 'react-router-dom';
import { ItemsGroupByType } from '../../../../core/models/organization/settings/enum';
import { FEATURES } from '@enterprise/core';
import { defaultEmptyPriceBreakValues, removeEmptyPriceBreaks } from '../../../../core/helpers/priceFieldHelpers';
import { StoresContext } from '../../../../contexts';
import AppStore from '../../../../store/AppStore';
import { RouterStore } from '../../../../store';
import { ProviderDetails } from '../../../../core/models';
import { SpecialActionsField } from '../../components/SpecialActionsField';

interface PageProps {
    action?: InvoiceItemEditType;
}

type QueryParams = {
    id: string;
    description: string;
    isSub: string;
    practice: string;
};

interface PageState {
    submitting: boolean;
    isVariantsMode: boolean;
    data: any;
    activeValueName: 'master' | string;
    value?: any;
    loadingError?: string;
    isShowMappingFlyover: boolean;
    unmappedItem?: InvoiceItemWithVariantDetail;
    unmappedProperties: Map<string, MappingError>;
    isScheduledSavePending?: boolean;
    pendingOperationParameters?: any;
}

interface PropertyState {
    value: string;
    active: boolean;
    error?: string | ReactNode;
}

export interface FormValuesData extends InvoiceItemDifference {
    master: Partial<FormValues>;
    [key: string]: Partial<FormValues>;
}

@observer
class ItemEditPageComponent extends React.Component<PageProps, PageState> {
    // todo: disposer and data should be moved to mobX store and make it reactive/one-way flow
    private disposer?: IReactionDisposer;
    private data: FormValuesData = {} as InvoiceItemData;
    private form: FullItemFormType;

    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    state: PageState = {
        submitting: false,
        isVariantsMode: false,
        data: {},
        activeValueName: '',
        isShowMappingFlyover: false,
        unmappedProperties: new Map(),
    };

    get store(): ItemsPageStore {
        return this.context.domain.itemsPageStore;
    }

    get constantsStore(): ConstantsStore {
        return this.context.domain.constantsStore;
    }

    get routerStore(): RouterStore {
        return this.context.ui.router;
    }

    get appStore(): AppStore {
        return this.context.ui.app;
    }

    back = () => {
        this.store.invoiceItem = undefined;
        this.store.isCreatingNewItem = false;
        this.routerStore.push('/control-center/items');
    };

    getOriginalItemParent = (originalKey?: string): InvoiceItemParent | undefined => {
        if (!originalKey) {
            return undefined;
        }

        const { master } = this.data;
        const { variants } = this.store.invoiceItem as InvoiceItemWithVariantDetail;
        const { pimsGeneratedId } = InvoiceItemsWithVariantDetails.splitDiffKey(originalKey);
        const originalVariant = variants.find((variant) => variant.pimsGeneratedId === pimsGeneratedId);

        if (!master?.itemParent) {
            return undefined;
        }

        return originalVariant?.itemParent;
    };

    unpackEntity(): InvoiceItemWithVariantDetail {
        function splitTaxes(val: any) {
            const v = { ...val };
            v.taxes = [].concat(
                (v.usageTaxes || []).map((t) => ({ ...t, isUsageTax: true })),
                (v.salesTaxes || []).map((t) => ({ ...t, isUsageTax: false })),
            );
            delete v.usageTaxes;
            delete v.salesTaxes;
            return v;
        }

        const { data } = this;
        const { master } = data;
        const entity = new Transformers.ToTypeTransformer(InvoiceItemWithVariantDetail).transform(splitTaxes(data.master));
        const activeSites = this.store.applications.filter((app) => app.isActive);
        const { variantKeys } = this.store;
        const variants: Partial<InvoiceItem>[] = activeSites.reduce((agg, { provider }) => {
            const variant = this.getVariant(data, provider);
            return variant ? [...agg, variant] : agg;
        }, [] as Partial<InvoiceItem>[]);

        entity.variants = variants
            .map((variant) => {
                // pimsGeneratedIds get lost on variants and will default to the master id, so we need to restore them
                const originalKey = variantKeys?.find((key) => {
                    const { siteId: existingSiteId } = InvoiceItemsWithVariantDetails.splitDiffKey(key);
                    return existingSiteId === variant.provider?.siteId;
                });

                if (originalKey) {
                    const { pimsGeneratedId } = InvoiceItemsWithVariantDetails.splitDiffKey(originalKey);
                    variant.pimsGeneratedId = pimsGeneratedId;
                }

                if (master.itemParent) {
                    const originalItemParent = this.getOriginalItemParent(originalKey);
                    variant.itemParent = originalItemParent;
                }

                return variant;
            })
            .filter((variant) => {
                const { master } = data;
                // remove all sub-products variants with does not have parent product before send to api
                return !master.itemParent || variant.itemParent;
            })
            .map(splitTaxes)
            .map(removeEmptyPriceBreaks)
            .map(defaultEmptyPriceBreakValues);
        return entity;
    }

    private getVariant = (data: InvoiceItemData, provider: ProviderDetails): Partial<InvoiceItem> | undefined => {
        const { master } = data;
        const { isCreatingNewItem } = this.store;
        const keys = Object.keys(data).filter((key) => key !== 'master');
        const existingVariantKey = keys.find((key) => InvoiceItemsWithVariantDetails.splitDiffKey(key).siteId === provider.siteId);
        if (existingVariantKey) {
            return data[existingVariantKey];
        }

        const hasProviderInMaster = master.providers?.find(({ siteId }) => siteId === provider.siteId);
        if (hasProviderInMaster || isCreatingNewItem) {
            return Object.assign(cloneDeep(master), { provider: { ...provider } });
        }

        return undefined;
    };

    checkMappingsAndSave = async (params: any, isScheduledSave = false) => {
        if ((!isScheduledSave && Boolean(params.validateOnly)) || this.state.submitting) {
            return;
        }

        this.setState({ submitting: true });

        const item = this.unpackEntity();
        const unmappedProperties = await this.store.getUnmappedProperties(item);
        if (unmappedProperties.size === 0) {
            return this.runPendingSave(isScheduledSave, params);
        }

        this.setState({
            unmappedProperties,
            isShowMappingFlyover: true,
            unmappedItem: item,
            pendingOperationParameters: params,
            isScheduledSavePending: isScheduledSave,
        });
    };

    runPendingSave = (isScheduledSave, params) => {
        return this.onSave(params, isScheduledSave);
    };

    onMappingFlyoverClosed = (areMappingProblemsResolved: boolean) => {
        let callback = () => {};

        if (areMappingProblemsResolved) {
            const params = this.state.pendingOperationParameters;
            const isScheduledSavePending = this.state.isScheduledSavePending;
            callback = () => this.runPendingSave(isScheduledSavePending, params);
        }

        this.setState(
            {
                unmappedProperties: new Map(),
                unmappedItem: undefined,
                isShowMappingFlyover: false,
                pendingOperationParameters: undefined,
                isScheduledSavePending: false,
                submitting: false,
            },
            callback,
        );
    };

    onSave = ({ name, date }, isScheduledSave: boolean) => {
        this.setState({ submitting: true });

        const item = this.unpackEntity();
        const message = this.store.getSuccessMessage(item);
        const saveRequest = isScheduledSave ? this.store.scheduleSave(item, date, message, name) : this.store.save(item, message);

        return saveRequest
            .then(() => {
                this.setState({ submitting: false });
                this.back();
            })
            .catch((e) => {
                this.setState({ submitting: false });
                return Promise.reject(e);
            });
    };

    setLoadingError() {
        this.setState({
            loadingError: Errors.DP_REST,
        });
    }

    async componentDidMount() {
        const itemAction = this.props.action;
        // This is not an ideal fix for this, but this is legacy code and will be going away soon. By adding the delay,
        // the item can fully be cleared before creating a new item. Work done as part of TD-2751
        this.store.isCreatingNewItem = itemAction === InvoiceItemEditType.New;
        if (this.store.isCreatingNewItem && this.store.isLoadingItem) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }

        this.store.invoiceItem = undefined;
        await this.constantsStore.load([Loadable.Applications]).catch(() => {
            this.setLoadingError();
        });

        if (itemAction === InvoiceItemEditType.New) {
            this.store.createInvoiceItem();
        } else if (itemAction === InvoiceItemEditType.Form) {
            const { id, description, isSub: subFlag, practice } = this.routerStore.params as QueryParams;

            if (id || description) {
                const isSub = Boolean(subFlag && JSON.parse(subFlag));
                await this.store.fetchInvoiceItem({ id, description, practice, isSub }).catch(() => {
                    this.setLoadingError();
                });
            } else {
                this.setLoadingError();
            }
        } else {
            // Assume a legacy path was used, change the path to the new format and reload
            this.routerStore.replace(`${RouterPaths.ControlCenterPages.EditItem}?id=${itemAction}`);
            this.componentDidMount();
        }

        this.disposer = autorun(() => {
            // this function is going to be executed only on this.store.invoiceItemDifference value change according to mobX documentation
            if (!this.store.invoiceItemDifference) {
                return;
            }

            this.data = Object.entries(this.store.invoiceItemDifference).reduce((agg, [key, value]) => {
                return {
                    ...agg,
                    [key]: {
                        ...value,
                        usageTaxes: value.taxes?.filter(({ isUsageTax }) => Boolean(isUsageTax)) || [],
                        salesTaxes: value.taxes?.filter(({ isUsageTax }) => !isUsageTax) || [],
                    } as FormValues,
                };
            }, {} as FormValuesData);
            this.enableItemMode();
        });
    }

    componentWillUnmount() {
        this.disposer?.();
    }

    private fieldsState = new Map<string, PropertyState>();

    private getDuplicateErrorMessage = (item: InvoiceItem, message: string, name: string): ReactNode => {
        const href = this.store.getEditUrl(item);

        return (
            <span data-automation-id="duplicate-error-message">
                {message}:{' '}
                <NavLink to={href} className={'spot-link'} target={'_blank'}>
                    {name}
                </NavLink>
            </span>
        );
    };

    private isNotExist = (value: string, meta: FieldState<unknown> | undefined, message: string) => {
        if (!value || !meta) {
            return undefined;
        }

        const { name, active } = meta;
        const prevState = this.fieldsState.get(name);

        if (!active && prevState?.active) {
            const state: PropertyState = { value, active: false, error: undefined };
            const request =
                (name === 'pimsId' && { id: value }) ||
                (name === 'hospitalDescription' && {
                    description: value,
                    matchHospitalDescription: true,
                    searchType: 'description',
                    searchValue: value,
                }) ||
                undefined;
            const errorRequest = async () => {
                const existingItem = await (request && this.store.fetchDuplicateInvoiceItem(request));
                return existingItem && this.getDuplicateErrorMessage(existingItem, message, value);
            };

            this.fieldsState.set(name, { ...state, error: errorRequest() });
        }

        if (active && prevState?.value !== value) {
            this.fieldsState.set(name, { value, active: true, error: undefined });
        }

        return this.fieldsState.get(name)?.error;
    };

    private mutateValue([name], state, { changeValue }) {
        // change the value to the same value, thus
        // triggering a revalidation of the same value
        // https://github.com/final-form/react-final-form/issues/559#issuecomment-510792155
        changeValue(state, name, (value) => value);
    }

    render() {
        const requiredIndicator = '* ';
        const constantsStore = toJS(this.constantsStore);
        const item = toJS(this.store.invoiceItem);
        const { submitting, isVariantsMode, activeValueName } = this.state;
        const { data } = this;
        const variantNamesWithoutMaster = Object.keys(data).filter((variantName) => variantName !== 'master');
        const areFieldsDisabled = !item || submitting || (this.state.isVariantsMode && variantNamesWithoutMaster.length === 0);
        const isLoadingConstants = constantsStore.isLoading;
        const { isLoadingItem, isCreatingNewItem, isLoaded, isAnimanaOnly, itemsGroupBy } = this.store;
        const isLoading = !isLoaded || isLoadingConstants || isLoadingItem || !item || !this.state.value;
        const { practice: queryPractice } = this.routerStore.params as QueryParams;

        const itemTypes: LookupItemTypes[] = this.store.getLookupResults(this.store.itemTypes);

        const pimsIdPlaceholder = this.store.isAnimanaOnly
            ? i18n.t('controlCenter:itemFields.code', 'Code')
            : i18n.t('controlCenter:itemFields.itemNumber', 'Item Number');

        const itemTypePlaceholder = this.store.isAnimanaOnly
            ? requiredIndicator + i18n.t('common:selectValue', 'Select a value')
            : i18n.t('common:selectValue', 'Select a value');

        const hospitalDescriptionPlaceholder = this.store.isAnimanaOnly
            ? requiredIndicator + i18n.t('controlCenter:itemFields.name', 'Name')
            : i18n.t('controlCenter:itemFields.hospitalDescription', 'HospitalDescription');

        const itemNumberAlreadyInUse = i18n.t('validation:itemNumberAlreadyInUse', 'Item Number already in use');
        const itemDescriptionAlreadyInUse = i18n.t('validation:itemDescriptionAlreadyInUse', 'Item Description already in use');

        const isItemTypeDisabled = isAnimanaOnly && !isCreatingNewItem;

        return (
            <>
                <RightSidebar
                    isLoading={isLoading}
                    loadingText={i18n.t('common:loading', 'Loading...')}
                    close={this.back}
                    modalHeaderBackground="transparent"
                    modalBodyStyle={{ marginLeft: '0px', marginRight: '0px', padding: '0px' }}
                >
                    <div className="item-edit-page">
                        {this.state.loadingError && <span>{this.state.loadingError}</span>}
                        {!this.state.loadingError && (
                            <>
                                <h1 style={{ padding: '12px 16px 0' }}>
                                    {isCreatingNewItem ? (
                                        <Trans i18nKey="common:item.createNewItem">Create New Item</Trans>
                                    ) : (
                                        <Trans i18nKey="common:item.editItem">Edit Item</Trans>
                                    )}
                                </h1>
                                <div className="header">
                                    <div className="variants-switcher">
                                        <a
                                            className={classNames({ active: !isVariantsMode })}
                                            onClick={this.enableItemMode}
                                            data-automation-id="item-details-button"
                                        >
                                            <small>
                                                <Trans i18nKey="common:item.itemDetails">Item Details</Trans>
                                            </small>
                                        </a>{' '}
                                        {!Boolean(queryPractice) && (
                                            <a
                                                className={classNames({ active: isVariantsMode })}
                                                onClick={this.enableVariantsMode}
                                                data-automation-id="clinic-variants-button"
                                            >
                                                <small>
                                                    <Trans i18nKey="common:item.clinicVariants">Clinic Variants</Trans>
                                                </small>
                                            </a>
                                        )}
                                    </div>
                                </div>

                                <Form
                                    data={{ ...data[activeValueName] }}
                                    key={activeValueName}
                                    formType={this.store.formType}
                                    onSubmit={(params) => this.checkMappingsAndSave(params)}
                                    mutators={{
                                        // potentially other mutators could be merged here
                                        mutateValue: this.mutateValue,
                                    }}
                                >
                                    {({ handleSubmit, invalid, pristine, form }: FormRenderProps<Partial<InvoiceItemWithDynamicProps>>) => {
                                        function submitForm() {
                                            form.change('validateOnly', false);
                                            handleSubmit();
                                        }

                                        return (
                                            <div>
                                                <fieldset disabled={areFieldsDisabled}>
                                                    <FormSpy onChange={this.onFormChanged} />
                                                    <FormSpy
                                                        subscription={{ active: true }}
                                                        // call validation on field 'active' state change
                                                        onChange={() => form.mutators.mutateValue('revalidate')}
                                                    />
                                                    <FormSpy
                                                        subscription={{ values: true }}
                                                        // call validation on field 'active' state change
                                                        onChange={({ values }) => {
                                                            if (typeof values.isActive === 'string') {
                                                                form.change('isActive', values.isActive === 'true');
                                                            }
                                                        }}
                                                    />
                                                    <div className="spot-form__field-group--horizontal headerFields">
                                                        <Field
                                                            name="pimsId"
                                                            parse={isCreatingNewItem ? this.toUpperCase : undefined}
                                                            validate={(val, _, meta) =>
                                                                isCreatingNewItem &&
                                                                itemsGroupBy === ItemsGroupByType.Id &&
                                                                this.isNotExist(val, meta, itemNumberAlreadyInUse)
                                                            }
                                                        >
                                                            {({ input, meta }) => (
                                                                <SpotTextInput {...input} error={meta} placeholder={pimsIdPlaceholder} />
                                                            )}
                                                        </Field>
                                                        <Field name="itemType.id" parse={(value) => value && Number(value)}>
                                                            {({ input, meta }) => (
                                                                <SpotSelectBox
                                                                    {...input}
                                                                    error={meta}
                                                                    disabled={isItemTypeDisabled}
                                                                    placeholder={itemTypePlaceholder}
                                                                >
                                                                    {itemTypes.map((type) => (
                                                                        <SpotSelectOption key={type.id} value={type.id}>
                                                                            {type.description}
                                                                        </SpotSelectOption>
                                                                    ))}
                                                                </SpotSelectBox>
                                                            )}
                                                        </Field>
                                                        <Field
                                                            name="hospitalDescription"
                                                            validate={(val, _, meta?) =>
                                                                isCreatingNewItem &&
                                                                itemsGroupBy === ItemsGroupByType.Description &&
                                                                this.isNotExist(val, meta, itemDescriptionAlreadyInUse)
                                                            }
                                                        >
                                                            {({ input, meta }) => {
                                                                const isCopyDescriptionLinkVisible =
                                                                    !areFieldsDisabled &&
                                                                    input.value &&
                                                                    !(meta && meta.error && meta.touched) &&
                                                                    !Boolean(queryPractice);
                                                                const copyHandler = () => this.copyHospitalDescriptionToAllVariants(input.value);
                                                                return (
                                                                    <div>
                                                                        <SpotTextInput
                                                                            {...input}
                                                                            error={meta}
                                                                            placeholder={hospitalDescriptionPlaceholder}
                                                                        />
                                                                        {isCopyDescriptionLinkVisible && (
                                                                            <span className="spot-link copy-description-link" onClick={copyHandler}>
                                                                                <Trans i18nKey="common:item.applyDescriptionToAllVariants" />
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }}
                                                        </Field>
                                                        <ScheduledItemSaveModal
                                                            form={form}
                                                            invalid={invalid}
                                                            defaultName={item?.hospitalDescription}
                                                            onSave={(params) => this.checkMappingsAndSave(params, true)}
                                                            title={i18n.t('controlCenter:items.scheduleItem', 'Schedule item')}
                                                        />
                                                        <SpotButton
                                                            disabled={submitting}
                                                            onClick={submitForm}
                                                            data-automation-id="item-edit-page-publish-button"
                                                        >
                                                            <Trans i18nKey="common:item.publish">Publish</Trans>
                                                        </SpotButton>
                                                    </div>
                                                </fieldset>
                                                <div style={{ backgroundColor: '#fff' }}>
                                                    {isVariantsMode && (
                                                        <div style={{ padding: '10px 10px 0px 10px' }}>
                                                            <VariantsControls
                                                                constants={constantsStore}
                                                                form={this.form}
                                                                selectedVariant={activeValueName}
                                                                existingVariantsIds={variantNamesWithoutMaster}
                                                                disabled={invalid}
                                                                onVariantChanged={this.variantChanged}
                                                                onVariantAdded={this.addVariant}
                                                            />
                                                            <hr />
                                                        </div>
                                                    )}
                                                    <fieldset disabled={areFieldsDisabled}>
                                                        {this.getFormFields(pristine, invalid, areFieldsDisabled)}
                                                    </fieldset>
                                                </div>
                                            </div>
                                        );
                                    }}
                                </Form>
                            </>
                        )}
                    </div>
                </RightSidebar>

                {this.state.isShowMappingFlyover && (
                    <MissingMappingFlyover
                        onClose={this.onMappingFlyoverClosed}
                        unmappedProperties={this.state.unmappedProperties}
                        unmappedItem={this.state.unmappedItem}
                    />
                )}
            </>
        );
    }

    private toUpperCase = (value) => {
        return value ? value.toUpperCase() : value;
    };

    private variantChanged = (name) => {
        const { data } = this;
        this.setState({
            activeValueName: name,
            value: { ...(data[name] || data.master) },
        });
    };

    private enableVariantsMode = () => {
        const { data } = this;
        const activeVariant = (Object.keys(data).filter((k) => k !== 'master') || [])[0] || 'master';
        this.setState({
            submitting: false,
            isVariantsMode: true,
            activeValueName: activeVariant,
            value: { ...data[activeVariant] },
        });
    };

    private enableItemMode = () => {
        const { data } = this;
        this.setState({
            activeValueName: 'master',
            submitting: false,
            isVariantsMode: false,
            value: { ...data.master },
        });
    };

    private addVariant = (provider, copyFrom = 'master') => {
        const { data } = this;
        const { invoiceItem } = this.store;
        if (!invoiceItem) {
            return;
        }
        const { pimsGeneratedId } = data.master;
        const variantDiffKey = InvoiceItemsWithVariantDetails.createDiffKey({ pimsGeneratedId }, provider.siteId);
        invoiceItem.diff = invoiceItem.diff || {};
        invoiceItem.diff[variantDiffKey] = cloneDeep(data[copyFrom] || {});
        data[variantDiffKey] = cloneDeep({ ...(data[variantDiffKey] || { ...data.master }), ...invoiceItem.diff[variantDiffKey] });
        data[variantDiffKey].provider = provider;
        this.setState({
            activeValueName: variantDiffKey,
            value: data[variantDiffKey],
        });
    };

    private patchData = (diffData: InvoiceItemDifference) => {
        const { activeValueName } = this.state;
        const { data } = this;

        Object.keys(diffData).forEach((key) => {
            data[key] = { ...data[key], ...diffData[key] };
        });

        this.variantChanged(activeValueName);
    };

    private onFormChanged = ({ values }) => {
        const { activeValueName } = this.state;
        if (!activeValueName) {
            return;
        }

        const { data } = this;
        data[activeValueName] = values;
    };

    private copyHospitalDescriptionToAllVariants = (description) => {
        const { data } = this;
        Object.keys(data).forEach((key) => {
            data[key].hospitalDescription = description;
        });
        Notifications.success(i18n.t('common:item.copiedHospitalDescriptionToAllVariants'));
    };

    private isSpecialActionsEnabled = (): boolean => {
        return this.appStore.featureManager.isAvailable(FEATURES.INVOICE_ITEM_SPECIAL_ACTIONS);
    };

    private getFormFields = (pristine, invalid, areFieldsDisabled) => {
        if (this.store.isAnimanaOnly) {
            const { activeValueName } = this.state;
            const { data } = this;
            const itemAction = this.props.action;
            return (
                <div>
                    <PanelWrapper>
                        <AnimanaControls
                            form={this.form}
                            store={this.store}
                            data={data}
                            patchData={this.patchData}
                            activeValueName={activeValueName}
                            disabled={areFieldsDisabled}
                            actionType={itemAction}
                        />
                    </PanelWrapper>
                </div>
            );
        }

        return (
            <SideTab data-automation-id={'item-edit-page'}>
                <TabItem label={i18n.t('common:item.info', 'Info')}>
                    <div>
                        <InfoBar title={i18n.t('common:item.info', 'Info')} isError={!pristine && invalid} />
                        <PanelWrapper>
                            <OverviewControls />
                            <PricingControls />
                        </PanelWrapper>
                    </div>
                </TabItem>
                <TabItem label={i18n.t('common:item.taxDiscounts', 'Tax & Discounts')}>
                    <div>
                        <InfoBar title={i18n.t('common:item.taxDiscounts', 'Tax & Discounts')} isError={!pristine && invalid} />
                        <PanelWrapper>
                            <TaxDiscControls />
                        </PanelWrapper>
                    </div>
                </TabItem>
                {this.isSpecialActionsEnabled() && (
                    <TabItem label={i18n.t('common:item.specialActions', 'Special Actions')}>
                        <div>
                            <InfoBar title={i18n.t('common:item.specialActions', 'Special Actions')} isError={!pristine && invalid} />
                            <PanelWrapper>
                                <SpecialActionsField name="specialActions" />
                            </PanelWrapper>
                        </div>
                    </TabItem>
                )}
            </SideTab>
        );
    };
}

export const ItemEditPage = () => <ItemEditPageComponent action={useParams<{ action: InvoiceItemEditType }>()?.action} />;

const PanelWrapper: FC = ({ children }: PropsWithChildren<{}>) => <div style={{ padding: '16px' }}>{children}</div>;
