import { INTENT } from '../styles';

export class CardModel {
    description?: string;
    id!: string;
    intent?: INTENT = INTENT.DEFAULT;
    placeholder?: boolean;
    secondaryDescription?: string;
    title!: string;
    children: CardModel[] = [];
    parentId?: string;
    isDisabled?: boolean = false;
    isPrimary?: boolean = false;
}
