import React, { PropsWithChildren, useMemo, useState } from 'react';
import i18n from 'i18next';
import styles from './siteAccessSection.module.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { Image } from '../../../components/helpers/Image';
import { SpotSvg, SpotRadio, SpotIconType } from '@enterprise/spot';
import { useStores } from '../../../hooks';
import { EnterpriseUserToken } from '@enterprise/common';
import { PermissionLevel } from '../../../core/enums/permissionLevel';

export type SiteAccessProps = PropsWithChildren<{
    disabled?: boolean;
    userId: string;
    permissionLevel: PermissionLevel;
    onChange: (userRole: PermissionLevel) => void;
}>;

export const SiteAccess = observer(function SiteAccessComponent(props: SiteAccessProps) {
    const {
        ui: { app },
    } = useStores();

    const { disabled, userId, permissionLevel, onChange } = props;
    const { user } = app.token as EnterpriseUserToken;
    const [expandedDetails, setExpandedDetails] = useState(true);
    const iconName = expandedDetails ? SpotIconType.CaretUp : SpotIconType.CaretDown;
    const isOwner = app.isOwner(userId);
    const permissionLevelInfo: { label: string; imageUrl: string; text: string } = useMemo(() => {
        if (isOwner) {
            return {
                label: i18n.t('siteAccessModule:systemOwnerAccessLevel', 'System Owner'),
                imageUrl: 'img/systemowner.png',
                text: i18n.t(
                    'siteAccessModule:systemOwnerText',
                    'The system owner can access all areas of the site for all connected practices. The system owner can’t be removed from the organization. Ownership can be transferred to another user.',
                ),
            };
        }
        return {
            label: i18n.t('siteAccessModule:decisionMakerAccessLevel', 'Decision Maker'),
            imageUrl: 'img/decisionMaker.png',
            text: i18n.t(
                'siteAccessModule:decisionMakerText',
                'Full access to metris and financial data. Ability to publish updates to practice management software. User and organization administration.',
            ),
        };
    }, [user]);

    //TODO: hardcoded for now, subject to change in the future
    const expandLists = (
        <div>
            <div className={styles.accessText}>
                <p className={styles.acccessTypeText}>{i18n.t('siteAccessModule:financialReporting', 'Financial Reporting')}</p>
                <span className={styles.acccessLevelText}>{i18n.t('siteAccessModule:fullAccessText', 'Full Access')}</span>
            </div>
            <div className={styles.accessText}>
                <p className={styles.acccessTypeText}>{i18n.t('siteAccessModule:pimsDataManagement', 'PIMS Data Management')}</p>
                <span className={styles.acccessLevelText}>{i18n.t('siteAccessModule:fullAccessText', 'Full Access')}</span>
            </div>
            <div className={styles.accessText}>
                <p className={styles.acccessTypeText}>{i18n.t('siteAccessModule:staffReporting', 'Staff Reporting')}</p>
                <span className={styles.acccessLevelText}>{i18n.t('siteAccessModule:fullAccessText', 'Full Access')}</span>
            </div>
            <div className={styles.accessText}>
                <p className={styles.acccessTypeText}>{i18n.t('siteAccessModule:systemAdministration', 'System Administration')}</p>
                <span className={styles.acccessLevelText}>{i18n.t('siteAccessModule:fullAccessText', 'Full Access')}</span>
            </div>
        </div>
    );

    return (
        <div className={styles.siteAccessSection} data-automation-id={'site-access-section'}>
            <div className={styles.accessOption}>
                {!disabled && !isOwner && (
                    <SpotRadio
                        value={PermissionLevel.DecisionMaker}
                        className={styles.radioButton}
                        onClick={(e) => {
                            onChange(PermissionLevel.DecisionMaker);
                            e.currentTarget.blur();
                        }}
                        checked={PermissionLevel.DecisionMaker === permissionLevel}
                    />
                )}
                <div className={styles.siteAccessText}>
                    <p className={styles.siteAccessLabel} data-automaton-id={'site-access-label'}>
                        {permissionLevelInfo.label}
                    </p>
                    <div className={styles.siteAccessSubtext} data-automaton-id={'site-access-subText'}>
                        {permissionLevelInfo.text}
                    </div>
                </div>
                <Image className={classNames('practices-empty-image', styles.siteAccessImage)} url={permissionLevelInfo.imageUrl} />
            </div>
            <div className={styles.accessDropdown}>
                {!disabled && !isOwner && (
                    <>
                        <div className={styles.accessDetailsDropDown}>
                            <SpotSvg
                                onClick={() => setExpandedDetails(!expandedDetails)}
                                icon={iconName}
                                style={expandedDetails ? { transform: 'rotate(90deg)' } : null}
                                className="spot-link__icon"
                            ></SpotSvg>
                            <span className={styles.accessDetailsText}>{i18n.t('siteAccessModule:accessDetailsText', 'Access Details')}</span>
                        </div>

                        <div className={expandedDetails ? styles.accessDetailsHidden : styles.accessDetailsShown}>{expandLists}</div>
                    </>
                )}
            </div>
        </div>
    );
});
