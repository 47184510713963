import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/ru'; // As new languages are added they must be imported for moment.js

const options: InitOptions = {
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    ns: ['controlCenter', 'validation', 'tos', 'common', 'marketing', 'masterData', 'myOrganization', 'myProfile', 'editUser'], // New namespaces need to be added here so they will be loaded
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
        useSuspense: false,
        transEmptyNodeValue: '',
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default',
    },
    detection: {
        order: ['localStorage', 'cookie', 'navigator'],
        lookupLocalStorage: 'i18nlocale',
    },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init(options);

i18n.on('languageChanged', (lng) => {
    moment.locale(lng);
});

export default i18n;
