import { GridCellParams, GridRowData, GridRowId, GridRowTreeNodeConfig, useGridApiRef } from '@enterprise/material-data-grid';
import { InvoiceItem } from '../../../../../core/models';
import { GridState, ItemEditQuery } from '../../../../../store/invoiceItem.store';
import { get, head, isEmpty } from 'lodash';
import { ScreenSizeType, useScreenSize } from '../../../../../hooks';
import { ItemsGridColumn, useItemGridColumns } from './useItemsGridColumns.hook';
import { useItemGridState } from './useItemsGridState.hook';

export interface UseItemGridProps {
    columnsState?: GridState;
    onColumnsStateChange?: (state: GridState) => void;
}

export const useItemGrid = (params: UseItemGridProps) => {
    const { isUpTo } = useScreenSize();
    const apiRef = useGridApiRef();
    const state = useItemGridState({ apiRef, ...params });
    const { isAutoGenerated, columnNames, columns, groupColumn } = useItemGridColumns({ apiRef });

    const getRowId = (row: GridRowData): string => {
        const { pimsGeneratedId, hospitalDescription, provider } = row as InvoiceItem;
        return `${pimsGeneratedId}-${hospitalDescription}-${provider.siteId}`;
    };

    const getGroupGeneratedRowNodes = (rowId?: GridRowId | null): GridRowTreeNodeConfig[] => {
        if (!rowId) {
            return [];
        }

        const node = apiRef.current.getRowNode(rowId);
        const parentGroupRowNodes = getGroupGeneratedRowNodes(node?.parent);

        if (node?.isAutoGenerated) {
            return [node, ...parentGroupRowNodes];
        }

        return [...parentGroupRowNodes];
    };

    const getQueryValue = (node: GridRowTreeNodeConfig): Partial<ItemEditQuery> => {
        const { id, groupingField } = node;
        const rowIds = apiRef.current?.getRowGroupChildren({ groupId: id, applyFiltering: true, applySorting: true });
        const rowId = head(rowIds);
        const invoiceItem = rowId && apiRef.current?.getRow<InvoiceItem>(rowId);

        switch (groupingField) {
            case ItemsGridColumn.Id:
                return { id: get(invoiceItem, ItemsGridColumn.Id) };
            case ItemsGridColumn.Description:
                return { description: get(invoiceItem, ItemsGridColumn.Description) };
            case ItemsGridColumn.Practice:
                return { practice: get(invoiceItem, ItemsGridColumn.Practice) };
            default:
                return {};
        }
    };

    const getGroupQuery = (rowId: GridRowId | null): ItemEditQuery | undefined => {
        const generatedRowNodes = getGroupGeneratedRowNodes(rowId);

        if (!generatedRowNodes.length) {
            return;
        }

        const query = generatedRowNodes.reduce((agg, node) => {
            return { ...agg, ...getQueryValue(node) };
        }, {} as ItemEditQuery);

        return (isEmpty(query) && undefined) || query;
    };

    const getInvoiceItemQuery = (rowId: GridRowId | null): ItemEditQuery | undefined => {
        if (!rowId) {
            return;
        }

        const invoiceItem = apiRef.current.getRow<InvoiceItem>(rowId);
        if (!invoiceItem) {
            return;
        }

        const query: ItemEditQuery = {
            id: invoiceItem[ItemsGridColumn.Id],
            description: invoiceItem[ItemsGridColumn.Description],
            isSub: Boolean(invoiceItem.itemParent?.pimsGeneratedId),
            practice: invoiceItem.provider.siteId,
        };

        return query;
    };

    const getQuery = (params: GridCellParams): ItemEditQuery | undefined => {
        const { id } = params;

        if (!isAutoGenerated(id)) {
            return getInvoiceItemQuery(id);
        }

        if (isAutoGenerated(id)) {
            return getGroupQuery(id);
        }

        return;
    };

    return {
        apiRef,
        isAutoGenerated,
        getRowId,
        getQuery,
        columnNames,
        columns,
        groupColumn,
        isTabletView: isUpTo(ScreenSizeType.md),
        state,
    };
};
