import { RouterPaths } from './RouterPaths';
import { UserRoles } from '@enterprise/common';

export interface NavigationMenuItem {
    label: string;
    path: string;
    iconPath?: string;
    notificationsCount?: number;
    iconographyNavigation?: boolean;
    automationId: string;
    roles?: UserRoles[];
}

export const menu: NavigationMenuItem[] = [
    {
        label: 'Analytics',
        path: RouterPaths.AnalyticsPages.AnalyticsDashboardsPage,
        iconPath: '/assets/img/menu.svg#analytics',
        automationId: 'analytics-navigation',
    },
    {
        label: 'Control Center',
        path: RouterPaths.ControlCenterPages.ControlCenter,
        iconPath: '/assets/img/menu.svg#control-center',
        automationId: 'control-center-navigation',
    },
    {
        label: 'Master Data',
        path: RouterPaths.MasterDataPages.ClassificationsMappingPage,
        iconPath: '/assets/img/menu.svg#master-data',
        automationId: 'master-data-navigation',
        roles: [UserRoles.SuperAdmin, UserRoles.SystemOwner],
    },
    {
        label: 'My Organization',
        path: RouterPaths.MyOrganizationPages.PracticeConnections,
        iconPath: '/assets/img/icons.svg#organization',
        notificationsCount: 2,
        iconographyNavigation: true,
        automationId: 'my-organization-navigation',
    },
];
