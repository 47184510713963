import React, { memo, PropsWithChildren } from 'react';
import { SpotRadio } from './SpotRadio';
import { Trans } from 'react-i18next';
import { SpotRadioGroup } from './SpotRadioGroup';

export interface SpotRadioButtonItem<T extends string | number> {
    value: T;
    label?: string;
    automationId?: string;
}

export type SpotRadioButtonProps<T extends string | number> = PropsWithChildren<{
    className?: string;
    error?: any;
    isHorizontal?: boolean;
    value: string;
    items: SpotRadioButtonItem<T>[];
    onChange: (value: T) => void;
}>;

export const SpotRadioButton = memo(function SpotRadioButton<T extends string | number>(props: SpotRadioButtonProps<T>) {
    const { isHorizontal, error, value: checkedValue, items, onChange, className } = props;

    return (
        <SpotRadioGroup isHorizontal={isHorizontal} error={error} className={className}>
            {items.map(({ value, label, automationId }) => (
                <SpotRadio
                    key={value}
                    name={'practices-access-type'}
                    value={value}
                    checked={value === checkedValue}
                    onChange={() => onChange(value)}
                    data-automation-id={automationId}
                >
                    <Trans key="myOrganization:allPractices.count">{label}</Trans>
                </SpotRadio>
            ))}
        </SpotRadioGroup>
    );
});
