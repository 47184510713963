/**
 * @class  The interface for the user authentication information.
 */
export class BaseUserToken<TUser extends object = object> {
    readonly user: TUser;
    access_token: string;
    constructor(user: TUser = {} as TUser, access_token = '') {
        this.user = user;
        this.access_token = access_token;
    }

    /**
     * return current username
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    getDisplayName(): string {
        return 'Anonymous';
    }

    /**
     * check if user has role
     * @param roleName
     */
    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    hasRole(roleName: string): boolean {
        return false;
    }
}
