import { GridOptions } from 'ag-grid-community';

export const DataGridDefaultOptions: GridOptions = {
    defaultColDef: {
        enableRowGroup: true,
        resizable: true,
        sortable: true,
    },
    enableRangeSelection: true,
    groupMultiAutoColumn: true,
    rowGroupPanelShow: 'always',
    rowHeight: 40,
    suppressDragLeaveHidesColumns: true,
    suppressMakeColumnVisibleAfterUnGroup: true,
};
