import React, { FC, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { INTENT, MAPPING_UI } from '../styles';
import { ActionMenuIcon } from '../icons/ActionMenuIcon';
import classNames from 'classnames';
import { EventHandlerContext } from './Board';
import { BoardEvent, ColumnActions } from '../models';
import { ActionItem } from '../interfaces';
import { ActionItemEvent } from '../models/events';
import { COLUMN } from '../models';

interface ActionMenuProps {
    actions: ActionItem[];
    sourceColumn: string;
}

// This popover could technically be in the Spot package but since
// we know that our version is not going to be the official library
// we should not tie our project specific libraries with the stand alone
// cfs mapping ui
export const ActionMenu: FC<ActionMenuProps> = ({ actions, sourceColumn = COLUMN.PRIMARY }) => {
    const [isOpen, setIsOpen] = useState(false);
    const eventHandler = useContext(EventHandlerContext);
    const wrapperRef: React.Ref<HTMLDivElement> = useRef(null);
    const spanRef: React.Ref<HTMLSpanElement> = useRef(null);

    const toggleOpen = function({ target }: SyntheticEvent): void {
        const { current } = spanRef;
        if (current && !current.isEqualNode(target as Node)) {
            setIsOpen(!isOpen);
        }
    };

    const handleClickOutside = function({ target }: MouseEvent): void {
        const { current } = wrapperRef;
        if (current && !current.contains(target as Node)) {
            setIsOpen(false);
        }
    };

    const menuClickEvent = function({ target }: SyntheticEvent): void {
        const listElement = (target as Element).closest('li');
        const actionId = listElement && listElement.getAttribute('value');

        if (!actionId) {
            return;
        }

        const actionEvent = new ActionItemEvent({
            id: actionId,
            sourceColumn,
        });
        const boardEvent = new BoardEvent({
            action: ColumnActions.ACTION_MENU_CLICK_ITEM,
            data: actionEvent,
        });
        eventHandler(boardEvent);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    });

    return (
        <div className={'spot-popover-wrapper'} onClick={toggleOpen} ref={wrapperRef}>
            <ActionMenuIcon className={MAPPING_UI.COLUMN_HEADER_ACTION_MENU_ICON} />
            <span ref={spanRef} className={classNames('spot-popover spot-popover--bottom-center', isOpen && 'spot-popover--shown')}>
                <ul>
                    {actions.map(({ icon, id, intent = INTENT.DEFAULT, text }) => (
                        <li className={classNames(MAPPING_UI.COLUMN_HEADER_ACTION_MENU_ITEM, intent)} key={id} onClick={menuClickEvent} value={id}>
                            {icon && (
                                <svg className={intent}>
                                    <use xlinkHref={icon} />
                                </svg>
                            )}
                            {text}
                        </li>
                    ))}
                </ul>
            </span>
        </div>
    );
};
