import * as React from 'react';

export interface FieldErrorProps {
    meta?: {
        error?: string;
        touched?: boolean;
        name?: string;
    };
}

export class SpotFieldError extends React.PureComponent<FieldErrorProps> {
    render() {
        const { meta } = this.props;
        return meta && meta.error && meta.touched ? (
            <span className={`spot-form__field-error automation-field-error-${meta.name}`} role="alert">
                <span className="spot-form__field-error-text"> {meta.error} </span>
            </span>
        ) : null;
    }
}
