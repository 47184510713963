import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { Form as FinalForm, FormProps as FinalFormProps, FormRenderProps as FinalFormRenderProps } from 'react-final-form';
import { FormType } from '../FormType';
import { FormApi, SubmissionErrors } from 'final-form';

export { Field, FormSpy, useForm, useField, useFormState } from 'react-final-form';
export * from 'final-form';

export interface FormRenderProps<FormValues = Record<string, any>, InitialFormValues = Partial<FormValues>>
    extends FinalFormRenderProps<FormValues, InitialFormValues> {}

interface FormProps<T, TEntity> extends Omit<FinalFormProps<T>, 'onSubmit'> {
    formType?: FormType<T, TEntity>;
    data?: T;
    children?: (renderProps: FormRenderProps<T>) => ReactNode;
    onSubmit: (
        values: TEntity,
        form: FormApi<T>,
        callback?: (errors?: SubmissionErrors) => void,
    ) => SubmissionErrors | Promise<SubmissionErrors> | void;
}

export const Form = <T, TEntity = T>(props: FormProps<T, TEntity>) => {
    const { formType, data, onSubmit, ...finalFormProps } = props;
    const [isLoading, setIsLoading] = useState<boolean>();

    const type = useMemo(() => {
        return formType || new FormType<T, TEntity>();
    }, [formType]);

    const internalProps = useMemo(() => {
        const formProps = type.buildForm(data);

        return formProps && { ...formProps };
    }, [data]);

    const onChangeDecorator = useCallback(
        (form: FormApi<T>) => {
            return form.subscribe(
                ({ values }) => {
                    type.onChange(values);
                    // form.change('typeDetails.unitOfMeasure.pimsGeneratedId');
                },
                { values: true },
            );
        },
        [type],
    );

    const decorators = useMemo(() => {
        return [onChangeDecorator, ...(finalFormProps.decorators || [])];
    }, [onChangeDecorator, finalFormProps.decorators]);

    const save = async (values: T, form: FormApi<T>, callback?: (errors?: SubmissionErrors) => void): Promise<SubmissionErrors | void> => {
        if (isLoading) {
            return;
        }

        setIsLoading(true);

        try {
            return await onSubmit(type.getEntity(values) as TEntity, form, callback);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {internalProps && decorators && (
                <div className={`${isLoading ? 'loading' : ''}`} style={{ height: '100%' }}>
                    <FinalForm {...internalProps} {...finalFormProps} decorators={decorators} onSubmit={save} validate={type?.validate.bind(type)} />
                </div>
            )}
        </>
    );
};
