import { toast } from 'react-toastify';
import * as React from 'react';
import { InvalidCredentialsException, ValidationException } from '@enterprise/common';
import { Container } from 'typedi';
import * as Hash from 'object-hash';
import { RootStore } from '../store/RootStore';
import { ValidationToast } from '../components/toasts/ValidationToast';

export type NotificationContent = React.ReactNode | (() => void);

interface NotificationOptions {
    /**
     * Called inside componentDidMount.
     */
    onOpen?: (...args: any[]) => void;

    /**
     * Called inside componentWillUnMount.
     */
    onClose?: (...args: any[]) => void;

    /**
     * Set a custom `toastId`
     */
    toastId?: number | string;
    autoClose?: number | false;
    /**
     * Set the default position to use.
     * `One of: 'top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left'`
     * `Default: 'top-right'`
     */
    position?: any;

    /**
     * Pass a custom close button.
     * To remove the close button pass `false`
     */
    closeButton?: React.ReactNode | false;
}

export class Notifications {
    private static instance?: Notifications;
    static getInstance() {
        if (!Notifications.instance) {
            Notifications.instance = new Notifications();
        }
        return Notifications.instance;
    }

    static success(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().success(content, options);
    }

    static info(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().info(content, options);
    }

    static warn(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().warn(content, options);
    }

    static error(content: NotificationContent, options?: NotificationOptions) {
        return Notifications.getInstance().error(content, options);
    }

    private static cleanMessage(message?: string): string | undefined {
        return message?.replace(/(\s\|\s\[.*?\])/g, '').replace(/(;\s?)/g, '. \n');
    }

    static fromException(error: any, options?: NotificationOptions) {
        const toastId = Hash.MD5(error.message || error);

        if (toast.isActive(toastId)) {
            return null;
        }
        if (error instanceof InvalidCredentialsException) {
            const message = this.cleanMessage(error.message);
            return Notifications.getInstance().warn(message, {
                toastId,
                onOpen: () => {
                    console.error('Client catch Unauthorized Exception');
                    const rootStore = Container.get(RootStore);
                    rootStore.ui.app.logout();
                },
            });
        }
        if (error instanceof ValidationException) {
            error.message = this.cleanMessage(error.message) as string;
            return Notifications.getInstance().error(<ValidationToast exception={error} />, { toastId, ...options });
        }

        const message = this.cleanMessage(error.message || error);
        return Notifications.getInstance().error(<pre className="notification-message">{message}</pre>, { toastId, ...options });
    }

    success(content: NotificationContent, options?: NotificationOptions) {
        return toast.success(content, options);
    }

    info(content: NotificationContent, options?: NotificationOptions) {
        return toast.info(content, options);
    }

    warn(content: NotificationContent, options?: NotificationOptions) {
        return toast.warn(content, options);
    }

    error(content: NotificationContent, options?: NotificationOptions) {
        return toast.error(content, options);
    }
}
