import React, { memo } from 'react';
import classNames from 'classnames';

type SpotVerticalRule = {
    className?: string;
};

export const SpotVerticalRule = memo(function SpotVerticalRuleComponent({ className }: SpotVerticalRule) {
    return <div className={classNames('spot-vertical-rule', className)} />;
});
