import React, { KeyboardEvent, useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { SpotSvg } from '@enterprise/spot';
import classNames from 'classnames';
import styles from './searchBar.module.scss';
import { SearchInput } from './searchInput';
import { GetItemQuery, InvoiceItem } from '../../core/models';

interface SearchbarProps {
    value?: string;
    onChange?: (value: string) => void;
    onSubmit?: (value: string) => void;
    disabled?: boolean;
    isSelfControlled?: boolean;
    secondary?: boolean;
    className?: string;
    searchItemsForAutoComplete?: (query: GetItemQuery) => Promise<InvoiceItem[]>;
}

enum SeatchType {
    CLASSIFICATION = 'classification',
    DESCRIPTION = 'description',
}

const AUTO_COMPLETE_SEARCH_SIZE = 20;
const CLASSIFICATION_TRIGGERS = ['class: ', 'class:'];

type Cancelable = { cancel: () => void };

export function SearchBar(props: SearchbarProps) {
    const { value = '', onChange, onSubmit, disabled, className } = props;
    const [autoCompleteItems, setAutoCompleteItems] = useState<any>([]);
    const [submitted, setSubmitted] = useState(false);
    const [requestInProgress, setRequestInProgress] = useState<Cancelable>();

    useEffect(() => {
        if (submitted) {
            setSubmitted(false);
            onSubmit?.(value);
        }
    }, [value, submitted]);

    const getAutoCompleteValues = (value: string) => {
        const state = { canceled: false };

        const timeout = setTimeout(async () => {
            if (!value) {
                setAutoCompleteItems([]);
                setRequestInProgress(undefined);
                return;
            }

            const classPrefix = CLASSIFICATION_TRIGGERS.find((trigger) => {
                if (value?.toLowerCase().includes(trigger)) {
                    return trigger;
                }
                return undefined;
            });

            const query: GetItemQuery = { searchValue: value, size: AUTO_COMPLETE_SEARCH_SIZE, unique: true };
            const classificationQuery: GetItemQuery = {
                searchValue: classPrefix ? `*${value.substring(classPrefix.length)}*` : value,
                searchType: SeatchType.CLASSIFICATION,
                size: AUTO_COMPLETE_SEARCH_SIZE,
                unique: true,
            };

            const items: InvoiceItem[] | undefined = !classPrefix ? await props.searchItemsForAutoComplete?.(query) : [];
            const classItems = await props.searchItemsForAutoComplete?.(classificationQuery);

            if (state.canceled) {
                return;
            }

            const filtered: InvoiceItem[] | undefined = items
                ?.filter((value, index, self) => {
                    return index === self.findIndex((t) => t.hospitalDescription === value.hospitalDescription);
                })
                .map((item) => {
                    return {
                        ...item,
                        displayable: { name: item.hospitalDescription, type: SeatchType.DESCRIPTION.toUpperCase() },
                    };
                });

            const classFiltered: InvoiceItem[] | undefined = classItems
                ?.filter((value, index, self) => {
                    return index === self.findIndex((t) => t.classification.name === value.classification.name);
                })
                .map((item) => {
                    return {
                        ...item,
                        displayable: { name: `Class: ${item.classification.name}`, type: SeatchType.CLASSIFICATION.toUpperCase() },
                    };
                });

            const combined = filtered?.concat(classFiltered || [])?.sort((a, b) => {
                return a?.displayable?.name && b?.displayable?.name ? a.displayable.name.localeCompare(b.displayable.name) : -1;
            });

            setAutoCompleteItems(combined);
            setRequestInProgress(undefined);
        }, 300);

        const cancellable = {
            cancel: () => {
                clearTimeout(timeout);
                state.canceled = true;
                setRequestInProgress(undefined);
            },
        };

        setRequestInProgress(cancellable);
    };

    const onChangeHandler = async (_: React.SyntheticEvent, value: string) => {
        requestInProgress?.cancel();
        getAutoCompleteValues(value);
        onChange?.(value);
    };

    const submitHandler = () => {
        requestInProgress?.cancel();
        setAutoCompleteItems([]);
        getAutoCompleteValues('');
        setSubmitted(true);
    };

    const keyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key == 'Enter') {
            submitHandler();
            const { value } = event.target as HTMLTextAreaElement;
            onChange?.(value);
        }
    };

    return (
        <div className={classNames('spot-form', 'spot-search-bar', styles.searchBar, className)}>
            <SearchInput
                data-automation-id="select-mui-searchbar-input"
                disabled={disabled}
                onChange={submitHandler}
                onInputChange={onChangeHandler}
                value={value}
                placeholder="Search…"
                onKeyDown={keyPress}
                filterOptions={() => {
                    return autoCompleteItems?.map((option) => option.displayable.name);
                }}
                options={autoCompleteItems?.map((option) => option.displayable.name)}
                loading={Boolean(requestInProgress)}
            />
            <Button
                data-automation-id="select-mui-search-button"
                classes={{ root: 'spot-search-bar__search-button' }}
                aria-label="search"
                onClick={submitHandler}
                disabled={disabled}
            >
                <SpotSvg className={'spot-icon'} icon="search" />
            </Button>
        </div>
    );
}
