// tslint:disable:max-classes-per-file
export class DuplicateEntityException extends Error {
    constructor(message) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, DuplicateEntityException.prototype);
    }
}

export class NotFoundException extends Error {
    constructor(message?: string, readonly data?: any) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NotFoundException.prototype);
    }
}

export class ValidationException extends Error {
    errors: { property: string; message: string; [prop: string]: any }[] = [];
    innerException: any;
    static fromHttpException(httpException: HttpException): ValidationException {
        const exception = new ValidationException(httpException.message);
        if (httpException.innerException && httpException.innerException.response.data) {
            exception.innerException = httpException.innerException.response.data.innerException;
            exception.errors = httpException.innerException.response.data.errors;
        } else {
            exception.innerException = httpException.innerException;
        }
        return exception;
    }

    constructor(message?: string, readonly data?: any) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ValidationException.prototype);
    }
}

export class NotSupportedException extends Error {
    constructor(message?: string) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, NotSupportedException.prototype);
    }
}

export class LogicException extends Error {
    constructor(message?: string) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, LogicException.prototype);
    }
}

export class InvalidConfigurationException extends Error {
    constructor(message?: string, readonly data?: any) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, InvalidConfigurationException.prototype);
    }
}

export class RuntimeException extends Error {
    constructor(message?: string, readonly data?: any) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, RuntimeException.prototype);
    }
}

export class InvalidCredentialsException extends Error {
    constructor(message?: string, readonly data?: any) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, InvalidCredentialsException.prototype);
    }
}

export class HttpException extends Error {
    constructor(public status: string, message: string, readonly innerException?: any) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, HttpException.prototype);
    }
}
