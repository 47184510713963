import { ProviderDetails } from '../../../datasource';
import { CornerstoneInvoiceItem } from './CornerstoneInvoiceItem';
import { DatasourceFullSiteInfo } from '../../../datasource/sites';

export class CornerstoneInvoiceItemWithVariantDetail extends CornerstoneInvoiceItem {
    diff: {
        [key: string]: Partial<CornerstoneInvoiceItem>;
    } = {};

    providers: ProviderDetails[];

    sites?: DatasourceFullSiteInfo[];

    variants: CornerstoneInvoiceItem[] = [];
}
