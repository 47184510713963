import { Inject, Service } from 'typedi';
import { BaseHttp } from '@enterprise/common';
import { ImportRowModel, ImportRowSummary } from '../core/models';
import { UploadCsvInfo } from '../core/interfaces/UploadCsvInfo';
import { ItemSheetRequestIndexes } from '../pages/controlCenter/import/ImportSelectHeaders';
import { ItemIndexType } from '../pages/controlCenter/import/ImportPage';

export class LookerDashboardFilters {
    [key: string]: string;
}

enum FormDataParams {
    CSVFILE = 'csvfile',
    SAP_INDEX_NUMBER = 'idexxSapIdIndexNumber',
    ITEM_INDEX_NUMBER = 'itemIndexNumber',
    ITEM_TYPE = 'itemType',
    COST_PRICE_INDEX_NUMBER = 'costPriceIndexNumber',
    LIST_PRICE_INDEX_NUMBER = 'listPriceIndexNumber',
    SALES_PRICEINDEX_NUMBER = 'salesPriceIndexNumber',
}

@Service()
export class ImportService {
    constructor(@Inject('http') private readonly http: BaseHttp) {}

    getItemDataForCSV(data: any): Promise<ImportRowModel[]> {
        return this.http.post(`/import/v1/item-sheet`, data);
    }

    downloadImportFile(fileName: string): Promise<Blob> {
        return this.http.get(`/v2/import/download?fileName=${fileName}`);
    }

    getItemDataForCSVV2(data: ItemSheetRequestIndexes, file: File): Promise<ImportRowSummary> {
        const formData = new FormData();
        formData.append(FormDataParams.CSVFILE, file);
        data?.idexxSapIdIndex && formData.append('idexxSapIdIndexNumber', data.idexxSapIdIndex);
        if (data?.itemIdIndex) {
            formData.append(FormDataParams.ITEM_INDEX_NUMBER, data.itemIdIndex);
            formData.append(FormDataParams.ITEM_TYPE, ItemIndexType.ID);
        } else if (data?.itemDescriptionIndex) {
            formData.append(FormDataParams.ITEM_INDEX_NUMBER, data.itemDescriptionIndex);
            formData.append(FormDataParams.ITEM_TYPE, ItemIndexType.DESCRIPTION);
        } else if (data?.itemSupplierCodeIndex) {
            formData.append(FormDataParams.ITEM_INDEX_NUMBER, data.itemSupplierCodeIndex);
            formData.append(FormDataParams.ITEM_TYPE, ItemIndexType.SUPPLIER_CODE);
        }
        data?.costPriceIndex && formData.append(FormDataParams.COST_PRICE_INDEX_NUMBER, data.costPriceIndex);
        data?.listPriceIndex && formData.append(FormDataParams.LIST_PRICE_INDEX_NUMBER, data.listPriceIndex);
        data?.salesPriceIndex && formData.append(FormDataParams.SALES_PRICEINDEX_NUMBER, data.salesPriceIndex);

        return this.http.post(`/v2/import/item-sheet`, formData);
    }

    getTaskDataForS3(data: UploadCsvInfo): Promise<void> {
        return this.http.post(`/import/v1/item-sheet/s3upload`, data);
    }

    getItemDataForDashboard(dashboardId: string, filters: LookerDashboardFilters): Promise<ImportRowModel[]> {
        return this.http.post(`/import/v1/sources/looker/dashboards/:id`, { filters }, { pathParams: { id: dashboardId } });
    }
}
