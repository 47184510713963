import { RedirectBroker } from '../router/RedirectBroker';
import { Service } from 'typedi';
import { makeAutoObservable } from 'mobx';
import queryString, { ParsedQuery } from 'query-string';
import { RedirectContext } from '../core/enums/RedirectContext';
import { NavigateFunction, Location } from 'react-router-dom';

@Service()
export class RouterStore {
    public location?: Location;

    public navigate?: NavigateFunction;

    // Handle update from history object
    constructor(private readonly redirectBroker: RedirectBroker) {
        makeAutoObservable(this);
    }

    get params(): ParsedQuery {
        return queryString.parse(this.location?.search || '') as ParsedQuery;
    }

    async redirectToTarget(context: RedirectContext): Promise<void> {
        const target = await this.redirectBroker.getRedirectTarget(context);
        this.push(target);
    }

    /*
     * History methods
     * https://github.com/alisd23/mobx-react-router/blob/master/src/store.js
     */
    push = (location: string, state?: unknown): void => {
        this.navigate?.(location, { state });
    };

    replace = (location: string, state?: unknown): void => {
        this.navigate?.(location, { state, replace: true });
    };

    go = (delta: number): void => {
        this.navigate?.(delta);
    };

    goBack = (): void => {
        this.navigate?.(-1);
    };

    goForward = (): void => {
        this.navigate?.(1);
    };
}
