import * as React from 'react';
import classNames from 'classnames';
import { SpotFieldError } from './SpotFieldError';

export interface SpotCheckboxGroupProps {
    error?: any;
    isHorizontal?: boolean;
}

export class SpotCheckboxGroup extends React.PureComponent<SpotCheckboxGroupProps & React.HTMLProps<HTMLFieldSetElement>> {
    render() {
        const { className, error, children, isHorizontal, ...rest } = this.props;
        return (
            <fieldset
                className={classNames('spot-form__checkbox-group', `spot-form__checkbox-group`, {
                    'spot-form__checkbox-group--horizontal': isHorizontal,
                })}
                role="group"
                {...rest}
            >
                <div className="spot-form__checkbox-group-inner">{children}</div>
                {error && <SpotFieldError meta={error} />}
            </fieldset>
        );
    }
}
