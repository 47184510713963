import React, { useEffect } from 'react';
import { Field, useField, useForm, useFormState } from '@enterprise/common';
import { SpotCheckbox, SpotCheckboxGroup, SpotRadio, SpotRadioGroup, SpotSelectBox, SpotSelectOption, SpotTextInput } from '@enterprise/spot';
import { InvoiceItemClassification, InvoiceItemInventory, InvoiceItemTypes, InvoiceItemWithVariantDetail } from '../../../../core/models';
import { LookupClassification, LookupSubClassification } from '../../../../core/models/datasource/lookups';
import { observer } from 'mobx-react';
import { useStores } from '../../../../hooks';

export const OverviewControls = observer(function OverviewControls() {
    const {
        domain: { itemsPageStore },
    } = useStores();

    const form = useForm();
    const { values } = useFormState<InvoiceItemWithVariantDetail>();
    const { classification, itemType, typeDetails } = values;
    const unitsOfMeasure = itemsPageStore.getLookupResults(itemsPageStore.unitsOfMeasure, values.provider?.siteId);
    const classifications: LookupClassification[] = itemsPageStore.getLookupResults(itemsPageStore.classifications, values.provider?.siteId);
    const classMatchedById = classifications.find(({ pimsGeneratedId }) => pimsGeneratedId && pimsGeneratedId === classification?.pimsGeneratedId);
    const classMatchedByDesc = classifications.find(({ description }) => description === classification?.description);
    const subClassifications = classMatchedById?.subClassifications || [];
    const classField = useField<InvoiceItemClassification>('classification');
    const subClassField = useField<LookupSubClassification>('subClassification');
    const isHazardousField = useField<boolean>('typeDetails.isHazardous');

    useEffect(() => {
        if (!classMatchedById && classMatchedByDesc) {
            form.change('classification', classMatchedByDesc);
        }
    }, [classMatchedById, classMatchedByDesc]);

    const getClassification = <T extends LookupClassification | LookupSubClassification>(
        classifications: T[] = [],
        pimsGeneratedId: string | null | undefined,
    ): T | undefined => {
        return classifications.find((classification) => classification.pimsGeneratedId === pimsGeneratedId);
    };

    const onClassificationChange = (pimsGeneratedId: string) => {
        if (!pimsGeneratedId) {
            classField.input.onChange({ pimsGeneratedId: null });
            return;
        }

        const { name, description, pimsId } = getClassification(classifications, pimsGeneratedId) || {};
        classField.input.onChange({ pimsGeneratedId, pimsId, name, description });
        onSubClassificationChange(null);
    };

    const onSubClassificationChange = (pimsGeneratedId: string | null) => {
        if (!pimsGeneratedId) {
            subClassField.input.onChange({ pimsGeneratedId: null });
            return;
        }

        const { name, description, pimsId } = getClassification(subClassifications, pimsGeneratedId) || {};
        subClassField.input.onChange({ pimsGeneratedId, pimsId, name, description });
    };

    const onIsHazardousChange = (value: boolean) => {
        isHazardousField.input.onChange(value);

        if (!value) {
            form.change('typeDetails.hasMsds', false);
        }
    };

    return (
        <div>
            <div className="spot-form__field-group spot-form__field-group--horizontal">
                <Field name="clientDescription">
                    {({ input, meta }) => (
                        <div>
                            <label className="spot-form__label">Client Description</label>
                            <SpotTextInput {...input} error={meta} placeholder="Client Description" />
                        </div>
                    )}
                </Field>
                <div>
                    <label className="spot-form__label">Primary Classification</label>
                    <SpotSelectBox
                        value={classField.input.value?.pimsGeneratedId}
                        onChange={(event) => onClassificationChange(event.target.value)}
                        error={{ ...classField.meta, error: classField.meta.error?.pimsGeneratedId }}
                        disabled={itemType?.id === InvoiceItemTypes.Dispensing}
                        data-automation-id="classification-select"
                    >
                        {classifications.map((classification) => (
                            <SpotSelectOption key={classification.pimsGeneratedId} value={classification.pimsGeneratedId}>
                                {classification.name}
                            </SpotSelectOption>
                        ))}
                    </SpotSelectBox>
                </div>
                <div>
                    <label className="spot-form__label">Subclass</label>
                    <SpotSelectBox
                        value={subClassField.input.value?.pimsGeneratedId}
                        onChange={(event) => onSubClassificationChange(event.target.value)}
                        error={{ ...classField.meta, error: subClassField.meta.error?.pimsGeneratedId }}
                        disabled={itemType?.id === InvoiceItemTypes.Dispensing || !subClassifications?.length}
                        data-automation-id="sub-classification-select"
                    >
                        {subClassifications?.map((subclass) => (
                            <SpotSelectOption key={subclass.pimsGeneratedId} value={subclass.pimsGeneratedId}>
                                {subclass.description}
                            </SpotSelectOption>
                        ))}
                    </SpotSelectBox>
                </div>
            </div>

            <div className="spot-form__field-group spot-form__field-group--horizontal">
                <Field name="typeDetails.unitOfMeasure.pimsGeneratedId" parse={(value) => value}>
                    {({ input, meta }) => (
                        <div>
                            <label className="spot-form__label">Unit Of Measure</label>
                            <SpotSelectBox {...input} disabled={itemType?.id !== InvoiceItemTypes.Inventory} error={meta}>
                                {unitsOfMeasure.map((unit) => (
                                    <SpotSelectOption key={unit.id} value={unit.id}>
                                        {unit.description}
                                    </SpotSelectOption>
                                ))}
                            </SpotSelectBox>
                        </div>
                    )}
                </Field>
                <Field name="barcode">
                    {({ input, meta }) => (
                        <div>
                            <label className="spot-form__label">Sell Barcode</label>
                            <SpotTextInput {...input} error={meta} placeholder="Sell Barcode" />
                        </div>
                    )}
                </Field>
            </div>

            <SpotCheckboxGroup>
                <Field name="typeDetails.isMiscellaneous" type="checkbox">
                    {({ input }) => (
                        <SpotCheckbox {...input} data-automation-id="is-miscellaneous-check-box">
                            Miscellaneous
                        </SpotCheckbox>
                    )}
                </Field>
                <Field name="typeDetails.isControlledSubstance" type="checkbox">
                    {({ input }) => (
                        <SpotCheckbox
                            {...input}
                            disabled={itemType?.id !== InvoiceItemTypes.Inventory}
                            data-automation-id="is-controlled-substance-checkbox"
                        >
                            Controlled substance
                        </SpotCheckbox>
                    )}
                </Field>
                <SpotCheckbox
                    checked={isHazardousField.input.value}
                    onChange={(event) => onIsHazardousChange(event.target.checked)}
                    disabled={itemType?.id !== InvoiceItemTypes.Inventory}
                    data-automation-id="is-hazardous-checkbox"
                >
                    Hazardous
                </SpotCheckbox>
                <Field name="typeDetails.hasMsds" type="checkbox">
                    {({ input }) => (
                        <SpotCheckbox
                            {...input}
                            disabled={itemType?.id !== InvoiceItemTypes.Inventory || !(typeDetails as InvoiceItemInventory)?.isHazardous}
                            data-automation-id="has-msds-checkboc"
                        >
                            MSDS
                        </SpotCheckbox>
                    )}
                </Field>
                <Field name="typeDetails.displayAsVaccine" type="checkbox">
                    {({ input }) => (
                        <SpotCheckbox {...input} data-automation-id="display-as-vaccine-checkbox">
                            Display On Vaccine Tab
                        </SpotCheckbox>
                    )}
                </Field>
            </SpotCheckboxGroup>

            <div className="spot-form__field-group spot-form__field-group--horizontal">
                <SpotRadioGroup>
                    <Field name="isActive" type="radio" value={true}>
                        {({ input }) => <SpotRadio {...input}>Active</SpotRadio>}
                    </Field>
                    <Field name="isActive" type="radio" value={false}>
                        {({ input }) => <SpotRadio {...input}>Inactive</SpotRadio>}
                    </Field>
                </SpotRadioGroup>
            </div>
        </div>
    );
});
