import React, { PropsWithChildren } from 'react';
import { EnterpriseUserToken } from '@enterprise/common';
import { Header } from '../header';
import styles from './flexLayout.module.scss';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useStores } from '../../../hooks';

export type FlexBoxLayoutProps = PropsWithChildren<{}>;

export const FlexLayout = observer(function FlexBoxLayout(props: FlexBoxLayoutProps) {
    const {
        ui: { app },
    } = useStores();
    const { children } = props;
    function logout(): void {
        app.logout();
    }

    const menuItems = app.getAvailableMenuItems();

    let orgName = '';
    if ((app.token as EnterpriseUserToken).user.organization) {
        orgName = (app.token as EnterpriseUserToken).user.organization.name;
    }
    return (
        <div className={classNames('main-layout', styles.mainLayout)} data-automaton-id={'main-layout'}>
            <div className={styles.header} data-automaton-id={'layout-header'}>
                <div className={styles.headerContainer} data-automaton-id={'header-container'}>
                    <Header
                        navigation={menuItems}
                        user={{
                            username: app.token.getDisplayName(),
                            orgName,
                            avatar: app.token?.user?.profile?.avatar,
                        }}
                        onLogout={logout}
                    />
                </div>
            </div>
            <div className={styles.main} data-automaton-id={'layout-main-content'}>
                {children}
            </div>
        </div>
    );
});
