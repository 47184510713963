import { Service } from 'typedi';
import { autorun, makeAutoObservable, reaction } from 'mobx';
import AppStore from './AppStore';
import ConstantsStore from './ConstantsStore';
import { BaseUserToken, IEnterpriseUser } from '@enterprise/common';
import { RouterStore } from './RouterStore';
import { AdvertisementStore } from './advertisement.store';
import { MyOrganizationPageStore } from '../pages/practiceConnections/MyOrganizationPageStore';
import { ActivityPageStore } from '../pages/controlCenter/activity/ActivityPageStore';
import { ItemsPageStore } from '../pages/controlCenter/items/ItemsPageStore';
import { AnalyticsPageStore } from '../pages/analytics/AnalyticsPageStore';
import { MasterDataPageStore } from '../pages/masterData/MasterDataPageStore';
import { ImportPageStore } from '../pages/controlCenter/import';
import { UsersStore } from './users.store';
import { InvoiceItemStore } from './invoiceItem.store';

export interface DomainStores {
    constantsStore: ConstantsStore;
    advertisementStore: AdvertisementStore;
    practicesStore: MyOrganizationPageStore;
    activityPageStore: ActivityPageStore;
    itemsPageStore: ItemsPageStore;
    analyticsPageStore: AnalyticsPageStore;
    masterDataPageStore: MasterDataPageStore;
    importPageStore: ImportPageStore;
    usersStore: UsersStore;
    invoiceItemStore: InvoiceItemStore;
}

export interface UiStores {
    router: RouterStore;
    app: AppStore;
}

@Service()
export class RootStore {
    domain: DomainStores;

    ui: UiStores;

    _isLoaded = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoaded(): boolean {
        return this._isLoaded && this.ui.app.isLoaded;
    }

    async bootstrap(): Promise<void> {
        await this.ui.app.bootstrap();

        this._isLoaded = true;
    }

    bootstrapStores(domainStores: DomainStores, uiStores: UiStores): void {
        this.domain = domainStores;
        this.ui = uiStores;

        autorun(() => {
            if (this.ui.app.title !== document.title) {
                document.title = this.ui?.app.title || document.title;
            }
        });

        reaction(() => {
            if (!this.isLoaded) {
                return;
            }

            return this.ui.app.token;
        }, this.onUserTokenChanged);
    }

    clean() {
        this._isLoaded = false;
        this.ui.app.clean();
    }

    onUserTokenChanged = (token?: BaseUserToken<IEnterpriseUser>) => {
        if (this.ui.app.isAuthenticated && Boolean(token?.user?.organization)) {
            void this.domain.constantsStore.load();
            this.domain.practicesStore.load();
        }
    };
}
