import * as React from 'react';
import { ChangeEvent } from 'react';
import { Trans } from 'react-i18next';
import { FieldCondition, Form, FormRenderProps, FormType } from '@enterprise/common';
import { Col, Row } from 'react-bootstrap';
import { SpotButton, SpotRadio, SpotRadioGroup } from '@enterprise/spot';
import { Field } from 'react-final-form';
import { MasterDataPageStore, SetupTypes } from '../MasterDataPageStore';
import { observer } from 'mobx-react';
import i18n from 'i18next';
import { ProTips } from '../../../components/helpers/ProTips';
import { MappingTypes } from '../../../core/enums/Mapping';
import { environment } from '../../../environment/environment';
import { StoresContext } from '../../../contexts';

interface PageProps {
    submit: (setupType) => void;
    page: MasterDataPageStore;
}

interface PageState {
    selectedFile?: File | null;
}

interface ClassificationSetup {
    setupType: string | undefined;
}

@observer
export class ClassificationsSetupPage extends React.Component<PageProps, PageState> {
    static contextType = StoresContext;

    context!: React.ContextType<typeof StoresContext>;

    get store(): MasterDataPageStore {
        return this.context.domain.masterDataPageStore;
    }

    static defaultProps = {
        page: {},
    };

    state: PageState = {
        selectedFile: undefined,
    };

    private data: ClassificationSetup = { setupType: '' };
    private form = new FormType<ClassificationSetup>();

    async componentDidMount() {
        await this.store.loadMappingFileStatus(MappingTypes.CLASSIFICATION);
    }

    componentWillUnmount() {
        this.store.stopListeningForMappingFileStatus();
    }

    submit = async ({ setupType }) => {
        this.data.setupType = setupType;

        if (setupType !== SetupTypes.UPLOAD_FILE) {
            this.props.submit(setupType);
            return;
        }

        if (this.state.selectedFile) {
            await this.store.deleteExistingMappingFileStatus();
            this.uploadMappingFile(this.state.selectedFile);
        }
    };

    uploadMappingFile = (file: File) => {
        this.store.uploadClassificationsMappingFile(file).then((wasFileUploadedSuccessfully) => {
            this.setState({ selectedFile: undefined });
            if (wasFileUploadedSuccessfully) {
                this.store.startListeningForMappingFileStatus();
            }
        });
    };

    render() {
        const { isUploadingMappingFile, isProcessingMappingFile, mappingFileProcessingError } = this.store;

        if (isUploadingMappingFile || isProcessingMappingFile) {
            return <ProTips text={i18n.t('masterData:classificationsPage.processingYourFile', 'Processing your file')} />;
        }

        return (
            <>
                <Row style={{ marginTop: '70px' }}>
                    <Col xs={1} />
                    <Col xs={11}>
                        <h1 className="classifications-header">
                            <Trans i18nKey="masterData:classificationsPage.manageYourData">Manage your data across databases and clinics</Trans>
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col xs={1} />
                    <Col xs={6}>
                        <div className="classifications-details">
                            <Trans i18nKey="masterData:classificationsPage.details">
                                Choose an option below to set-up your master data set. The master set represents the goal state for the data within
                                your practices.
                            </Trans>
                        </div>
                    </Col>
                    <Col xs={5} />
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <Col xs={1} />
                    <Col xs={11}>
                        <Form formType={this.form} data={this.data} onSubmit={this.submit}>
                            {({ handleSubmit }: FormRenderProps<ClassificationSetup>) => (
                                <>
                                    <SpotRadioGroup>
                                        <Field name="setupType" type="radio" value={SetupTypes.OUR_CLASSIFICATIONS}>
                                            {({ input }) => (
                                                <SpotRadio {...input} value={SetupTypes.OUR_CLASSIFICATIONS}>
                                                    <Trans i18nKey="masterData:classificationsPage.useOurMasterClassifications">
                                                        Use our master classifications
                                                    </Trans>
                                                </SpotRadio>
                                            )}
                                        </Field>
                                        <Field name="setupType" type="radio" value={SetupTypes.UPLOAD_FILE}>
                                            {({ input }) => (
                                                <>
                                                    <SpotRadio {...input} value={SetupTypes.UPLOAD_FILE}>
                                                        <Trans i18nKey="masterData:classificationsPage.uploadFile">Upload a file</Trans>
                                                        <a
                                                            href={environment.MAPPING.classificationTemplateFileUrl}
                                                            className="spot-link margin-left-2"
                                                        >
                                                            <Trans i18nKey="masterData:classificationsPage.downloadMasterDataTemplate">
                                                                (Download Master Data Template)
                                                            </Trans>
                                                        </a>
                                                    </SpotRadio>

                                                    <FieldCondition when="setupType" is={SetupTypes.UPLOAD_FILE}>
                                                        <input
                                                            accept={'.csv'}
                                                            onChange={this.onFileChange}
                                                            type="file"
                                                            className="spot-form__input mapping-file-input"
                                                        />

                                                        {mappingFileProcessingError && (
                                                            <span className="spot-form__field-error mapping-file-error">
                                                                <span className="spot-form__field-error-text">
                                                                    <Trans
                                                                        i18nKey="masterData:classificationsPage.processingFileFailed"
                                                                        values={{ error: mappingFileProcessingError }}
                                                                    />
                                                                </span>
                                                            </span>
                                                        )}
                                                    </FieldCondition>
                                                </>
                                            )}
                                        </Field>
                                        <Field name="setupType" type="radio" value={SetupTypes.EXISTING_DATABASE}>
                                            {({ input }) => (
                                                <SpotRadio {...input} value={SetupTypes.EXISTING_DATABASE}>
                                                    <Trans i18nKey="masterData:classificationsPage.useExistingDatabase">
                                                        Use an existing database
                                                    </Trans>
                                                </SpotRadio>
                                            )}
                                        </Field>
                                    </SpotRadioGroup>

                                    <SpotButton isPrimary={true} disabled={false} onClick={handleSubmit}>
                                        <Trans i18nKey="masterData:classificationsPage.setupMaster">Set up Master</Trans>
                                    </SpotButton>
                                </>
                            )}
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }

    private onFileChange = (event: ChangeEvent<HTMLInputElement>): void => {
        if (event.target?.files) {
            const file = event.target.files.item(0);
            this.setState({ selectedFile: file });
        }
    };
}
