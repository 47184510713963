import { RouterPaths } from './RouterPaths';
import i18n from '../i18n';
import { UserRoles } from '@enterprise/common';
import { RootStore } from '../store';
import { FEATURES } from '@enterprise/core';

export type PageNavigationItem = {
    to: string;
    title: string;
    automationId: string;
    roles?: UserRoles[];
    hidden?: (store: RootStore) => boolean;
};

export const managementNavigationSet: PageNavigationItem[] = [
    {
        to: RouterPaths.MyOrganizationPages.PracticeConnections,
        title: i18n.t('myOrganization:practiceConnection', 'Practice Connections'),
        automationId: 'practice-connections-submenu-item',
    },
    {
        to: RouterPaths.MyOrganizationPages.UserManagement,
        title: i18n.t('myOrganization:userManagement', 'User Management'),
        automationId: 'user-management-submenu-item',
        roles: [UserRoles.SuperAdmin, UserRoles.SystemOwner],
    },
];

export const controlCenterSet: PageNavigationItem[] = [
    {
        to: RouterPaths.ControlCenterPages?.Items,
        title: i18n.t('controlCenter:invoiceItems', 'Invoice Items'),
        automationId: 'invoice-items-submenu-item',
    },
    {
        to: RouterPaths.ControlCenterPages?.Import,
        title: i18n.t('controlCenter:import.tab', 'Import'),
        automationId: 'import-submenu-item',
        hidden: ({ ui }) => !ui.app.featureManager.isAvailable(FEATURES.IMPORT, true),
    },
    {
        to: RouterPaths.ControlCenterPages?.Activity,
        title: i18n.t('controlCenter:activity', 'Activity'),
        automationId: 'activity-submenu-item',
    },
];
