import * as React from 'react';

export enum SpotIconType {
    AddAUser = 'add-a-user',
    Add = 'add',
    AlertNotification = 'alert-notification',
    AnimalCanine = 'animal-canine',
    AnimalEquine = 'animal-equine',
    AnimalFeline = 'animal-feline',
    AnimalOther = 'animal-other',
    AppMenu = 'app-menu',
    Back = 'back',
    Bell = 'bell',
    Blocked = 'blocked',
    Calendar = 'calendar',
    Camera = 'camera',
    Cancel = 'cancel',
    CaretDown = 'caret-down',
    CaretUp = 'caret-up',
    Cart = 'cart',
    CheckboxIndeterminate = 'checkbox-indeterminate',
    Checkmark = 'checkmark',
    Close = 'close',
    Collapse = 'collapse',
    Communicate = 'communicate',
    Contribute = 'contribute',
    Copy = 'copy',
    Dashboard = 'dashboard',
    Delete = 'delete',
    Document = 'document',
    DoubleLeft = 'double-left',
    DoubleRight = 'double-right',
    Download = 'download',
    Ecg = 'ecg',
    Edit = 'edit',
    Email = 'email',
    Euro = 'euro',
    Expand = 'expand',
    Export = 'export',
    FavoriteFeatured = 'favorite-featured',
    FileExcel = 'file-excel',
    FileImage = 'file-image',
    FilePdf = 'file-pdf',
    FileTxt = 'file-txt',
    FileWord = 'file-word',
    FileZip = 'file-zip',
    Flag = 'flag',
    FolderHistory = 'folder-history',
    Fullscreen = 'fullscreen',
    Heart = 'heart',
    Help2 = 'help-2',
    Help = 'help',
    Hide = 'hide',
    Home = 'home',
    Image = 'image',
    InProgress = 'in-progress',
    Inbox = 'inbox',
    Info2 = 'info-2',
    Info = 'info',
    List = 'list',
    LockPrivate = 'lock-private',
    Menu = 'menu',
    Money = 'money',
    More = 'more',
    Next = 'next',
    OpenNewWindow = 'open-new-window',
    Paperclip = 'paperclip',
    Patient = 'patient',
    Pause = 'pause',
    PhoneContact = 'phone-contact',
    Pin2 = 'pin-2',
    Pin = 'pin',
    Play = 'play',
    Previous = 'previous',
    Price = 'price',
    Print = 'print',
    RecentlySearched = 'recently-searched',
    RefreshRedo = 'refresh-redo',
    Reports = 'reports',
    Request = 'request',
    Save = 'save',
    Search = 'search',
    Send = 'send',
    Settings = 'settings',
    Share = 'share',
    Show = 'show',
    Spinner = 'spinner',
    TestLab = 'test-lab',
    TimeClock = 'time-clock',
    Undo = 'undo',
    Upload = 'upload',
    UserInfo = 'user-info',
    Users = 'users',
    Video = 'video',
    Workstation = 'workstation',
    ZoomIn = 'zoom-in',
    ZoomOut = 'zoom-out',
}

// type should start to be valid after migration to typescript 4.1
// IconName should be a union type for SpotIconType enum values
// type IconName = `${SpotIconType}`;
type IconName = string;

interface SpotIconProps {
    type?: 'icon' | 'logos' | 'symbol';
    icon: IconName;
    title?: string;
    width?: string;
    height?: string;
    className?: string;
    [key: string]: any;
}

export class SpotSvg extends React.PureComponent<SpotIconProps> {
    static defaultProps = {
        type: 'icon',
    };

    render() {
        const { icon, title, type, ...rest } = this.props;
        let path = '';
        switch (type) {
            case 'icon':
                path = `/assets/spot/icons/spot_icons.svg#${icon}`;
                break;
            case 'symbol':
                path = `/assets/spot/symbols/spot_symbols.svg#${icon}`;
                break;
            default:
                path = `/assets/spot/${type}/${icon}`;
        }

        return (
            <svg {...rest}>
                {title && <title>{title}</title>}
                <use href={path} />
            </svg>
        );
    }
}
