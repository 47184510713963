import { Service } from 'typedi';
import { makeAutoObservable, toJS } from 'mobx';
import { OrganizationService } from '../services/OrganizationService';
import { RootStore } from './RootStore';
import { User } from '../core/models';
import { DetailedUser, UserAvatars, UserPolicy, UserPractices, UserService } from '@enterprise/common';
import { PermissionLevel } from '../core/enums/permissionLevel';

interface UpdateUser {
    id: string;
    displayName: string;
    permissionLevel?: PermissionLevel;
    avatar: string;
    userPractices?: UserPractices;
}

@Service()
export class UsersStore {
    isLoading = false;

    isLoadingUserDetails = false;
    isLoadingUserAccess = false;
    isLoadingAvatars = false;

    isSavingUserAccess = false;
    isSavingUser = false;

    private _users?: User[];

    get users(): User[] | undefined {
        return this._users && toJS(this._users);
    }

    private _user: DetailedUser;

    get user(): DetailedUser {
        return toJS(this._user);
    }

    private _userPolicy?: UserPolicy;

    get userPermissionLevel(): PermissionLevel {
        return this.getPermissionLevel(this._user, this._userPolicy);
    }

    get userDisplayName(): string {
        if (this._user?.profile?.displayName) {
            return this._user.profile.displayName;
        }
        if (this._user?.profile?.firstName && this._user?.profile?.lastName) {
            return `${this._user.profile.firstName} ${this._user.profile.lastName}`;
        }

        return this._user?.username;
    }

    private _userPracticesAccess?: UserPractices;

    get userPracticesAccess(): UserPractices | undefined {
        return toJS(this._userPracticesAccess);
    }

    private _userAvatars?: UserAvatars;

    get userAvatars(): UserAvatars | undefined {
        return toJS(this._userAvatars);
    }

    constructor(private readonly service: OrganizationService, private readonly rootStore: RootStore, private readonly userService: UserService) {
        makeAutoObservable(this);
    }

    async fetchUsers(): Promise<void> {
        this.isLoading = true;
        const { token } = this.rootStore.ui.app;
        const { organization } = token.user;

        this._users = (organization?.id && (await this.service.getUsers(organization.id))) || [];

        this.isLoading = false;
    }

    async setUserPolicy(siteAccessType: PermissionLevel | undefined, userId: string): Promise<void> {
        if (this.isLoading) {
            return;
        }

        try {
            this.isLoading = true;
            await this.userService.setUserPolicy(siteAccessType, userId);
        } finally {
            this.isLoading = false;
        }
    }

    async fetchUserAccess(userId: string): Promise<void> {
        this.isLoadingUserAccess = true;

        this._userPracticesAccess = await this.userService.getUserPracticeAccess(userId);

        this.isLoadingUserAccess = false;
    }

    async fetchUserAvatars(userId: string): Promise<any> {
        if (this.isLoadingAvatars) {
            return;
        }

        this.isLoadingAvatars = true;

        this._userAvatars = await this.userService.getUserAvatars(userId);

        this.isLoadingAvatars = false;
    }

    async updateUserAccess(userId: string, userPractices?: UserPractices): Promise<void> {
        if (this.isSavingUserAccess) {
            return;
        }

        try {
            this.isSavingUserAccess = true;
            const { practices, regions } = await this.userService.getUserPracticeAccess(userId);
            const userPracticesToDelete: UserPractices = {
                practices: practices?.filter((id) => !userPractices?.practices?.includes(id)),
                regions: regions?.filter((id) => !userPractices?.regions?.includes(id)),
            };
            await this.userService.removeUserPracticeAccess(userId, userPracticesToDelete);
            this._userPracticesAccess = await this.userService.addUserPracticeAccess(userId, userPractices);
        } finally {
            this.isSavingUserAccess = false;
        }
    }

    async fetchUserDetails(userId: string): Promise<DetailedUser> {
        try {
            this.isLoadingUserDetails = true;
            const detailedUser = await this.userService.getUserDetails(userId);
            this._user = detailedUser;

            await this.fetchUserPolicy(detailedUser?.policies[0]);

            return detailedUser;
        } finally {
            this.isLoadingUserDetails = false;
        }
    }

    private async fetchUserPolicy(policyId: string): Promise<void> {
        if (!policyId) {
            this._userPolicy = undefined;
            return;
        }

        const userPolicy = await this.userService.getUserPolicy(policyId);

        this._userPolicy = userPolicy && { name: userPolicy.name, id: userPolicy.id };
    }

    private getPermissionLevel(user: DetailedUser, userPolicy?: UserPolicy): PermissionLevel {
        if (this.rootStore.ui.app.isOwner(user?.id)) {
            return PermissionLevel.SystemOwner;
        }

        return (userPolicy?.name as PermissionLevel) || PermissionLevel.DecisionMaker;
    }

    public async updateUser(user: UpdateUser): Promise<void> {
        if (this.isSavingUser) {
            return;
        }

        try {
            this.isSavingUser = true;
            const { id, displayName, permissionLevel, avatar, userPractices } = user;
            const { app } = this.rootStore.ui;

            await app.setUserDisplayName(displayName, id);
            await this.setUserPolicy(permissionLevel, id);
            await app.setUserAvatar(avatar, id);
            await this.updateUserAccess(id, userPractices);
            await this.updateUserInStore({ ...user, userPractices: this._userPracticesAccess });
        } finally {
            this.isSavingUser = false;
        }
    }

    private async updateUserInStore(user: UpdateUser): Promise<void> {
        const { id, displayName } = user;
        if (!id) {
            return;
        }

        this._user = { ...this._user, profile: { ...this._user.profile, displayName } };

        const updatedUser = this._users?.find((item) => item.id === id);
        if (updatedUser) {
            await this.fetchUsers();
        }
    }
}
