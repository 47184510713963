import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FeatureOrComingSoon } from '../../components/helpers/FeatureOrComingSoon';
import { PageWrapper } from '../../layout/main/pageWrapper';
import { FEATURES } from '@enterprise/core';
import { DashboardsPage } from './dashboards/dashboardsPage';
import { DemoDashboardPage } from './dashboards/demoDashboardPage';
import { AnalyticsAdvertisementViewer } from './analyticsAdvertisementViewer';
import { SpotLoading } from '@enterprise/spot';
import { useStores } from '../../hooks';
import { Route } from 'react-router-dom';
import { RouterPaths } from '../../router/RouterPaths';
import { RootRoutes } from '../../router';

export const AnalyticsPage = observer(function AnalyticsPage() {
    const {
        domain: { analyticsPageStore },
        ui: { app },
    } = useStores();

    useEffect(() => {
        void analyticsPageStore.fetchLookerData();
    }, []);

    const [isPageLoading, setIsPageLoading] = useState(true);
    const pricingTier = app.token.user.organization?.pricingTier;
    const { isLookerDataLoading, connectedPractices } = analyticsPageStore;
    const hasLookerData = Boolean(connectedPractices?.length);

    useEffect(() => {
        if (isLookerDataLoading) {
            setIsPageLoading(false);
        }
    }, [isLookerDataLoading]);

    const showAnalytics = () => {
        return (
            <PageWrapper className="analytics-page">
                <Row>
                    <Col xs="12">
                        <div className="content">
                            <FeatureOrComingSoon name={FEATURES.ANALYTICS}>
                                <>
                                    {isLookerDataLoading && (
                                        <div className={'looker-spinner'}>
                                            <SpotLoading text="Loading..." />
                                        </div>
                                    )}

                                    {!isPageLoading && !isLookerDataLoading && (
                                        <div className={'analytics-feature-content'}>
                                            {hasLookerData && pricingTier && <AnalyticsAdvertisementViewer {...pricingTier} />}
                                            {hasLookerData ? <DashboardsPage /> : <DemoDashboardPage />}
                                        </div>
                                    )}
                                </>
                            </FeatureOrComingSoon>
                        </div>
                    </Col>
                </Row>
            </PageWrapper>
        );
    };

    return (
        <RootRoutes>
            <Route path={`${RouterPaths.AnalyticsPages.AnalyticsDashboardsPage}/:dashboardId`} element={showAnalytics()} />
            <Route path={`${RouterPaths.AnalyticsPages.AnalyticsLooksPage}/:lookId`} element={showAnalytics()} />
            <Route path={`${RouterPaths.AnalyticsPages.AnalyticsDashboardsPage}`} element={showAnalytics()} />
        </RootRoutes>
    );
});
