import styles from './usersGrid.module.scss';
import { useMemo } from 'react';
import { ScreenSizeType, useScreenSize } from '../../../hooks';

const headerSize = new Map<ScreenSizeType, string>([
    [ScreenSizeType.xs, styles.headerXs],
    [ScreenSizeType.sm, styles.headerSm],
    [ScreenSizeType.md, styles.headerMd],
    [ScreenSizeType.lg, styles.headerLg],
    [ScreenSizeType.xl, styles.headerXl],
]);

const rowSize = new Map<ScreenSizeType, string>([
    [ScreenSizeType.xs, styles.rowXs],
    [ScreenSizeType.sm, styles.rowSm],
    [ScreenSizeType.md, styles.rowMd],
    [ScreenSizeType.lg, styles.rowLg],
    [ScreenSizeType.xl, styles.rowXl],
]);

export interface UsersGridSize {
    headerHeight: number;
    rowHeight: number;
    isMobileView: boolean;
}

export const useUsersGridSize = (): UsersGridSize => {
    const { screenSize, isUpTo } = useScreenSize();
    return useMemo<UsersGridSize>(() => {
        return {
            headerHeight: Number(headerSize.get(screenSize)),
            rowHeight: Number(rowSize.get(screenSize)),
            isMobileView: isUpTo(ScreenSizeType.lg),
        };
    }, [screenSize]);
};
