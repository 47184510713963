import * as React from 'react';
import { ValidationException } from '@enterprise/common';

export const ValidationToast = ({ exception }) => (
    <div>
        <p>Validation Error</p>
        <ul>
            {(exception as ValidationException).errors.map((e, i) => (
                <li key={i}>
                    {' '}
                    {e.property}: {e.message}{' '}
                </li>
            ))}
        </ul>
    </div>
);
