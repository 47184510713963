import { Service } from 'typedi';
import { AnalyticsService, EmbedLinkParams } from '../../services/analytics.service';
import { makeAutoObservable, toJS } from 'mobx';
import { EmbedLink } from '../../core/models/analytics/EmbedLink';
import { FEATURES, Services } from '@enterprise/core';
import { LookerConnectedPractice } from '../../core/models';
import { head } from 'lodash';

export interface ActiveDashboardInfo {
    embedLink?: EmbedLink;
    practice?: LookerConnectedPractice;
    timeFrame?: Date;
}

@Service()
export class AnalyticsPageStore {
    isLookerDataLoading: boolean;
    isEmbedLinksLoading: boolean;
    isEnrolmentsLoading: boolean;

    private fetchEmbedLinksRequest?: Promise<EmbedLink[]>;
    private _activeDashboard?: ActiveDashboardInfo;
    private _connectedPractices?: LookerConnectedPractice[];
    private _availableDatePeriods?: Date[];
    private _embedLinks?: EmbedLink[];
    private _enrolments?: Record<string, unknown>[];

    get connectedPractices(): LookerConnectedPractice[] | undefined {
        return toJS(this._connectedPractices);
    }

    get availableDatePeriods(): Date[] | undefined {
        return toJS(this._availableDatePeriods);
    }

    get embedLinks(): EmbedLink[] | undefined {
        return toJS(this._embedLinks);
    }

    get activeDashboard(): ActiveDashboardInfo | undefined {
        return toJS(this._activeDashboard);
    }

    set activeDashboard(dash: ActiveDashboardInfo | undefined) {
        this._activeDashboard = dash;
    }

    get enrolments(): Record<string, unknown>[] | undefined {
        return toJS(this._enrolments);
    }

    constructor(private readonly analyticsService: AnalyticsService, private featureManager: Services.FeatureManager) {
        makeAutoObservable(this);
    }

    async fetchEmbedLinks(params: EmbedLinkParams = {}, setDafault = true): Promise<void> {
        this.isEmbedLinksLoading = true;

        try {
            const fetchEmbedLinksRequest = this.analyticsService.getEmbedLinks(params);
            this.fetchEmbedLinksRequest = fetchEmbedLinksRequest;
            const embedLinks = await fetchEmbedLinksRequest;

            if (this.fetchEmbedLinksRequest !== fetchEmbedLinksRequest) {
                return;
            }

            const selectedEmbedLink = embedLinks.find(({ name }) => name === this.activeDashboard?.embedLink?.name) || head(embedLinks);
            this._embedLinks = embedLinks;
            if (setDafault) {
                this._activeDashboard = {
                    ...(this.activeDashboard || {}),
                    embedLink: selectedEmbedLink,
                };
            }

            this.isEmbedLinksLoading = false;
        } catch {
            this.isEmbedLinksLoading = false;
        }
    }

    async fetchDeepLinkForDashboardId(dashboardId): Promise<void> {
        this.isEmbedLinksLoading = true;
        try {
            const fetchDeepLinkRequest = this.analyticsService.getDeepLinkDashboard(dashboardId);
            const deepLink = await fetchDeepLinkRequest;

            if (deepLink) {
                this._activeDashboard = {
                    ...(this.activeDashboard || {}),
                    embedLink: deepLink,
                };
            } else {
                this.isEmbedLinksLoading = false;
                this.fetchEmbedLinks();
            }
            this.isEmbedLinksLoading = false;
        } catch (e) {
            this.isEmbedLinksLoading = false;
        }
    }

    async fetchDeepLinkForLookId(lookId): Promise<void> {
        this.isEmbedLinksLoading = true;
        try {
            const fetchDeepLinkRequest = this.analyticsService.getDeepLinkLook(lookId);
            const deepLink = await fetchDeepLinkRequest;
            if (deepLink) {
                this._activeDashboard = {
                    ...(this.activeDashboard || {}),
                    embedLink: deepLink,
                };
            } else {
                this.isEmbedLinksLoading = false;
                this.fetchEmbedLinks();
            }
            this.isEmbedLinksLoading = false;
        } catch (e) {
            this.isEmbedLinksLoading = false;
        }
    }

    async fetchLookerData(): Promise<void> {
        this.isLookerDataLoading = true;

        try {
            const { connectedPractices, timeFrames } = await this.analyticsService.getLookerStatus();
            const selectedPractice = connectedPractices.find(({ name }) => name === this.activeDashboard?.practice?.name) || head(connectedPractices);
            const selectedTimeFrame = timeFrames?.find((item) => item === this.activeDashboard?.timeFrame) || head(timeFrames);

            this._connectedPractices = connectedPractices;
            this._availableDatePeriods = timeFrames;
            this._activeDashboard = {
                ...(this.activeDashboard || {}),
                practice: selectedPractice,
                timeFrame: selectedTimeFrame,
            };
        } finally {
            this.isLookerDataLoading = false;
        }
    }

    updateActiveDashboard = async (params: ActiveDashboardInfo): Promise<void> => {
        const { practice, timeFrame } = params;
        this._activeDashboard = toJS(params);
        await this.fetchEmbedLinks({ practiceName: practice?.name, timeFrame });
    };

    async fetchEnrollments(): Promise<void> {
        this.isEnrolmentsLoading = true;

        try {
            this._enrolments = await this.analyticsService.getEnrollments();
        } finally {
            this.isEnrolmentsLoading = false;
        }
    }

    get isImportFeatureEnabled(): boolean {
        return this.featureManager.isAvailable(FEATURES.IMPORT, true);
    }
}
