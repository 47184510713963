import React from 'react';
import { Autocomplete, AutocompleteProps, CircularProgress, TextField } from '@mui/material';
import styles from './searchInput.module.scss';
import classNames from 'classnames';

type SearchProps<T> = AutocompleteProps<T, boolean | undefined, boolean | undefined, boolean | undefined> & {
    ['data-automation-id']: string;
};

export function SearchInput<T>(props: Omit<SearchProps<T>, 'renderInput'>) {
    const { ['data-automation-id']: automationId, loading, ...inputProps } = props;

    return (
        <Autocomplete
            freeSolo
            disableClearable
            classes={{ ...inputProps.classes, root: classNames(styles.input, inputProps.classes?.root), input: styles.inputBase }}
            {...inputProps}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        placeholder={inputProps.placeholder}
                        inputProps={{ ...params.inputProps, ['data-automation-id']: automationId }}
                        InputProps={{
                            ...params.InputProps,
                            classes: {
                                root: classNames('spot-form__input spot-search-bar__input', styles.textField),
                                notchedOutline: styles.notchedOutline,
                            },
                            endAdornment: (
                                <React.Fragment>
                                    {loading && <CircularProgress size={20} />}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                    />
                );
            }}
        />
    );
}
