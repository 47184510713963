import AppStore from './AppStore';
import { environment } from '../environment/environment';
import { Feature } from '@enterprise/common';

interface FeaturesMap {
    [key: string]: () => Feature | undefined;
}

export class FeatureStore implements FeaturesMap {
    constructor(appStore: AppStore) {
        if (environment.features) {
            environment.features.forEach((name) => {
                this[name] = () => {
                    return appStore.token?.user?.FEATURES?.find((feature) => feature.name === name);
                };
            });
        }
    }

    [key: string]: () => Feature | undefined;
}
